import React from 'react'
import { history } from '../store'

class PDAList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: {
        code: '',
        customer: '',
      },
    };
  }

  goTo(id, e) {
    history.push(`/pdas/${id}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  render() {
    const { filters } = this.state;
    const { pdas } = this.props;

    // Apply the filters to filter the pdas array
    const filteredpdas = pdas.filter(pda => {
      const { code: codeFilter, customer: customerFilter } = filters;

      if (
        codeFilter &&
        (!pda.Code ||
          !pda.Code.toLowerCase().includes(codeFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        customerFilter &&
        (!pda.Customer.Fullname ||
          !pda.Customer.Fullname.toLowerCase().includes(customerFilter.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the customer in the filtered list
      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Código</th>
              <th scope="col">Cliente</th>
              <th scope="col">SN Dispositivo</th>
              <th scope="col">Ventas</th>
            </tr>
            <tr>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Code"
                  value={filters.code}
                  onChange={e => this.handleFilterChange('code', e.target.value)}
                />
              </th>
              <th scope="col">
                <input
                  type="text"
                  placeholder="Cliente"
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredpdas.map(pda => (
              <tr key={pda.Code}
                onClick={e => this.goTo(pda._id, e)}>
                <th scope="row">{pda.Code}</th>
                <td scope="row">{pda.Customer.Fullname}</td>
                <td scope="row">{pda.Token}</td>
                <td scope="row">{pda.LastSale}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default PDAList