import React from 'react'
import { history } from '../store'

class LowBluetoothByCustomerList extends React.Component {
  goTo(Serial, e) {
    history.push(`/machines/${Serial}`)
  }

  renderLowBluetoothList() {
    const { matrixs, machines } = this.props;

    // Create an object to store fault objects by customer name
    const lowbluetoothByCustomer = {};

    // Iterate over matrixs and machines to populate faultsByCustomer object
    matrixs.forEach((matrix) => {
      const serial = matrix.Serial;
      const machine = machines.find((machine) => machine.Serial === serial);

      if (machine) {
        const customer = machine.Customer;
        const customerName = customer && customer.Fullname ? customer.Fullname : '';
        const key = customerName;

        if (!lowbluetoothByCustomer[key]) {
          lowbluetoothByCustomer[key] = {
            customer: customerName,
            TOTAL_UNCONFIGURED: 0,
            TOTAL_FB: 0,
          };
        }

        matrix.Locker.forEach((lock) => {

          if (lock.Configured && lock.Status === 'FB') {
            lowbluetoothByCustomer[key].TOTAL_FB++;
          }else if (!lock.Configured) {
            lowbluetoothByCustomer[key].TOTAL_UNCONFIGURED++;
          }
        });
      }
    });

    // Convert faultsByCustomer object to an array of fault objects
    const lowbluetoothList = Object.values(lowbluetoothByCustomer);

    // Sort the faultList by customer name in alphabetical order
    lowbluetoothList.sort((a, b) => a.customer.localeCompare(b.customer));

    return lowbluetoothList.map((fault) => (
      <tr key={fault.customer}>
        <th>{fault.customer}</th>
        <td>{fault.TOTAL_FB}</td>
        <td>{fault.TOTAL_UNCONFIGURED}</td>
      </tr>
    ));
  }

  render() {
    if (!this.props.matrixs) {
      return null
    }

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Total Low Bluetooth</th>
              <th scope="col">Total Sin Configurar</th>
            </tr>
          </thead>
          <tbody>
            {this.renderLowBluetoothList()}
          </tbody>
        </table>
      </div>
    )
  }
}

export default LowBluetoothByCustomerList

