import React from 'react'
import { history } from '../store'

class CalendarsList extends React.Component {

  goTo(id, e) {
    history.push(`/calendars/${id}`)
  }

  render() {

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Fecha de creación</th>
            </tr>
          </thead>
          <tbody>
            {this.props.calendars.map(calendar => (
              <tr key={calendar._id} onClick={e => this.goTo(calendar._id, e)}>
                <td>{calendar.Name}</td>
                <td>{(new Date(calendar.TimeStamp)).toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</td>
              </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CalendarsList

