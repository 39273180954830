import React from 'react'
import { history } from '../store'

import MachineStatusBadge from './MachineStatusBadge'
import MachineCashBadge from './MachineCashBadge'

class MachineList extends React.Component {
  goTo(Serial, e) {
    history.push(`/machines/${Serial}`)
  }

  getTPVPresent(machine) {

    for (let i = 0; i < machine.PaymentDevices.length; i++) {
      const device = machine.PaymentDevices[i];

      if (device.Type == "TPV" && machine.Tpv_serial) {
        return true;
      }
    }
  }

  getDeviceModel(machine) {
    for (let i = 0; i < machine.PaymentDevices.length; i++) {
      const device = machine.PaymentDevices[i];
      if (device.Type === "TPV" && (device.Device === "PAYTEF" || device.Device === "SPIRE")) {
        return <span>SPu90</span>;
      }else if (device.Type === "TPV") {
        return <span>{device.Device}</span>;
      }else {
        return <span>NO DEVICE</span>;
      }
    }

    return <span>NO DEVICE</span>;
  }

  getPlatform(machine) {
    for (let i = 0; i < machine.PaymentDevices.length; i++) {
      const device = machine.PaymentDevices[i];
      if (device.Type === "TPV" && device.Device === "PAYTEF") {
        return <span>{device.Device}</span>;
      }else if (device.Type === "TPV" && device.Device === "Q5000") {
        return <span>WIPAY</span>;
      }else if (device.Type === "TPV") {
        return <span>REDSYS</span>;
      }
    }
  }

  getDeviceType(machine) {

    for (let i = 0; i < machine.PaymentDevices.length; i++) {
      const device = machine.PaymentDevices[i];

      if (device.Type == "TPV" && device.Device != "PAYTEF") {
        return true;
      }
    }
  }

  getMerchantCode(machine){
    for (let i = 0; i < machine.PaymentDevices.length; i++) {
      const device = machine.PaymentDevices[i];

      if (device.Type == "TPV" && device.Device === "PAYTEF") {
        return true;
      }
    }
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Máquina</th>
              <th scope="col">Cliente</th>
              <th scope="col">Modelo</th>
              <th scope="col">Plataforma</th>
              <th scope="col">SN</th>
              <th scope="col">Código de comercio</th>
              <th scope="col">TCOD</th>
              <th scope="col">Clave Firma</th>
              <th scope="col">Terminal</th>
            </tr>
          </thead>
          <tbody>
            {this.props.machines.map(machine => (
              <tr key={machine.Serial} className={this.getTPVPresent(machine) ? '' : 'bg-danger text-white'}>
                <th scope="row">{machine.Serial}</th>
                <td>
                  {(machine.Customer && machine.Customer.Fullname) || 'None'}
                </td>
                <td>
                  {this.getDeviceModel(machine)}
                </td>
                <td>
                  {this.getPlatform(machine)}
                </td>
                <td>
                  {machine.Tpv_serial}
                </td>
                {this.getDeviceType(machine) ? (
                    <td>
                      {machine.TpvBusinessCode}
                    </td>
                  ) : (<td>-</td>)
                }
                {this.getMerchantCode(machine) ? (
                    <td>
                      {machine.Tpv_tcod}
                    </td>
                  ) : (<td>-</td>)
                }
                {this.getDeviceType(machine) ? (
                    <td>
                      {machine.TpvFirmKeyCode}
                    </td>
                  ) : (<td>-</td>)
                }
                {this.getDeviceType(machine) ? (
                    <td>
                      {machine.TpvTerminalCode}
                    </td>
                  ) : (<td>-</td>)
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default MachineList
