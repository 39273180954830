import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { bindActionCreators } from 'redux';
import { SubmissionError } from 'redux-form';
import moment from 'moment';
import { fetchFaults } from '../actions/faults';
import { fetchCustomers } from '../actions/customers';
import { fetchMachines } from '../actions/machines';
import { renderInputField, renderSelectField } from '../utils/forms';

const validateDateRange = (values) => {
  const { StartDate, EndDate } = values;

  if (StartDate && EndDate) {
    const startDate = moment(StartDate);
    const endDate = moment(EndDate);

    if (endDate.diff(startDate, 'months') > 0) {
      return {
        EndDate: 'El rango máximo de consulta es de un mes.',
      };
    }
  }

  return {};
};

class FaultsReportFilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loaded: false };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { role, fetchMachines, fetchCustomers } = this.props;

    fetchMachines();

    fetchCustomers();

    this.load(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps);
  }

  load(props) {
    const { machines, customers, faults } = props;

    if (!this.state.loaded && machines && customers) {
      this.setState({ loaded: true });
    }
  }

  submit(props) {

    const { fetchFaults } = this.props;

    return fetchFaults(
      props.Machine,
      props.Customer,
      props.StartDate,
      props.EndDate,
    ).then((response) => {
      if (response.error) {
        throw new SubmissionError({
          _error: response.error.message,
          ...response.error.fields,
        });
      }

      return response;
    });
  }

  render() {
    const { loaded } = this.state;
    const {
      error,
      submitting,
      handleSubmit,
      machines,
      customers,
      role,
    } = this.props;

    if (!loaded || !machines || !customers) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        <div className="row">
          <div className="col">
            <Field
              name="Customer"
              component={renderSelectField}
              label="Cliente"
            >
              <option value="">Todos</option>
              {customers &&
                customers
                  .filter((customer) => customer.Type === 'GENERAL')
                  .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                  .map((customer) => (
                    <option value={customer._id} key={customer._id}>
                      {customer.Fullname}
                    </option>
                  ))}
            </Field>
          </div>
          <div className="col">
            <Field
              name="Machine"
              component={renderSelectField}
              label="Máquina"
            >
              <option value="">Todas</option>
              {machines.map((machine) => (
                <option value={machine._id} key={machine._id}>
                  {machine.Serial} - {machine.Customer?.Fullname || 'None'}
                </option>
              ))}
            </Field>
          </div>
          <div className="col">
            <Field
              name="StartDate"
              component={renderInputField}
              type="date"
              label="Fecha inicio"
            />
          </div>
          <div className="col">
            <Field
              name="EndDate"
              component={renderInputField}
              type="date"
              label="Fecha fin"
            />
            {error && <div className="text-danger">{error.EndDate}</div>}
          </div>
        </div>
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row">
          <button
            className="btn bg-vw-dark text-white mr-1 button-center"
            type="submit"
            disabled={submitting}
          >
            <i className="fas fa-sync mr-1"></i>Generar
          </button>
        </div>
      </form>
    );
  }
}

FaultsReportFilterForm = reduxForm({
  form: 'FaultsReportFilterForm'
})(FaultsReportFilterForm);

const selector = formValueSelector('FaultsReportFilterForm');

const mapStateToProps = (state) => ({
  machines: state.machines.machines,
  customers: state.customers.customers,
  faults: state.faults,
  role: state.auth.role,
  initialValues: {
    Machine: '',
    Customer: '',
    StartDate: new Date().toISOString().substring(0, 10),
    EndDate: new Date().toISOString().substring(0, 10)
  },
  formValues: {
    Machine: selector(state, 'Machine'),
    Customer: selector(state, 'Customer'),
    StartDate: selector(state, 'StartDate'),
    EndDate: selector(state, 'EndDate'),
  }
});

const mapDispatchToProps = (dispatch) => ({
  fetchFaults: bindActionCreators(fetchFaults, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FaultsReportFilterForm);