import React from 'react'

class Box extends React.Component {

  render() {
    let bgcolor = "white"
    if(this.props.bgcolor !== null && this.props.bgcolor !== undefined){
      bgcolor = this.props.bgcolor
    }

    return (
      <div className={`p-3 mb-3 bg-${bgcolor} rounded box-shadow`}>
        <h6 className={`box-border-bottom mb-2 border-gray pb-2 mb-0 text-${this.props.textcolor}`}>
          {this.props.icon &&
           <i className={`fas fa-${this.props.icon} float-right mr-1`}></i>
          }
          {this.props.title}
        </h6>
        {this.props.children}
      </div>
    )
  }
}

export default Box
