import { CALL_API } from '../middleware/api'

export const PROMOTERS_REQUEST = 'PROMOTERS_REQUEST'
export const PROMOTERS_SUCCESS = 'PROMOTERS_SUCCESS'
export const PROMOTERS_FAILURE = 'PROMOTERS_FAILURE'

export function fetchPromoters(customer, status) {
  let params = ''

  if (customer !== undefined) {
    params += `&customer=${customer}`
  }

  if (status !== undefined) {
    params += `&status=${status}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `promoters?${params}`,
      authenticated: true,
      types: [PROMOTERS_REQUEST, PROMOTERS_SUCCESS, PROMOTERS_FAILURE]
    }
  }
}

export const PROMOTER_REQUEST = 'PROMOTER_REQUEST'
export const PROMOTER_SUCCESS = 'PROMOTER_SUCCESS'
export const PROMOTER_FAILURE = 'PROMOTER_FAILURE'

export function fetchPromoter(number) {
  if (typeof number === 'string') {
    number = parseInt(number, 10)
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `promoters/${number}`,
      authenticated: true,
      types: [PROMOTER_REQUEST, PROMOTER_SUCCESS, PROMOTER_FAILURE]
    }
  }
}

export const PROMOTER_ADD_REQUEST = 'PROMOTER_ADD_REQUEST'
export const PROMOTER_ADD_SUCCESS = 'PROMOTER_ADD_SUCCESS'
export const PROMOTER_ADD_FAILURE = 'PROMOTER_ADD_FAILURE'

export function addPromoter(promoter) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'promoters',
      authenticated: true,
      body: JSON.stringify(promoter),
      types: [PROMOTER_ADD_REQUEST, PROMOTER_ADD_SUCCESS,
        PROMOTER_ADD_FAILURE]
    }
  }
}

export const PROMOTER_UPDATE_REQUEST = 'PROMOTER_UPDATE_REQUEST'
export const PROMOTER_UPDATE_SUCCESS = 'PROMOTER_UPDATE_SUCCESS'
export const PROMOTER_UPDATE_FAILURE = 'PROMOTER_UPDATE_FAILURE'

export function updatePromoter(number, props) {
  if (typeof number === 'string') {
    number = parseInt(number, 10)
  }

  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `promoters/${number}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [PROMOTER_UPDATE_REQUEST, PROMOTER_UPDATE_SUCCESS,
        PROMOTER_UPDATE_FAILURE]
    }
  }
}
