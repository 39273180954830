import React from 'react'
import { history } from '../store'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'
import { Field, FieldArray, reduxForm, SubmissionError, change } from 'redux-form'

class QRZonesList extends React.Component {

  render() {
    return (
      <Field name="Zone"
        component={renderSelectField}
        label="Zona">
        <option value="">Selecciona una zona...</option>
        <option value="0">General</option>
        {this.props.customers.map(customer => {
          if (customer._id === this.props.customerSelected) {
            return customer.Zones.map(zone => {
              return (
                <option value={zone.Zone} key={zone.Zone}>
                  {zone.Zone} / {zone.Description}
                </option>
              )
            })
          }
        })}
      </Field>
    )
  }
}

export default QRZonesList
