import {
  ARQUEOS_REQUEST, ARQUEOS_SUCCESS, ARQUEOS_FAILURE,
  ARQUEOS_SALES_REQUEST, ARQUEOS_SALES_SUCCESS, ARQUEOS_SALES_FAILURE
} from '../actions/arqueos'

const INITIAL_STATE = {
  arqueos: null, arqueo: null, arqueosNeedRefresh: false, error: null, loading: false
}

const arqueosReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ARQUEOS_REQUEST:
      return {
        ...state,
        arqueosNeedRefresh: false, error: null, loading: true
      }
    case ARQUEOS_SUCCESS:
      return {
        ...state,
        arqueosNeedRefresh: false, arqueos: action.response, error: null, loading: false
      }
    case ARQUEOS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case ARQUEOS_SALES_REQUEST:
      return {
        ...state,
        arqueosNeedRefresh: false, error: null, loading: true
      }
    case ARQUEOS_SALES_SUCCESS:
      return {
        ...state,
        arqueosNeedRefresh: false, arqueos: action.response, error: null, loading: false
      }
    case ARQUEOS_SALES_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default arqueosReducer

