import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchObjective } from '../actions/objectives'
import { fetchArqueos } from '../actions/arqueos'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ObjectiveList from '../components/ObjectiveList'
import ErrorMessage from '../components/ErrorMessage'

class ObjectiveDetailsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const year = searchParams.get('year');

    this.props.fetchObjective(this.props.match.params.customer, year)
      .then((answer) => {

        let arqueoObjective = answer.response
        const today = new Date(arqueoObjective.TimeStamp);
        const currentYear = today.getFullYear();
        const yearStart = new Date(currentYear, 0, 1);
        const formattedStartDate = `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
        const yearEnd = new Date(currentYear + 1, 0, 0);
        const formattedEndDate = `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

        this.props.fetchArqueos('', arqueoObjective.Customer._id, formattedStartDate, formattedEndDate, '');
      })
      .catch((error) => {
        console.error(error);
      });

  }

  calculateTotalObjective(objective) {

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const weeks = ['W1', 'W2', 'W3', 'W4', 'W5', 'W6'];

    let totalObjectives = 0;

    {
      months.map((month) => {
        weeks.map(week => (
          totalObjectives += objective[month][week]

        ))
      }
      )
    }

    return totalObjectives
  }

  calculateTotalReal() {

    let totalReal = 0;

    {
      this.props.arqueos.map((arqueo) => {
        totalReal += this.calculatePriceWithoutTax(arqueo.SalesTotal, arqueo.Tax)
      }
      )
    }

    return totalReal
  }

  progressBar(actual, objective) {

    const percentage = Math.min((actual / objective) * 100, 100);

    let color = "bg-warning"

    if (percentage < 50) {
      color = "bg-danger"
    } else if (percentage === 100) {
      color = "bg-success"
    }

    return (
      <div className="progress-container">
        <div className={`progress-bar ${color}`} style={{ width: `${percentage}%` }}></div>
        <div className="progress-percentage">{percentage.toFixed(2)}%</div>
      </div>
    );
  }

  calculateTotalMonthObjective(objective, month) {

    const weeks = ['W1', 'W2', 'W3', 'W4', 'W5', 'W6'];

    let totalObjectives = 0;

    {
      weeks.map(week => (
        totalObjectives += objective[month][week]

      ))
    }

    return totalObjectives
  }

  calculateTotalMonthReal(month) {

    const filteredArqueos = this.props.arqueos.filter(arqueo => new Date(arqueo.TimeStamp).getUTCMonth() === month);

    let totalReal = 0;

    filteredArqueos.forEach((arqueo) => {
      totalReal += this.calculatePriceWithoutTax(arqueo.SalesTotal, arqueo.Tax);
    });

    return totalReal;
  }

  getISOWeekNumber(date) {
    // Get the first day of the month
    var firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    
    // Adjust if the first day of the month is not Monday
    var adjustment = (firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1);

    // Calculate the number of days since the start of the month including adjustment
    var daysSinceMonthStart = date.getDate() + adjustment;
    
    // Calculate the week number
    var weekNumber = Math.ceil(daysSinceMonthStart / 7);

    // Ensure week number is at least 1
    weekNumber = Math.max(weekNumber, 1);

    return weekNumber;
  }

  calculateTotalWeekReal(month, week) {

    const filteredArqueos = this.props.arqueos.filter(arqueo => {
      const parts = arqueo.TimeStamp.split(/[-T:.Z]/);
      const arqueoDate = new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]));
      const arqueoWeekOfMonth = this.getISOWeekNumber(arqueoDate);
      const arqueoMonth = arqueoDate.getUTCMonth(); // Note: getUTCMonth() returns 0-11

      // Assuming 'month' is 0-indexed (0 for January, 1 for February, etc.)
      // and 'week' is the week number you want to filter by
      return arqueoMonth === month && arqueoWeekOfMonth === week;
    });

    let totalReal = 0;

    {
      filteredArqueos.map((arqueo) => {
        totalReal += this.calculatePriceWithoutTax(arqueo.SalesTotal, arqueo.Tax)
      }
      )
    }


    return totalReal
  }

  calculatePriceWithoutTax(priceWithTax, taxRate) {
    // Convert tax rate from percentage to decimal
    const taxMultiplier = 1 + taxRate / 100;

    // Calculate price without tax and round to two decimal places
    const priceWithoutTax = (priceWithTax / taxMultiplier).toFixed(2);

    // Convert the string back to a number
    return parseFloat(priceWithoutTax);
  }

  renderObjectives() {
    const { objectives, error, loading, arqueos } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!objectives || loading || !arqueos) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <ObjectiveList objectives={objectives} />
    )
  }

  render() {
    const { objective, error, loading, role, arqueos } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!objective || loading || !arqueos) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const weeks = ['W1', 'W2', 'W3', 'W4', 'W5', 'W6'];

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-dark">OBJETIVO </span>
              <span className="text-vw-light">{objective.Customer.Fullname} {(new Date(objective.TimeStamp)).getFullYear()}</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4 text-right">
            <Link to={`/objectives/${objective.Customer._id}/edit?year=${(new Date(objective.TimeStamp)).getFullYear()}`}>
              <button className="btn bg-vw-light text-white mr-1">
                <i className="fas fa-edit mr-1"></i>Editar
              </button>
            </Link>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col col-12 col-lg-12">
            <div className="table-responsive">
              <table className="table table-hover table-clickable">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Año</th>
                    <th scope="col">Real</th>
                    <th scope="col">Objetivo</th>
                    <th scope="col">Progreso</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{(new Date(objective.TimeStamp)).getFullYear()}</td>
                    <td>{this.calculateTotalReal().toFixed(2)}€</td>
                    <td>{this.calculateTotalObjective(objective).toFixed(2)}€</td>
                    <td>{this.progressBar(this.calculateTotalReal(), this.calculateTotalObjective(objective))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          {months.map((month, index) => (
            <div key={month} className="mb-5 col-xs-12 col-sm-6 col-md-6">
              <h5>{month}</h5>
              <div className="week-fields">
                <div className="table-responsive">
                  <table className="table table-hover table-clickable">
                    <thead className="thead-dark">
                      <tr>
                        <th>Total</th>
                        <th>{this.calculateTotalMonthReal(index).toFixed(2)}€</th>
                        <th>{this.calculateTotalMonthObjective(objective, month).toFixed(2)}€</th>
                        <th>{this.progressBar(this.calculateTotalMonthReal(index), this.calculateTotalMonthObjective(objective, month))}</th>
                      </tr>
                    </thead>
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">Semana</th>
                        <th scope="col">Real</th>
                        <th scope="col">Objetivo</th>
                        <th scope="col">Progreso</th>
                      </tr>
                    </thead>
                    <tbody>
                      {weeks.map((week, windex) => (
                        <tr key={`${month}.${week}`}>
                          <td>{week}</td>
                          <td>{this.calculateTotalWeekReal(index, windex + 1).toFixed(2)}€</td>
                          <td>{(objective[month][week]).toFixed(2)}€</td>
                          <td>{this.progressBar(this.calculateTotalWeekReal(index, windex + 1), objective[month][week])}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  objective: state.objectives.objective,
  arqueos: state.arqueos.arqueos,
  error: state.objectives.error,
  loading: state.arqueos.loading,
  role: state.auth.role,
})

const mapDispatchToProps = dispatch => ({
  fetchObjective: bindActionCreators(fetchObjective, dispatch),
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ObjectiveDetailsView)

