import {
  REPLACEMENTS_REQUEST, REPLACEMENTS_SUCCESS, REPLACEMENTS_FAILURE,
  REPLACEMENTS_UPDATE_REQUEST, REPLACEMENTS_UPDATE_SUCCESS, REPLACEMENTS_UPDATE_FAILURE,
  REPLACEMENT_REQUEST, REPLACEMENT_SUCCESS, REPLACEMENT_FAILURE
} from '../actions/replacements'

const INITIAL_STATE = {
  replacement:null, replacements: null, error: null, loading: false
}

const replacementsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REPLACEMENTS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case REPLACEMENTS_SUCCESS:
      return {
        ...state,
        replacements: action.response, error: null, loading: false
      }
    case REPLACEMENTS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case REPLACEMENTS_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case REPLACEMENTS_UPDATE_SUCCESS:
      return {
        ...state,
        replacements: action.response, error: null, loading: false
      }
    case REPLACEMENTS_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case REPLACEMENT_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case REPLACEMENT_SUCCESS:
      return {
        ...state,
        replacement: action.response, error: null, loading: false
      }
    case REPLACEMENT_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default replacementsReducer

