import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { fetchPromoter, updatePromoter } from '../actions/promoters'
import { fetchCustomers } from '../actions/customers'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

class PromoterEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { promoters, fetchPromoter, match, fetchCustomers } = this.props

    fetchCustomers()

    if (!promoters.promoter) {
      fetchPromoter(match.params.number)
    } else {
      this.setState({ loaded: true })
    }

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loaded && nextProps.promoters.promoter) {
      this.setState({ loaded: true })
    }
  }

  load(props) {
    const { customers } = props

    if (!this.state.loaded &&
        customers) {

      this.setState({
        loaded: true
      })
    }
  }

  submit(props) {
    const { updatePromoter, promoters } = this.props

    return updatePromoter(promoters.promoter.number, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { promoter, promoters, error, pristine, submitting, handleSubmit, customers } = this.props

    if (!this.state.loaded) {
      if (promoters.error) {
        return (
          <ErrorMessage message={promoters.error.message} />
        )
      }

      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">EDITAR </span>
          <span className="text-vw-light">PROMOTOR</span>
        </h1>
        <div className="row">
            <div className="col col-12 col-lg-4">
              <Box title="Personal" icon="user">
                <Field name="name"
                  component={renderInputField}
                  type="text"
                  label="Nombre" />
                <Field name="surname"
                  component={renderInputField}
                  type="text"
                  label="Apellidos" />
                <Field name="birthday"
                  component={renderInputField}
                  type="date"
                  label="Cumpleaños" />
                <Field name="mail"
                  component={renderInputField}
                  type="text"
                  label="E-mail" />
                <Field name="phone"
                  component={renderInputField}
                  type="text"
                  label="Teléfono" />
                <Field name="password"
                  component={renderInputField}
                  type="text"
                  label="Código / Password" />
              </Box>
            </div>
            <div className="col col-12 col-lg-5">
              <Box title="Horario" icon="user">
                <div className="row timetable">
                  <label> Lunes </label>
                  <Field name="monday_start1"
                    component={renderInputField}
                    type="text" />
                  <Field name="monday_end1"
                    component={renderInputField}
                    type="text" />
                  <h3>  :  </h3>
                  <Field name="monday_start2"
                    component={renderInputField}
                    type="text" />
                  <Field name="monday_end2"
                    component={renderInputField}
                    type="text" />
                </div>
                <div className="row timetable">
                  <label> Martes </label>
                  <Field name="tuesday_start1"
                    component={renderInputField}
                    type="text" />
                  <Field name="tuesday_end1"
                    component={renderInputField}
                    type="text" />
                  <h3>  :  </h3>
                  <Field name="tuesday_start2"
                    component={renderInputField}
                    type="text" />
                  <Field name="tuesday_end2"
                    component={renderInputField}
                    type="text" />
                </div>
                <div className="row timetable">
                  <label> Miércoles </label>
                  <Field name="wednesday_start1"
                    component={renderInputField}
                    type="text" />
                  <Field name="wednesday_end1"
                    component={renderInputField}
                    type="text" />
                  <h3>  :  </h3>
                  <Field name="wednesday_start2"
                    component={renderInputField}
                    type="text" />
                  <Field name="wednesday_end2"
                    component={renderInputField}
                    type="text" />
                </div>
                <div className="row timetable">
                  <label> Jueves </label>
                  <Field name="thursday_start1"
                    component={renderInputField}
                    type="text" />
                  <Field name="thursday_end1"
                    component={renderInputField}
                    type="text" />
                  <h3>  :  </h3>
                  <Field name="thursday_start2"
                    component={renderInputField}
                    type="text" />
                  <Field name="thursday_end2"
                    component={renderInputField}
                    type="text" />
                </div>
                <div className="row timetable">
                  <label> Viernes </label>
                  <Field name="friday_start1"
                    component={renderInputField}
                    type="text" />
                  <Field name="friday_end1"
                    component={renderInputField}
                    type="text" />
                  <h3>  :  </h3>
                  <Field name="friday_start2"
                    component={renderInputField}
                    type="text" />
                  <Field name="friday_end2"
                    component={renderInputField}
                    type="text" />
                </div>
                <div className="row timetable">
                  <label> Sábado </label>
                  <Field name="saturday_start1"
                    component={renderInputField}
                    type="text" />
                  <Field name="saturday_end1"
                    component={renderInputField}
                    type="text" />
                  <h3>  :  </h3>
                  <Field name="saturday_start2"
                    component={renderInputField}
                    type="text" />
                  <Field name="saturday_end2"
                    component={renderInputField}
                    type="text" />
                </div>
                <div className="row timetable">
                  <label> Domingo </label>
                  <Field name="sunday_start1"
                    component={renderInputField}
                    type="text" />
                  <Field name="sunday_end1"
                    component={renderInputField}
                    type="text" />
                  <h3>  :  </h3>
                  <Field name="sunday_start2"
                    component={renderInputField}
                    type="text" />
                  <Field name="sunday_end2"
                    component={renderInputField}
                    type="text" />
                </div>
              </Box>
            </div>
            <div className="col col-12 col-lg-3">
              <Box title="Comisiones del promotor" icon="hand-holding-usd">
                <Field name="Promoter_fees.VWPACK"
                  type="text"
                  component={renderInputField}
                  label="Pack" />
                <Field name="Promoter_fees.VWLAY"
                  type="text"
                  component={renderInputField}
                  label="Lay" />
                <Field name="Promoter_fees.VWGO"
                  type="text"
                  component={renderInputField}
                  label="Go" />
                <Field name="Promoter_fees.VWCHARGER"
                  type="text"
                  component={renderInputField}
                  label="Cargador" />
              </Box>
            </div>
          </div>
          { error && <div className="alert alert-danger">{error}</div> }
          <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                  disabled={pristine || submitting}>
            Guardar
          </button>
        </form>
    )
  }
}

PromoterEditForm = reduxForm({
  form: 'PromoterEditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/promoters/${result.response.number}`)
  }
})(PromoterEditForm)

const mapStateToProps = state => ({
  promoters: state.promoters,
  customers: state.customers.customers,
  initialValues: state.promoters.promoter
})

const mapDispatchToProps = dispatch => ({
  fetchPromoter: bindActionCreators(fetchPromoter, dispatch),
  updatePromoter: bindActionCreators(updatePromoter, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PromoterEditForm)


