import { CALL_API } from '../middleware/api'

export const OPERATORS_REQUEST = 'OPERATORS_REQUEST'
export const OPERATORS_SUCCESS = 'OPERATORS_SUCCESS'
export const OPERATORS_FAILURE = 'OPERATORS_FAILURE'

export function fetchOperators() {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `operators`,
      authenticated: true,
      types: [OPERATORS_REQUEST, OPERATORS_SUCCESS, OPERATORS_FAILURE]
    }
  }
}

export const OPERATOR_ADD_REQUEST = 'OPERATOR_ADD_REQUEST'
export const OPERATOR_ADD_SUCCESS = 'OPERATOR_ADD_SUCCESS'
export const OPERATOR_ADD_FAILURE = 'OPERATOR_ADD_FAILURE'

export function addOperator(operator) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'operators',
      authenticated: true,
      body: JSON.stringify(operator),
      types: [OPERATOR_ADD_REQUEST, OPERATOR_ADD_SUCCESS, OPERATOR_ADD_FAILURE]
    }
  }
}

export const OPERATOR_UPDATE_REQUEST = 'OPERATOR_UPDATE_REQUEST'
export const OPERATOR_UPDATE_SUCCESS = 'OPERATOR_UPDATE_SUCCESS'
export const OPERATOR_UPDATE_FAILURE = 'OPERATOR_UPDATE_FAILURE'

export function updateOperator(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `operators/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [OPERATOR_UPDATE_REQUEST, OPERATOR_UPDATE_SUCCESS,
        OPERATOR_UPDATE_FAILURE]
    }
  }
}

export const OPERATOR_REQUEST = 'OPERATOR_REQUEST'
export const OPERATOR_SUCCESS = 'OPERATOR_SUCCESS'
export const OPERATOR_FAILURE = 'OPERATOR_FAILURE'

export function fetchOperator(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `operators/${id}`,
      authenticated: true,
      types: [OPERATOR_REQUEST, OPERATOR_SUCCESS, OPERATOR_FAILURE]
    }
  }
}
