import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchAudits } from '../actions/audits'
import { fetchMachines } from '../actions/machines'
import { fetchCustomers } from '../actions/customers';

import { renderInputField, renderSelectField } from '../utils/forms'

class AuditFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchMachines, fetchCustomers } = this.props

    fetchMachines();
    fetchCustomers();

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { machines, customers } = props

    if (!this.state.loaded && machines && customers) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    return this.props.fetchAudits(props.Customer, props.Machine, props.Type, props.StartDate, props.EndDate)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, machines, customers } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        <div className="row">
          <div className="col">
            <Field
              name="Customer"
              component={renderSelectField}
              label="Cliente"
            >
              <option value="">Todos</option>
              {customers &&
                customers
                  .filter((customer) => customer.Type === 'GENERAL')
                  .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                  .map((customer) => (
                    <option value={customer._id} key={customer._id}>
                      {customer.Fullname}
                    </option>
                  ))}
            </Field>
          </div>
          <div className="col">
            <Field name="Machine"
              component={renderSelectField}
              label="Máquina">
              <option value="">Todas</option>
              {machines.map(machine => {
                return (
                  <option value={machine._id} key={machine._id}>
                    {machine.Serial}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className="col">
            <Field name="Type"
              component={renderSelectField}
              label="Tipo">
              <option value="">Todas</option>
              <option value="INFO">INFO</option>
              <option value="LOGIN">LOGIN</option>
              <option value="ERROR">ERROR</option>
              <option value="MONEY">MONEY</option>
              <option value="CHANGE">CHANGE</option>
              <option value="INFO_LOCKERS">INFO LOCKERS</option>
              <option value="ERROR_LOCKERS">ERROR LOCKERS</option>
            </Field>
          </div>
          <div className="col">
            <Field name="StartDate"
              component={renderInputField}
              type="date"
              label="Fecha inicio" />
          </div>
          <div className="col">
            <Field name="EndDate"
              component={renderInputField}
              type="date"
              label="Fecha fin" />
          </div>
        </div>
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row">
          <button className="btn bg-vw-dark text-white mr-1 button-center"
            type="submit"
            disabled={submitting}>
            <i className="fas fa-sync mr-1"></i>Actualizar
          </button>
        </div>
      </form>
    )
  }
}

AuditFilterForm = reduxForm({
  form: 'AuditFilterForm',
})(AuditFilterForm)

const mapStateToProps = state => ({
  machines: state.machines.machines,
  customers: state.customers.customers,
  initialValues: {
    machine: '',
    Customer: '',
    StartDate: new Date().toISOString().substring(0, 10),
    EndDate: new Date().toISOString().substring(0, 10)
  }
})

const mapDispatchToProps = dispatch => ({
  fetchAudits: bindActionCreators(fetchAudits, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditFilterForm)

