import { CALL_API } from '../middleware/api'

export const FAULTS_REQUEST = 'FAULTS_REQUEST'
export const FAULTS_SUCCESS = 'FAULTS_SUCCESS'
export const FAULTS_FAILURE = 'FAULTS_FAILURE'

export function fetchFaults(Machine, Customer, StartDate, EndDate) {
  let params = ''

  if (Machine !== undefined) {
    params += `&Machine=${Machine}`
  }

  if (Customer !== undefined) {
    params += `&Customer=${Customer}`
  }

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `faults?${params}`,
      authenticated: true,
      types: [FAULTS_REQUEST, FAULTS_SUCCESS, FAULTS_FAILURE]
    }
  }
}
