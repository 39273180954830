import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Box from '../components/Box'

import { fetchSupportTickets } from '../actions/supporttickets'

import SupportTicketOpenList from '../components/SupportTicketOpenList'
import SupportTicketClosedList from '../components/SupportTicketClosedList'
import ErrorMessage from '../components/ErrorMessage'
import SupportTicketsFilterForm from '../forms/SupportTicketsFilterForm'


class SupportTicketsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchSupportTickets('', '', '', '', '', '')
  }

  renderOpenSupportTickets() {
    const { supporttickets, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!supporttickets || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <SupportTicketOpenList supporttickets={supporttickets} />
    )
  }

  renderClosedSupportTickets() {
    const { supporttickets, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!supporttickets || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <SupportTicketClosedList supporttickets={supporttickets} />
    )
  }

  render() {
    const { role } = this.props

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">TICKETS</span>
            </h1>
          </div>
          {role === 'ADMIN' &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-end">
                <button className="btn btn-info ml-2">
                  <i className="fas fa-file-excel mr-1"></i>Descargar Listado
                </button>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <SupportTicketsFilterForm />
        </Box>
        <br></br>
        <br></br>
        <h1>
          <span className="text-vw-dark">TICKETS </span>
          <span className="text-vw-light">ABIERTOS</span>
          <hr className="bg-vw-light" />
        </h1>
        {this.renderOpenSupportTickets()}
        <br></br>
        <br></br>
        <h1>
          <span className="text-vw-dark">TICKETS </span>
          <span className="text-vw-light">CERRADOS</span>
          <hr className="bg-vw-light" />
        </h1>
        {this.renderClosedSupportTickets()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  supporttickets: state.supporttickets.supporttickets,
  error: state.supporttickets.error,
  role: state.auth.role,
  loading: state.supporttickets.loading,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchSupportTickets: bindActionCreators(fetchSupportTickets, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportTicketsView)
