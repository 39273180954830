import React from 'react';
import { history } from '../store';

class BluetoothList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixLockCounts: {}, // To store lock counts for each matrix
      matrixTotalLockCounts: {}
    };
  }

  componentDidMount() {
    this.calculateBluetoothFailsCounts();
  }

  getErrorCode(error) {

    switch (error) {
      case '0x00': return 'OK'
      case '0xFF': return 'INITIALIZED'
      case '0x01': return 'CONNECTED'
      case '0x02': return 'ESP32 PROCESS SUCC'
      case '0x03': return 'USER PIN ASSIGNED'
      case '0x04': return 'CONN FAULT'
      case '0x05': return 'SCAN TIMEOUT'
      case '0x06': return 'GEN ERROR 1'
      case '0x07': return 'ESP32 PROCESS UNSUCC'
      case '0x08': return 'GEN ERROR 2'
      case '0x09': return 'GEN ERROR 3'
      case '0x0A': return 'GEN ERROR 4'
      case '0x0B': return 'GEN ERROR 5'
      case '0x0C': return 'USER PIN UNASSIGNED'
      default: return error
    }
  }

  calculateBluetoothFailsCounts() {
    const matrixLockCounts = {};
    let matrixTotalLockCounts = {};

    let versionTotalCounts = {
      "total": 0,
      "blue": 0,
      "0x00": 0,
      "0xFF": 0,
      "0x01": 0,
      "0x02": 0,
      "0x03": 0,
      "0x04": 0,
      "0x05": 0,
      "0x06": 0,
      "0x07": 0,
      "0x08": 0,
      "0x09": 0,
      "0x0A": 0,
      "0x0B": 0,
      "0x0C": 0
    };

    this.props.matrixs.forEach(matrix => {
      const versionCounts = {
        "0x00": 0,
        "0xFF": 0,
        "0x01": 0,
        "0x02": 0,
        "0x03": 0,
        "0x04": 0,
        "0x05": 0,
        "0x06": 0,
        "0x07": 0,
        "0x08": 0,
        "0x09": 0,
        "0x0A": 0,
        "0x0B": 0,
        "0x0C": 0
      };

      matrix.Locker.forEach(lock => {
        const kerongVersion = lock.ErrorBle;
        versionCounts[kerongVersion] = (versionCounts[kerongVersion] || 0) + 1;
        versionTotalCounts[kerongVersion] = (versionTotalCounts[kerongVersion] || 0) + 1;
        versionTotalCounts["total"] = (versionTotalCounts["total"] || 0) + 1;
        if(lock.ErrorBle !== "0x03" ){
          versionTotalCounts["blue"] = (versionTotalCounts["blue"] || 0) + 1;
        }
      });

      matrixLockCounts[matrix.Serial] = versionCounts;

    });

    matrixTotalLockCounts = versionTotalCounts;

    this.setState({ matrixLockCounts, matrixTotalLockCounts });
  }

  goTo(Serial, e) {
    history.push(`/machines/${Serial}`);
  }

  render() {
    const { matrixLockCounts, matrixTotalLockCounts } = this.state;

    return (
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Total Escaneados</th>
              <th scope="col">OK</th>
              <th scope="col">INITIALIZED</th>
              <th scope="col">CONNECTED</th>
              <th scope="col">ESP32 PROCESS SUCC</th>
              <th scope="col">USER PIN ASSIGNED</th>
              <th scope="col">CONN FAULT</th>
              <th scope="col">SCAN TIMEOUT</th>
              <th scope="col">GEN ERROR 1</th>
              <th scope="col">ESP32 PROCESS UNSUCC</th>
              <th scope="col">GEN ERROR 2</th>
              <th scope="col">GEN ERROR 3</th>
              <th scope="col">GEN ERROR 4</th>
              <th scope="col">GEN ERROR 5</th>
              <th scope="col">USER PIN UNASSIGNED</th>
            </tr>
            <tr>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["blue"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x00"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0xFF"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x01"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x02"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x03"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x04"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x05"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x06"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x07"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x08"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x09"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x0A"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x0B"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
              <td>{matrixTotalLockCounts ? ((matrixTotalLockCounts["0x0C"]/matrixTotalLockCounts["total"])*100).toFixed(2) : 0}%</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{matrixTotalLockCounts ? `${matrixTotalLockCounts["blue"]} / ${matrixTotalLockCounts["total"]}` : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x00"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0xFF"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x01"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x02"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x03"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x04"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x05"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x06"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x07"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x08"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x09"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x0A"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x0B"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0x0C"] : 0}</td>
            </tr>
          </tbody>
        </table>
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Máquina</th>
              <th scope="col">OK</th>
              <th scope="col">INITIALIZED</th>
              <th scope="col">CONNECTED</th>
              <th scope="col">ESP32 PROCESS SUCC</th>
              <th scope="col">USER PIN ASSIGNED</th>
              <th scope="col">CONN FAULT</th>
              <th scope="col">SCAN TIMEOUT</th>
              <th scope="col">GEN ERROR 1</th>
              <th scope="col">ESP32 PROCESS UNSUCC</th>
              <th scope="col">GEN ERROR 2</th>
              <th scope="col">GEN ERROR 3</th>
              <th scope="col">GEN ERROR 4</th>
              <th scope="col">GEN ERROR 5</th>
              <th scope="col">USER PIN UNASSIGNED</th>
            </tr>
          </thead>
          <tbody>
            {this.props.matrixs.map(matrix => (
              <tr key={matrix.Serial} onClick={e => this.goTo(matrix.Serial, e)}>
                <td>{matrix.Serial}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x00"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0xFF"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x01"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x02"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x03"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x04"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x05"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x06"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x07"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x08"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x09"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x0A"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x0B"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0x0C"] : 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default BluetoothList;

