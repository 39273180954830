import React from 'react';
import { history } from '../store'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import Box from '../components/Box';
import { passwordRecoveryEmail } from '../actions/auth'
import PasswordRecoveryForm from '../forms/PasswordRecoveryForm';

class PasswordRecoveryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: null,
    };
  }

  goTo(e) {
    history.push(`/admin`)
  }

  submit = (props) => {
    return this.props.passwordRecoveryEmail(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        // Update state to show success message
        this.setState({
          successMessage: 'Hemos enviado un correo al email facilitado. Sigue los pasos para recuperar la contraseña.',
        });

        return response
      })
      .catch(error => {
        // Handle other errors if needed
        console.error('Password recovery error:', error);
      });
  };

  render() {
    const { successMessage } = this.state;

    return (
      <div className="h-100 bg-login">
        <div className="container h-100">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col col-lg-6">
              <div className="text-center mb-3">
                <img className="logo" src="/images/logo.png" alt="Logo" />
              </div>
              <Box title="Recuperación de contraseña" icon="key" bgcolor="white">
                {successMessage ? (
                  <div>
                    <div className="success-message mb-3">{successMessage}</div>
                    <button className="btn bg-vw-light text-white mr-1 mb-1" onClick={e => this.goTo(e)}>
                      Salir
                    </button>
                  </div>
                ) : (
                  <PasswordRecoveryForm onSubmit={this.submit} />
                )}
              </Box>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  passwordRecoveryEmail: bindActionCreators(passwordRecoveryEmail, dispatch)
})

export default connect(() => ({}), mapDispatchToProps)(PasswordRecoveryView);
