import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchPDAs } from '../actions/pdas'

import PDAList from '../components/PDAList'
import ErrorMessage from '../components/ErrorMessage'

class PDAsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }

  }

  componentDidMount() {
    this.props.fetchPDAs()
  }

  render() {
    const { role, type, pdas, error } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!pdas) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>

        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">PARQUE DE </span>
              <span className="text-vw-light">PDAs</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-end">
                <Link to="/pdas/add">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus mr-1"></i>Nueva
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <PDAList pdas={pdas} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  pdas: state.pdas.pdas,
  error: state.pdas.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchPDAs: bindActionCreators(fetchPDAs, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PDAsView)
