import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchWithdrawals } from '../actions/withdrawals'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import WithdrawalEntryList from '../components/WithdrawalEntryList'

import WithdrawalFilterForm from '../forms/WithdrawalFilterForm'

class WithdrawalView extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const now = new Date()
    const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().substring(0, 10)
    const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().substring(0, 10)
    this.props.fetchWithdrawals('', '', firstDayOfMonth, lastDayOfMonth)
  }

  renderWithdrawals() {
    const { withdrawals, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!withdrawals || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (<WithdrawalEntryList withdrawals={withdrawals} />)
  }

  renderBankWithdrawals() {
    const { withdrawals, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!withdrawals || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let withdrawalInBank = 0
    let withdrawalOutBank = 0

    withdrawals.forEach(withdrawal => {

      if (withdrawal.Bank && withdrawal.Balanced) {

        switch (withdrawal.Type) {
          case 0: {
            withdrawalInBank = withdrawalInBank + (withdrawal.Units050CoinCashReal * 0.50) + withdrawal.Units100CoinCashReal + (withdrawal.Units200CoinCashReal * 2)
            break
          }
          case 1: {
            withdrawalInBank = withdrawalInBank + (withdrawal.Units5BillCashReal * 5) + (withdrawal.Units10BillCashReal * 10) + (withdrawal.Units20BillCashReal * 20) + (withdrawal.Units50BillCashReal * 50)
            break
          }
          case 2: {
            withdrawalInBank = withdrawalInBank + (withdrawal.Units050CoinCashReal * 0.50) + withdrawal.Units100CoinCashReal + (withdrawal.Units200CoinCashReal * 2)
            break
          }
        }

      } else if (!withdrawal.Bank && withdrawal.Balanced) {
        switch (withdrawal.Type) {
          case 0: {
            withdrawalOutBank = withdrawalInBank + (withdrawal.Units050CoinCashReal * 0.50) + withdrawal.Units100CoinCashReal + (withdrawal.Units200CoinCashReal * 2)
            break
          }
          case 1: {
            withdrawalOutBank = withdrawalInBank + (withdrawal.Units5BillCashReal * 5) + (withdrawal.Units10BillCashReal * 10) + (withdrawal.Units20BillCashReal * 20) + (withdrawal.Units50BillCashReal * 50)
            break
          }
          case 2: {
            withdrawalOutBank = withdrawalInBank + (withdrawal.Units050CoinCashReal * 0.50) + withdrawal.Units100CoinCashReal + (withdrawal.Units200CoinCashReal * 2)
            break
          }
        }
      }

    });

    return (
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Ingresado</th>
              <th scope="col">Pendiente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{withdrawalInBank} €</td>
              <td>{withdrawalOutBank} €</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderBalanceWithdrawals() {
    const { withdrawals, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!withdrawals || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let withdrawalTotalBalance = 0

    withdrawals.forEach(withdrawal => {

      if (withdrawal.Balanced) {

        withdrawalTotalBalance = withdrawalTotalBalance + withdrawal.Balance

      }

    });

    return (
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Balance General</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{withdrawalTotalBalance} €</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  render() {

    const { role } = this.props

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">RETIROS DE </span>
              <span className="text-vw-light">EFECTIVO</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <WithdrawalFilterForm />
        </Box>
        {role === 'ADMIN' &&
          <div className="row mb-2">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <Box title="Ingresos" icon="filter">
                {this.renderBankWithdrawals()}
              </Box>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6">
              <Box title="Total Balance" icon="filter">
                {this.renderBalanceWithdrawals()}
              </Box>
            </div>
          </div>
        }
        <Box title="Retiros de caja">
          {this.renderWithdrawals()}
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  withdrawals: state.withdrawals.withdrawals,
  error: state.withdrawals.error,
  loading: state.withdrawals.loading,
  form: state.form,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchWithdrawals: bindActionCreators(fetchWithdrawals, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalView)


