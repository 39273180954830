import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchPDA } from '../actions/pdas'

class PDADetailsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
    };

  }

  componentDidMount() {
    this.props.fetchPDA(this.props.match.params.id)
  }

  render() {
    const { role, type, pda, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!pda || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-8 col-md-8">
            <h1>
              <span className="text-vw-light">PDA </span>
              <span className="text-vw-dark">{pda.Code}</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-4 col-md-4 text-right">
              <Link to={`/pdas/${pda._id}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <div className="row mb-2">
          <div className="col">
            <Box title="Resumen" icon="sliders-h">
              <div className="row">
                <div className="col col-12 col-xs-12 col-md-4">
                  <PropertyLabel name="Código"
                    value={pda.Code} />
                  <PropertyLabel name="Cliente"
                    value={pda.Customer.Fullname} />
                  <PropertyLabel name="SN Dispositivo"
                    value={pda.Token} />
                </div>
              </div>
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  pda: state.pdas.pda,
  loading: state.pdas.loading,
  error: state.pdas.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchPDA: bindActionCreators(fetchPDA, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PDADetailsView)
