import { CALL_API } from '../middleware/api'

export const MODULES_REQUEST = 'MODULES_REQUEST'
export const MODULES_SUCCESS = 'MODULES_SUCCESS'
export const MODULES_FAILURE = 'MODULES_FAILURE'

export function fetchModules() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'modules',
      authenticated: true,
      types: [MODULES_REQUEST, MODULES_SUCCESS, MODULES_FAILURE]
    }
  }
}
export const MODULE_REQUEST = 'MODULE_REQUEST'
export const MODULE_SUCCESS = 'MODULE_SUCCESS'
export const MODULE_FAILURE = 'MODULE_FAILURE'

export function fetchModule(code) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `modules/${code}`,
      authenticated: true,
      types: [MODULE_REQUEST, MODULE_SUCCESS, MODULE_FAILURE]
    }
  }
}

export const MODULE_ADD_REQUEST = 'MODULE_ADD_REQUEST'
export const MODULE_ADD_SUCCESS = 'MODULE_ADD_SUCCESS'
export const MODULE_ADD_FAILURE = 'MODULE_ADD_FAILURE'

export function addModule(module) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'modules',
      authenticated: true,
      body: JSON.stringify(module),
      types: [MODULE_ADD_REQUEST, MODULE_ADD_SUCCESS, MODULE_ADD_FAILURE]
    }
  }
}

export const MODULE_UPDATE_REQUEST = 'MODULE_UPDATE_REQUEST'
export const MODULE_UPDATE_SUCCESS = 'MODULE_UPDATE_SUCCESS'
export const MODULE_UPDATE_FAILURE = 'MODULE_UPDATE_FAILURE'

export function updateModule(code, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `modules/${code}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [MODULE_UPDATE_REQUEST, MODULE_UPDATE_SUCCESS,
        MODULE_UPDATE_FAILURE]
    }
  }
}

export const MODULE_RESET = 'MODULE_RESET'

export function resetModule() {
  return { type: MODULE_RESET }
}

export const MODULE_DOWNLOAD_REQUEST = 'MODULE_DOWNLOAD_REQUEST'
export const MODULE_DOWNLOAD_SUCCESS = 'MODULE_DOWNLOAD_SUCCESS'
export const MODULE_DOWNLOAD_FAILURE = 'MODULE_DOWNLOAD_FAILURE'

export function fetchModuleDownloadCSV() {
  let params = ''
  
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `modules/csv?${params}`,
      authenticated: true,
      types: [MODULE_DOWNLOAD_REQUEST, MODULE_DOWNLOAD_SUCCESS, MODULE_DOWNLOAD_FAILURE]
    }
  }
}

