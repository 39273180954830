import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Line, Doughnut, Bar, Radar } from 'react-chartjs-2'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchArqueos } from '../actions/arqueos'
import ArqueosFilterForm from '../forms/ArqueosFilterForm'


class ArqueosView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showForm: false,
    }

  }

  componentDidMount() {
    const today = new Date();
    const currentYear = today.getFullYear();
    const yearStart = new Date(currentYear, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(currentYear + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    this.props.fetchArqueos('', '', formattedStartDate, formattedEndDate, '');
  }

  getItemBackgroundColor(item) {
    switch (item) {
      case 'LOCKERBNS':
        return '#001F3F';
      case 'LOCKERCNS':
        return '#0074D9';
      case 'LOCKERBWS':
        return '#7FDBFF';
      case 'LOCKERCWS':
        return '#39CCCC';
      case 'LOCKERBNM':
        return '#3D9970';
      case 'LOCKERCNM':
        return '#2ECC40';
      case 'LOCKERBWM':
        return '#01FF70';
      case 'LOCKERCWM':
        return '#FFDC00';
      case 'LOCKERBNL':
        return '#FF851B';
      case 'LOCKERCNL':
        return '#FF4136';
      case 'LOCKERBWL':
        return '#85144B';
      case 'LOCKERCWL':
        return '#F012BE';
      case 'LOCKERBNXL':
        return '#B10DC9';
      case 'LOCKERCNXL':
        return '#111111';
      case 'LOCKERBWXL':
        return '#AAAAAA';
      case 'LOCKERCWXL':
        return '#DDDDDD';
      case 'LOCKERBN2XL':
        return '#FF7F50';
      case 'LOCKERCN2XL':
        return '#FFD700';
      case 'LOCKERBW2XL':
        return '#FFA500';
      case 'LOCKERCW2XL':
        return '#FF8C00';
      case 'LOCKERBN3XL':
        return '#FF4500';
      case 'LOCKERCN3XL':
        return '#FF6347';
      case 'LOCKERBW3XL':
        return '#FF8C69';
      case 'LOCKERCW3XL':
        return '#CD5C5C';
      case 'TUMBONAS':
        return '#8B0000';
      case 'FLOTADORES':
        return '#FF1493';
      case 'FLOTADORESHALFDAY':
        return '#8B4513';
      case 'FASTPASS':
        return '#228B22';
      default:
        return '#000000'; // Default color if name doesn't match any case
    }
  }

  generateRandomColor(year) {

    let color = ''

    switch (year) {
      case '2022':
        color = 'rgba(255, 0, 0, 1)';
        break;
      case '2023':
        color = 'rgba(75, 192, 192, 1)';
        break;
      case '2024':
        color = 'rgba(255, 191, 0, 1)';
        break;
      default:
        color = 'rgba(0, 0, 0, 0)';
        break;
    }

    return color

  };

  generateRandomColorBackground(year) {

    let color = ''

    switch (year) {
      case '2022':
        color = 'rgba(255, 0, 0, 0.2)';
        break;
      case '2023':
        color = 'rgba(75, 192, 192, 0.2)';
        break;
      case '2024':
        color = 'rgba(255, 191, 0, 0.2)';
        break;
      default:
        color = 'rgba(75, 192, 192, 0.2)';
        break;

    }

    return color
  };

  renderAmountDayChart() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const dataByYear = {};

    arqueos.forEach((arqueo) => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const dayOfMonth = `${month}-${day}`;

      dataByYear[year] = dataByYear[year] || {};
      dataByYear[year][dayOfMonth] = dataByYear[year][dayOfMonth] || 0;
      dataByYear[year][dayOfMonth] += Number(arqueo.SalesTotal);
    });

    const datasets = [];

    Object.keys(dataByYear).forEach((year) => {
      const yearData = dataByYear[year];

      const data = Array.from({ length: 12 * 31 }, (_, index) => {
        const month = Math.floor(index / 31) + 1;
        const day = (index % 31) + 1;

        // Check if the month and day combination is valid
        if (
          (month === 2 && day > 28) || // February
          (([4, 6, 9, 11].includes(month) && day > 30) || // April, June, September, November
            (day > 31)) // All other months
        ) {
          return null; // Invalid date, return null
        } else {
          const dayOfMonth = `${month}-${day}`;
          return yearData[dayOfMonth] || 0;
        }
      });

      datasets.push({
        label: `Ventas ${year}`,
        data: data,
        fill: true,
        backgroundColor: this.generateRandomColorBackground(year),
        borderColor: this.generateRandomColor(year),
        borderWidth: 2,
        order: 0,
      });
    });

    const chartData = {
      labels: Array.from({ length: 12 * 31 }, (_, index) => {
        const month = Math.floor(index / 31) + 1;
        const day = (index % 31) + 1;

        if (
          (month === 2 && day > 28) || // February
          (([4, 6, 9, 11].includes(month) && day > 30) || // April, June, September, November
            (day > 31)) // All other months
        ) {
          return ''; // Invalid date, return empty string
        } else {
          return `${month}-${day}`;
        }
      }),
      datasets: datasets,
    };

    const chartOptions = {
      scales: {
        x: {
          type: 'category', // Use category scale for day labels
          labels: {
            autoSkip: false,
            maxRotation: 0,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
      maintainAspectRatio: false,
    };

    return <Line data={chartData} options={chartOptions} height={200} />;
  }

  getMonthDays(month) {
    switch (month) {
      case 1: return 31
      case 2: return 28
      case 3: return 31
      case 4: return 30
      case 5: return 31
      case 6: return 30
      case 7: return 31
      case 8: return 31
      case 9: return 30
      case 10: return 31
      case 11: return 30
      case 12: return 31
    }
  }

  renderAmountMonthChart() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const dataByYear = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();

      dataByYear[year] = dataByYear[year] || {};
      dataByYear[year][month] = dataByYear[year][month] || 0;
      dataByYear[year][month] += Number(arqueo.SalesTotal);

    });

    const datasets = [];

    Object.keys(dataByYear).forEach(year => {
      const yearData = dataByYear[year];

      const data = Array.from({ length: 12 }, (_, index) => {
        const month = index + 1;
        return (yearData[month] && yearData[month]) || 0;
      });

      datasets.push({
        label: `Total Mensual ${year}`,
        data: data,
        fill: true,
        backgroundColor: this.generateRandomColorBackground(year),
        borderColor: this.generateRandomColor(year),
        borderWidth: 2,
        order: 0
      });
    });

    const chartData = {
      labels: Array.from({ length: 12 }, (_, index) => {
        const month = index;
        const monthLabel = new Date(0, month).toLocaleString('default', { month: 'short' }); // Get month abbreviation
        return monthLabel;
      }),
      datasets: datasets,
    };

    const chartOptions = {
      scales: {
        x: {
          type: 'category', // Use category scale for day labels
          labels: {
            autoSkip: false,
            maxRotation: 0,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
      maintainAspectRatio: true
    };

    return <Bar data={chartData} options={chartOptions} height={200} />;
  }

  renderAmountBiweeklyChart() {
    const { arqueos, error } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || arqueos.length === 0) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const dataByMonth = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const month = date.getMonth(); // Get the month (0-based index)
      const day = date.getDate();

      const isFirstHalf = day <= 15;

      const period = isFirstHalf ? 'First Half' : 'Last Half';

      dataByMonth[`${year}-${month + 1}`] = dataByMonth[`${year}-${month + 1}`] || { 'First Half': 0, 'Last Half': 0 };
      dataByMonth[`${year}-${month + 1}`][period] += Number(arqueo.SalesTotal);
    });

    const datasets = [
      {
        label: 'First Half',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
      },
      {
        label: 'Last Half',
        data: [],
        backgroundColor: 'rgba(255, 168, 0, 0.2)',
        borderColor: 'rgba(255, 168, 0)',
        borderWidth: 2,
      },
    ];

    // Fill dataset data array
    Object.keys(dataByMonth).forEach(key => {
      const [year, month] = key.split('-');
      datasets[0].data.push(dataByMonth[key]['First Half']);
      datasets[1].data.push(dataByMonth[key]['Last Half']);
    });

    const chartData = {
      labels: Object.keys(dataByMonth),
      datasets: datasets,
    };

    const chartOptions = {
      scales: {
        x: {
          title: {
            display: true,
            text: 'Month',
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Total Sales',
          },
        },
      },
      maintainAspectRatio: true,
    };

    return <Bar data={chartData} options={chartOptions} height={200} />;
  }

  renderAmountWeekChart() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const dataByYear = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const weekNumber = this.getISOWeek(date);
      const day = date.getUTCDay(); // 0 (Sunday) to 6 (Saturday)

      dataByYear[year] = dataByYear[year] || {};
      dataByYear[year][weekNumber] = dataByYear[year][weekNumber] || 0;
      dataByYear[year][weekNumber] += Number(arqueo.SalesTotal);
    });

    const datasets = [];
    const weeksInYear = 52;

    Object.keys(dataByYear).forEach(year => {
      const yearData = dataByYear[year];

      const data = Array.from({ length: weeksInYear }, (_, index) => {
        const weekNumber = index + 1;
        return (
          (yearData[weekNumber] && yearData[weekNumber]) || 0
        );
      });

      datasets.push({
        label: `Total Semanal ${year}`,
        data: data,
        fill: true,
        backgroundColor: this.generateRandomColorBackground(year),
        borderColor: this.generateRandomColor(year),
        borderWidth: 2,
        order: 0,
      });
    });

    const chartData = {
      labels: Array.from({ length: weeksInYear }, (_, index) => `${index + 1}`),
      datasets: datasets,
    };

    const chartOptions = {
      scales: {
        x: {
          type: 'category', // Use category scale for week labels
          labels: {
            autoSkip: false,
            maxRotation: 0,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
      maintainAspectRatio: true,
    };

    return <Bar data={chartData} options={chartOptions} height={200} />;
  }

  renderAmountMediaMonthChart() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const dataByYear = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();

      dataByYear[year] = dataByYear[year] || {};
      dataByYear[year][month] = dataByYear[year][month] || 0;
      dataByYear[year][month] += Number(arqueo.SalesTotal);
    });

    const datasets = [];

    Object.keys(dataByYear).forEach(year => {
      const yearData = dataByYear[year];

      const data = Array.from({ length: 12 }, (_, index) => {
        const month = index + 1;
        return (yearData[month] && yearData[month] / this.getMonthDays(month)) || 0;
      });

      datasets.push({
        label: `Promedio Mensual ${year}`,
        data: data,
        fill: true,
        backgroundColor: this.generateRandomColorBackground(year),
        borderColor: this.generateRandomColor(year),
        borderWidth: 2,
        order: 0
      });
    });

    const chartData = {
      labels: Array.from({ length: 12 }, (_, index) => {
        const month = index;
        const monthLabel = new Date(0, month).toLocaleString('default', { month: 'short' }); // Get month abbreviation
        return monthLabel;
      }),
      datasets: datasets,
    };

    const chartOptions = {
      scales: {
        x: {
          type: 'category', // Use category scale for day labels
          labels: {
            autoSkip: false,
            maxRotation: 0,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
      maintainAspectRatio: true
    };

    return <Line data={chartData} options={chartOptions} height={200} />;
  }

  renderAmountMediaBiweeklyChart() {
    const { arqueos, error } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos || arqueos.length === 0) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const dataByMonth = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const month = date.getMonth(); // Get the month (0-based index)
      const day = date.getDate();

      const isFirstHalf = day <= 15;

      const period = isFirstHalf ? 'First Half' : 'Last Half';

      dataByMonth[`${year}-${month + 1}`] = dataByMonth[`${year}-${month + 1}`] || { 'First Half': 0, 'Last Half': 0 };
      dataByMonth[`${year}-${month + 1}`][period] += Number(arqueo.SalesTotal);
    });

    const datasets = [
      {
        label: 'First Half',
        data: [],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 2,
      },
      {
        label: 'Last Half',
        data: [],
        backgroundColor: 'rgba(255, 168, 0, 0.2)',
        borderColor: 'rgba(255, 168, 0)',
        borderWidth: 2,
      },
    ];

    // Fill dataset data array
    Object.keys(dataByMonth).forEach(key => {
      const [year, month] = key.split('-');

      datasets[0].data.push(dataByMonth[key]['First Half'] / 15);

      if (month === 2) {
        datasets[1].data.push(dataByMonth[key]['Last Half'] / 13);
      } else if (month === 4 || month === 6 || month === 9 || month === 11) {
        datasets[1].data.push(dataByMonth[key]['Last Half'] / 15);
      } else {
        datasets[1].data.push(dataByMonth[key]['Last Half'] / 16);
      }

    });

    const chartData = {
      labels: Object.keys(dataByMonth),
      datasets: datasets,
    };

    const chartOptions = {
      scales: {
        x: {
          title: {
            display: true,
            text: 'Month',
          },
        },
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: 'Total Sales',
          },
        },
      },
      maintainAspectRatio: true,
    };

    return <Bar data={chartData} options={chartOptions} height={200} />;
  }

  renderAmountMediaWeekChart() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const dataByYear = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const weekNumber = this.getISOWeek(date);
      const day = date.getUTCDay(); // 0 (Sunday) to 6 (Saturday)

      dataByYear[year] = dataByYear[year] || {};
      dataByYear[year][weekNumber] = dataByYear[year][weekNumber] || 0;
      dataByYear[year][weekNumber] += Number(arqueo.SalesTotal);
    });

    const datasets = [];
    const weeksInYear = 52;

    Object.keys(dataByYear).forEach(year => {
      const yearData = dataByYear[year];

      const data = Array.from({ length: weeksInYear }, (_, index) => {
        const weekNumber = index + 1;
        return (
          (yearData[weekNumber] && yearData[weekNumber] / this.getWeekDays(weekNumber)) || 0
        );
      });

      datasets.push({
        label: `Promedio Semanal ${year}`,
        data: data,
        fill: true,
        backgroundColor: this.generateRandomColorBackground(year),
        borderColor: this.generateRandomColor(year),
        borderWidth: 2,
        order: 0,
      });
    });

    const chartData = {
      labels: Array.from({ length: weeksInYear }, (_, index) => `${index + 1}`),
      datasets: datasets,
    };

    const chartOptions = {
      scales: {
        x: {
          type: 'category', // Use category scale for week labels
          labels: {
            autoSkip: false,
            maxRotation: 0,
          },
        },
        y: {
          beginAtZero: true,
        },
      },
      maintainAspectRatio: true,
    };

    return <Line data={chartData} options={chartOptions} height={200} />;
  }

  // Helper function to get the ISO week number
  getISOWeek(date) {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(
      ((date - startOfYear) / 86400000 + startOfYear.getDay() + 1) / 7
    );
    return weekNumber;
  }

  // Helper function to get the number of days in a week
  getWeekDays(weekNumber) {
    const year = new Date().getFullYear();
    const startOfWeek = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const endOfWeek = new Date(year, 0, 1 + weekNumber * 7);
    const daysInWeek = Math.round((endOfWeek - startOfWeek) / (24 * 60 * 60 * 1000)) + 1;
    return daysInWeek;
  }

  renderRegisterDaysArqueos() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    const arqueosByYearMonth = {};
    arqueos.forEach(arqueo => {
      const originalDate = new Date(arqueo.TimeStamp);

      // Extract date components
      const year = originalDate.getUTCFullYear();
      const month = originalDate.getUTCMonth();
      const day = originalDate.getUTCDate();

      arqueosByYearMonth[year] = arqueosByYearMonth[year] || {};
      arqueosByYearMonth[year][month] = arqueosByYearMonth[year][month] || [];

      if (!arqueosByYearMonth[year][month].includes(day)) {
        arqueosByYearMonth[year][month].push(day);
      }

    });

    const currentYear = new Date().getFullYear();
    const years = Object.keys(arqueosByYearMonth);

    return (
      <div className="calendar-container">
        {years.map(year => (
          <div key={year} className="calendar-year">
            <h2>{year}</h2>
            {Array.from({ length: 12 }, (_, month) => {
              const monthDays = new Date(year, month + 1, 0).getDate();
              const arqueoDays = arqueosByYearMonth[year] && arqueosByYearMonth[year][month] ? arqueosByYearMonth[year][month] : [];
              return (
                <div key={month} className="calendar-month">
                  <h3>{new Date(year, month, 1).toLocaleString('default', { month: 'long' })}</h3>
                  {Array.from({ length: monthDays }, (_, day) => {
                    const dayNumber = day + 1; // Add 1 to get the day number
                    return (
                      <div
                        key={day}
                        className={`calendar-day ${arqueoDays.includes(dayNumber) ? 'has-arqueo' : ''}`}
                      >
                        {dayNumber}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    );
  }

  renderRegisterDonutTotal() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const totalByYear = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();

      totalByYear[year] = totalByYear[year] || 0;
      totalByYear[year] += Number(arqueo.SalesTotal);
    });

    const background = [];
    const label = [];

    Object.keys(totalByYear).forEach(year => {
      background.push(this.generateRandomColor(year));
      label.push(<h5>{`(${year}: ${totalByYear[year].toFixed(2)})`}</h5>)
    });

    const data = {
      labels: Object.keys(totalByYear),
      datasets: [{
        label: Object.keys(totalByYear),
        data: Object.values(totalByYear),
        backgroundColor: background,
        hoverOffset: 4
      }]
    };

    const options = {
      maintainAspectRatio: true,
      legend: {
        position: 'left',
      },
    };

    return <Doughnut
      data={data}
      options={options}>
    </Doughnut>;
  }

  renderRegisterDonutTotalUnits() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const totalByYear = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();

      totalByYear[year] = totalByYear[year] || 0;

      Object.keys(arqueo.Items).forEach(item => {
        totalByYear[year] += Number(arqueo.Items[item]);
      });
    });

    const background = [];
    const label = [];

    Object.keys(totalByYear).forEach(year => {
      background.push(this.generateRandomColor(year));
      label.push(<h5>{`(${year}: ${totalByYear[year]})`}</h5>)
    });

    const data = {
      labels: Object.keys(totalByYear),
      datasets: [{
        label: Object.keys(totalByYear),
        data: Object.values(totalByYear),
        backgroundColor: background,
        hoverOffset: 4
      }]
    };

    const options = {
      maintainAspectRatio: true,
      legend: {
        position: 'left',
      },
    };

    return <Doughnut
      data={data}
      options={options}>
    </Doughnut>;
  }

  renderResumeTotalUnits() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const totalByYear = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();

      totalByYear[year] = totalByYear[year] || 0;

      Object.keys(arqueo.Items).forEach(item => {
        totalByYear[year] += Number(arqueo.Items[item]);
      });
    });

    const resumeUnits = [];

    Object.keys(totalByYear).forEach(year => {
      resumeUnits.push(<h6>{`Año ${year}: ${totalByYear[year].toLocaleString("es-ES")} uds`}</h6>)
    });

    return resumeUnits
  }

  renderResumeTotal() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const totalByYear = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();

      totalByYear[year] = totalByYear[year] || 0;
      totalByYear[year] += Number(arqueo.SalesTotal);
    });

    const resumeTotal = [];

    Object.keys(totalByYear).forEach(year => {
      resumeTotal.push(<h6>{`Año ${year}: ${totalByYear[year].toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h6>)
    });

    return resumeTotal
  }

  renderResumeTotalHistoricUnits() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    let totalByYear = 0;

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();

      Object.keys(arqueo.Items).forEach(item => {
        totalByYear += Number(arqueo.Items[item]);
      });
    });

    return (<h6>{`Total: ${totalByYear.toLocaleString("es-ES")} uds`}</h6>)
  }

  renderResumeTotalHistoric() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    let totalByYear = 0;

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();
      const month = date.getUTCMonth() + 1;
      const day = date.getUTCDate();

      totalByYear += Number(arqueo.SalesTotal);
    });

    return (<h6>{`Total: ${totalByYear.toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h6>)
  }

  renderRegisterRadarTotalUnits() {
    const { arqueos, error, loading, auth } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!arqueos) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    // Prepare data for the chart
    const totalByYear = {};
    const generateLabels = {};

    arqueos.forEach(arqueo => {
      const date = new Date(arqueo.TimeStamp);
      const year = date.getFullYear();

      totalByYear[year] = totalByYear[year] || {};

      Object.keys(arqueo.Items).forEach(item => {
        totalByYear[year][item] = (totalByYear[year][item] || 0) + Number(arqueo.Items[item]);

        generateLabels[item] = generateLabels[item] || 0;
      });
    });

    const labels = Object.keys(generateLabels);
    const datasets = [];

    Object.keys(totalByYear).forEach(year => {
      const items = totalByYear[year];

      datasets.push({
        label: `${year}`,
        data: Object.values(items),
        backgroundColor: this.generateRandomColorBackground(year),
        borderColor: this.generateRandomColor(year),
        borderWidth: 1,
      })
    });

    const radarData = {
      labels,
      datasets,
    };

    return <Radar data={radarData} />
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              KPI DE VENTAS
            </h1>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <Box title="Filtro" icon="filter">
              <ArqueosFilterForm />
            </Box>
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-xs-12 col-sm-3 col-md-3">
            <Box title="Histórico de ventas">
              {this.renderResumeTotalHistoric()}
            </Box>
            <Box title="Resumen de ventas">
              {this.renderResumeTotal()}
            </Box>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-3">
            <Box title="Total de ventas">
              {this.renderRegisterDonutTotal()}
            </Box>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-3">
            <Box title="Total por unidades">
              {this.renderRegisterDonutTotalUnits()}
            </Box>
          </div>
          <div className="col-xs-12 col-sm-3 col-md-3">
            <Box title="Histórico de unidades">
              {this.renderResumeTotalHistoricUnits()}
            </Box>
            <Box title="Resumen de unidades">
              {this.renderResumeTotalUnits()}
            </Box>
          </div>
        </div>
        <Box title="Total por día">
          {this.renderAmountDayChart()}
        </Box>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Total semanal">
              {this.renderAmountWeekChart()}
            </Box>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Total quincenal">
              {this.renderAmountBiweeklyChart()}
            </Box>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Total mensual">
              {this.renderAmountMonthChart()}
            </Box>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Media semanal">
              {this.renderAmountMediaWeekChart()}
            </Box>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Media quincenal">
              {this.renderAmountMediaBiweeklyChart()}
            </Box>
          </div>
          <div className="col-xs-12 col-sm-4 col-md-4">
            <Box title="Media mensual">
              {this.renderAmountMediaMonthChart()}
            </Box>
          </div>
        </div>

        <Box title="Días registrados">
          {this.renderRegisterDaysArqueos()}
        </Box>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  arqueos: state.arqueos.arqueos,
  error: state.arqueos.error,
  loading: state.arqueos.loading,
  auth: state.auth,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ArqueosView)
