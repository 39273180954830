import React from 'react'
import { history } from '../store'
import * as dateFormat from 'dateformat'

import IncidenceStatusBadge from './IncidenceStatusBadge'
import IncidenceCategoryBadge from './IncidenceCategoryBadge'
import IncidenceTimeBadge from './IncidenceTimeBadge'

class IncidenceClosedList extends React.Component {
  goTo(number, e) {
    history.push(`/incidences/${number}`)
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fecha Apertura</th>
              <th scope="col">Cliente</th>
              <th scope="col">Máquina</th>
              <th scope="col">Categoria</th>
              <th scope="col">Max Tiempo Respuesta</th>
              <th scope="col">Max Tiempo Reparación</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            {this.props.incidences.map(incidence => {
              const saleDate = new Date(incidence.createdAt);
              saleDate.setHours(saleDate.getHours() - 2);
              const formattedDate = `${saleDate.getDate()}/${saleDate.getMonth() + 1}/${saleDate.getFullYear()} ${saleDate.getHours()}:${saleDate.getMinutes()}`;

              if (incidence.Status === "CLOSED") {
                return (
                  <tr key={incidence.number}
                    onClick={e => this.goTo(incidence.number, e)}>
                    <th scope="row">{incidence.number}</th>
                    <td>{formattedDate}</td>
                    <td>{incidence.Customer.Fullname}</td>
                    <td>{incidence.Machine.Serial}</td>
                    <td>
                      <IncidenceCategoryBadge category={incidence.Category} />
                    </td>
                    <td>{incidence.ResponseForecastTime}h</td>
                    <td>{incidence.RepairingForecastTime}h</td>
                    <td>
                      <IncidenceStatusBadge status={incidence.Status} />
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default IncidenceClosedList

