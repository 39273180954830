import {
  FAULTS_REQUEST, FAULTS_SUCCESS, FAULTS_FAILURE
} from '../actions/faults'

const INITIAL_STATE = {
  faults: null, error: null, loading: false
}

const faultsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FAULTS_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case FAULTS_SUCCESS:
        return {
          ...state,
          faults: action.response, error: null, loading: false
        }
      case FAULTS_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      default:
        return state
    }
  }

export default faultsReducer

