import {
  WITHDRAWALS_REQUEST, WITHDRAWALS_SUCCESS, WITHDRAWALS_FAILURE,
  WITHDRAWALS_RESET, WITHDRAWAL_REQUEST, WITHDRAWAL_SUCCESS, WITHDRAWAL_FAILURE,
  WITHDRAWAL_UPDATE_REQUEST, WITHDRAWAL_UPDATE_SUCCESS, WITHDRAWAL_UPDATE_FAILURE
} from '../actions/withdrawals'

const INITIAL_STATE = {
  withdrawals: null, withdrawal: null, withdrawalsNeedRefresh: false, error: null, loading: false
}

const withdrawalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WITHDRAWALS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case WITHDRAWALS_SUCCESS:
      return {
        ...state,
        withdrawals: action.response, error: null, loading: false
      }
    case WITHDRAWALS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case WITHDRAWALS_RESET:
      return {
        ...state,
        withdrawals: null, error: null, loading: false
      }
    case WITHDRAWAL_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case WITHDRAWAL_SUCCESS:
      return {
        ...state,
        withdrawal: action.response, error: null, loading: false
      }
    case WITHDRAWAL_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case WITHDRAWAL_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case WITHDRAWAL_UPDATE_SUCCESS:
      return {
        ...state,
        withdrawal: action.response, withdrawalsNeedRefresh: true, error: null,
        loading: false
      }
    case WITHDRAWAL_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default withdrawalsReducer


