import React from 'react'
import { history } from '../store'

class FaultsByMachineList extends React.Component {
  goTo(Serial, e) {
    history.push(`/machines/${Serial}`)
  }

  renderFaultList() {
    const { matrixs, machines } = this.props;

    let faultList = [];

    // Create an array of fault objects with customer name, serial, and fault counts
    matrixs.forEach((matrix) => {
      const faults = {
        customer: "",
        serial: matrix.Serial,
        BROKEN_LOCK: 0,
        GREEN_BLOCK: 0,
        RED_BLOCK: 0,
        PINPAD_BLOCK: 0,
        DOOR_BLOCK: 0,
        PINPAD_ACTIVE: 0,
        TOTAL_FAULTS: 0,
      };

      machines.forEach((machine) => {
        if (machine.Serial === matrix.Serial) {
          faults.customer = machine.Customer?.Fullname || 'None';
        }
      });

      matrix.Locker.forEach((lock) => {
        if (lock.FaultCode !== undefined && lock.Status === "FL") {
          switch (lock.FaultCode) {
            case "BROKEN_LOCK":
              faults.BROKEN_LOCK++;
              faults.TOTAL_FAULTS++;
              break;
            case "GREEN_BLOCK":
              faults.GREEN_BLOCK++;
              faults.TOTAL_FAULTS++;
              break;
            case "RED_BLOCK":
              faults.RED_BLOCK++;
              faults.TOTAL_FAULTS++;
              break;
            case "PINPAD_BLOCK":
              faults.PINPAD_BLOCK++;
              faults.TOTAL_FAULTS++;
              break;
            case "DOOR_BLOCK":
              faults.DOOR_BLOCK++;
              faults.TOTAL_FAULTS++;
              break;
            case "PINPAD_ACTIVE":
              faults.PINPAD_ACTIVE++;
              faults.TOTAL_FAULTS++;
              break;
            default:
              break;
          }
        }
      });

      if (faults.TOTAL_FAULTS > 0) {
        faultList.push(faults);
      }
    });

    // Sort the faultList by customer name in alphabetical order
    faultList.sort((a, b) => a.customer.localeCompare(b.customer));

    return faultList.map((fault) => (
      <tr key={fault.serial} onClick={(e) => this.goTo(fault.serial, e)}>
        <th>{fault.customer}</th>
        <th>{fault.serial}</th>
        <td>{fault.TOTAL_FAULTS}</td>
        <td>{fault.BROKEN_LOCK}</td>
        <td>{fault.GREEN_BLOCK}</td>
        <td>{fault.RED_BLOCK}</td>
        <td>{fault.PINPAD_BLOCK}</td>
        <td>{fault.DOOR_BLOCK}</td>
        <td>{fault.PINPAD_ACTIVE}</td>
      </tr>
    ));
  }

  render() {
    if (!this.props.matrixs) {
      return null
    }

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Máquina</th>
              <th scope="col">Total averias</th>
              <th scope="col">Pinchos rotos</th>
              <th scope="col">Pin verde, no abre</th>
              <th scope="col">Pin rojo, no abre</th>
              <th scope="col">Teclado no enciende</th>
              <th scope="col">Puerta no cierra</th>
              <th scope="col">Teclado encendido</th>
            </tr>
          </thead>
          <tbody>
            {this.renderFaultList()}
          </tbody>
        </table>
      </div>
    )
  }
}

export default FaultsByMachineList

