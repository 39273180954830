import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError, change } from 'redux-form'
import { fetchCustomers } from '../actions/customers'
import ErrorMessage from '../components/ErrorMessage'

import Box from '../components/Box'
import { addQR } from '../actions/qrs'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'
import QRZonesList from '../components/QRZonesList'

class QRAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      customerSelected: "",
      showDynamicField: false
    };

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchCustomers } = this.props

    fetchCustomers()
  }

  submit(props) {
    return this.props.addQR(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  handleShowDynamicField() {
    this.setState({
      showDynamicField: true
    });
  }

  render() {
    const { customers, error, pristine, submitting, handleSubmit } = this.props
    const { showDynamicField } = this.state;

    if (!customers) {
      return (
        <ErrorMessage
          message="No hay clientes o máquinas disponibles para nuevos contratos" />
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">NUEVO </span>
          <span className="text-vw-light">QR</span>
        </h1>
        <hr className="bg-vw-light" />
        <div className="row">
          <div className="col col-12 col-xs-12 col-md-4">
          </div>
          <div className="col col-12 col-xs-12 col-md-4">
            <Box title="Configuración" icon="cubes">
              <Field name="Customer"
                component={renderSelectField}
                label="Cliente"
                onChange={(e) => { this.setState({ customerSelected: e.target.value }), this.handleShowDynamicField() }}>
                <option value="">Selecciona un cliente...</option>
                {customers &&
                  customers
                    .filter((customer) => customer.Type === 'GENERAL')
                    .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                    .map((customer) => (
                      <option value={customer._id} key={customer._id}>
                        {customer.Fullname}
                      </option>
                    ))}
              </Field>
              {showDynamicField && <QRZonesList customers={customers} customerSelected={this.state.customerSelected} />}
              <Field name="Type"
                component={renderSelectField}
                label="Tipo">
                <option value="">Selecciona un tipo...</option>
                <option value="pay">Pago</option>
                <option value="free">Gratis</option>
                <option value="test">Test</option>
              </Field>
              <Field name="Mode"
                component={renderSelectField}
                label="Modo">
                <option value="">Selecciona un modo...</option>
                <option value="0">24H CON PIN</option>
                <option value="1">OTO SIN PIN</option>
              </Field>
            </Box>
          </div>
          <div className="col col-12 col-xs-12 col-md-4">
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

QRAddForm = reduxForm({
  form: 'QRAddForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/qrs/${result.response._id}`)
  }
})(QRAddForm)

const mapStateToProps = state => ({
  customers: state.customers.customers
})

const mapDispatchToProps = dispatch => ({
  addQR: bindActionCreators(addQR, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(QRAddForm)

