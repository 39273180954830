import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as dateFormat from 'dateformat'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchIncidence, updateIncidence } from '../actions/incidences'

class IncidenceDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      closing: false,
      closeConfirmShown: false
    }

    this.closeIncidence = this.closeIncidence.bind(this)
    this.closeConfirmShow = this.closeConfirmShow.bind(this)
    this.closeConfirmToggle = this.closeConfirmToggle.bind(this)
  }

  componentDidMount() {
    this.props.fetchIncidence(this.props.match.params.number)
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.deleting && !nextProps.loading) {
      this.props.push('/incidences')
    }
  }

  closeIncidence() {
    const { updateIncidence, incidence } = this.props

    this.setState({ deleting: true })
    updateIncidence(incidence.number, { Status: 'CLOSED' })
  }

  closeConfirmShow() {
    this.setState({ closeConfirmShown: true })
  }

  closeConfirmToggle() {
    this.setState({ closeConfirmShown: !this.state.closeConfirmShown })
  }

  render() {
    const { role, incidence, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!incidence || loading || !incidence.Machine || !incidence.Customer) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    const OpenDate = new Date(incidence.createdAt)
    const RepairDate = new Date(incidence.ResponseTime)
    const CloseDate = new Date(incidence.CloseTime)

    const ResponseTime = RepairDate.getTime() - OpenDate.getTime();
    const hourResponseTimeDiff = Math.floor(ResponseTime / (1000 * 60 * 60)); // Get the number of whole hours
    const minuteResponseTimeDiff = Math.floor((ResponseTime / (1000 * 60)) % 60); // Get the number of remaining minutes

    const RepairTime = CloseDate.getTime() - RepairDate.getTime();
    const hourRepairTimeDiff = Math.floor(RepairTime / (1000 * 60 * 60)); // Get the number of whole hours
    const minuteRepairTimeDiff = Math.floor((RepairTime / (1000 * 60)) % 60); // Get the number of remaining minutes

    const saleDate = new Date(incidence.createdAt);
    saleDate.setHours(saleDate.getHours() - 2);
    const formattedDate = `${saleDate.getDate()}/${saleDate.getMonth() + 1}/${saleDate.getFullYear()} ${saleDate.getHours().toString().padStart(2, '0')}:${saleDate.getMinutes().toString().padStart(2, '0')}`;

    const saleDateClose = new Date(incidence.CloseTime);
    saleDateClose.setHours(saleDateClose.getHours() - 2);
    const formattedDateClose = `${saleDateClose.getDate()}/${saleDateClose.getMonth() + 1}/${saleDateClose.getFullYear()} ${saleDateClose.getHours().toString().padStart(2, '0')}:${saleDateClose.getMinutes().toString().padStart(2, '0')}`;

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">INCIDENCIA </span>
              <span className="text-vw-dark"> {incidence.number} </span>
            </h1>
          </div>
          {role === 'ADMIN' && incidence.Status !== 'CLOSED' &&
            <div className="col-xs-12 col-sm-6 col-md-6 text-right">
              {incidence.Status === 'OPEN' &&
                <Link to={`/incidences/${incidence.number}/actuation`}>
                  <button className="btn btn-success mr-1">
                    <i className="fas fa-edit mr-1"></i>Iniciar Reparación
                  </button>
                </Link>
              }
              <Link to={`/incidences/${incidence.number}/edit`}>
                <button className="btn btn-warning mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
              <Link to={`/incidences/${incidence.number}/resolution`}>
                <button className="btn btn-danger mr-1">
                  <i className="fas fa-times-circle mr-1"></i>Cerrar
                </button>
              </Link>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Incidencia" icon="exclamation-triangle">
              <PropertyLabel name="Número" value={incidence.number} />
              <PropertyLabel name="Fecha de creación" value={formattedDate} />
              <PropertyLabel name="Máquina" value={incidence.Machine.Serial} />
              <PropertyLabel name="Categoria" value={incidence.Category} />
              <PropertyLabel name="Estado" value={incidence.Status} />
              <PropertyLabel name="Max Tiempo Respuesta" value={`${incidence.ResponseForecastTime}h`} />
              <PropertyLabel name="Max Tiempo Reparación" value={`${incidence.RepairingForecastTime}h`} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Tiempos" icon="clock">
              <PropertyLabel name="Tiempo de Respuesta" value={`${hourResponseTimeDiff.toString().padStart(2, '0')}:${minuteResponseTimeDiff.toString().padStart(2, '0')}h`} />
              <PropertyLabel name="Tiempo de Reparación" value={`${hourRepairTimeDiff.toString().padStart(2, '0')}:${minuteRepairTimeDiff.toString().padStart(2, '0')}h`}  />
              {incidence.CloseTime &&
                <PropertyLabel name="Fecha de Cierre" value={formattedDateClose} />
              }   
            </Box>
            <Box title="Responsables" icon="user">
              <PropertyLabel name="Abierta" value={incidence.Executor} />
              <PropertyLabel name="Respuesta" value={incidence.Responder}  />
              <PropertyLabel name="Cerrada" value={incidence.Resolver}  />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Cliente" icon="user">
              <PropertyLabel name="Nombre" value={incidence.Customer.Fullname} />
              <PropertyLabel name="E-Mail" value={incidence.Customer.Email} />
              <PropertyLabel name="Teléfono" value={incidence.Customer.Phone} />
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Box title="Información" icon="info-circle-fill">
              <PropertyLabel name="Descripción" value={incidence.Description} ml />
              <PropertyLabel name="Actuación" value={incidence.Actuation} ml />
              <PropertyLabel name="Resolución" value={incidence.Resolution} ml />
            </Box>
          </div>
        </div>
        {/* Confirm close */}
        <div>
          <Modal isOpen={this.state.closeConfirmShown}
            toggle={this.closeConfirmToggle}>
            <ModalHeader toggle={this.closeConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de cerrar la incidencia?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.closeIncidence}>
                Cerrar
              </Button>
              <Button color="secondary" onClick={this.closeConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  incidence: state.incidences.incidence,
  loading: state.incidences.loading,
  error: state.incidences.error,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchIncidence: bindActionCreators(fetchIncidence, dispatch),
  updateIncidence: bindActionCreators(updateIncidence, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidenceDetailsView)

