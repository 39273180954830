import {
  MATRIXS_REQUEST, MATRIXS_SUCCESS, MATRIXS_FAILURE,
  MATRIX_REQUEST, MATRIX_SUCCESS, MATRIX_FAILURE,
  DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE,
  MATRIX_DELETE_REQUEST, MATRIX_DELETE_SUCCESS, MATRIX_DELETE_FAILURE
} from '../actions/matrixs'

const INITIAL_STATE = {
  matrixs: null, matrix: null, error: null, loading: false
}

const matrixsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MATRIXS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case MATRIXS_SUCCESS:
      return {
        ...state,
        matrixs: action.response, error: null, loading: false
      }
    case MATRIXS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case MATRIX_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case MATRIX_SUCCESS:
      return {
        ...state,
        matrix: action.response, error: null, loading: false
      }
    case MATRIX_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case DOWNLOAD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case DOWNLOAD_SUCCESS:
      return {
        ...state,
        error: null, loading: false
      }
    case DOWNLOAD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case MATRIX_DELETE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case MATRIX_DELETE_SUCCESS:
      return {
        ...state,
        matrix: null, error: null, loading: false
      }
    case MATRIX_DELETE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default matrixsReducer

