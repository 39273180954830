import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchCustomers, fetchSAPValues } from '../actions/customers'

import CustomerList from '../components/CustomerList'
import ErrorMessage from '../components/ErrorMessage'

class CustomersView extends React.Component {
  constructor(props) {
    super(props)

    this.handleDownloadSAPValuesCSV = this.handleDownloadSAPValuesCSV.bind(this);
  }

  componentDidMount() {
    this.props.fetchCustomers()
  }

  handleDownloadSAPValuesCSV() {
    const { fetchSAPValues } = this.props

    fetchSAPValues();
  }

  render() {
    const { role, type, customers, loading, error } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!customers || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">CLIENTES</h1>
          </div>
          {(role === 'ADMIN' && type !== 'PRODUCTION' && type !== 'LIMITED') &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-center">
                <Link to="/customers/add">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus mr-1"></i>
                    <span className="d-none d-sm-inline ml-1">Nuevo Cliente</span>
                  </button>
                </Link>
              </div>
              <div className="col d-flex justify-content-center">
                <Link to="/customersgroup/add">
                  <button type="button" className="btn bg-vw-yellow">
                    <i className="fas fa-plus mr-1"></i>
                    <span className="d-none d-sm-inline ml-1">Nuevo Grupo</span>
                  </button>
                </Link>
              </div>
              <div className="col d-flex justify-content-center">
                <button className="btn bg-vw-excel text-white"
                  onClick={this.handleDownloadSAPValuesCSV}>
                  <i className="fas fa-file-excel"></i>
                  <span className="d-none d-sm-inline ml-1">Descargar SAP</span>
                </button>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <CustomerList customers={customers} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customers: state.customers.customers,
  error: state.customers.error,
  loading: state.customers.loading,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  fetchSAPValues: bindActionCreators(fetchSAPValues, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomersView)

