import React from 'react'
import * as dateFormat from 'dateformat'
import { history } from '../store'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

class WithdrawalEntryList extends React.Component {

  renderType(Type) {

    switch (Type) {
      case 0:
        return "Retiro de Monedas"
      case 1:
        return "Retiro de Billetes"
      case 2:
        return "Retiro de Cambio"
      case 3:
        return "Fondo de caja"
    }
  }

  renderBalance(Balance) {
    if (Balance > 0) {
      return "success"
    } else if (Balance < 0) {
      return "danger"
    } else {
      return "primary"
    }
  }

  renderBalanceSign(Balance) {
    if (Balance > 0) {
      return "+"
    } else if (Balance < 0) {
      return ""
    } else {
      return ""
    }
  }

  goToEdit(id, e) {
    history.push(`withdrawals/${id}/edit`)
  }

  goToBalance(id, e) {
    history.push(`withdrawals/${id}/balance`)
  }

  render() {
    const { role } = this.props

    return (
      <div>
        <div className="table-responsive">
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Tipo</th>
                <th scope="col">Máquina</th>
                <th scope="col">Fecha/Hora</th>
                <th scope="col">Pin / Trabajador</th>
                <th scope="col">Desglose Máquina</th>
                <th scope="col">Total Máquina</th>
                <th scope="col">Desglose Recuento</th>
                <th scope="col">Total Recuento</th>
                <th scope="col">Balance</th>
                {role === 'ADMIN' &&
                  <th scope="col">Acciones</th>
                }
                {role === 'ADMIN' &&
                  <th scope="col">Ingresado</th>
                }
              </tr>
            </thead>
            <tbody>
              {this.props.withdrawals.map(withdrawal => (
                <tr key={withdrawal._id}>
                  <td>{this.renderType(withdrawal.Type)}</td>
                  <td>{withdrawal.Machine.Serial}</td>
                  <td>{dateFormat(new Date(withdrawal.TimeStamp), 'dd/mm/yy HH:MM')}</td>
                  <td>{withdrawal.WorkerSession}</td>
                  {/* Desglose Maquina */}
                  {withdrawal.Type == 0 &&
                    <td>
                      0.50€: {withdrawal.Units050CoinCash}<br></br>
                      1€: {withdrawal.Units100CoinCash}<br></br>
                      2€: {withdrawal.Units200CoinCash}
                    </td>
                  }
                  {withdrawal.Type == 1 &&
                    <td>
                      5€: {withdrawal.Units5BillCash}<br></br>
                      10€: {withdrawal.Units10BillCash}<br></br>
                      20€: {withdrawal.Units20BillCash}<br></br>
                      50€: {withdrawal.Units50BillCash}
                    </td>
                  }
                  {withdrawal.Type == 2 &&
                    <td>
                      0.50€: {withdrawal.Units050CoinCash}<br></br>
                      1€: {withdrawal.Units100CoinCash}<br></br>
                      2€: {withdrawal.Units200CoinCash}
                    </td>
                  }
                  {withdrawal.Type == 3 &&
                    <td>
                      0.50€: {withdrawal.Units050CoinCash}<br></br>
                      1€: {withdrawal.Units100CoinCash}<br></br>
                      2€: {withdrawal.Units200CoinCash}
                    </td>
                  }
                  {/* Total Maquina */}
                  {withdrawal.Type == 0 &&
                    <td>{(withdrawal.Units050CoinCash * 0.50) + withdrawal.Units100CoinCash + (withdrawal.Units200CoinCash * 2)} €</td>
                  }
                  {withdrawal.Type == 1 &&
                    <td>{(withdrawal.Units5BillCash * 5) + (withdrawal.Units10BillCash * 10) + (withdrawal.Units20BillCash * 20) + (withdrawal.Units50BillCash * 50)} €</td>
                  }
                  {withdrawal.Type == 2 &&
                    <td>{(withdrawal.Units050CoinCash * 0.50) + withdrawal.Units100CoinCash + (withdrawal.Units200CoinCash * 2)} €</td>
                  }
                  {withdrawal.Type == 3 &&
                    <td>{(withdrawal.Units050CoinCash * 0.50) + withdrawal.Units100CoinCash + (withdrawal.Units200CoinCash * 2)} €</td>
                  }
                  {/* Desglose Recuento */}
                  {withdrawal.Balanced && withdrawal.Type == 0 &&
                    <td>
                      0.50€: {withdrawal.Units050CoinCashReal}<br></br>
                      1€: {withdrawal.Units100CoinCashReal}<br></br>
                      2€: {withdrawal.Units200CoinCashReal}<br></br>
                    </td>
                  }
                  {withdrawal.Balanced && withdrawal.Type == 1 &&
                    <td>
                      5€: {withdrawal.Units5BillCashReal}<br></br>
                      10€: {withdrawal.Units10BillCashReal}<br></br>
                      20€: {withdrawal.Units20BillCashReal}<br></br>
                      50€: {withdrawal.Units50BillCashReal}
                    </td>
                  }
                  {withdrawal.Balanced && withdrawal.Type == 2 &&
                    <td>
                      0.50€: {withdrawal.Units050CoinCashReal}<br></br>
                      1€: {withdrawal.Units100CoinCashReal}<br></br>
                      2€: {withdrawal.Units200CoinCashReal}
                    </td>
                  }
                  {withdrawal.Balanced && withdrawal.Type == 3 &&
                    <td>
                      0.50€: {withdrawal.Units050CoinCashReal}<br></br>
                      1€: {withdrawal.Units100CoinCashReal}<br></br>
                      2€: {withdrawal.Units200CoinCashReal}
                    </td>
                  }
                  {!withdrawal.Balanced &&
                    <td>NaN €</td>
                  }
                  {/* Total Recuento */}
                  {withdrawal.Type == 0 &&
                    <td>{(withdrawal.Units050CoinCashReal * 0.50) + withdrawal.Units100CoinCashReal + (withdrawal.Units200CoinCashReal * 2)} €</td>
                  }
                  {withdrawal.Type == 1 &&
                    <td>{(withdrawal.Units5BillCashReal * 5) + (withdrawal.Units10BillCashReal * 10) + (withdrawal.Units20BillCashReal * 20) + (withdrawal.Units50BillCashReal * 50)} €</td>
                  }
                  {withdrawal.Type == 2 &&
                    <td>{(withdrawal.Units050CoinCashReal * 0.50) + withdrawal.Units100CoinCashReal + (withdrawal.Units200CoinCashReal * 2)} €</td>
                  }
                  {withdrawal.Type == 3 &&
                    <td>{(withdrawal.Units050CoinCashReal * 0.50) + withdrawal.Units100CoinCashReal + (withdrawal.Units200CoinCashReal * 2)} €</td>
                  }
                  {/* Balance */}
                  {withdrawal.Balance && withdrawal.Balanced || withdrawal.Type == 3 ? (
                    <td>
                      <div className={`badge badge-${this.renderBalance(withdrawal.Balance)}`}>
                        {this.renderBalanceSign(withdrawal.Balance)}{withdrawal.Balance} €
                      </div>
                    </td>
                  ) : (
                    <td>
                      <button className="btn btn-warning mr-1" type="button" onClick={e => this.goToBalance(withdrawal._id, e)}>
                        <i className="fas fa-calculator mr-1"></i>Cuadrar Recuento
                      </button>
                    </td>
                  )
                  }
                  {/* Acciones */}
                  {role === 'ADMIN' &&
                    <td>
                      <button className="btn bg-vw-light text-white mr-1" type="button" onClick={e => this.goToEdit(withdrawal._id, e)}>
                        <i className="fas fa-edit mr-1"></i>Editar
                      </button>
                    </td>
                  }
                  {/* Ingresado */}
                  {role === 'ADMIN' && 
                  
                  ( withdrawal.Bank ? (
                    <td>SI</td>
                  ) : (
                    <td>NO</td>
                  ))}
                </tr>
              )
              )}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  role: state.auth.role
})

export default connect(mapStateToProps)(WithdrawalEntryList)

