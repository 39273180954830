import React from 'react'
import * as dateFormat from 'dateformat'
import RemoteActionStatusBadge from './RemoteActionStatusBadge'

class OpeningsList extends React.Component {

  formatTime(timestamp) {
    const saleDate = new Date(timestamp);
    saleDate.setHours(saleDate.getHours() - 2);
    const formattedDate = `${saleDate.getDate()}/${saleDate.getMonth() + 1}/${saleDate.getFullYear()} ${saleDate.getHours().toString().padStart(2, '0')}:${saleDate.getMinutes().toString().padStart(2, '0')}`;

    return (formattedDate)
  }

  getOpens(opens) {
    switch (opens) {
      case 0: return <div className="lockerOpeningID bg-error">{opens}</div>
      case 1: return <div className="lockerOpeningID bg-warning">{opens}</div>
      case 2: return <div className="lockerOpeningID bg-success">{opens}</div>
    }
  }

  render() {

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Estado</th>
              <th scope="col">Aperturas Restantes</th>
              <th scope="col">Factura</th>
              <th scope="col">Fecha/Hora</th>
              <th scope="col">Máquina</th>
              <th scope="col">Cliente</th>
              <th scope="col">Locker</th>
              <th scope="col">SN</th>
              <th scope="col">Teléfono</th>
              <th scope="col">Código</th>
            </tr>
          </thead>
          <tbody>
            {this.props.openings.map(opening => (
              <tr key={opening._id}>
                <td><RemoteActionStatusBadge status={opening.Status} /></td>
                <td>{this.getOpens(opening.Opens)}</td>
                <td>{opening.Invoice}</td>
                <td>{this.formatTime(opening.TimeStamp)}</td>
                <td>{opening.Machine}</td>
                <td>{opening.Customer.Fullname}</td>
                <td><div className="lockerID">{opening.Matricula}</div></td>
                <td>{opening.Locker}</td>
                <td>{opening.Phone}</td>
                <td>{opening.AccessCode}</td>
              </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default OpeningsList

