import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'

import { addSupportTicket } from '../actions/supporttickets'
import { fetchMachines } from '../actions/machines'

import { renderSelectField, renderTextAreaField } from '../utils/forms'

class SupportTicketAddForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitted: false
    };

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchMachines } = this.props

    this.setState({ isSubmitted: false });

    fetchMachines()
  }

  submit(props) {
    const { addSupportTicket, id } = this.props

    props.Customer = id
    props.TimeStamp = new Date();

    return addSupportTicket(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }else{
          this.setState({ isSubmitted: true });
        }

        return response
      })
  }

  render() {
    const { machines, error } = this.props;
    const { isSubmitted } = this.state;

    if (!machines) {
      return null
    }

    if (!isSubmitted) {
      return (
        <div>
          <h1>
            <span className="text-vw-dark">CREAR </span>
            <span className="text-vw-light">TICKET</span>
          </h1>

          <form onSubmit={this.props.handleSubmit(this.submit)}>
            <div className="row">
              <div className="col col-12 col-lg-4">
                <Box title="Asignación" icon="exclamation-triangle">
                  <Field name="Machine"
                    component={renderSelectField}
                    label="Máquina">
                    <option value="">Selecciona una máquina...</option>
                    {machines.map(machine => {
                      return (
                        <option value={machine._id} key={machine._id}>
                          {machine.Serial}
                        </option>
                      )
                    })}
                  </Field>
                  <Field name="Category"
                    component={renderSelectField}
                    label="Categoria">
                    <option value="">Indica el fallo...</option>
                    <option value="COINS">Pago Monedas</option>
                    <option value="BILLS">Pago Billetes</option>
                    <option value="TPV">Pago Tarjeta</option>
                    <option value="SCREEN_OFF">Pantalla Apagada</option>
                    <option value="SCREEN_BREAK">Pantalla Rota</option>
                    <option value="SCREEN_TOUCH">Táctil No Responde</option>
                    <option value="PRINTER_TICKET">Impresora Ticket</option>
                    <option value="KIOSC_DOOR">Kiosco Puerta</option>
                    <option value="KIOSC_OFF">Kiosco No Enciende</option>
                    <option value="OTHER">Otro</option>
                  </Field>
                </Box>
              </div>
              <div className="col col-12 col-lg-8">
                <Box title="Incidencia" icon="exclamation-triangle">
                  <Field name="Description"
                    component={renderTextAreaField}
                    label="Descripción" />
                </Box>
              </div>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
              disabled={this.props.pristine || this.props.submitting}>
              Crear
            </button>
          </form>
        </div>
      )
    } else {
      return (
        <div>
          <h1>
            <span className="text-vw-dark">¡TICKET ENVIADO </span>
            <span className="text-vw-light">CORRECTAMENTE!</span>
          </h1>
          <Box>
            <h5>Grácias por enviar tu ticket, lo revisaremos a la mayor brevedad posible para darte una solución.</h5>
            <br></br>
            <h5>En caso de avería o incidencia urgente puedes ponerte en contacto directamente con nuestro soporte.</h5>
            <br></br>
            <h5>Teléfono: +34 722 35 93 39</h5>
            <br></br>
            <h5>Horario de atención: Lunes a Viernes, 9:00h a 17:00h</h5>
          </Box>
        </div>
      )
    }
  }
}

SupportTicketAddForm = reduxForm({
  form: 'SupportTicketAddForm',
  enableReinitialize: true
})(SupportTicketAddForm)

const mapStateToProps = state => ({
  machines: state.machines.machines,
  auth: state.auth,
  id: state.auth.id,
})

const mapDispatchToProps = dispatch => ({
  addSupportTicket: bindActionCreators(addSupportTicket, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportTicketAddForm)

