import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchSales } from '../actions/sales';
import { fetchMachines } from '../actions/machines';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import Box from '../components/Box';
import SaleList from '../components/SaleList';
import ErrorMessage from '../components/ErrorMessage';
import PropertyLabel from '../components/PropertyLabel';
import OcupationFilterForm from '../forms/OcupationFilterForm';

class OcupationView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCustomer: '',
      ocupationMedia: 0,
    };
  }

  handleFormSubmit = (customer) => {
    this.setState({ selectedCustomer: customer });
  };

  componentDidMount() {
    this.props.fetchMachines();
    const currentDate = new Date();
    //const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
    //const StartDate = firstDayOfMonth.toISOString().substring(0, 10);
    const EndDate = new Date().toISOString().substring(0, 10);

    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - 6); // Subtract 6 days to get the start date
    const startDateFormatted = startDate.toISOString().substring(0, 10);

    this.props.fetchSales('', '', startDateFormatted, EndDate, '', '', '', '');
    
  }

  calculateOccupationPercentage() {
    const { sales, machines } = this.props;

    let computeLastIndex = 0;

    if (this.state.selectedCustomer != '') {
      machines.forEach((machine) => {

        if (machine.Customer._id === this.state.selectedCustomer) {
          computeLastIndex += machine.LastIndex;
        }

      });
    } else {
      machines.forEach((machine) => {

        computeLastIndex += machine.LastIndex;

      });
    }

    // Create an object to store the total sales and last index count by day
    const occupationData = {};
    let totalOcupationMedia = 0;
    let ocupationDays = 0;

    // Iterate over each sale
    sales.forEach((sale) => {

      let hasTumbonas = false;

      // Check if any item in sale.Item has "TUMBONAS" as the item value
      if (sale.Items) { 
        hasTumbonas = sale.Items.some((item) => item.item === "TUMBONAS");
      }

      if (sale.Amount > 0 && !hasTumbonas && sale.PaymentMethod !== "TOKEN") {
        const date = new Date(sale.TimeStamp);
        const day = date.getDate();

        // Initialize the counters for the day if not already present
        if (!occupationData[day]) {
          occupationData[day] = {
            totalSales: 0,
            totalLastIndex: computeLastIndex
          };
        }

        // Increment the total sales count for the day
        occupationData[day].totalSales += 1;

        // // Find the machine corresponding to the sale
        // const machine = machines.find((machine) => machine._id === sale.Machine._id);
        // if (machine) {
        //   // Add the machine's last index to the total last index for the day
        //   occupationData[day].totalLastIndex += machine.LastIndex;
        // }
      }

    });

    // Calculate the occupation percentage for each day
    const occupationPercentage = {};
    const ocupationMedia = {};

    Object.entries(occupationData).forEach(([day, dayData]) => {
      const { totalSales, totalLastIndex } = dayData;
      const percentage = (totalSales / totalLastIndex) * 100;

      totalOcupationMedia += percentage; // Accumulate percentage values
      ocupationDays++;

      occupationPercentage[day] = percentage.toFixed(2); // Rounding the percentage to 2 decimal places
    });

    Object.entries(occupationPercentage).forEach(([day, dayData]) => {

      ocupationMedia[day] = totalOcupationMedia / ocupationDays; // Calculate the overall ocupationMedia
    });

    // Create an object to hold both the occupationPercentage and ocupationMedia
    const result = {
      occupationPercentage,
      ocupationMedia: ocupationMedia,
    };

    return result;
  }

  renderSalesTotals() {
    const { occupationPercentage, ocupationMedia } = this.calculateOccupationPercentage();

    const chartData = {
      xLabels: Object.keys(occupationPercentage),
      yLabels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
      datasets: [
        {
          label: 'Media de ocupación %',
          data: Object.values(ocupationMedia),
          borderColor: 'rgba(255, 0, 0)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          type: 'line',
          order: 1
        },
        {
          label: 'Tasa de ocupación %',
          data: Object.values(occupationPercentage),
          backgroundColor: 'rgba(75, 192, 192, 0.6)', // Set the bar color
          order: 0
        },
      ],
    };

    const chartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      width: 300,
      height: 500,
      scales: {
        y: {
          display: true,
          beginAtZero: true,
          min: 0,
          max: 100,
          ticks: {
            stepSize: 10, // Set the interval between ticks to 10
            callback: function (value) {
              return value + "%"; // Append '%' to the tick value
            },
          },
        },
        x: {
          display: true,
          title: {
            display: true,
            text: 'Day',
          },
        },
      },
    };

    return (
      <div id="canvas-container">
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  }

  renderSales() {
    const { sales, error, loading, machines } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!sales || loading || !machines) {
      return (
        <div className="spinner">
          <img src="/images/logo.png" alt="Logo" />
        </div>
      );
    }

    return (
      <div>{this.renderSalesTotals()}</div>
    )
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">OCUPACIÓN</h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <OcupationFilterForm
            selectedCustomer={this.state.selectedCustomer}
            onSubmit={this.handleFormSubmit}
          />
        </Box>
        <div>{this.renderSales()}</div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  sales: state.sales.sales,
  error: state.sales.error,
  loading: state.sales.loading,
  machines: state.machines.machines,
  form: state.form
});

const mapDispatchToProps = dispatch => ({
  fetchSales: bindActionCreators(fetchSales, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OcupationView);


