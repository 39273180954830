import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError, FieldArray } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { fetchWithdrawal, updateWithdrawal } from '../actions/withdrawals'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

class WithdrawalEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { withdrawal, withdrawals, fetchWithdrawal, match } = this.props

    fetchWithdrawal(match.params.id) 

  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loaded && nextProps.withdrawals.withdrawal) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    const { updateWithdrawal, withdrawals } = this.props

    return updateWithdrawal(withdrawals.withdrawal._id, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { withdrawal, withdrawals, error, pristine, submitting, handleSubmit } = this.props

    if (!this.state.loaded) {
      if (withdrawals.error) {
        return (
          <ErrorMessage message={withdrawals.error.message} />
        )
      }

      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">EDITAR </span>
          <span className="text-vw-light">RETIRO</span>
        </h1>
        {(withdrawals.withdrawal.Type == 0 || withdrawals.withdrawal.Type == 2 || withdrawals.withdrawal.Type == 3) ?
          (
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Moneda</th>
                  <th scope="col">{`Ticket (unidades)`}</th>
                  <th scope="col">{`Recuento (unidades)`}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>0.50 €</td>
                  <td>
                    <Field name="Units050CoinCash"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                  <td>
                    <Field name="Units050CoinCashReal"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                </tr>
                <tr>
                  <td>1 €</td>
                  <td>
                    <Field name="Units100CoinCash"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                  <td>
                    <Field name="Units100CoinCashReal"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                </tr>
                <tr>
                  <td>2 €</td>
                  <td>
                    <Field name="Units200CoinCash"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                  <td>
                    <Field name="Units200CoinCashReal"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Billete</th>
                  <th scope="col">{`Ticket (unidades)`}</th>
                  <th scope="col">{`Recuento (unidades)`}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>5 €</td>
                  <td>
                    <Field name="Units5BillCash"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                  <td>
                    <Field name="Units50BillCashReal"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                </tr>
                <tr>
                  <td>10 €</td>
                  <td>
                    <Field name="Units10BillCash"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                  <td>
                    <Field name="Units10BillCashReal"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                </tr>
                <tr>
                  <td>20 €</td>
                  <td>
                    <Field name="Units20BillCash"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                  <td>
                    <Field name="Units20BillCashReal"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                </tr>
                <tr>
                  <td>50 €</td>
                  <td>
                    <Field name="Units50BillCash"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                  <td>
                    <Field name="Units50BillCashReal"
                      component={renderInputField}
                      type="text"
                      label="" />
                  </td>
                </tr>
              </tbody>
            </table>
          )
        }
        <Box title="Ingresado" icon="user">
          <Field name="Bank"
            component={renderSelectField}
            label="">
            <option value="">El dinero ha sido ingresado?</option>
            <option value="true">SI</option>
            <option value="false">NO</option>
          </Field>
        </Box>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1" type="submit"
          disabled={pristine || submitting}>
          Cuadrar
        </button>
      </form>
    )
  }
}

WithdrawalEditForm = reduxForm({
  form: 'WithdrawalEditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/withdrawals`)
  }
})(WithdrawalEditForm)

const mapStateToProps = state => ({
  withdrawals: state.withdrawals,
  initialValues: state.withdrawals.withdrawal
})

const mapDispatchToProps = dispatch => ({
  fetchWithdrawal: bindActionCreators(fetchWithdrawal, dispatch),
  updateWithdrawal: bindActionCreators(updateWithdrawal, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalEditForm)


