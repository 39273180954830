import React from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchIncidences } from '../actions/incidences'
import { fetchMachines } from '../actions/machines'
import { fetchCustomers } from '../actions/customers'

import { renderInputField, renderSelectField } from '../utils/forms'

class IncidencesFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchMachines, fetchCustomers } = this.props

    fetchMachines()
    fetchCustomers()

    this.load(this.props, true)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props, refresh = false) {
    const { machines, customers, incidences } = props

    if (refresh || (!incidences.incidences && !incidences.loading)) {
      this.submit(this.props.formValues)
    }

    if (!this.state.loaded && machines && customers) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    return this.props.fetchIncidences(props.Machine, props.Customer, props.Status, props.Category, props.StartDate, props.EndDate)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, machines, customers } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        <div className="row">
          <div className="col">
            <Field
              name="Customer"
              component={renderSelectField}
              label="Cliente"
            >
              <option value="">Todos</option>
              {customers &&
                customers
                  .filter((customer) => customer.Type === 'GENERAL')
                  .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                  .map((customer) => (
                    <option value={customer._id} key={customer._id}>
                      {customer.Fullname}
                    </option>
                  ))}
            </Field>
          </div>
          <div className="col">
            <Field name="Machine"
              component={renderSelectField}
              label="Máquina">
              <option value="">Todas</option>
              {machines.map(machine => {
                return (
                  <option value={machine._id} key={machine._id}>
                    {machine.Serial}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className="col">
            <Field name="Category"
              component={renderSelectField}
              label="Categoria">
              <option value="">Todas</option>
              <option value="Minor">Pregunta</option>
              <option value="Breakdown">Avería</option>
              <option value="UrgentBreakdown">Avería Urgente</option>
            </Field>
          </div>
          <div className="col">
            <Field name="Status"
              component={renderSelectField}
              label="Estado">
              <option value="">Todos</option>
              <option value="OPEN">Abiertas</option>
              <option value="REPAIR">En Reparación</option>
              <option value="CLOSED">Cerradas</option>
            </Field>
          </div>
          <div className="col">
            <Field name="StartDate"
              component={renderInputField}
              type="date"
              label="Fecha inicio" />
          </div>
          <div className="col">
            <Field name="EndDate"
              component={renderInputField}
              type="date"
              label="Fecha fin" />
          </div>
        </div>
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row">
          <button className="btn bg-vw-dark text-white mr-1 button-center"
            type="submit"
            disabled={submitting}>
            <i className="fas fa-sync mr-1"></i>Actualizar
          </button>
        </div>
      </form>
    )
  }
}

IncidencesFilterForm = reduxForm({
  form: 'IncidencesFilterForm',
  destroyOnUnmount: false
})(IncidencesFilterForm)

const selector = formValueSelector('IncidencesFilterForm')

const mapStateToProps = state => ({
  machines: state.machines.machines,
  incidences: state.incidences,
  customers: state.customers.customers,
  initialValues: {
    Machine: '',
    Customer: '',
    Status: '',
    Category: '',
    StartDate: new Date().toISOString().substring(0, 10),
    EndDate: new Date().toISOString().substring(0, 10)
  },
  formValues: {
    Machine: selector(state, 'Machine'),
    Customer: selector(state, 'Customer'),
    Status: selector(state, 'Status'),
    Category: selector(state, 'Category'),
    StartDate: selector(state, 'StartDate'),
    EndDate: selector(state, 'EndDate')
  }
})

const mapDispatchToProps = dispatch => ({
  fetchIncidences: bindActionCreators(fetchIncidences, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidencesFilterForm)
