import { CALL_API } from '../middleware/api'

export const SUPPORTTICKETS_REQUEST = 'SUPPORTTICKETS_REQUEST'
export const SUPPORTTICKETS_SUCCESS = 'SUPPORTTICKETS_SUCCESS'
export const SUPPORTTICKETS_FAILURE = 'SUPPORTTICKETS_FAILURE'

export function fetchSupportTickets(Machine, Customer, Status, Category, StartDate, EndDate) {
  let params = ''

  if (Customer !== undefined) {
    params += `&Customer=${Customer}`
  }

  if (Machine !== undefined) {
    params += `&Machine=${Machine}`
  }

  if (Status !== undefined) {
    params += `&Status=${Status}`
  }

  if (Category !== undefined) {
    params += `&Category=${Category}`
  }

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `supporttickets?${params}`,
      authenticated: true,
      types: [SUPPORTTICKETS_REQUEST, SUPPORTTICKETS_SUCCESS, SUPPORTTICKETS_FAILURE]
    }
  }
}

export const SUPPORTTICKET_REQUEST = 'SUPPORTTICKET_REQUEST'
export const SUPPORTTICKET_SUCCESS = 'SUPPORTTICKET_SUCCESS'
export const SUPPORTTICKET_FAILURE = 'SUPPORTTICKET_FAILURE'

export function fetchSupportTicket(number) {
  if (typeof number === 'string') {
    number = parseInt(number, 10)
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `supporttickets/${number}`,
      authenticated: true,
      types: [SUPPORTTICKET_REQUEST, SUPPORTTICKET_SUCCESS, SUPPORTTICKET_FAILURE]
    }
  }
}

export const SUPPORTTICKET_ADD_REQUEST = 'SUPPORTTICKET_ADD_REQUEST'
export const SUPPORTTICKET_ADD_SUCCESS = 'SUPPORTTICKET_ADD_SUCCESS'
export const SUPPORTTICKET_ADD_FAILURE = 'SUPPORTTICKET_ADD_FAILURE'

export function addSupportTicket(supportticket) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'supporttickets',
      authenticated: true,
      body: JSON.stringify(supportticket),
      types: [SUPPORTTICKET_ADD_REQUEST, SUPPORTTICKET_ADD_SUCCESS,
        SUPPORTTICKET_ADD_FAILURE]
    }
  }
}

export const SUPPORTTICKET_DELETE_REQUEST = 'SUPPORTTICKET_DELETE_REQUEST'
export const SUPPORTTICKET_DELETE_SUCCESS = 'SUPPORTTICKET_DELETE_SUCCESS'
export const SUPPORTTICKET_DELETE_FAILURE = 'SUPPORTTICKET_DELETE_FAILURE'

export function closeTicketSupport(number) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `supporttickets/close/${number}`,
      authenticated: true,
      types: [
        SUPPORTTICKET_DELETE_REQUEST,
        SUPPORTTICKET_DELETE_SUCCESS,
        SUPPORTTICKET_DELETE_FAILURE
      ]
    }
  }
}
