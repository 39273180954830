import React from 'react'

class AssignedItemNumber extends React.Component {
    render() {
        return (
            <div className="table-responsive">
                <table className="table table-sm table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Número de Taquillas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">Total de Taquillas</th>
                            <td>{this.props.totalLockers}</td>
                        </tr>
                        {this.props.items.map(item => (
                            <tr key={item.code}>
                                <th scope="row">{item.code}</th>
                                <td>{item.lockerCount}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default AssignedItemNumber