import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UniversalUpdateAppAddForm from '../forms/UniversalUpdateAppAddForm'
import UniversalUpdateEspressifAddForm from '../forms/UniversalUpdateEspressifAddForm'
import { addUniversalUpdateParameters, addUniversalUpdateReboot, addUniversalUpdateUnrent } from '../actions/universalupdates'
import Box from '../components/Box'

class UniversalUpdatesView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    };

    this.updateReboot = this.updateReboot.bind(this);
    this.updateParameters = this.updateParameters.bind(this);
    this.updateUnrent = this.updateUnrent.bind(this);
  }

  componentDidMount() {

  }

  updateParameters() {
    this.props.addUniversalUpdateParameters()
  }

  updateReboot() {
    this.props.addUniversalUpdateReboot()
  }

  updateUnrent() {
    this.props.addUniversalUpdateUnrent()
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <h1 className="text-vw-dark">ACTUALIZACIONES GENERALES</h1>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <Box title="Actualizar todas las APPs de Kioscos" icon="hand-paper">
              <UniversalUpdateAppAddForm />
            </Box>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <Box title="Actualizar todos los Espressif" icon="hand-paper">
              <UniversalUpdateEspressifAddForm />
            </Box>
          </div>
        </div>
        <div className="row mb-2">
        <div className="col-xs-2 col-sm-2 col-md-2">
            <Box title="Parametros" icon="hand-paper">
              <button className="btn bg-vw-light text-white mr-1" onClick={this.updateParameters}>
                <i className="fas fa-bolt mr-1"></i>Ejecutar
              </button>
            </Box>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2">
            <Box title="Reiniciar" icon="hand-paper">
              <button className="btn bg-vw-light text-white mr-1" onClick={this.updateReboot}>
                <i className="fas fa-bolt mr-1"></i>Ejecutar
              </button>
            </Box>
          </div>
          <div className="col-xs-2 col-sm-2 col-md-2">
            <Box title="Desalquilar" icon="hand-paper">
              <button className="btn bg-vw-light text-white mr-1" onClick={this.updateUnrent}>
                <i className="fas fa-bolt mr-1"></i>Ejecutar
              </button>
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch => ({
  addUniversalUpdateParameters: bindActionCreators(addUniversalUpdateParameters, dispatch),
  addUniversalUpdateReboot: bindActionCreators(addUniversalUpdateReboot, dispatch),
  addUniversalUpdateUnrent: bindActionCreators(addUniversalUpdateUnrent, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UniversalUpdatesView)
