import React from 'react'
import { history } from '../store'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import * as dateFormat from 'dateformat'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { closeTicketSupport } from '../actions/supporttickets'

class SupportTicketOpenList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      deleting: false,
      deleteConfirmShown: false,
      deleteId: null,
    }

    this.closeTicket = this.closeTicket.bind(this)
    this.handleCloseTicket = this.handleCloseTicket.bind(this)
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this)
  }

  goTo(number, e) {
    history.push(`/supporttickets/${number}`)
  }

  handleCloseTicket(number, e) {

    e.stopPropagation();

    this.setState({
      deleteConfirmShown: true,
      deleteId: number
    })
  }

  deleteConfirmToggle() {
    this.setState({ deleteConfirmShown: !this.state.deleteConfirmShown })
  }

  closeTicket() {
    const { closeTicketSupport } = this.props

    this.setState({ deleting: true, deleteConfirmShown: false })
    closeTicketSupport(this.state.deleteId)
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fecha Apertura</th>
              <th scope="col">Cliente</th>
              <th scope="col">Máquina</th>
              <th scope="col">Categoria</th>
              <th scope="col">Descripción</th>
              <th scope="col">Estado</th>
              <th scope="col">Archivar</th>
            </tr>
          </thead>
          <tbody>
            {this.props.supporttickets.map(supportticket => {
              if (supportticket.Status === "OPEN") {
                return (
                  <tr key={supportticket.number}>
                    <th scope="row">{supportticket.number}</th>
                    <td>{(new Date(supportticket.TimeStamp)).toLocaleString()}</td>
                    <td>{supportticket.Customer.Fullname ? supportticket.Customer.Fullname : "VACWAY"}</td>
                    <td>{supportticket.Machine.Serial}</td>
                    <td>{supportticket.Category}</td>
                    <td>{supportticket.Description}</td>
                    <td>{supportticket.Status}</td>
                    <td>
                      <button className="btn btn-danger"
                        onClick={(e) => this.handleCloseTicket(supportticket.number, e)}>
                        <i className="fas fa-times mr-1"></i>Archivar
                      </button>
                    </td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
        {/* Confirm deletion */}
        <div>
          <Modal isOpen={this.state.deleteConfirmShown}
            toggle={this.deleteConfirmToggle}>
            <ModalHeader toggle={this.deleteConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de archivar el ticket?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.closeTicket}>
                Si
              </Button>
              <Button color="secondary" onClick={this.deleteConfirmToggle}>
                No
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  
})

const mapDispatchToProps = dispatch => ({
  closeTicketSupport: bindActionCreators(closeTicketSupport, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SupportTicketOpenList)


