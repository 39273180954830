import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { fetchSalesArqueos } from '../actions/arqueos'
import { fetchMachines } from '../actions/machines'
import { fetchCustomers } from '../actions/customers'

import { renderInputField, renderSelectField } from '../utils/forms'

class ArqueosSalesFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      loading: false
    }

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { role, fetchMachines, fetchCustomers } = this.props

    fetchMachines()

    if (role === 'ADMIN') {
      fetchCustomers()
    }

    this.load(this.props);
    this.savePdf = this.savePdf.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { role, machines, customers, arqueos } = props

    if (!arqueos.loading && arqueos.arqueosNeedRefresh) {
      this.submit(this.props.formValues)
    }

    if (!this.state.loaded && machines && (role !== 'ADMIN' || customers)) {
      this.setState({ loaded: true })
    }
  }

  savePdf = () => {
    // Calculate the size of the entire document
    const fullHeight = document.documentElement.scrollHeight;
    const fullWidth = document.documentElement.scrollWidth;
  
    // Settings for html2canvas
    const canvasOptions = {
      windowWidth: fullWidth,
      windowHeight: fullHeight,
      scrollX: -window.scrollX,
      scrollY: -window.scrollY,
      x: 0,
      y: 0,
      width: fullWidth,
      height: fullHeight,
    };
  
    html2canvas(document.body, canvasOptions).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
  
      // Define PDF size
      const pdfWidth = 210;  // A4 width in mm
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
  
      const pdf = new jsPDF('p', 'mm', [pdfWidth, pdfHeight]);
  
      // Add image to PDF
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save("VACWAY_KPIs.pdf");
    });
  }

  submit(props) {
    this.setState({ loading: true }); // Start loading

    const selectedYear = parseInt(props.Year, 10);
    const yearStart = new Date(selectedYear, 0, 1);
    const formattedStartDate =
      `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(selectedYear + 1, 0, 0);
    const formattedEndDate =
      `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    return this.props.fetchSalesArqueos(props.BusinessModel, props.Customer, formattedStartDate, formattedEndDate, props.Country)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        this.setState({ loading: false }); // Stop loading
        return response
      })
      .catch(error => {
        this.setState({ loading: false }); // Stop loading on error as well
        throw error;
      });
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, machines, customers, role } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        {submitting ? (<div className="row"></div>) : (
          <div className="row">
            <div className="col">
              <Field
                name="Customer"
                component={renderSelectField}
                label="Cliente"
              >
                <option value="">Todos</option>
                {customers &&
                  customers
                    .filter((customer) => customer.Type === 'GENERAL')
                    .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                    .map((customer) => (
                      <option value={customer._id} key={customer._id}>
                        {customer.Fullname}
                      </option>
                    ))}
              </Field>
            </div>
            {role === 'ADMIN' &&
              <div className="col">

                <Field name="BusinessModel"
                  component={renderSelectField}
                  label="Modelo">
                  <option value="">Todos</option>
                  <option value="REVENUE SHARE">REVENUE SHARE</option>
                  <option value="SPACE RENTAL">SPACE RENTAL</option>
                  <option value="SALE">VENTA</option>
                </Field>

              </div>
            }
            {role === 'ADMIN' &&
              <div className="col">

                <Field name="Country"
                  component={renderSelectField}
                  label="País">
                  <option value="">Todos</option>
                  <option value="España">
                    España
                  </option>
                  <option value="Italia">
                    Italia
                  </option>
                </Field>

              </div>
            }
            <div className="col">
              <Field
                name="Year"
                component={renderSelectField}
                label="Año"
              >
                {Array.from({ length: 13 }, (_, i) => 2018 + i).map((year) => (
                  <option value={year} key={year}>
                    {year}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        )}
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row justify-content-center mt-2">
          {submitting ? (<div class="loading-wheel"></div>) : (
            <button className="btn bg-vw-dark text-white mr-2"
              type="submit"
              disabled={submitting}>
              <i className="fas fa-sync mr-1"></i>
              Aplicar filtros
            </button>
          )}
          {submitting ? (<div class="loading-wheel"></div>) : (
            <button
              className="btn bg-vw-excel text-white ml-2"
              onClick={this.savePdf}
              type="button"
              disabled={submitting}
            >
              <i className="fas fa-file-excel mr-1"></i>
              Guardar PDF
            </button>
          )}
        </div> 
      </form>
    )
  }
}

ArqueosSalesFilterForm = reduxForm({
  form: 'ArqueosFilterForm',
})(ArqueosSalesFilterForm)

const selector = formValueSelector('ArqueosFilterForm')

const mapStateToProps = state => ({
  machines: state.machines.machines,
  customers: state.customers.customers,
  arqueos: state.arqueos,
  role: state.auth.role,
  initialValues: {
    BusinessModel: '',
    Customer: '',
    Country: '',
    Year: new Date().getFullYear().toString(),
  },
  formValues: {
    BusinessModel: selector(state, 'BusinessModel'),
    Customer: selector(state, 'Customer'),
    Country: selector(state, 'Country'),
    Year: selector(state, 'Year'),
  }
})

const mapDispatchToProps = dispatch => ({
  fetchSalesArqueos: bindActionCreators(fetchSalesArqueos, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ArqueosSalesFilterForm)
