import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { deleteSinglePrice, modifySinglePrice } from '../actions/calendars'

class Calendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedPrice: null,           // Stores the selected price object for modification or deletion
      newPrice: '',                  // Stores the new price input value
      showModal: null,               // Controls which modal is shown: 'delete' or 'modify'
      selectedPriceEntry: null,      // Tracks the clicked price entry to show the buttons
      selectedDayEntry: null,
      selectedMonthEntry: null,
      selectedYearEntry: null,
    };
  }

  generateCalendarDays(month, year) {
    const days = [];
    const firstDate = new Date(year, month, 1);
    const lastDate = new Date(year, month + 1, 0);

    let firstDayIndex = firstDate.getDay();

    // Adjust firstDayIndex so that 0 represents Monday
    firstDayIndex = (firstDayIndex === 0) ? 6 : firstDayIndex - 1;

    // Add empty slots for the days before the first day of the month
    for (let i = 0; i < firstDayIndex; i++) {
      days.push({ day: null });
    }

    // Add the actual days of the month
    for (let i = 1; i <= lastDate.getDate(); i++) {
      const dayDate = new Date(year, month, i);

      // Correct way to compare dates (ignoring time)
      const priceData = this.props.priceCalendar.find(
        (entry) => {
          const entryDate = new Date(entry.date);
          return (
            entryDate.getDate() === dayDate.getDate() &&
            entryDate.getMonth() === dayDate.getMonth() &&
            entryDate.getFullYear() === dayDate.getFullYear()
          );
        }
      );

      days.push({ day: i, priceData });
    }

    return days;
  }

  getZoneName(zone) {

    switch (zone) {
      case 0:
        return 'GENERAL';
      case 1:
        return 'BAQUEIRA';
      case 2:
        return 'BERET';
      case 3:
        return 'BONAIGUA';
      case 4:
        return 'RUDA';
      case 5:
        return 'BOSQUE';
      default:
        return 'NONE';
    }
  }

  getZoneColor(zone) {

    switch (zone) {
      case 0:
        return 'bg-primary';
      case 1:
        return 'bg-white';
      case 2:
        return 'bg-success';
      case 3:
        return 'bg-info';
      case 4:
        return 'bg-warning';
      case 5:
        return 'bg-secondary';
      default:
        return 'bg-danger';
    }
  }

  getTypeName(type) {

    switch (type) {
      case 'BN3XL':
        return '2 PAX';
      case 'CN3XL':
        return '3 PAX';
      case 'BW3XL':
        return '4 PAX';
      default:
        return 'NONE';
    }
  }

  // Method to handle opening the modification modal
  handleModifyPrice = (price) => {
    this.setState({ selectedPrice: price, newPrice: price.price, showModal: 'modify' });
  };

  // Method to handle opening the deletion modal
  handleDeletePrice = (price) => {
    this.setState({ selectedPrice: price, showModal: 'delete' });
  };

  // Method to confirm and execute deletion
  handleConfirmDelete = (event) => {
    event.preventDefault();
    const { selectedPrice } = this.state;
    this.props.deleteSinglePrice(selectedPrice._id)
    this.setState({ showModal: null, selectedPrice: null });
  };

  // Method to handle the form submission for modification
  handleConfirmModify = (event) => {
    event.preventDefault();
    const { selectedPrice, newPrice } = this.state;
    this.props.modifySinglePrice(selectedPrice._id, parseFloat(newPrice))
    this.setState({ showModal: null, selectedPrice: null, newPrice: '' });
  };

  // Method to close the modal without action
  handleCloseModal = () => {
    this.setState({ showModal: null, selectedPrice: null, newPrice: '' });
  };

  // Method to handle input changes for the new price
  handlePriceChange = (event) => {
    this.setState({ newPrice: event.target.value });
  };

  handlePriceEntryClick = (price, day, month, year) => {
    this.setState({
      selectedPriceEntry: this.state.selectedPriceEntry === price ? null : price,
      selectedDayEntry: day,
      selectedMonthEntry: month,
      selectedYearEntry: year,
    });
  };

  // Render the modal based on state
  renderModal() {
    const { showModal, selectedPrice, newPrice } = this.state;

    if (!showModal) return null;

    return (
      <div>
        {showModal === 'delete' ? (
          <div className="text-center">
            <h4>Confirmar Eliminación</h4>
            <p>¿Está seguro que desea eliminar esta entrada de precio?</p>
            <form onSubmit={this.handleConfirmDelete}>
              <div>
                <button className="btn bg-info text-white mr-5" type="submit">Sí, eliminar</button>
                <button className="btn bg-danger text-white" type="button" onClick={this.handleCloseModal}>Cancelar</button>
              </div>
            </form>
          </div>
        ) : (
          <div className="text-center">
            <h4 className="mb-5">Modificar Precio</h4>
            <form onSubmit={this.handleConfirmModify}>
              <div>
                <label htmlFor="newPrice" className="mr-5">Nuevo Precio: </label>
                <input
                  id="newPrice"
                  type="number"
                  value={newPrice}
                  onChange={this.handlePriceChange}
                  step="1"
                  required
                  className="text-center mt-2 mb-2"
                />
              </div>
              <div className="text-center mt-5">
                <button className="btn bg-info text-white mr-5" type="submit">Guardar</button>
                <button className="btn bg-danger text-white" type="button" onClick={this.handleCloseModal}>Cancelar</button>
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { year, startMonth, endMonth } = this.props;
    const { selectedPriceEntry, selectedDayEntry, selectedMonthEntry, selectedYearEntry } = this.state;

    const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    // Determine the range of months to display
    const monthsToDisplay = [];
    if (startMonth <= endMonth) {
      for (let i = startMonth; i <= endMonth; i++) {
        monthsToDisplay.push({ month: i, year });
      }
    } else {
      for (let i = startMonth; i <= 11; i++) {
        monthsToDisplay.push({ month: i, year });
      }
      for (let i = 0; i <= endMonth; i++) {
        monthsToDisplay.push({ month: i, year: year + 1 });
      }
    }

    return (
      <div className="mt-5">
        {monthsToDisplay.map(({ month, year }) => {
          const days = this.generateCalendarDays(month, year);
          const hasPrices = days.some(day => day.priceData);

          if (!hasPrices) return null;

          return (
            <div key={`${month}-${year}`} className="calendar">
              <h5>{monthNames[month]} {year}</h5>
              <div className="calendar-grid">
                {['Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb', 'Dom'].map((day) => (
                  <div key={day} className="calendar-day-header">{day}</div>
                ))}
                {days.map((day, index) => (
                  <div
                    key={index}
                    className={`calendar-operator-day ${day.day ? (day.priceData ? 'with-price' : 'no-price') : 'no-day'}`}>
                    {day.day && (
                      <>
                        <span className="day-number">{day.day}</span>
                        {day.priceData && (
                          <div className="price-info">
                            {day.priceData.prices
                              .slice() // Create a shallow copy of the array to avoid mutating the original array
                              .sort((a, b) => {
                                const nameA = this.getZoneName(a.zone).toUpperCase(); // Convert to uppercase to ensure case-insensitive comparison
                                const nameB = this.getZoneName(b.zone).toUpperCase(); // Convert to uppercase to ensure case-insensitive comparison
                                if (nameA < nameB) return -1;
                                if (nameA > nameB) return 1;
                                return 0;
                              })
                              .map((price, idx) => (
                                <div key={idx} className={`price-entry ${this.getZoneColor(price.zone)}`} onClick={() => this.handlePriceEntryClick(price, day.day, month, year)}>
                                  <span>{this.getZoneName(price.zone)} - {this.getTypeName(price.type)}: {(price.price).toFixed(2)}€</span>
                                  {selectedPriceEntry === price && (
                                    <div className="price-buttons">
                                      <button className="btn bg-dark text-white" onClick={() => this.handleModifyPrice(price)}>Modificar</button>
                                      <button className="btn bg-danger text-white" onClick={() => this.handleDeletePrice(price)}>Eliminar</button>
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
        <div>
          <Modal isOpen={this.state.showModal}>
            <ModalHeader toggle={this.handleCloseModal}>
              {selectedDayEntry !== null && selectedMonthEntry !== null && selectedYearEntry !== null && selectedPriceEntry !== null ? (
                <div className="text-center">
                  <p>Entrada de precio</p>
                  <p className="text-vw-dark">{selectedDayEntry} de {monthNames[selectedMonthEntry]} de {selectedYearEntry}</p>
                  <p>{this.getZoneName(selectedPriceEntry.zone)} - {this.getTypeName(selectedPriceEntry.type)}: {(selectedPriceEntry.price).toFixed(2)}€</p>
                </div>
              ) : (
                <div className="text-center">
                  <p>No hay información disponible</p>
                </div>
              )}
            </ModalHeader>
            <ModalBody>
              {this.renderModal()}
            </ModalBody>
            <ModalFooter>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  priceCalendar: state.calendars.calendar.PriceCalendar,
  loading: state.calendars.loading,
  error: state.calendars.error,
});

const mapDispatchToProps = dispatch => ({
  deleteSinglePrice: bindActionCreators(deleteSinglePrice, dispatch),
  modifySinglePrice: bindActionCreators(modifySinglePrice, dispatch),
  push: bindActionCreators(push, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);