import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { fetchCustomer, updateCustomer } from '../actions/customers'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

class CustomerEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }
    this.submit = this.submit.bind(this)
    this.renderItems = this.renderItems.bind(this)
  }

  componentDidMount() {
    const { customers, fetchCustomer, match } = this.props

    if (!customers.customer) {
      fetchCustomer(match.params.id)
    } else {
      this.setState({ loaded: true })
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.loaded && nextProps.customers.customer) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    const { updateCustomer, customers } = this.props

    props.Executor = this.props.fullname

    return updateCustomer(customers.customer._id, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  renderZones(props) {
    const { fields } = props

    return (
      <div>
        <div className="table-responsive">
          <table className="table table-sm table-stripped">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Zona</th>
                <th scope="col">Descripción</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {fields.map((zone, index) => (

                <tr key={index}>
                  <td>
                    <Field
                      name={`${zone}.Zone`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <Field
                      name={`${zone}.Description`}
                      type="text"
                      component={renderInputField}
                    />
                  </td>
                  <td>
                    <button className="btn btn-danger mr-1" type="button"
                      onClick={() => fields.remove(index)}>
                      <i className="fas fa-trash mr-1"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="text-right">
          <button className="btn bg-vw-light text-white" type="button"
            onClick={() => fields.push({})}>
            <i className="fas fa-plus mr-1"></i>
            Añadir
          </button>
        </div>
      </div>
    )
  }

  renderItems(props) {
    const { fields } = props;

    const handleDragEnd = (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(fields);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      // Manually update the fields array with the new order
      fields.removeAll();

      // Push the reordered items back into the fields array, preserving comission and item.item values
      items.forEach((item) => {
        fields.push({

          item: {
            item: item.item.item,
            comission: item.item.comission
          },
        });
      });
    };

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <div>
          <div className="table-responsive">
            <table className="table table-sm table-stripped">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Artículo</th>
                  <th scope="col">Comisión Cliente (%)</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <Droppable droppableId="fields">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {fields.map((member, index) => (
                      <Draggable key={index} draggableId={member} index={index}>
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>
                              <Field
                                name={`${member}.item`}
                                component={renderSelectField}
                              >
                                <option value="" key="default">Selecciona un artículo...</option>
                                <option value="LOCKERBNS" key="LOCKERBNS">Básica</option>
                                <option value="LOCKERCNS" key="LOCKERCNS">Básica Carga</option>
                                <option value="LOCKERBWS" key="LOCKERBWS">Básica XL</option>
                                <option value="LOCKERCWS" key="LOCKERCWS">Básica Carga XL</option>
                                <option value="LOCKERBNM" key="LOCKERBNM">Grande</option>
                                <option value="LOCKERCNM" key="LOCKERCNM">Grande Carga</option>
                                <option value="LOCKERBWM" key="LOCKERBWM">Grande XL</option>
                                <option value="LOCKERCWM" key="LOCKERCWM">Grande Carga XL</option>
                                <option value="LOCKERBNL" key="LOCKERBNL">Jumbo</option>
                                <option value="LOCKERCNL" key="LOCKERCNL">Jumbo Carga</option>
                                <option value="LOCKERBWL" key="LOCKERBWL">Jumbo XL</option>
                                <option value="LOCKERCWL" key="LOCKERCWL">Jumbo Carga XL</option>
                                <option value="LOCKERBNXL" key="LOCKERBNXL">Jumbo 4</option>
                                <option value="LOCKERCNXL" key="LOCKERCNXL">Jumbo 4 Carga</option>
                                <option value="LOCKERBWXL" key="LOCKERBWXL">Jumbo 4 XL</option>
                                <option value="LOCKERCWXL" key="LOCKERCWXL">Jumbo 4 Carga XL</option>
                                <option value="LOCKERBN2XL" key="LOCKERBN2XL">Jumbo 5</option>
                                <option value="LOCKERCN2XL" key="LOCKERCN2XL">Jumbo 5 Carga</option>
                                <option value="LOCKERBW2XL" key="LOCKERBW2XL">Jumbo 5 XL</option>
                                <option value="LOCKERCW2XL" key="LOCKERCW2XL">Jumbo 5 Carga XL</option>
                                <option value="LOCKERBN3XL" key="LOCKERBN3XL">Ski</option>
                                <option value="LOCKERCN3XL" key="LOCKERCN3XL">Ski Carga</option>
                                <option value="LOCKERBW3XL" key="LOCKERBW3XL">Ski XL</option>
                                <option value="LOCKERCW3XL" key="LOCKERCW3XL">Ski Carga XL</option>
                                <option value="TUMBONAS" key="TUMBONAS">Tumbonas</option>
                                <option value="FLOTADORES" key="FLOTADORES">Flotadores</option>
                                <option value="FLOTADORESHALFDAY" key="FLOTADORESHALFDAY">Flotadores Medio Día</option>
                                <option value="FASTPASS" key="FASTPASS">Fastpass</option>
                              </Field>
                            </td>
                            <td>
                              <Field
                                name={`${member}.comission`}
                                type="text"
                                component={renderInputField}
                              />
                            </td>
                            <td>
                              <button
                                className="btn btn-danger mr-1"
                                type="button"
                                onClick={() => fields.remove(index)}
                              >
                                <i className="fas fa-trash mr-1"></i>
                              </button>
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </div>
          <div className="text-right">
            <button
              className="btn bg-vw-light text-white"
              type="button"
              onClick={() => fields.push({})}
            >
              <i className="fas fa-plus mr-1"></i>
              Añadir
            </button>
          </div>
        </div>
      </DragDropContext>
    );
  }

  render() {
    const { customers, error, pristine, submitting, handleSubmit } = this.props

    if (!this.state.loaded) {
      if (customers.error) {
        return (
          <ErrorMessage message={customers.error.message} />
        )
      }

      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">EDITAR </span>
          <span className="text-vw-light">CLIENTE</span>
        </h1>
        <div className="row">
          <div className="col">
            <Box title="Cliente" icon="user">
              <Field name="Fullname"
                type="text"
                component={renderInputField}
                label="Nombre completo" />
              <Field name="Category"
                component={renderSelectField}
                label="Categoria">
                <option value="">Selecciona una categoria...</option>
                <option value="BIGPLAYER">Big player</option>
                <option value="AWATERPARK">A Waterpark</option>
                <option value="BWATERPARK">B Waterpark</option>
                <option value="CWATERPARK">C Waterpark</option>
                <option value="BAQUEIRA">BAQUEIRA</option>
              </Field>
              <Field name="Contact_person"
                type="text"
                component={renderInputField}
                label="Persona de contacto" />
              <Field name="Email"
                type="text"
                component={renderInputField}
                label="E-mail" />
              <Field name="Password"
                type="password"
                component={renderInputField}
                label="Password" />
              <Field name="Address"
                type="text"
                component={renderTextAreaField}
                label="Dirección" />
              <Field name="Phone"
                type="text"
                component={renderInputField}
                label="Teléfono 1" />
              <Field name="PostalCode"
                type="text"
                component={renderInputField}
                label="Código Postal" />
              <Field name="City"
                type="text"
                component={renderInputField}
                label="Ciudad" />
              <Field name="Province"
                type="text"
                component={renderInputField}
                label="Provincia" />
              <Field name="Language"
                type="text"
                component={renderInputField}
                label="Idioma" />
              <Field name="Country"
                component={renderSelectField}
                label="País">
                <option value="">Selecciona un país...</option>
                <option value="España">España</option>
                <option value="Francia">Francia</option>
                <option value="Italia">Italia</option>
                <option value="Chequia">Chequia</option>
                <option value="Germany">Alemania</option>
                <option value="Netherlands">Holanda</option>
              </Field>
            </Box>
            <Box title="Extra" icon="file-alt">
              <Field name="Group"
                component={renderSelectField}
                label="Grupo al que pertenece">
                <option value="">Selecciona un grupo...</option>
                <option value="ASPRO">ASPRO PARKS</option>
                <option value="GRPR">GRUPO PARQUES REUNIDOS</option>
                <option value="SANTAMARIA">GRUPO SANTAMARIA</option>
                <option value="ISLAMAGICA">GRUPO ISLA MAGICA</option>
              </Field>
              <Field name="ExternalId"
                type="text"
                component={renderInputField}
                label="Identificador Externo" />
              <Field name="IncidencesEmail"
                type="text"
                component={renderInputField}
                label="Email de Reporte" />
            </Box>
          </div>
          <div className="col">
            <Box title="Facturación" icon="file-alt">
              <Field name="Invoice_taxid"
                type="text"
                component={renderInputField}
                label="NIF/CIF" />
              <Field name="Invoice_tax"
                type="text"
                component={renderInputField}
                label="IVA aplicable (%)" />
              <Field name="Social_name"
                type="text"
                component={renderInputField}
                label="Razón social" />
              <Field name="Invoice_address"
                type="text"
                component={renderInputField}
                label="Dirección de facturación" />
            </Box>
            <Box title="Modelo de Negocio" icon="briefcase">
              <Field name="BusinessModel"
                component={renderSelectField}
                label="Modelo">
                <option value="">Selecciona un modelo...</option>
                <option value="REVENUE SHARE">REVENUE SHARE</option>
                <option value="SPACE RENTAL">SPACE RENTAL</option>
                <option value="SALE">VENTA</option>
              </Field>
              <Field name="SpaceRentalAlquiler"
                component={renderInputField}
                type="text"
                label="Alquiler Anual" />
            </Box>
            <Box title="TPV Virtual" icon="file-alt">
              <Field name="Redsys.MERCHANTCODE"
                type="text"
                component={renderInputField}
                label="Código de comercio" />
              <Field
                name="Redsys.SECRETKEY"
                type="text"
                component={renderInputField}
                label="Clave de cifrado" />
              <Field
                name="Redsys.CURRENCY"
                type="text"
                component={renderInputField}
                label="Moneda" />
              <Field
                name="Redsys.TERMINAL"
                type="text"
                component={renderInputField}
                label="Terminal" />
              <Field
                name="Redsys.MERCHANTURL"
                type="text"
                component={renderInputField}
                label="URL Tienda" />
            </Box>
            <Box title="SAP" icon="file-alt">
              <Field
                name="SAP.Model"
                component={renderSelectField}
                label="Modelo">
                <option value="">Selecciona un modelo...</option>
                <option value="VC">VENTAS CONTADO (Punto de Venta)</option>
                <option value="CV">COMISION VENTA (Solicitud Factura)</option>
              </Field>
              <Field
                name="SAP.CompanyID"
                component={renderSelectField}
                label="CompanyID">
                <option value="">Selecciona una empresa...</option>
                <option value="ES1000">ES1000</option>
                <option value="IT1000">IT1000</option>
              </Field>
              <Field
                name="SAP.CountryCode"
                component={renderSelectField}
                label="CountryCode">
                <option value="">Selecciona un código de país...</option>
                <option value="ES">ESPAÑA</option>
                <option value="IT">ITALIA</option>
                <option value="EU">EUROPA</option>
              </Field>
              <Field name="SAP.ClientID"
                type="text"
                component={renderInputField}
                label="ClientID" />
              <Field
                name="SAP.SiteID"
                type="text"
                component={renderInputField}
                label="SiteID" />
              <Field
                name="SAP.TaxCountryCode"
                type="text"
                component={renderInputField}
                label="TaxCountryCode" />
              <Field
                name="SAP.TaxationCharacteristicsCode"
                type="text"
                component={renderInputField}
                label="TaxationCharacteristicsCode" />
              <Field
                name="SAP.InventoryManagedLocationID"
                type="text"
                component={renderInputField}
                label="InventoryManagedLocationID" />
              <Field
                name="SAP.LogisticsAreaID"
                type="text"
                component={renderInputField}
                label="LogisticsAreaID" />
              <Field
                name="SAP.TaxRegionCode"
                type="text"
                component={renderInputField}
                label="TaxRegionCode" />
            </Box>
          </div>
          <div className="col">
            <Box title="Artículos" icon="shopping-cart">
              <FieldArray name="Items" component={this.renderItems} />
            </Box>
            <Box title="">
              <FieldArray name="Zones" component={this.renderZones} />
            </Box>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

CustomerEditForm = reduxForm({
  form: 'CustomerEditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/customers/${result.response._id}`)
  }
})(CustomerEditForm)

const mapStateToProps = state => ({
  customers: state.customers,
  initialValues: state.customers.customer,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch),
  updateCustomer: bindActionCreators(updateCustomer, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEditForm)


