import {
  RULES_REQUEST, RULES_SUCCESS, RULES_FAILURE,
  RULE_ADD_REQUEST, RULE_ADD_SUCCESS, RULE_ADD_FAILURE,
  RULE_UPDATE_REQUEST, RULE_UPDATE_SUCCESS, RULE_UPDATE_FAILURE,
  RULE_REQUEST, RULE_SUCCESS, RULE_FAILURE
} from '../actions/rules'

const INITIAL_STATE = {
  rule: null, rules: null, error: null, loading: false
}

const rulesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RULES_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case RULES_SUCCESS:
      return {
        ...state,
        rules: action.response, error: null, loading: false
      }
    case RULES_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case RULE_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case RULE_ADD_SUCCESS:
      return {
        ...state,
        rule: action.response, error: null, loading: false
      }
    case RULE_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case RULE_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case RULE_UPDATE_SUCCESS:
      return {
        ...state,
        rule: action.response, error: null, loading: false
      }
    case RULE_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case RULE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case RULE_SUCCESS:
      return {
        ...state,
        rule: action.response, error: null, loading: false
      }
    case RULE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default rulesReducer


