import { CALL_API } from '../middleware/api'

export const RENTALS_REQUEST = 'RENTALS_REQUEST'
export const RENTALS_SUCCESS = 'RENTALS_SUCCESS'
export const RENTALS_FAILURE = 'RENTALS_FAILURE'

export function fetchRentals(machine, locker, startDate, endDate) {
  let params = ''

  if (machine !== undefined) {
    params += `&machine=${machine}`
  }

  if (locker !== undefined) {
    params += `&locker=${locker}`
  }

  if (startDate !== undefined) {
    params += `&startDate=${startDate}`
  }

  if (endDate !== undefined) {
    params += `&endDate=${endDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `rentals?${params}`,
      authenticated: true,
      types: [RENTALS_REQUEST, RENTALS_SUCCESS, RENTALS_FAILURE]
    }
  }
}

export const RENTAL_ADD_REQUEST = 'RENTAL_ADD_REQUEST'
export const RENTAL_ADD_SUCCESS = 'RENTAL_ADD_SUCCESS'
export const RENTAL_ADD_FAILURE = 'RENTAL_ADD_FAILURE'

export function addRental(rental) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'rentals',
      authenticated: true,
      body: JSON.stringify(rental),
      types: [RENTAL_ADD_REQUEST, RENTAL_ADD_SUCCESS, RENTAL_ADD_FAILURE]
    }
  }
}

export function addCounterInvoiceRental(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `rentals/counterinvoice/${id}`,
      authenticated: true,
      types: [RENTAL_ADD_REQUEST, RENTAL_ADD_SUCCESS, RENTAL_ADD_FAILURE]
    }
  }
}

export const RENTAL_UPDATE_REQUEST = 'RENTAL_UPDATE_REQUEST'
export const RENTAL_UPDATE_SUCCESS = 'RENTAL_UPDATE_SUCCESS'
export const RENTAL_UPDATE_FAILURE = 'RENTAL_UPDATE_FAILURE'

export function updateRental(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `rentals/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [RENTAL_UPDATE_REQUEST, RENTAL_UPDATE_SUCCESS,
        RENTAL_UPDATE_FAILURE]
    }
  }
}

export const RENTAL_REQUEST = 'RENTAL_REQUEST'
export const RENTAL_SUCCESS = 'RENTAL_SUCCESS'
export const RENTAL_FAILURE = 'RENTAL_FAILURE'

export function fetchRental(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `rentals/${id}`,
      authenticated: true,
      types: [RENTAL_REQUEST, RENTAL_SUCCESS, RENTAL_FAILURE]
    }
  }
}
