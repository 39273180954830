import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchMachines } from '../actions/machines'

import MachineList from '../components/MachineList'
import ErrorMessage from '../components/ErrorMessage'
import MachinesCashSummary from "../components/MachinesCashSummary";
import { fetchBateriesCSV } from '../actions/matrixs'
import { fetchSNCSV } from '../actions/matrixs'

class MachinesView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      summaryOpen: false,
    }

    this.toggle = this.toggle.bind(this);
    this.handleDownloadBateriesCSV = this.handleDownloadBateriesCSV.bind(this);
    this.handleDownloadSNCSV = this.handleDownloadSNCSV.bind(this);
  }

  toggle() {
    this.setState({
      summaryOpen: !this.state.summaryOpen
    })
  }

  handleDownloadBateriesCSV() {
    const { fetchBateriesCSV } = this.props

    fetchBateriesCSV();
  }

  handleDownloadSNCSV() {
    const { fetchSNCSV } = this.props

    fetchSNCSV();
  }

  componentDidMount() {
    this.props.fetchMachines()
  }

  render() {
    const { machines, error, role, type } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!machines) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>

        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">PARQUE DE </span>
              <span className="text-vw-light">KIOSCOS</span>
            </h1>
          </div>
          {(role === 'ADMIN' && (type === 'GOD' || type === 'ROOT')) &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-center">
                <Link to="/machines/add">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus"></i>
                    <span className="d-none d-sm-inline ml-1">Nueva</span>
                  </button>
                </Link>
              </div>
              <div className="col d-flex justify-content-center">
                <button type="button" className="btn bg-vw-dark text-white" onClick={e => this.toggle()}>
                  <i className="fas fa-expand"></i>
                  <span className="d-none d-sm-inline ml-1">Ver cash parque</span>
                </button>
              </div>
              <div className="col d-flex justify-content-center">
                <button className="btn btn-info"
                  onClick={this.handleDownloadBateriesCSV}>
                  <i className="fas fa-file-excel"></i>
                  <span className="d-none d-sm-inline ml-1">Descargar Baterias</span>
                </button>
              </div>
              <div className="col d-flex justify-content-center">
                <button className="btn btn-warning"
                  onClick={this.handleDownloadSNCSV}>
                  <i className="fas fa-file-excel"></i>
                  <span className="d-none d-sm-inline ml-1">Descargar Listado SN</span>
                </button>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        {
          this.state.summaryOpen && <div>
            <MachinesCashSummary machines={machines} /> <br />
            <hr />
            <br />
          </div>
        }


        <MachineList machines={machines} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machines: state.machines.machines,
  error: state.machines.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchBateriesCSV: bindActionCreators(fetchBateriesCSV, dispatch),
  fetchSNCSV: bindActionCreators(fetchSNCSV, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MachinesView)
