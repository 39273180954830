import React from 'react'

class IncidenceTimeBadge extends React.Component {
  getColor() {

    if (this.props.category !== "Minor") {

      let difference = 0
      let calculeActualTime = this.props.hourDiff + (this.props.minuteDiff / 60)

      difference = parseInt(this.props.compareTime) - calculeActualTime

      if (difference < 0) {
        return "danger"
      } else {
        return "success"
      }
    }else{
      return "dark"
    }

  }

  render() {
    return (
      <div className={`badge badge-${this.getColor()}`}>
        {`${(this.props.hourDiff).toString().padStart(2, '0')}:${(this.props.minuteDiff).toString().padStart(2, '0')}h`}
      </div>
    )
  }
}

export default IncidenceTimeBadge

