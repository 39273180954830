import React from 'react'
import { Field, FieldArray, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import Box from '../components/Box'
import { bindActionCreators } from 'redux'
import ColorPickerField from '../utils/ColorPickerField'
import { renderInputField, renderSelectField } from '../utils/forms'

import { fetchArticles, updateArticle } from '../actions/articles'

class ArticlesView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      editSeason: false,
      editWhitelist: false,
      editClickAndCollect: false
    }

    this.handleSaveSection = this.handleSaveSection.bind(this)
    this.handleCancelEdit = this.handleCancelEdit.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.renderSeason = this.renderSeason.bind(this)
    this.renderOperators = this.renderOperators.bind(this)
    this.renderClick = this.renderClick.bind(this)
  }


  componentDidMount() {
    this.props.fetchArticles();
  }

  // Define the onSubmit method to handle form submission
  onSubmit(formValues) {
    console.log("formvalues: ", formValues)
    const updatedFormValues = {
      ...formValues,
      ClickAndCollect: (formValues.ClickAndCollect || []).map(locker => ({
        ...locker,
        type: locker.type || 'BN3XL', // Set default value for type if not provided
        zone: locker.zone || '1',      // Set default value for zone if not provided
        price: locker.price || '' // Ensure price is not undefined
      })),
      Season: (formValues.Season || []).map(locker => ({
        ...locker,
        type: locker.type || 'BN3XL', // Set default value for type if not provided
        zone: locker.zone || '1',      // Set default value for zone if not provided
        price: locker.price || '' // Ensure price is not undefined
      }))
    };
    return this.props.updateArticle(updatedFormValues).then(() => {
      this.props.fetchArticles();
    });
  }

  handleSaveSection(section) {
    // Trigger form submission to save the values
    this.props.handleSubmit(this.onSubmit)();

    // Switch back to the view mode for the specific section
    switch (section) {
      case 'season':
        this.setState({ editSeason: false });
        break;
      case 'whitelist':
        this.setState({ editWhitelist: false });
        break;
      case 'clickandcollect':
        this.setState({ editClickAndCollect: false });
        break;
      default:
        break;
    }
  }

  handleCancelEdit(section) {
    switch (section) {
      case 'season':
        this.setState({ editSeason: false })
        break
      case 'whitelist':
        this.setState({ editWhitelist: false })
        break
      case 'clickandcollect':
        this.setState({ editClickAndCollect: false })
        break
      default:
        break
    }
  }

  getZoneName(zone) {

    switch (zone) {
      case 0:
        return 'GENERAL';
      case 1:
        return 'BAQUEIRA';
      case 2:
        return 'BERET';
      case 3:
        return 'BONAIGUA';
      case 4:
        return 'RUDA';
      case 5:
        return 'BOSQUE';
      default:
        return 'NONE';
    }
  }

  getZoneColor(zone) {

    switch (zone) {
      case 0:
        return 'bg-primary';
      case 1:
        return 'bg-white';
      case 2:
        return 'bg-success';
      case 3:
        return 'bg-info';
      case 4:
        return 'bg-warning';
      case 5:
        return 'bg-secondary';
      default:
        return 'bg-danger';
    }
  }

  getTypeName(type) {

    switch (type) {
      case 'BN3XL':
        return '2 PAX';
      case 'CN3XL':
        return '3 PAX';
      case 'BW3XL':
        return '4 PAX';
      default:
        return 'NONE';
    }
  }

  renderSeason(props) {
    const { fields } = props
    return (
      <div>
        {this.state.editSeason && (
          <div className="table-responsive">
            <table className="table table-sm table-stripped">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Tipo</th>
                  <th scope="col">Zona</th>
                  <th scope="col">Precio</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {fields.map((locker, index) => (
                  <tr key={index}>
                    <td>
                      <Field name={`${locker}.type`}
                        component={renderSelectField}
                      >
                        <option value="BN3XL">2 PAX</option>
                        <option value="CN3XL">3 PAX</option>
                        <option value="BW3XL">4 PAX</option>
                      </Field>
                    </td>
                    <td>
                      <Field
                        name={`${locker}.zone`}
                        component={renderSelectField}
                      >
                        <option value="1">BAQUEIRA</option>
                        <option value="2">BERET</option>
                        <option value="3">BONAIGUA</option>
                        <option value="4">RUDA</option>
                        <option value="5">BOSQUE</option>
                      </Field>
                    </td>
                    <td>
                      <Field
                        name={`${locker}.price`}
                        type="number"
                        component={renderInputField}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => fields.remove(index)}
                      >
                        <i className="fas fa-trash mr-1"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-right">
              <button
                className="btn bg-vw-light text-white"
                type="button"
                onClick={() => fields.push({})}
              >
                <i className="fas fa-plus mr-1"></i> Añadir
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderOperators(props) {
    const { fields } = props
    return (
      <div>
        {this.state.editWhitelist && (
          <div className="table-responsive">
            <table className="table table-sm table-stripped">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Color</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {fields.map((operator, index) => (
                  <tr key={index}>
                    <td>
                      <Field
                        name={`${operator}.name`}
                        type="text"
                        component={renderInputField}
                      />
                    </td>
                    <td>
                      <div className="d-flex justify-content-center align-items-center form-group">
                        <Field
                          name={`${operator}.color`}
                          component={ColorPickerField}
                        />
                      </div>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => fields.remove(index)}
                      >
                        <i className="fas fa-trash mr-1"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-right">
              <button
                className="btn bg-vw-light text-white"
                type="button"
                onClick={() => fields.push({})}
              >
                <i className="fas fa-plus mr-1"></i> Añadir
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderClick(props) {
    const { fields } = props
    return (
      <div>
        {this.state.editClickAndCollect && (
          <div className="table-responsive">
            <table className="table table-sm table-stripped">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Tipo</th>
                  <th scope="col">Zona</th>
                  <th scope="col">Precio</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {fields.map((locker, index) => (
                  <tr key={index}>
                    <td>
                      <Field name={`${locker}.type`}
                        component={renderSelectField}
                      >
                        <option value="BN3XL">2 PAX</option>
                        <option value="CN3XL">3 PAX</option>
                        <option value="BW3XL">4 PAX</option>
                      </Field>
                    </td>
                    <td>
                      <Field
                        name={`${locker}.zone`}
                        component={renderSelectField}
                      >
                        <option value="1">BAQUEIRA</option>
                        <option value="2">BERET</option>
                        <option value="3">BONAIGUA</option>
                        <option value="4">RUDA</option>
                        <option value="5">BOSQUE</option>
                      </Field>
                    </td>
                    <td>
                      <Field
                        name={`${locker}.price`}
                        type="number"
                        component={renderInputField}
                      />
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        type="button"
                        onClick={() => fields.remove(index)}
                      >
                        <i className="fas fa-trash mr-1"></i>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="text-right">
              <button
                className="btn bg-vw-light text-white"
                type="button"
                onClick={() => fields.push({})}
              >
                <i className="fas fa-plus mr-1"></i> Añadir
              </button>
            </div>
          </div>
        )}
      </div>
    )
  }

  renderSeasons(seasons) {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Zona</th>
              <th scope="col">Tipo</th>
              <th scope="col">Precio</th>
            </tr>
          </thead>
          <tbody>
            {seasons
              .slice() // Create a shallow copy of the array to avoid mutating the original array
              .sort((a, b) => {
                const nameA = this.getZoneName(a.zone).toUpperCase(); // Convert to uppercase to ensure case-insensitive comparison
                const nameB = this.getZoneName(b.zone).toUpperCase(); // Convert to uppercase to ensure case-insensitive comparison
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map(season => (
                <tr>
                  <td className={`${this.getZoneColor(season.zone)}`} scope="row">{this.getZoneName(season.zone)}</td>
                  <td scope="row">{this.getTypeName(season.type)}</td>
                  <td scope="row">{(season.price).toFixed(2)}€</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  renderClickandcollects(clickandcollects) {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Zona</th>
              <th scope="col">Tipo</th>
              <th scope="col">Precio</th>
            </tr>
          </thead>
          <tbody>
            {clickandcollects
              .slice() // Create a shallow copy of the array to avoid mutating the original array
              .sort((a, b) => {
                const nameA = this.getZoneName(a.zone).toUpperCase(); // Convert to uppercase to ensure case-insensitive comparison
                const nameB = this.getZoneName(b.zone).toUpperCase(); // Convert to uppercase to ensure case-insensitive comparison
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return 0;
              })
              .map(clickandcollect => (
                <tr>
                  <td className={`${this.getZoneColor(clickandcollect.zone)}`} scope="row">{this.getZoneName(clickandcollect.zone)}</td>
                  <td scope="row">{this.getTypeName(clickandcollect.type)}</td>
                  <td scope="row">{(clickandcollect.price).toFixed(2)}€</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  renderWhitelists(whitelists) {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Nombre</th>
              <th scope="col">Color</th>
            </tr>
          </thead>
          <tbody>
            {whitelists.map(whitelist => (
              <tr>
                <td scope="row">{whitelist.name}</td>
                <td scope="row">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        backgroundColor: whitelist.color,
                        border: '1px solid #000', // Optional border for better visibility
                        marginRight: '10px', // Adds some space between the color box and the text
                      }}
                    ></div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  render() {
    const { handleSubmit, loading, error, articles } = this.props

    if (loading || !articles) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">ARTÍCULOS</h1>
          </div>
        </div>
        <hr className="bg-vw-light" />

        {error && <div className="alert alert-danger">{error}</div>}

        <form onSubmit={handleSubmit(this.onSubmit)}>
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <Box title="Precios de Temporada">
                {!this.state.editSeason && (
                  <div>
                    {this.renderSeasons(articles.Season)}
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => this.setState({ editSeason: true })}
                    >
                      Editar
                    </button>
                  </div>
                )}
                {this.state.editSeason && (
                  <>
                    <FieldArray name="Season" component={this.renderSeason} />
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => this.handleSaveSection('season')}
                    >
                      Guardar
                    </button>
                    <button
                      className="btn btn-secondary ml-2"
                      type="button"
                      onClick={() => this.handleCancelEdit('season')}
                    >
                      Cancelar
                    </button>
                  </>
                )}
              </Box>
            </div>

            <div className="col-xs-12 col-md-4">
              <Box title="Listas blancas">
                {!this.state.editWhitelist && (
                  <div>
                    {this.renderWhitelists(articles.Whitelist)}
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => this.setState({ editWhitelist: true })}
                    >
                      Editar
                    </button>
                  </div>
                )}
                {this.state.editWhitelist && (
                  <>
                    <FieldArray name="Whitelist" component={this.renderOperators} />
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => this.handleSaveSection('whitelist')}
                    >
                      Guardar
                    </button>
                    <button
                      className="btn btn-secondary ml-2"
                      type="button"
                      onClick={() => this.handleCancelEdit('whitelist')}
                    >
                      Cancelar
                    </button>
                  </>
                )}
              </Box>
            </div>

            <div className="col-xs-12 col-md-4">
              <Box title="Precios Click&Collect">
                {!this.state.editClickAndCollect && (
                  <div>
                    {this.renderClickandcollects(articles.ClickAndCollect)}
                    <button
                      className="btn btn-warning"
                      type="button"
                      onClick={() => this.setState({ editClickAndCollect: true })}
                    >
                      Editar
                    </button>
                  </div>
                )}
                {this.state.editClickAndCollect && (
                  <>
                    <FieldArray name="ClickAndCollect" component={this.renderClick} />
                    <button
                      className="btn btn-success"
                      type="button"
                      onClick={() => this.handleSaveSection('clickandcollect')}
                    >
                      Guardar
                    </button>
                    <button
                      className="btn btn-secondary ml-2"
                      type="button"
                      onClick={() => this.handleCancelEdit('clickandcollect')}
                    >
                      Cancelar
                    </button>
                  </>
                )}
              </Box>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  articles: state.articles.articles,
  loading: state.articles.loading,
  error: state.articles.error,
  initialValues: {
    ...state.articles.articles
  }
})

const mapDispatchToProps = (dispatch) => ({
  fetchArticles: bindActionCreators(fetchArticles, dispatch),
  updateArticle: bindActionCreators(updateArticle, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'ArticlesForm',
    enableReinitialize: true
  })(ArticlesView)
)
