import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { fetchRental, updateRental } from '../actions/rentals'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'

class RentalEditForm extends React.Component {
       constructor(props) {
              super(props)

              this.state = {
                     loaded: false
              }

              this.load = this.load.bind(this)
              this.submit = this.submit.bind(this)
       }

       componentDidMount() {
              const { fetchRental, match } = this.props

              fetchRental(match.params.id)

              this.load(this.props)
       }

       componentWillReceiveProps(nextProps) {
              this.load(nextProps)
       }

       load(props) {
              const { rental, initialize } = props;

              if (!this.state.loaded && rental) {
                     this.setState({
                            loaded: true
                     });
                     initialize(rental);  // Initialize form with rental data
              }
       }

       submit(props) {
              const { updateRental, rental } = this.props

              return updateRental(rental._id, props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       render() {
              const { rental, error, pristine, submitting, handleSubmit } = this.props
              const { loaded } = this.state

              if (!loaded || !rental) {
                     return (
                            <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
                     )
              }

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">EDITAR </span>
                                   <span className="text-vw-light">ALQUILER</span>
                            </h1>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Cliente" icon="cubes">
                                                 <Field name="Name"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Nombre del cliente" />
                                                 <Field name="Phone"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Telefono del cliente" />
                                                 <Field name="Email"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Email del cliente" />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={pristine || submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

RentalEditForm = reduxForm({
       form: 'RentalEditForm',
       keepDirtyOnReinitialize: true,
       enableReinitialize: true,
       updateUnregisteredFields: true,
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/rentals`)
       }
})(RentalEditForm)

const mapStateToProps = state => {
       return {
              rental: state.rentals.rental,
              initialValues: state.rentals.rental,
       }
}

const mapDispatchToProps = dispatch => ({
       updateRental: bindActionCreators(updateRental, dispatch),
       fetchRental: bindActionCreators(fetchRental, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RentalEditForm)
