import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchContracts } from '../actions/contracts'

import ContractActiveList from '../components/ContractActiveList'
import ContractTerminatedList from '../components/ContractTerminatedList'
import ErrorMessage from '../components/ErrorMessage'

class ContractsView extends React.Component {
  componentDidMount() {
    this.props.fetchContracts()
  }

  render() {
    const { role, type, contracts, error } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!contracts) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">CONTRATOS </span>
              <span className="text-vw-light">ACTIVOS</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-end">
                <Link to="/contracts/create">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus mr-1"></i>Nuevo
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <ContractActiveList contracts={contracts} />
        <br></br>
        <br></br>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">CONTRATOS </span>
              <span className="text-vw-light">TERMINADOS</span>
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <ContractTerminatedList contracts={contracts} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  contracts: state.contracts.contracts,
  error: state.contracts.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchContracts: bindActionCreators(fetchContracts, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractsView)

