import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import { push } from 'react-router-redux'

import { updateCalendar } from '../actions/calendars'

import { renderInputField, renderSelectField } from '../utils/forms'

class CalendarPricesAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false
    }

    this.submit = this.submit.bind(this)
  }

  submit(props) {
    const { calendar } = this.props
    this.setState({ loading: true }); // Start loading

    return this.props.updateCalendar(calendar._id, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        this.setState({ loading: false }); // Stop loading
        return response
      })
      .catch(error => {
        this.setState({ loading: false }); // Stop loading on error as well
        throw error;
      });
  }

  render() {
    const { error, submitting, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        {submitting ? (<div className="row"></div>) : (
          <div className="row">
            <div className="col">
              <Field name="startDate"
                component={renderInputField}
                type="date"
                label="Fecha inicio" />
            </div>
            <div className="col">
              <Field name="endDate"
                component={renderInputField}
                type="date"
                label="Fecha fin" />
            </div>
            <div className="col">
              <Field
                name="zone"
                component={renderSelectField}
                label="Zona"
              >
                <option value="1">BAQUEIRA</option>
                <option value="2">BERET</option>
                <option value="3">BONAIGUA</option>
                <option value="4">RUDA</option>
                <option value="5">BOSQUE</option>
              </Field>
            </div>
            <div className="col">
              <Field
                name="type"
                component={renderSelectField}
                label="Tipo de Taquilla"
              >
                <option value="BN3XL">2 PAX</option>
                <option value="CN3XL">3 PAX</option>
                <option value="BW3XL">4 PAX</option>
              </Field>
            </div>
            <div className="col">
              <Field
                name="price"
                component={renderInputField}
                type="number"
                label="Precio"
              />
            </div>
          </div>
        )}
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row">
          {submitting ? (<div className="loading-wheel"></div>) : (
            <button className="btn bg-vw-dark text-white mr-1 button-center"
              type="submit"
              disabled={submitting}>
              <i className="fas fa-edit mr-1"></i>
              Añadir precios
            </button>
          )}
        </div>
      </form>
    )
  }
}

// Validation function to check if the price is 0
const validate = values => {
  const errors = {}

  if (values.price === 0) {
    errors.price = 'El precio no puede ser 0'
  }

  // You can add other validation rules here

  return errors
}

CalendarPricesAddForm = reduxForm({
  form: 'CalendarPricesAddForm',
  validate,  // Add the validate function here
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/calendars/${result.response._id}`)
  },
  enableReinitialize: true
})(CalendarPricesAddForm)

const mapStateToProps = state => ({
  calendar: state.calendars.calendar,
  role: state.auth.role,
  initialValues: {
    startDate: new Date().toISOString().substring(0, 10),
    endDate: new Date().toISOString().substring(0, 10),
    zone: '1',
    type: 'BN3XL',
    price: 0
  }
})

const mapDispatchToProps = dispatch => ({
  updateCalendar: bindActionCreators(updateCalendar, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarPricesAddForm)
