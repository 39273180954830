import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'

import { addOperator } from '../actions/operators'
import { fetchCalendars } from '../actions/calendars'
import { fetchRules } from '../actions/rules'

class OperatorAddForm extends React.Component {
       constructor(props) {
              super(props)

              this.submit = this.submit.bind(this)
       }

       componentDidMount() {
              const { fetchCalendars, fetchRules } = this.props

              fetchCalendars();
              fetchRules();
       }

       submit(props) {

              return this.props.addOperator(props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       renderRules(props, rules) {
              const { fields } = props

              return (
                     <div>
                            <div className="table-responsive">
                                   <table className="table table-sm table-stripped">
                                          <tbody>
                                                 {fields.map((registerrule, index) => (
                                                        <tr key={index}>
                                                               <td>
                                                                      {index + 1}
                                                               </td>
                                                               <td>
                                                                      <Field name={`${registerrule}.BusinessRule`}
                                                                             component={renderSelectField}
                                                                      >
                                                                             {rules &&
                                                                                    rules
                                                                                           .sort((a, b) => a.Name.localeCompare(b.Name))
                                                                                           .map((rule) => (
                                                                                                  <option value={rule._id} key={rule._id}>
                                                                                                         {rule.Name}
                                                                                                  </option>
                                                                                           ))}
                                                                      </Field>
                                                               </td>
                                                               <td>
                                                                      <button className="btn btn-danger mr-1" type="button"
                                                                             onClick={() => fields.remove(index)}>
                                                                             <i className="fas fa-trash mr-1"></i>
                                                                      </button>
                                                               </td>
                                                        </tr>
                                                 ))}
                                          </tbody>
                                   </table>
                            </div>
                            <div className="text-right">
                                   <button className="btn bg-vw-light text-white" type="button"
                                          onClick={() => fields.push({})}>
                                          <i className="fas fa-plus mr-1"></i>
                                          Añadir
                                   </button>
                            </div>
                     </div>
              )
       }

       render() {
              const { calendars, error, pristine, submitting, handleSubmit, rules } = this.props

              if (!calendars || !rules) {
                     return (
                            <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
                     )
              }

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">AGREGADOR </span>
                                   <span className="text-vw-light">NUEVO</span>
                            </h1>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Agregador" icon="cubes">
                                                 <Field name="Name"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Nombre del agregador" />
                                                 <Field name="InvoiceType"
                                                        component={renderSelectField}
                                                        label="Modelo de faturación">
                                                        <option value="">Selecciona un modo...</option>
                                                        <option value="Invoice">Factura</option>
                                                        <option value="Note">Albarán</option>
                                                        <option value="Free">No Contable</option>
                                                 </Field>
                                                 <Field name="Comission"
                                                        type="number"
                                                        component={renderInputField}
                                                        label="Comisión" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Calendario de precios" icon="calendar">
                                                 <Field name="Calendar"
                                                        component={renderSelectField}
                                                        label="Calendario de precios"
                                                 >
                                                        {calendars &&
                                                               calendars
                                                                      .sort((a, b) => a.Name.localeCompare(b.Name))
                                                                      .map((calendar) => (
                                                                             <option value={calendar._id} key={calendar._id}>
                                                                                    {calendar.Name}
                                                                             </option>
                                                                      ))}
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Reglas de negocio" icon="pencil-ruler">
                                                <FieldArray name="BusinessRules" component={(fieldArrayProps) => this.renderRules(fieldArrayProps, rules)} />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={pristine || submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

OperatorAddForm = reduxForm({
       form: 'OperatorAddForm',
       keepDirtyOnReinitialize: true,
       enableReinitialize: true,
       updateUnregisteredFields: true,
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/operators/${result.response._id}`)
       }
})(OperatorAddForm)

const mapStateToProps = state => ({
       auth: state.auth,
       role: state.auth.role,
       type: state.auth.type,
       fullname: state.auth.fullname,
       calendars: state.calendars.calendars,
       rules: state.rules.rules,
})

const mapDispatchToProps = dispatch => ({
       addOperator: bindActionCreators(addOperator, dispatch),
       fetchCalendars: bindActionCreators(fetchCalendars, dispatch),
       fetchRules: bindActionCreators(fetchRules, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatorAddForm)
