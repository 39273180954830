import { CALL_API } from '../middleware/api'

export const QRS_REQUEST = 'QRS_REQUEST'
export const QRS_SUCCESS = 'QRS_SUCCESS'
export const QRS_FAILURE = 'QRS_FAILURE'

export function fetchQRs() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'qrs',
      authenticated: true,
      types: [QRS_REQUEST, QRS_SUCCESS, QRS_FAILURE]
    }
  }
}
export const QR_REQUEST = 'QR_REQUEST'
export const QR_SUCCESS = 'QR_SUCCESS'
export const QR_FAILURE = 'QR_FAILURE'

export function fetchQR(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `qrs/${id}`,
      authenticated: true,
      types: [QR_REQUEST, QR_SUCCESS, QR_FAILURE]
    }
  }
}

export const QR_ADD_REQUEST = 'QR_ADD_REQUEST'
export const QR_ADD_SUCCESS = 'QR_ADD_SUCCESS'
export const QR_ADD_FAILURE = 'QR_ADD_FAILURE'

export function addQR(qr) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs',
      authenticated: true,
      body: JSON.stringify(qr),
      types: [QR_ADD_REQUEST, QR_ADD_SUCCESS, QR_ADD_FAILURE]
    }
  }
}

export const QR_UPDATE_REQUEST = 'QR_UPDATE_REQUEST'
export const QR_UPDATE_SUCCESS = 'QR_UPDATE_SUCCESS'
export const QR_UPDATE_FAILURE = 'QR_UPDATE_FAILURE'

export function updateQR(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `qrs/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [QR_UPDATE_REQUEST, QR_UPDATE_SUCCESS,
        QR_UPDATE_FAILURE]
    }
  }
}

export const QR_RESET = 'QR_RESET'

export function resetQR() {
  return { type: QR_RESET }
}

//ONLINE SALE

export const PREBOOKING_REQUEST = 'PREBOOKING_REQUEST'
export const PREBOOKING_SUCCESS = 'PREBOOKING_SUCCESS'
export const PREBOOKING_FAILURE = 'PREBOOKING_FAILURE'

export function postPrebooking(prebooking) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/prebooking',
      authenticated: true,
      body: JSON.stringify(prebooking),
      types: [PREBOOKING_REQUEST, PREBOOKING_SUCCESS, PREBOOKING_FAILURE]
    }
  }
}

export const CANCEL_PREBOOKING_REQUEST = 'CANCEL_PREBOOKING_REQUEST'
export const CANCEL_PREBOOKING_SUCCESS = 'CANCEL_PREBOOKING_SUCCESS'
export const CANCEL_PREBOOKING_FAILURE = 'CANCEL_PREBOOKING_FAILURE'

export function postCancelPrebooking(cancelprebooking) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/cancelprebooking',
      authenticated: true,
      body: JSON.stringify(cancelprebooking),
      types: [CANCEL_PREBOOKING_REQUEST, CANCEL_PREBOOKING_SUCCESS, CANCEL_PREBOOKING_FAILURE]
    }
  }
}

export const BOOKING_REQUEST = 'BOOKING_REQUEST'
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS'
export const BOOKING_FAILURE = 'BOOKING_FAILURE'

export function postBooking(booking) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'qrs/booking',
      authenticated: true,
      body: JSON.stringify(booking),
      types: [BOOKING_REQUEST, BOOKING_SUCCESS, BOOKING_FAILURE]
    }
  }
}

export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST'
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS'
export const CUSTOMER_FAILURE = 'CUSTOMER_FAILURE'

export function fetchCustomer(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `qrs/customer/${id}`,
      authenticated: true,
      types: [CUSTOMER_REQUEST, CUSTOMER_SUCCESS, CUSTOMER_FAILURE]
    }
  }
}

export const PDASALE_REQUEST = 'PDASALE_REQUEST'
export const PDASALE_SUCCESS = 'PDASALE_SUCCESS'
export const PDASALE_FAILURE = 'PDASALE_FAILURE'

export function postPDAsale(sale) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'sales/pda',
      authenticated: true,
      body: JSON.stringify(sale),
      types: [PDASALE_REQUEST, PDASALE_SUCCESS, PDASALE_FAILURE]
    }
  }
}

