export function getMachineStatus(machine) {
  if (!machine.Last_signal) {
    return 'UNKNOWN'
  }

  let now = new Date().getTime()
  let diff = (now - Date.parse(machine.Last_signal)) / 1000

  if (diff < 60 * 1) {
    if (machine.Status === 'OPERATIVE') {
      return 'ONLINE'
    } else if (machine.Status === 'SLEEP') {
      return 'SLEEP'
    } else if (machine.Status === 'NIGHT_SCAN') {
      return 'NIGHTSCAN'
    } else if (machine.Status === 'MAINTENANCE') {
      return 'MANTENIMIENTO'
    } else if (machine.Status === 'LOCKERS_FULL') {
      return 'LOCKERS LLENOS'
    } else if (machine.Status === 'TICKET_EMPTY') {
      return 'SIN TICKET'
    } else if (machine.Status === 'OPERATIVE_SLEEP') {
      return 'SLEEP ONLINE'
    } else if (machine.Status === 'MAINTENANCE_SLEEP') {
      return 'SLEEP MAINTENANCE'
    } else if (machine.Status === 'KERONG_OTA') {
      return 'OTA KERONG'
    } else if (machine.Status === 'BOOTLOADER_OTA') {
      return 'OTA BOOTLOADER'
    } else if (machine.Status === 'BLE_OFF') {
      return 'POWER DOWN'
    } else {
      return 'UNKNOWN'
    }
  }

  machine.Status = 'OFFLINE'

  return 'OFFLINE'
}

export function getMachineCash(machine) {

  let totalBills = (machine.Bills_in_box.five * 5.00 + machine.Bills_in_box.ten * 10.00 + machine.Bills_in_box.twenty * 20.00)

  if (totalBills <= 1500) {
    return 'OK'
  } else if (totalBills > 1500) {
    return 'FULL'
  }

}

export function getMachineStatusColor(machine) {
  let status = getMachineStatus(machine)

  if (status === 'ONLINE') {
    return '#28a745'
  } else if (status === 'SLEEP') {
    return '#0066ff'
  } else if (status === 'NIGHTSCAN') {
    return '#26c4f6'
  } else if (status === 'MANTENIMIENTO') {
    return '#ff7b25'
  } else if (status === 'LOCKERS LLENOS') {
    return '#ff0000'
  } else if (status === 'SIN TICKET') {
    return '#ff0000'
  } else if (status === 'SLEEP ONLINE') {
    return '#28a745'
  } else if (status === 'SLEEP MAINTENANCE') {
    return '#ff7b25'
  } else if (status === 'OTA KERONG') {
    return '#800080'
  } else if (status === 'OTA BOOTLOADER') {
    return '#800080'
  } else if (status === 'POWER DOWN') {
    return '#288BA8'
  } else {
    return '#727370'
  }
}

export function getMachineCashColor(machine) {
  let status = getMachineCash(machine)

  if (status === 'OK') {
    return '#28a745'
  } else if (status === 'FULL') {
    return '#ff0000'
  } else {
    return '#28a745'
  }
}

export function getMachinePicture(type) {

  return '/images/machines/big.png'
}

export function getMachineCirclePicture(type) {

  return '/images/machines/big_circle.png'
}

export function getMachineThumbnail(type) {

  return '/images/machines/big_thumb.png'
}

export function getMachineTypeInitial(type) {
  return type.toUpperCase()[0]
}

export function getMachineCoordinates(Location) {
  const latlng = Location.split(',')

  if (latlng.length !== 2) {
    return null
  }

  const coordinates = {
    lat: parseFloat(latlng[0]),
    lng: parseFloat(latlng[1])
  }

  if (isNaN(coordinates.lat) || isNaN(coordinates.lng)) {
    return null
  }

  return coordinates
}

export function getLockersName(name) {

  switch (name) {
    case 'LOCKERBNS':
      return 'Básica';
    case 'LOCKERCNS':
      return 'Básica Carga';
    case 'LOCKERBWS':
      return 'Básica XL';
    case 'LOCKERCWS':
      return 'Básica Carga XL';
    case 'LOCKERBNM':
      return 'Grande';
    case 'LOCKERCNM':
      return 'Grande Carga';
    case 'LOCKERBWM':
      return 'Grande XL';
    case 'LOCKERCWM':
      return 'Grande Carga XL';
    case 'LOCKERBNL':
      return 'Jumbo';
    case 'LOCKERCNL':
      return 'Jumbo Carga';
    case 'LOCKERBWL':
      return 'Jumbo XL';
    case 'LOCKERCWL':
      return 'Jumbo Carga XL';
    case 'LOCKERBNXL':
      return 'Jumbo 4';
    case 'LOCKERCNXL':
      return 'Jumbo 4 Carga';
    case 'LOCKERBWXL':
      return 'Jumbo 4 XL';
    case 'LOCKERCWXL':
      return 'Jumbo 4 Carga XL';
    case 'LOCKERBN2XL':
      return 'Jumbo 5';
    case 'LOCKERCN2XL':
      return 'Jumbo 5 Carga';
    case 'LOCKERBW2XL':
      return 'Jumbo 5 XL';
    case 'LOCKERCW2XL':
      return 'Jumbo 5 Carga XL';
    case 'LOCKERBN3XL':
      return 'Ski';
    case 'LOCKERCN3XL':
      return 'Ski Carga';
    case 'LOCKERBW3XL':
      return 'Ski XL';
    case 'LOCKERCW3XL':
      return 'Ski Carga XL';
    default:
      return name;
  }
}
