import React from 'react'
import { history } from '../store'

class LowBluetoothByMachineList extends React.Component {
  goTo(Serial, e) {
    history.push(`/machines/${Serial}`)
  }

  renderLowBluetoothList() {
    const { matrixs, machines } = this.props;
  
    let lowbluetoothList = [];
  
    // Create an array of fault objects with customer name, serial, and fault counts
    matrixs.forEach((matrix) => {
      const faults = {
        customer: "",
        serial: matrix.Serial,
        TOTAL_UNCONFIGURED: 0,
        TOTAL_FB: 0,

      };
  
      machines.forEach((machine) => {
        if (machine.Serial === matrix.Serial) {
          faults.customer = machine.Customer?.Fullname || 'None';
        }
      });
  
      matrix.Locker.forEach((lock) => {
          if (lock.Configured && lock.Status === 'FB') {
            faults.TOTAL_FB++;
          }else if (!lock.Configured) {
            faults.TOTAL_UNCONFIGURED++;
          }
      });
  
      if (faults.TOTAL_UNCONFIGURED > 0 || faults.TOTAL_FB > 0) {
        lowbluetoothList.push(faults);
      }
    });
  
    // Sort the faultList by customer name in alphabetical order
    lowbluetoothList.sort((a, b) => a.customer.localeCompare(b.customer));
  
    return lowbluetoothList.map((fault) => (
      <tr key={fault.serial} onClick={(e) => this.goTo(fault.serial, e)}>
        <th>{fault.customer}</th>
        <th>{fault.serial}</th>
        <td>{fault.TOTAL_FB}</td>
        <td>{fault.TOTAL_UNCONFIGURED}</td>
      </tr>
    ));
  }

  render() {
    if (!this.props.matrixs) {
      return null
    }

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Máquina</th>
              <th scope="col">Total Low Bluetooth</th>
              <th scope="col">Total Sin Configurar</th>
            </tr>
          </thead>
          <tbody>
            {this.renderLowBluetoothList()}
          </tbody>
        </table>
      </div>
    )
  }
}

export default LowBluetoothByMachineList

