import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { fetchRule, updateRule } from '../actions/rules'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'

class RuleEditForm extends React.Component {
       constructor(props) {
              super(props)

              this.state = {
                     loaded: false
              }

              this.load = this.load.bind(this)
              this.submit = this.submit.bind(this)
       }

       componentDidMount() {
              const { fetchRule, match } = this.props

              fetchRule(match.params.id)

              this.load(this.props)
       }

       componentWillReceiveProps(nextProps) {
              this.load(nextProps)
       }

       load(props) {
              const { rule, initialize } = props;

              if (!this.state.loaded && rule) {
                     this.setState({
                            loaded: true
                     });
                     initialize(rule);  // Initialize form with rule data
              }
       }

       submit(props) {
              const { updateRule, rule } = this.props

              return this.props.updateRule(rule._id, props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       render() {
              const { rule, error, pristine, submitting, handleSubmit } = this.props
              const { loaded } = this.state

              if (!loaded || !rule) {
                     return (
                            <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
                     )
              }

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">EDITAR </span>
                                   <span className="text-vw-light">REGLA DE NEGOCIO</span>
                            </h1>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Regla" icon="cubes">
                                                 <Field name="Name"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Nombre de la regla" />
                                                 <Field name="Type"
                                                        component={renderSelectField}
                                                        label="Tipo">
                                                        <option value="">Selecciona un tipo...</option>
                                                        <option value="MaxDayDiscount">Descuento por días</option>
                                                        <option value="AlwaysDiscount">Descuento fijo</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Configuración" icon="cubes">
                                                 <Field name="MaxDays"
                                                        type="number"
                                                        component={renderInputField}
                                                        label="Dias aplicables" />
                                                 <Field name="DiscountPercentage"
                                                        type="number"
                                                        component={renderInputField}
                                                        label="Porcentaje de descuento" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Otros" icon="cubes">
                                                 <Field name="Condition"
                                                        component={renderTextAreaField}
                                                        label="Condiciones" />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={pristine || submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

RuleEditForm = reduxForm({
       form: 'RuleEditForm',
       keepDirtyOnReinitialize: true,
       enableReinitialize: true,
       updateUnregisteredFields: true,
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/rules`)
       }
})(RuleEditForm)

const mapStateToProps = state => {
       return {
              rule: state.rules.rule,
              initialValues: state.rules.rule,
       }
}

const mapDispatchToProps = dispatch => ({
       updateRule: bindActionCreators(updateRule, dispatch),
       fetchRule: bindActionCreators(fetchRule, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RuleEditForm)
