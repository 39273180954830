import { CALL_API } from '../middleware/api'

export const CALENDARS_REQUEST = 'CALENDARS_REQUEST'
export const CALENDARS_SUCCESS = 'CALENDARS_SUCCESS'
export const CALENDARS_FAILURE = 'CALENDARS_FAILURE'

export function fetchCalendars() {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `calendars`,
      authenticated: true,
      types: [CALENDARS_REQUEST, CALENDARS_SUCCESS, CALENDARS_FAILURE]
    }
  }
}

export const CALENDAR_ADD_REQUEST = 'CALENDAR_ADD_REQUEST'
export const CALENDAR_ADD_SUCCESS = 'CALENDAR_ADD_SUCCESS'
export const CALENDAR_ADD_FAILURE = 'CALENDAR_ADD_FAILURE'

export function addCalendar(calendar) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'calendars',
      authenticated: true,
      body: JSON.stringify(calendar),
      types: [CALENDAR_ADD_REQUEST, CALENDAR_ADD_SUCCESS, CALENDAR_ADD_FAILURE]
    }
  }
}

export const CALENDAR_UPDATE_REQUEST = 'CALENDAR_UPDATE_REQUEST'
export const CALENDAR_UPDATE_SUCCESS = 'CALENDAR_UPDATE_SUCCESS'
export const CALENDAR_UPDATE_FAILURE = 'CALENDAR_UPDATE_FAILURE'

export function updateCalendar(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `calendars/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [CALENDAR_UPDATE_REQUEST, CALENDAR_UPDATE_SUCCESS,
        CALENDAR_UPDATE_FAILURE]
    }
  }
}

export const CALENDAR_REQUEST = 'CALENDAR_REQUEST'
export const CALENDAR_SUCCESS = 'CALENDAR_SUCCESS'
export const CALENDAR_FAILURE = 'CALENDAR_FAILURE'

export function fetchCalendar(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `calendars/${id}`,
      authenticated: true,
      types: [CALENDAR_REQUEST, CALENDAR_SUCCESS, CALENDAR_FAILURE]
    }
  }
}

export function fetchCalendarByOperator(id, type, zone) {
  // Use URLSearchParams to build the query string
  const searchParams = new URLSearchParams();

  // Append type if provided
  if (type !== undefined) {
    searchParams.append('type', type);
  }

  // Append zone if provided
  if (zone !== undefined) {
    searchParams.append('zone', zone);
  }

  // Construct the final endpoint with the query string
  const params = searchParams.toString();
  const endpoint = `calendars/operator/${id}${params ? '?' + params : ''}`;

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint,  // Use the dynamically generated endpoint
      authenticated: true,
      types: [CALENDAR_REQUEST, CALENDAR_SUCCESS, CALENDAR_FAILURE]
    }
  }
}

export const CALENDAR_SINGLE_MODIFY_REQUEST = 'CALENDAR_SINGLE_MODIFY_REQUEST'
export const CALENDAR_SINGLE_MODIFY_SUCCESS = 'CALENDAR_SINGLE_MODIFY_SUCCESS'
export const CALENDAR_SINGLE_MODIFY_FAILURE = 'CALENDAR_SINGLE_MODIFY_FAILURE'

export function modifySinglePrice(id, newPrice) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `calendars/singleModify/${id}`,
      authenticated: true,
      body: JSON.stringify({ newPrice }),
      types: [CALENDAR_SINGLE_MODIFY_REQUEST, CALENDAR_SINGLE_MODIFY_SUCCESS,
        CALENDAR_SINGLE_MODIFY_FAILURE]
    }
  }
}

export const CALENDAR_SINGLE_DELETE_REQUEST = 'CALENDAR_SINGLE_DELETE_REQUEST'
export const CALENDAR_SINGLE_DELETE_SUCCESS = 'CALENDAR_SINGLE_DELETE_SUCCESS'
export const CALENDAR_SINGLE_DELETE_FAILURE = 'CALENDAR_SINGLE_DELETE_FAILURE'

export function deleteSinglePrice(id) {
  return {
    [CALL_API]: {
      method: 'DELETE',
      endpoint: `calendars/singleDelete/${id}`,
      authenticated: true,
      types: [CALENDAR_SINGLE_DELETE_REQUEST, CALENDAR_SINGLE_DELETE_SUCCESS,
        CALENDAR_SINGLE_DELETE_FAILURE]
    }
  }
}