import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchWithdrawals } from '../actions/withdrawals'
import { fetchMachines } from '../actions/machines'

import { renderInputField, renderSelectField } from '../utils/forms'

class WithdrawalFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchMachines } = this.props

    fetchMachines()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { machines } = props

    if (!this.state.loaded && machines) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    return this.props.fetchWithdrawals(props.Machine, props.Type, props.StartDate, props.EndDate)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, machines } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        <div className="row">
          <div className="col">
            <Field name="Machine"
                   component={renderSelectField}
                   label="Máquina">
              <option value="">Todas</option>
              {machines.map(machine => {
                return (
                  <option value={machine._id} key={machine._id}>
                    {machine.Serial}
                  </option>
                )
              })}
            </Field>
          </div>
          <div className="col">
            <Field name="Type"
                   component={renderSelectField}
                   label="Tipo">
              <option value="">Todas</option>
              <option value="0">Retiro de Monedas</option>
              <option value="1">Retiro de Billetes</option>
              <option value="2">Retiro de Cambio</option>
            </Field>
          </div>
          <div className="col">
            <Field name="StartDate"
                   component={renderInputField}
                   type="date"
                   label="Fecha inicio"/>
          </div>
          <div className="col">
            <Field name="EndDate"
                   component={renderInputField}
                   type="date"
                   label="Fecha fin"/>
          </div>
        </div>
        <div className="form-row">
          { error && <div className="alert alert-danger">{error}</div> }
        </div>
        <div className="form-row">
          <button className="btn bg-vw-dark text-white mr-1 button-center button-center"
                  type="submit"
                  disabled={submitting}>
            <i className="fas fa-sync mr-1"></i>Actualizar
          </button>
        </div>
      </form>
    )
  }
}

WithdrawalFilterForm = reduxForm({
  form: 'WithdrawalFilterForm',
})(WithdrawalFilterForm)

const mapStateToProps = state => {
  const now = new Date()
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().substring(0, 10)
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().substring(0, 10)
  
  return {
    machines: state.machines.machines,
    initialValues: {
      machine: '',
      StartDate: firstDayOfMonth,
      EndDate: lastDayOfMonth
    }
  }
}

const mapDispatchToProps = dispatch => ({
  fetchWithdrawals: bindActionCreators(fetchWithdrawals, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawalFilterForm)

