import { CALL_API } from '../middleware/api'

export const RULES_REQUEST = 'RULES_REQUEST'
export const RULES_SUCCESS = 'RULES_SUCCESS'
export const RULES_FAILURE = 'RULES_FAILURE'

export function fetchRules() {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `rules`,
      authenticated: true,
      types: [RULES_REQUEST, RULES_SUCCESS, RULES_FAILURE]
    }
  }
}

export const RULE_ADD_REQUEST = 'RULE_ADD_REQUEST'
export const RULE_ADD_SUCCESS = 'RULE_ADD_SUCCESS'
export const RULE_ADD_FAILURE = 'RULE_ADD_FAILURE'

export function addRule(rule) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'rules',
      authenticated: true,
      body: JSON.stringify(rule),
      types: [RULE_ADD_REQUEST, RULE_ADD_SUCCESS, RULE_ADD_FAILURE]
    }
  }
}

export const RULE_UPDATE_REQUEST = 'RULE_UPDATE_REQUEST'
export const RULE_UPDATE_SUCCESS = 'RULE_UPDATE_SUCCESS'
export const RULE_UPDATE_FAILURE = 'RULE_UPDATE_FAILURE'

export function updateRule(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `rules/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [RULE_UPDATE_REQUEST, RULE_UPDATE_SUCCESS,
        RULE_UPDATE_FAILURE]
    }
  }
}

export const RULE_REQUEST = 'RULE_REQUEST'
export const RULE_SUCCESS = 'RULE_SUCCESS'
export const RULE_FAILURE = 'RULE_FAILURE'

export function fetchRule(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `rules/${id}`,
      authenticated: true,
      types: [RULE_REQUEST, RULE_SUCCESS, RULE_FAILURE]
    }
  }
}
