import {
  PDAS_REQUEST, PDAS_SUCCESS, PDAS_FAILURE,
  PDA_REQUEST, PDA_SUCCESS, PDA_FAILURE,
  PDA_ADD_REQUEST, PDA_ADD_SUCCESS, PDA_ADD_FAILURE,
  PDA_UPDATE_REQUEST, PDA_UPDATE_SUCCESS, PDA_UPDATE_FAILURE,
  PDA_RESET,
  PDASALE_REQUEST, PDASALE_SUCCESS, PDASALE_FAILURE,
  PDA_BYCUSTOMER_REQUEST, PDA_BYCUSTOMER_SUCCESS, PDA_BYCUSTOMER_FAILURE
} from '../actions/pdas'

const INITIAL_STATE = {
  pdas: null, pdasNeedRefresh: false, pda: null, error: null, loading: false,
  pdasale: null
}

const pdasReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PDAS_REQUEST:
      return {
        ...state,
        pdas: null, error: null, loading: true
      }
    case PDAS_SUCCESS:
      return {
        ...state,
        pdas: action.response, error: null, loading: false
      }
    case PDAS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PDA_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PDA_SUCCESS:
      return {
        ...state,
        pda: action.response, error: null, loading: false
      }
    case PDA_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PDA_BYCUSTOMER_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PDA_BYCUSTOMER_SUCCESS:
      return {
        ...state,
        pda: action.response, error: null, loading: false
      }
    case PDA_BYCUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PDA_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PDA_ADD_SUCCESS:
      return {
        ...state,
        pda: action.response, pdasNeedRefresh: true, error: null,
        loading: false
      }
    case PDA_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PDA_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case PDA_UPDATE_SUCCESS:
      return {
        ...state,
        pda: action.response, pdasNeedRefresh: true, error: null,
        loading: false
      }
    case PDA_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case PDA_RESET:
      return {
        ...state,
        pda: null, error: null, loading: false
      }
    case PDASALE_REQUEST:
      return {
        ...state,
        pdasale: null, error: null, loading: true
      }
    case PDASALE_SUCCESS:
      return {
        ...state,
        pdasale: action.response, error: null, loading: false
      }
    case PDASALE_FAILURE:
      return {
        ...state,
        pdasale: action.error, loading: false
      }
    default:
      return state
  }
}

export default pdasReducer

