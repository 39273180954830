import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import Calendar from '../components/Calendar'; // Import the Calendar component
import { fetchCalendar } from '../actions/calendars'
import CalendarPricesAddForm from '../forms/CalendarPricesAddForm'

class CalendarDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startMonth: 10,  // Default to Octubre
      endMonth: 2,     // Default to Febrero
    };
  }

  componentDidMount() {
    this.props.fetchCalendar(this.props.match.params.id);
  }

  handleMonthChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: parseInt(value) });
  };

  render() {
    const { calendar, error, loading } = this.props;
    const { startMonth, endMonth } = this.state;
    const currentYear = new Date().getFullYear();

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!calendar || loading) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const monthNames = [
      'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    return (
      <div>
        <div className="row mb-2">
          <div className="col">
            <h1>
              <span className="text-vw-light">CALENDARIO  </span>
              <span className="text-vw-dark">{calendar.Name}</span>
            </h1>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-2 col-md-2">
            <Box title="Meses a mostrar" icon="calendar-alt">
              <div className="mt-3 mb-1">
                <div className="col mb-2">
                  <h6 className="text-dark">Mes de Inicio</h6>
                  <select className="form-control false" name="startMonth" value={startMonth} onChange={this.handleMonthChange}>
                    {monthNames.map((monthName, index) => (
                      <option key={index} value={index}>{monthName}</option>
                    ))}
                  </select>
                </div>
                <div className="col">
                  <h6 className="text-dark">Mes de Fin</h6>
                  <select className="form-control false" name="endMonth" value={endMonth} onChange={this.handleMonthChange}>
                    {monthNames.map((monthName, index) => (
                      <option key={index} value={index}>{monthName}</option>
                    ))}
                  </select>
                </div>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-sm-10 col-md-10">
            <Box title="Formulario de actualización de precios" icon="calendar-alt">
              <CalendarPricesAddForm />
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Box title="Calendario de Precios" icon="calendar-alt">
              <Calendar
                year={currentYear}
                priceCalendar={calendar.PriceCalendar}
                startMonth={startMonth}
                endMonth={endMonth}
              />
            </Box>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  calendar: state.calendars.calendar,
  loading: state.calendars.loading,
  error: state.calendars.error,
});

const mapDispatchToProps = dispatch => ({
  fetchCalendar: bindActionCreators(fetchCalendar, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarDetailsView);
