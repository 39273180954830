import {
  CUSTOMERS_REQUEST, CUSTOMERS_SUCCESS, CUSTOMERS_FAILURE,
  CUSTOMER_REQUEST, CUSTOMER_SUCCESS, CUSTOMER_FAILURE,
  CUSTOMER_ADD_REQUEST, CUSTOMER_ADD_SUCCESS, CUSTOMER_ADD_FAILURE,
  CUSTOMER_UPDATE_REQUEST, CUSTOMER_UPDATE_SUCCESS, CUSTOMER_UPDATE_FAILURE,
  CUSTOMER_RESET,
  CUSTOMERSCASH_REQUEST, CUSTOMERSCASH_SUCCESS, CUSTOMERSCASH_FAILURE,
  CUSTOMERCASH_REQUEST, CUSTOMERCASH_SUCCESS, CUSTOMERCASH_FAILURE,
  CUSTOMERCASH_ADD_REQUEST, CUSTOMERCASH_ADD_SUCCESS, CUSTOMERCASH_ADD_FAILURE,
  CUSTOMERCASH_UPDATE_REQUEST, CUSTOMERCASH_UPDATE_SUCCESS, CUSTOMERCASH_UPDATE_FAILURE,
  CUSTOMERSPDA_REQUEST, CUSTOMERSPDA_SUCCESS, CUSTOMERSPDA_FAILURE,
  CUSTOMERPDA_REQUEST, CUSTOMERPDA_SUCCESS, CUSTOMERPDA_FAILURE,
  CUSTOMERPDA_ADD_REQUEST, CUSTOMERPDA_ADD_SUCCESS, CUSTOMERPDA_ADD_FAILURE,
  CUSTOMERPDA_UPDATE_REQUEST, CUSTOMERPDA_UPDATE_SUCCESS, CUSTOMERPDA_UPDATE_FAILURE,
  CUSTOMERSGROUP_REQUEST, CUSTOMERSGROUP_SUCCESS, CUSTOMERSGROUP_FAILURE,
  CUSTOMERGROUP_REQUEST, CUSTOMERGROUP_SUCCESS, CUSTOMERGROUP_FAILURE,
  CUSTOMERGROUP_ADD_REQUEST, CUSTOMERGROUP_ADD_SUCCESS, CUSTOMERGROUP_ADD_FAILURE,
  CUSTOMERGROUP_UPDATE_REQUEST, CUSTOMERGROUP_UPDATE_SUCCESS, CUSTOMERGROUP_UPDATE_FAILURE

} from '../actions/customers'

const INITIAL_STATE = {
  customers: null, customersNeedRefresh: false, customer: null, error: null,
  loading: false
}

const customersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMERS_REQUEST:
      return {
        ...state,
        customers: null, error: null, loading: true
      }
    case CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.response, error: null, loading: false
      }
    case CUSTOMERS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMER_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.response, error: null, loading: false
      }
    case CUSTOMER_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMER_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMER_ADD_SUCCESS:
      return {
        ...state,
        customer: action.response, customersNeedRefresh: true, error: null,
        loading: false
      }
    case CUSTOMER_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMER_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMER_UPDATE_SUCCESS:
      return {
        ...state,
        customer: action.response, customersNeedRefresh: true, error: null,
        loading: false
      }
    case CUSTOMER_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMER_RESET:
      return {
        ...state,
        customer: null, error: null, loading: false
      }
    case CUSTOMERSCASH_REQUEST:
      return {
        ...state,
        customers: null, error: null, loading: true
      }
    case CUSTOMERSCASH_SUCCESS:
      return {
        ...state,
        customers: action.response, error: null, loading: false
      }
    case CUSTOMERSCASH_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMERCASH_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMERCASH_SUCCESS:
      return {
        ...state,
        customer: action.response, error: null, loading: false
      }
    case CUSTOMERCASH_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMERCASH_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMERCASH_ADD_SUCCESS:
      return {
        ...state,
        customer: action.response, customersNeedRefresh: true, error: null,
        loading: false
      }
    case CUSTOMERCASH_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMERCASH_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMERCASH_UPDATE_SUCCESS:
      return {
        ...state,
        customer: action.response, customersNeedRefresh: true, error: null,
        loading: false
      }
    case CUSTOMERCASH_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMERSPDA_REQUEST:
      return {
        ...state,
        customers: null, error: null, loading: true
      }
    case CUSTOMERSPDA_SUCCESS:
      return {
        ...state,
        customers: action.response, error: null, loading: false
      }
    case CUSTOMERSPDA_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMERPDA_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMERPDA_SUCCESS:
      return {
        ...state,
        customer: action.response, error: null, loading: false
      }
    case CUSTOMERPDA_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMERPDA_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMERPDA_ADD_SUCCESS:
      return {
        ...state,
        customer: action.response, customersNeedRefresh: true, error: null,
        loading: false
      }
    case CUSTOMERPDA_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CUSTOMERPDA_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CUSTOMERPDA_UPDATE_SUCCESS:
      return {
        ...state,
        customer: action.response, customersNeedRefresh: true, error: null,
        loading: false
      }
    case CUSTOMERPDA_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
      case CUSTOMERSGROUP_REQUEST:
        return {
          ...state,
          customers: null, error: null, loading: true
        }
      case CUSTOMERSGROUP_SUCCESS:
        return {
          ...state,
          customers: action.response, error: null, loading: false
        }
      case CUSTOMERSGROUP_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case CUSTOMERGROUP_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case CUSTOMERGROUP_SUCCESS:
        return {
          ...state,
          customer: action.response, error: null, loading: false
        }
      case CUSTOMERGROUP_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case CUSTOMERGROUP_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case CUSTOMERGROUP_ADD_SUCCESS:
        return {
          ...state,
          customer: action.response, customersNeedRefresh: true, error: null,
          loading: false
        }
      case CUSTOMERGROUP_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case CUSTOMERGROUP_UPDATE_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case CUSTOMERGROUP_UPDATE_SUCCESS:
        return {
          ...state,
          customer: action.response, customersNeedRefresh: true, error: null,
          loading: false
        }
      case CUSTOMERGROUP_UPDATE_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
    default:
      return state
  }
}

export default customersReducer

