import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as dateFormat from 'dateformat'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import SoldItemList from '../components/SoldItemsList'
import ErrorMessage from '../components/ErrorMessage'

import { fetchSale } from '../actions/sales'

class SaleDetailsView extends React.Component {
  componentDidMount() {
    this.props.fetchSale(this.props.match.params.id)
  }

  parseXml = (xmlString) => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    const data = {};
    const elements = xmlDoc.getElementsByTagName('*');
    for (let i = 0; i < elements.length; i++) {
      const tagName = elements[i].tagName;
      const textContent = elements[i].textContent;

      if (elements[i].childNodes.length === 1 && elements[i].childNodes[0].nodeType === 3) {
        data[tagName] = textContent;
      }
    }
    return data;
  }

  render() {
    const { sale, error, loading, auth } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!sale || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    const saleDate = new Date(sale.TimeStamp);
    saleDate.setHours(saleDate.getHours() - 2);
    const formattedDate = `${saleDate.getDate()}/${saleDate.getMonth() + 1}/${saleDate.getFullYear()} ${saleDate.getHours().toString().padStart(2, '0')}:${saleDate.getMinutes().toString().padStart(2, '0')}`;


    let processedDetails = '';

    if (sale.Details) {
      processedDetails = sale.Details.replace(/C:/g, 'Moneda:').replace(/B:/g, 'Billete:');
    }

    let processedPaidout = '';

    if (sale.Paidout) {
      processedPaidout = sale.Paidout.replace(/C:/g, 'Moneda:').replace(/B:/g, 'Billete:');
    }

    let transactionDetails = null;

    if (sale && sale.Origin && sale.PaymentMethod === 'TPV') {
      const xmlData = this.parseXml(sale.Origin);
      transactionDetails = (
        <div className="transaction-details-grid">
          {Object.entries(xmlData).map(([key, value]) => (
            <PropertyLabel name={key} value={value} key={key} />
          ))}
        </div>
      );
    } else if (sale && sale.PaymentMethod === 'CASH') {
      transactionDetails = (
        <div className="row">
          <div className="col col-12 col-xs-12 col-md-6">
            <PropertyLabel name="Introducido" ml
              value={processedDetails} />
          </div>
          <div className="col col-12 col-xs-12 col-md-6">
            <PropertyLabel name="Cambio" ml
              value={processedPaidout} />
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col">
            <h1>
              <span className="text-vw-light">VENTA  </span>
              <span className="text-vw-dark">{sale.Code}</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Box title="Importe" icon="dollar">
              <div className="d-flex justify-content-between">
                <h1 className="mr-3">Total: </h1>
                <h1>{`${parseFloat(sale.Amount).toLocaleString("es-ES", { style: "currency", currency: "EUR" })}`}</h1>
              </div>
            </Box>
            <Box title="Resumen" icon="sliders-h">
              <div className="row">
                <div className="col col-12 col-xs-12 col-md-6">
                  <PropertyLabel name="ID"
                    value={sale._id} />
                  <PropertyLabel name="Fecha"
                    value={formattedDate} />
                  <PropertyLabel name="Cliente"
                    value={sale.Customer.Fullname} />
                  {sale.PaymentMethod != "QR" &&
                    <PropertyLabel name="Máquina"
                      value={sale.Machine.Serial} />
                  }
                </div>
                <div className="col col-12 col-xs-12 col-md-6">
                  <PropertyLabel name="Método de pago"
                    value={sale.PaymentMethod} />
                  {sale.PaymentMethod === "QR" &&
                    <PropertyLabel name="Referéncia TPV Virtual"
                      value={sale.RedsysCode} />
                  }
                  <PropertyLabel name="Factura"
                    value={sale.Code} />
                </div>
              </div>
            </Box>
          </div>
          <div className="col">
            <Box title="Artículos vendidos" icon="shopping-cart">
              <SoldItemList items={sale.Items} auth={auth} />
            </Box>
            <Box title="Detalles de la transacción" icon="shopping-cart">
              {transactionDetails}
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  sale: state.sales.sale,
  loading: state.sales.loading,
  error: state.sales.error,
  auth: state.auth
})

const mapDispatchToProps = dispatch => ({
  fetchSale: bindActionCreators(fetchSale, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SaleDetailsView)



