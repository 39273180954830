import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchObjectives } from '../actions/objectives'
import { fetchCustomers } from '../actions/customers'
import { fetchArqueos } from '../actions/arqueos'

import { renderInputField, renderSelectField } from '../utils/forms'

class ObjectivesFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { role, fetchCustomers } = this.props

    fetchCustomers()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { role, customers, objectives } = props

    if (!this.state.loaded && customers) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    const today = new Date(props.Year);
    const currentYear = today.getFullYear();
    const yearStart = new Date(currentYear, 0, 1);
    const formattedStartDate = `${yearStart.getFullYear()}-${(yearStart.getMonth() + 1).toString().padStart(2, '0')}-${yearStart.getDate().toString().padStart(2, '0')}`;
    const yearEnd = new Date(currentYear + 1, 0, 0);
    const formattedEndDate = `${yearEnd.getFullYear()}-${(yearEnd.getMonth() + 1).toString().padStart(2, '0')}-${yearEnd.getDate().toString().padStart(2, '0')}`;

    this.props.fetchArqueos('', props.Customer || '', formattedStartDate, formattedEndDate, '')
      .then(response => {
        return this.props.fetchObjectives(props.Customer, props.Year)
          .then(response => {
            if (response.error) {
              throw new SubmissionError({
                _error: response.error.message,
                ...response.error.fields
              })
            }

            return response
          })
      })
  }

  render() {
    const { loaded } = this.state
    const { isLoading, error, submitting, handleSubmit, customers, role } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        <div className="row">
          <Field
            name="Customer"
            component={renderSelectField}
            label="Cliente"
          >
            <option value="">Todos</option>
            {customers &&
              customers
                .filter((customer) => customer.Type === 'GENERAL')
                .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                .map((customer) => (
                  <option value={customer._id} key={customer._id}>
                    {customer.Fullname}
                  </option>
                ))}
          </Field>
          <Field name="Year" component={renderSelectField} label="Año">
            {[...Array(10)].map((_, i) => {
              const year = new Date().getFullYear() - i;
              return <option key={year} value={year}>{year}</option>;
            })}
          </Field>
        </div>
        <div className="form-row">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="form-row">
          {isLoading ? (
            <div class="loading-wheel"></div>
          ) : (
            <button className="btn bg-vw-dark text-white mr-1 button-center"
              type="submit"
              disabled={submitting}>
              <i className="fas fa-sync mr-1"></i>Actualizar
            </button>
          )}
        </div>
      </form>
    )
  }
}

ObjectivesFilterForm = reduxForm({
  form: 'ObjectivesFilterForm',
})(ObjectivesFilterForm)

const selector = formValueSelector('ObjectivesFilterForm')

const mapStateToProps = state => ({
  customers: state.customers.customers,
  objectives: state.objectives,
  arqueos: state.arqueos.arqueos,
  role: state.auth.role,
  initialValues: {
    Customer: '',
    Year: new Date().toISOString().substring(0, 10)
  },
  formValues: {
    Customer: selector(state, 'Customer'),
    Year: selector(state, 'Year')
  },
  isLoading: state.customers.loading || state.objectives.loading || state.arqueos.loading,
})

const mapDispatchToProps = dispatch => ({
  fetchObjectives: bindActionCreators(fetchObjectives, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  fetchArqueos: bindActionCreators(fetchArqueos, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ObjectivesFilterForm)
