import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { addCustomerGroup } from '../actions/customers'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

class CustomerGroupAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  submit(props) {

    const { match } = this.props

    props.Executor = this.props.fullname
    
    return this.props.addCustomerGroup(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { error, pristine, submitting, handleSubmit } = this.props

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">NUEVO </span>
          <span className="text-vw-light">USUARIO GRUPO</span>
        </h1>
        <div className="row">
          <div className="col">
            <Box title="Cliente" icon="user">
              <Field name="Fullname"
                type="text"
                component={renderInputField}
                label="Nombre completo" />
              <Field name="Email"
                type="text"
                component={renderInputField}
                label="E-mail" />
              <Field name="Password"
                type="password"
                component={renderInputField}
                label="Password" />
              <Field name="Group"
                component={renderSelectField}
                label="Grupo al que pertenece">
                <option value="">Selecciona un grupo...</option>
                <option value="ASPRO">ASPRO PARKS</option>
                <option value="GRPR">GRUPO PARQUES REUNIDOS</option>
                <option value="SANTAMARIA">GRUPO SANTAMARIA</option>
                <option value="ISLAMAGICA">GRUPO ISLA MAGICA</option>
              </Field>
            </Box>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

CustomerGroupAddForm = reduxForm({
  form: 'CustomerGroupAddForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/customers/`)
  }
})(CustomerGroupAddForm)

const mapStateToProps = state => ({
  auth: state.auth,
  role: state.auth.role,
  type: state.auth.type,
  fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
  addCustomerGroup: bindActionCreators(addCustomerGroup, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerGroupAddForm)

