import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'
import CustomerCashList from '../components/CustomerCashList'
import CustomerPDAList from '../components/CustomerPDAList'

import { fetchCustomer, fetchCustomers } from '../actions/customers'
import AssignedZoneList from '../components/AssignedZoneList'
import AssignedComissionList from '../components/AssignedComissionList'
import AssignedItemNumber from '../components/AssignedItemNumber'
import { getLockersName } from '../utils/machines'

class CustomerDetailsView extends React.Component {
  componentDidMount() {
    this.props.fetchCustomer(this.props.match.params.id)
    this.props.fetchCustomers()
  }

  getSAPModel(model) { 
    
    switch(model){
      case "VC": return "VENTAS CONTADO (Punto de Venta)";
      case "CV": return "COMISION VENTA (Solicitud Factura)";
    }
  
  }

  render() {
    const { role, type, customers, customer, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!customer || !customers || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">CLIENTE </span>
              <span className="text-vw-dark">{customer.Fullname}</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-6 col-md-6 text-right">
              <Link to={`/customers/${customer._id}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
              <Link to={`/customerscash/${customer._id}/add`}>
                <button className="btn bg-warning mr-1">
                  <i className="fas fa-plus mr-1"></i>Añadir Usuario CashControl
                </button>
              </Link>
              <Link to={`/customerspda/${customer._id}/add`}>
                <button className="btn bg-dark text-white mr-1">
                  <i className="fas fa-plus mr-1"></i>Añadir Usuario PDAControl
                </button>
              </Link>
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Cliente" icon="user">
              <PropertyLabel name="Nombre completo"
                value={customer.Fullname} />
              <PropertyLabel name="Categoria"
                value={customer.Category} />
              <PropertyLabel name="Persona de contacto"
                value={customer.Contact_person} />
              <PropertyLabel name="E-Mail"
                value={customer.Email} />
              <PropertyLabel name="Dirección"
                value={customer.Address} />
              <PropertyLabel name="Teléfono"
                value={customer.Phone} />
              <PropertyLabel name="Idioma"
                value={customer.Language} />
              <PropertyLabel name="Código postal"
                value={customer.PostalCode} />
              <PropertyLabel name="Ciudad"
                value={customer.City} />
              <PropertyLabel name="Provincia"
                value={customer.Province} />
              <PropertyLabel name="País"
                value={customer.Country} />
            </Box>
            <Box title="Extra" icon="file-alt">
              <PropertyLabel name="Grupo al que pertenece"
                value={customer.Group} />
              <PropertyLabel name="Identificador Externo"
                value={customer.ExternalId} />
              <PropertyLabel name="Email de Reportes"
                value={customer.IncidencesEmail} />
              <PropertyLabel name="Última edición"
                value={customer.Executor} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Facturación" icon="file-alt">
              <PropertyLabel name="NIF/CIF"
                value={customer.Invoice_taxid} />
              <PropertyLabel name="IVA aplicable (%)"
                value={customer.Invoice_tax} />
              <PropertyLabel name="Razón social"
                value={customer.Social_name} />
              <PropertyLabel name="Dirección"
                value={customer.Invoice_address} />
            </Box>
            <Box title="Modelo de negocio" icon="briefcase">
              <PropertyLabel name="Modelo"
                value={customer.BusinessModel} />
              {customer.SpaceRentalAlquiler &&
                <PropertyLabel name="Alquiler Anual"
                  value={`${customer.SpaceRentalAlquiler} €`} />}
            </Box>
            <Box title="TPV Virtual" icon="file-alt">
              <PropertyLabel name="Código de comercio"
                value={customer.Redsys.MERCHANTCODE} />
              <PropertyLabel name="Clave de cifrado"
                value={customer.Redsys.SECRETKEY} />
              <PropertyLabel name="Moneda"
                value={customer.Redsys.CURRENCY} />
              <PropertyLabel name="Terminal"
                value={customer.Redsys.TERMINAL} />
              <PropertyLabel name="URL Tienda"
                value={customer.Redsys.MERCHANTURL} />
            </Box>
            <Box title="SAP" icon="file-alt">
              <PropertyLabel name="Model"
                value={this.getSAPModel(customer.SAP.Model)} />
              <PropertyLabel name="CompanyID"
                value={customer.SAP.CompanyID} />
              <PropertyLabel name="CountryCode"
                value={customer.SAP.CountryCode} />
              <PropertyLabel name="ClientID"
                value={customer.SAP.ClientID} />
              <PropertyLabel name="SiteID"
                value={customer.SAP.SiteID} />
              <PropertyLabel name="TaxCountryCode"
                value={customer.SAP.TaxCountryCode} />
              <PropertyLabel name="TaxationCharacteristicsCode"
                value={customer.SAP.TaxationCharacteristicsCode} />
              <PropertyLabel name="InventoryManagedLocationID"
                value={customer.SAP.InventoryManagedLocationID} />
              <PropertyLabel name="LogisticsAreaID"
                value={customer.SAP.LogisticsAreaID} />
              <PropertyLabel name="TaxRegionCode"
                value={customer.SAP.TaxRegionCode} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Artículos" icon="shopping-cart">
              <AssignedComissionList items={customer.Items} />
            </Box>
            <Box title="Zonas" icon="door-closed">
              <AssignedZoneList Zones={customer.Zones} />
            </Box>
            <Box title="Usuarios Cash Control" icon="user">
              <CustomerCashList customers={customers} customer={customer} />
            </Box>
            <Box title="Usuarios PDA Control" icon="user">
              <CustomerPDAList customers={customers} customer={customer} />
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customers: state.customers.customers,
  customer: state.customers.customer,
  loading: state.customers.loading,
  error: state.customers.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetailsView)
