import React from 'react'
import * as dateFormat from 'dateformat'

class AuditEntryList extends React.Component {
  renderType(Categoria) {
    let badgeColor = ''

    if (Categoria === 'INFO') {
      badgeColor = '#28a745'
    } else if (Categoria === 'CHANGE') {
      badgeColor = '#ffc107'
    } else if (Categoria === 'MONEY') {
      badgeColor = '#f48c42'
    } else if (Categoria === 'LOGIN') {
      badgeColor = '#0077ff'
    } else if (Categoria === 'INFO_LOCKERS') {
      badgeColor = '#28fe75'
    } else {
      badgeColor = '#ff0000'
    }

    return (
      <div className="badge"
           style={{backgroundColor: badgeColor}}>
        { Categoria }
      </div>
    )
  }

  formatTime(timestamp) {
    const saleDate = new Date(timestamp);
    saleDate.setHours(saleDate.getHours() - 2);
    const formattedDate = `${saleDate.getDate()}/${saleDate.getMonth() + 1}/${saleDate.getFullYear()} ${saleDate.getHours().toString().padStart(2, '0')}:${saleDate.getMinutes().toString().padStart(2, '0')}`;

    return (formattedDate)
  }

  render() {

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Máquina</th>
              <th scope="col">Fecha/Hora</th>
              <th scope="col">Tipo</th>
              <th scope="col">Pin / Trabajador</th>
              <th scope="col">Evento</th>
              <th scope="col">Descripción</th>
            </tr>
          </thead>
          <tbody>
            {this.props.audits.map(audit => (
              <tr key={audit._id}>
                <td>{audit.Machine.Serial}</td>
                <td>{this.formatTime(audit.TimeStamp)}</td>
                <td>{this.renderType(audit.Categoria)}</td>
                <td>{audit.WorkerSession}</td>
                <td>{audit.Evento}</td>
                <td>{audit.Description}</td>
              </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AuditEntryList

