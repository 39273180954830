import { CALL_API } from '../middleware/api'

export const ARTICLES_REQUEST = 'ARTICLES_REQUEST'
export const ARTICLES_SUCCESS = 'ARTICLES_SUCCESS'
export const ARTICLES_FAILURE = 'ARTICLES_FAILURE'

export function fetchArticles() {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `articles`,
      authenticated: true,
      types: [ARTICLES_REQUEST, ARTICLES_SUCCESS, ARTICLES_FAILURE]
    }
  }
}

export const ARTICLES_RESET = 'ARTICLES_RESET'

export function resetArticles() {
  return { type: ARTICLES_RESET }
}

export const ARTICLE_UPDATE_REQUEST = 'ARTICLE_UPDATE_REQUEST'
export const ARTICLE_UPDATE_SUCCESS = 'ARTICLE_UPDATE_SUCCESS'
export const ARTICLE_UPDATE_FAILURE = 'ARTICLE_UPDATE_FAILURE'

export function updateArticle(article) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: 'articles',
      authenticated: true,
      body: JSON.stringify(article),
      types: [ARTICLE_UPDATE_REQUEST, ARTICLE_UPDATE_SUCCESS,
        ARTICLE_UPDATE_FAILURE]
    }
  }
}