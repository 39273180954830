import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Box from '../components/Box'
import { fetchMatrixs } from '../actions/matrixs'
import { fetchMachines } from '../actions/machines'
import ErrorMessage from '../components/ErrorMessage'
import { Link } from 'react-router-dom'
import FaultsByCustomerList from '../components/FaultsByCustomerList'
import FaultsByMachineList from '../components/FaultsByMachineList'

class FaultsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchMatrixs('')
    this.props.fetchMachines()
  }

  identifyFaults() {

    const { matrixs } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    // BROKEN_LOCK, GREEN_BLOCK, RED_BLOCK, PINPAD_BLOCK, DOOR_BLOCK, PINPAD_ACTIVE

    let TOTAL_FAULTS = 0;

    let BROKEN_LOCK = 0;
    let GREEN_BLOCK = 0;
    let RED_BLOCK = 0;
    let PINPAD_BLOCK = 0;
    let DOOR_BLOCK = 0;
    let PINPAD_ACTIVE = 0;

    matrixs.forEach(matrix => {
      {
        matrix.Locker.forEach((lock, index) => {

          if (lock.FaultCode !== undefined && lock.Status === 'FL') {
            switch (lock.FaultCode) {
              case 'BROKEN_LOCK':
                BROKEN_LOCK++;
                TOTAL_FAULTS++;

                break;
              case 'GREEN_BLOCK':
                GREEN_BLOCK++;
                TOTAL_FAULTS++;

                break;
              case 'RED_BLOCK':
                RED_BLOCK++;
                TOTAL_FAULTS++;

                break;
              case 'PINPAD_BLOCK':
                PINPAD_BLOCK++;
                TOTAL_FAULTS++;

                break;
              case 'DOOR_BLOCK':
                DOOR_BLOCK++;
                TOTAL_FAULTS++;

                break;
              case 'PINPAD_ACTIVE':
                PINPAD_ACTIVE++;
                TOTAL_FAULTS++;

                break;
              default:
                break;
            }
          }
        })
      }
    })

    return (
      <div className="row p-3 justify-content-center">

        <div className="faultBoxTotal">
          <h5>TOTAL AVERIAS: {TOTAL_FAULTS}</h5>
        </div>

        <div className="faultBox">
          <h5>Pinchos rotos:</h5>
          <h5>{BROKEN_LOCK}</h5>
        </div>
        <div className="faultBox">
          <h5>Pin verde, no abre:</h5>
          <h5>{GREEN_BLOCK}</h5>
        </div>

        <div className="faultBox">
          <h5>Pin rojo, no abre:</h5>
          <h5>{RED_BLOCK}</h5>
        </div>

        <div className="faultBox">
          <h5>Teclado no enciende:</h5>
          <h5>{PINPAD_BLOCK}</h5>
        </div>

        <div className="faultBox">
          <h5>Puerta no cierra:</h5>
          <h5>{DOOR_BLOCK}</h5>
        </div>

        <div className="faultBox">
          <h5>Teclado encendido:</h5>
          <h5>{PINPAD_ACTIVE}</h5>
        </div>

      </div>
    )
  }

  render() {
    const { matrixs, machines } = this.props

    if (!matrixs || !machines) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              AVERIAS
            </h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
            <div className="col d-flex justify-content-end">
              <Link to="/faults/report">
                <button type="button" className="btn bg-vw-excel text-white">
                  <i className="fas fa-file-excel mr-1"></i>Informes de reparación
                </button>
              </Link>
            </div>
          </div>
        </div>
        <Box title="Cómputo General" icon="info">
          {this.identifyFaults()}
        </Box>
        <Box title="Averias por cliente">
          <FaultsByCustomerList matrixs={matrixs} machines={machines} />
        </Box>
        <Box title="Averias por máquina">
          <FaultsByMachineList matrixs={matrixs} machines={machines} />
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  matrixs: state.matrixs.matrixs,
  machines: state.machines.machines,
  error: state.matrixs.error,
  loading: state.matrixs.loading,
  role: state.auth.role,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(FaultsView)
