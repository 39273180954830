import {
    SHIFTS_REQUEST, SHIFTS_SUCCESS, SHIFTS_FAILURE,
    SHIFTS_RESET
  } from '../actions/shifts'
  
  const INITIAL_STATE = {
    shifts: null, error: null, loading: false
  }
  
  const shiftsReducer = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case SHIFTS_REQUEST:
          return {
            ...state,
            error: null, loading: true
          }
        case SHIFTS_SUCCESS:
          return {
            ...state,
            shifts: action.response, error: null, loading: false
          }
        case SHIFTS_FAILURE:
          return {
            ...state,
            error: action.error, loading: false
          }
        case SHIFTS_RESET:
          return {
            ...state,
            shifts: null, error: null, loading: false
          }
        default:
          return state
      }
    }
  
  export default shiftsReducer
  
  
  