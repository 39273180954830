import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { fetchPromoters } from '../actions/promoters'
import { fetchCustomers } from '../actions/customers'

import { renderInputField, renderSelectField } from '../utils/forms'

class PromotersFilterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchCustomers } = this.props

    fetchCustomers()

    this.load(this.props, true)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props, refresh=false) {
    const { customers, promoters } = props

    if (refresh || (!promoters.promoters && !promoters.loading)) {
      this.submit(this.props.formValues)
    }

    if (!this.state.loaded && customers) {
      this.setState({ loaded: true })
    }
  }

  submit(props) {
    return this.props.fetchPromoters(props.customer, props.status)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, customers } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)} className="mb-3">
        <div className="row">
          <div className="col">           
            <Field name="status"
                   component={renderSelectField}
                   label="Estado">
              <option value="">Todos</option>
              <option value="ALTA">ALTA</option>
              <option value="BAJA">BAJA</option>
            </Field>
          </div>
        </div>
        <div className="form-row">
          { error && <div className="alert alert-danger">{error}</div> }
        </div>
        <div className="form-row">
          <button className="btn bg-vw-dark text-white mr-1 button-center"
                  type="submit"
                  disabled={submitting}>
            <i className="fas fa-sync mr-1"></i>Actualizar
          </button>
        </div>
      </form>
    )
  }
}

PromotersFilterForm = reduxForm({
  form: 'PromotersFilterForm',
  destroyOnUnmount: false
})(PromotersFilterForm)

const selector = formValueSelector('PromotersFilterForm')

const mapStateToProps = state => ({
  customers: state.customers.customers,
  promoters: state.promoters,
  initialValues: {
    customer: '',
    status: 'ALTA'
  },
  formValues: {
    customer: selector(state, 'customer'),
    status: selector(state, 'status')
  }
})

const mapDispatchToProps = dispatch => ({
  fetchPromoters: bindActionCreators(fetchPromoters, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PromotersFilterForm)
