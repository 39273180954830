import { CALL_API } from '../middleware/api'

export const OPENINGS_REQUEST = 'OPENINGS_REQUEST'
export const OPENINGS_SUCCESS = 'OPENINGS_SUCCESS'
export const OPENINGS_FAILURE = 'OPENINGS_FAILURE'

export function fetchOpenings(Machine, Status, StartDate, EndDate) {
  let params = ''

  if (Machine !== undefined) {
    params += `&Machine=${Machine}`
  }

  if (Status !== undefined) {
    params += `&Status=${Status}`
  }

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `openings?${params}`,
      authenticated: true,
      types: [OPENINGS_REQUEST, OPENINGS_SUCCESS, OPENINGS_FAILURE]
    }
  }
}
