import { CALL_API } from '../middleware/api'

export const WITHDRAWALS_REQUEST = 'WITHDRAWALS_REQUEST'
export const WITHDRAWALS_SUCCESS = 'WITHDRAWALS_SUCCESS'
export const WITHDRAWALS_FAILURE = 'WITHDRAWALS_FAILURE'

export function fetchWithdrawals(Machine, Type, StartDate, EndDate) {
  let params = ''

  if (Machine !== undefined) {
    params += `&Machine=${Machine}`
  }

  if (Type !== undefined) {
    params += `&Type=${Type}`
  }

  if (StartDate !== undefined) {
    params += `&StartDate=${StartDate}`
  }

  if (EndDate !== undefined) {
    params += `&EndDate=${EndDate}`
  }

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `withdrawal?${params}`,
      authenticated: true,
      types: [WITHDRAWALS_REQUEST, WITHDRAWALS_SUCCESS, WITHDRAWALS_FAILURE]
    }
  }
}

export const WITHDRAWAL_REQUEST = 'WITHDRAWAL_REQUEST'
export const WITHDRAWAL_SUCCESS = 'WITHDRAWAL_SUCCESS'
export const WITHDRAWAL_FAILURE = 'WITHDRAWAL_FAILURE'

export function fetchWithdrawal(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `withdrawal/${id}`,
      authenticated: true,
      types: [WITHDRAWAL_REQUEST, WITHDRAWAL_SUCCESS, WITHDRAWAL_FAILURE]
    }
  }
}

export const WITHDRAWAL_UPDATE_REQUEST = 'WITHDRAWAL_UPDATE_REQUEST'
export const WITHDRAWAL_UPDATE_SUCCESS = 'WITHDRAWAL_UPDATE_SUCCESS'
export const WITHDRAWAL_UPDATE_FAILURE = 'WITHDRAWAL_UPDATE_FAILURE'

export function updateWithdrawal(id, props) {

  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `withdrawal/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [WITHDRAWAL_UPDATE_REQUEST, WITHDRAWAL_UPDATE_SUCCESS,
        WITHDRAWAL_UPDATE_FAILURE]
    }
  }
}

export const WITHDRAWALS_RESET = 'WITHDRAWALS_RESET'

export function resetWithdrawals() {
  return { type: WITHDRAWALS_RESET }
}
