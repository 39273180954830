import React from 'react'

class IncidenceStatusBadge extends React.Component {
  getColor(status) {

    switch(status) {
      case "CLOSED": 
        return "danger"
      case "REPAIR": 
        return "warning"
      case "OPEN": 
        return "info"
      default: 
        return "info"
    }

  }

  getText(status) {

    switch(status) {
      case "CLOSED": 
        return "CERRADA"
      case "REPAIR": 
        return "EN REPARACIÓN"
      case "OPEN": 
        return "ABIERTA"
      default: 
        return "ABIERTA"
    }
  }

  render() {
    return (
      <div className={`badge badge-${this.getColor(this.props.status)}`}>
        { this.getText(this.props.status) }
      </div>
    )
  }
}

export default IncidenceStatusBadge

