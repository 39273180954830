import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchPromoters } from '../actions/promoters'

import Box from '../components/Box'
import PromoterList from '../components/PromoterList'
import ErrorMessage from '../components/ErrorMessage'

import PromotersFilterForm from '../forms/PromotersFilterForm'

class PromotersView extends React.Component {
  constructor(props) {
    super(props)

  }

  renderPromoters() {
    const { promoters, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!promoters || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <PromoterList promoters={promoters} />
    )
  }

  render() {
    const { role, type } = this.props

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              PROMOTORES
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-end">
                <Link to="/promoters/create">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus mr-1"></i>Nuevo
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <PromotersFilterForm />
        </Box>
        {this.renderPromoters()}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  promoters: state.promoters.promoters,
  error: state.promoters.error,
  loading: state.promoters.loading,
  role: state.auth.role,
  type: state.auth.type,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchPromoters: bindActionCreators(fetchPromoters, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PromotersView)

