import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { Field, FieldArray, reduxForm, SubmissionError } from 'redux-form'

import Box from '../components/Box'
import { addRule } from '../actions/rules'
import { renderInputField, renderSelectField, renderTextAreaField } from '../utils/forms'

class RuleAddForm extends React.Component {
       constructor(props) {
              super(props)

              this.submit = this.submit.bind(this)
       }

       submit(props) {

              return this.props.addRule(props)
                     .then(response => {
                            if (response.error) {
                                   throw new SubmissionError({
                                          _error: response.error.message,
                                          ...response.error.fields
                                   })
                            }

                            return response
                     })
       }

       render() {
              const { error, pristine, submitting, handleSubmit } = this.props

              return (
                     <form onSubmit={handleSubmit(this.submit)}>
                            <h1>
                                   <span className="text-vw-dark">NUEVA </span>
                                   <span className="text-vw-light">REGLA DE NEGOCIO</span>
                            </h1>

                            <div className="row mb-5">
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Regla" icon="cubes">
                                                 <Field name="Name"
                                                        type="text"
                                                        component={renderInputField}
                                                        label="Nombre de la regla" />
                                                 <Field name="Type"
                                                        component={renderSelectField}
                                                        label="Tipo">
                                                        <option value="">Selecciona un tipo...</option>
                                                        <option value="MaxDayDiscount">Descuento por días</option>
                                                        <option value="AlwaysDiscount">Descuento fijo</option>
                                                 </Field>
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Configuración" icon="cubes">
                                                 <Field name="MaxDays"
                                                        type="number"
                                                        component={renderInputField}
                                                        label="Dias aplicables" />
                                                 <Field name="DiscountPercentage"
                                                        type="number"
                                                        component={renderInputField}
                                                        label="Porcentaje de descuento" />
                                          </Box>
                                   </div>
                                   <div className="col-xs-12 col-md-4">
                                          <Box title="Otros" icon="cubes">
                                                 <Field name="Condition"
                                                        component={renderTextAreaField}
                                                        label="Condiciones" />
                                          </Box>
                                   </div>
                            </div>
                            {error && <div className="alert alert-danger">{error}</div>}
                            <button className="btn bg-vw-light text-white mr-1 save" type="submit"
                                   disabled={pristine || submitting}>
                                   Guardar
                            </button>
                     </form>
              )
       }
}

RuleAddForm = reduxForm({
       form: 'RuleAddForm',
       keepDirtyOnReinitialize: true,
       enableReinitialize: true,
       updateUnregisteredFields: true,
       onSubmitSuccess: (result, dispatch, props) => {
              props.push(`/rules`)
       }
})(RuleAddForm)

const mapStateToProps = state => ({
       auth: state.auth,
       role: state.auth.role,
       type: state.auth.type,
       fullname: state.auth.fullname,
})

const mapDispatchToProps = dispatch => ({
       addRule: bindActionCreators(addRule, dispatch),
       push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RuleAddForm)
