import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchCalendars } from '../actions/calendars'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import CalendarsList from '../components/CalendarsList'

class CalendarsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchCalendars()
  }

  renderCalendars() {
    const { calendars, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!calendars || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (<CalendarsList calendars={calendars} />)
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">CALENDARIOS </span>
              <span className="text-vw-dark">DE PRECIOS</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
            <div className="col d-flex justify-content-end">
              <Link to="/calendars/add">
                <button type="button" className="btn bg-vw-light text-white">
                  <i className="fas fa-plus"></i>
                  <span className="d-none d-sm-inline ml-1">Nuevo</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Listado">
          {this.renderCalendars()}
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  calendars: state.calendars.calendars,
  error: state.calendars.error,
  loading: state.calendars.loading,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchCalendars: bindActionCreators(fetchCalendars, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarsView)


