import React from 'react'
import { history } from '../store'

class CustomerPDAList extends React.Component {
  goTo(id, e) {
    history.push(`/customerspda/${id}`)
  }


  render() {
    if (!this.props.customers) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Nombre completo</th>
              <th scope="col">E-Mail</th>
              <th scope="col">Tipo</th>
            </tr>
          </thead>
          <tbody>
            {this.props.customers.map(selected => {

              if ((selected.Type === "PDA") && (selected.Customer === this.props.customer._id)) {

                return (
                  <tr key={selected.number}
                    onClick={e => this.goTo(selected._id, e)}>
                    <th scope="row">{selected.number}</th>
                    <td>{selected.Fullname}</td>
                    <td>{selected.Email}</td>
                    <td>{selected.Type}</td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default CustomerPDAList

