import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError } from 'redux-form'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { updateReplacement, fetchReplacement } from '../actions/replacements'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'

class ReplacementsEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { replacement, fetchReplacement, updateReplacement, match } = this.props

    fetchReplacement(match.params.id)

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loaded: true })
  }

  load(props) {
    const { replacement } = props

    if (!this.state.loaded &&
      replacement) {

      this.setState({
        loaded: true
      })
    }
  }

  submit(props) {
    const { updateReplacement, replacement, match } = this.props

    return updateReplacement(match.params.id, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { replacement, error, pristine, submitting, handleSubmit, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!replacement) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <form onSubmit={handleSubmit(this.submit)}>
        <h1>
          <span className="text-vw-dark">EDITAR </span>
          <span className="text-vw-light">RECAMBIOS</span>
        </h1>
        <div className="row mb-2">
          <div className="col-xs-12 col-md-3">
            <Box title="Cierre" icon="sliders-h">
              <Field name="LOCK_MAIN.Price"
                component={renderInputField}
                type="text"
                label="Centralita" />
              <Field name="LOCK_PINPAD.Price"
                component={renderInputField}
                type="text"
                label="Teclado" />
              <Field name="LOCK_ANCHORAGE.Price"
                component={renderInputField}
                type="text"
                label="Cierre fijo" />
              <Field name="LOCK_PLATEN.Price"
                component={renderInputField}
                type="text"
                label="Pletina" />
              <Field name="BATTERIES.Price"
                component={renderInputField}
                type="text"
                label="Baterias" />
              <Field name="HINGE.Price"
                component={renderInputField}
                type="text"
                label="Bisagra" />
            </Box>
          </div>
          <div className="col-xs-12 col-md-3">
            <Box title="Kiosco" icon="sliders-h">
              <Field name="KIOSC_SCREEN.Price"
                component={renderInputField}
                type="text"
                label="Pantalla" />
              <Field name="KIOSC_COINCO_COIN_ENTRY.Price"
                component={renderInputField}
                type="text"
                label="Entrada monedas COINCO" />
              <Field name="KIOSC_COINCO_COIN_EXIT.Price"
                component={renderInputField}
                type="text"
                label="Salida monedas COINCO" />
              <Field name="KIOSC_PCB.Price"
                component={renderInputField}
                type="text"
                label="Placa control" />
              <Field name="KIOSC_ESPRESSIF.Price"
                component={renderInputField}
                type="text"
                label="Espressif" />
              <Field name="KIOSC_HUB.Price"
                component={renderInputField}
                type="text"
                label="Hub" />
            </Box>
          </div>
          <div className="col-xs-12 col-md-3">
            <Box title="Métodos de pago" icon="sliders-h">
              <Field name="PAYMENT_COINCO.Price"
                component={renderInputField}
                type="text"
                label="Coinco" />
              <Field name="PAYMENT_NV9USB.Price"
                component={renderInputField}
                type="text"
                label="NV9USB" />
              <Field name="PAYMENT_SPU90.Price"
                component={renderInputField}
                type="text"
                label="SPu90" />
              <Field name="PAYMENT_HOPPER.Price"
                component={renderInputField}
                type="text"
                label="Hopper" />
              <Field name="PAYMENT_U1000.Price"
                component={renderInputField}
                type="text"
                label="U1000" />
            </Box>
          </div>
          <div className="col-xs-12 col-md-3">
            <Box title="Impresoras" icon="sliders-h">
              <Field name="PRINTER_CASHINO.Price"
                component={renderInputField}
                type="text"
                label="Cashino" />
              <Field name="PRINTER_BROTHER.Price"
                component={renderInputField}
                type="text"
                label="Brother" />
            </Box>
            <Box title="Otros" icon="sliders-h">
              <Field name="USB_WIRE.Price"
                component={renderInputField}
                type="text"
                label="Cable USB" />
              <Field name="LOCK_NUMBER.Price"
                component={renderInputField}
                type="text"
                label="Número" />
            </Box>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-md-12">
            <Box title="Puertas" icon="sliders-h">
              <div className="row mb-2">
                <div className="col-xs-12 col-md-4">
                  <Field name="DOOR_BNS.Price"
                    component={renderInputField}
                    type="text"
                    label="Básica" />
                  <Field name="DOOR_BWS.Price"
                    component={renderInputField}
                    type="text"
                    label="Básica XL" />
                </div>
                <div className="col-xs-12 col-md-4">
                  <Field name="DOOR_BNM.Price"
                    component={renderInputField}
                    type="text"
                    label="Grande" />
                  <Field name="DOOR_BWM.Price"
                    component={renderInputField}
                    type="text"
                    label="Grande XL" />
                </div>
                <div className="col-xs-12 col-md-4">
                  <Field name="DOOR_BNL.Price"
                    component={renderInputField}
                    type="text"
                    label="Jumbo" />
                  <Field name="DOOR_BWL.Price"
                    component={renderInputField}
                    type="text"
                    label="Jumbo XL" />
                </div>
              </div>
            </Box>
          </div >
        </div >
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn bg-vw-light text-white mr-1 save" type="submit"
          disabled={pristine || submitting}>
          Guardar
        </button>
      </form>
    )
  }
}

ReplacementsEditForm = reduxForm({
  form: 'ReplacementsEditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/replacements`)
  }
})(ReplacementsEditForm)

const mapStateToProps = state => ({
  replacement: state.replacements.replacement,
  error: state.replacements.error,
  loading: state.replacements.loading,
  auth: state.auth,
  initialValues: {
    ...state.replacements.replacement,
  }
})

const mapDispatchToProps = dispatch => ({
  updateReplacement: bindActionCreators(updateReplacement, dispatch),
  fetchReplacement: bindActionCreators(fetchReplacement, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ReplacementsEditForm)


