import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError, FieldArray } from 'redux-form'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'

import { updateIncidence, fetchIncidence } from '../actions/incidences'
import { fetchMachines } from '../actions/machines'
import { fetchCustomers } from '../actions/customers'

class IncidenceEditForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      fCustomers: null,
      fMachines: null
    }

    this.load = this.load.bind(this)

    this.state = { loaded: false }
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchIncidence, fetchMachines, fetchCustomers, match } = this.props

    fetchIncidence(match.params.number)
    fetchCustomers()
    fetchMachines()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { incidence, machines, customers } = props

    if (!this.state.loaded &&
      incidence && machines && customers) {

      this.setState({
        loaded: true
      })
    }
  }

  submit(props) {
    const { updateIncidence, incidence } = this.props

    return updateIncidence(incidence.number, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { customers, machines, error, pristine, submitting, handleSubmit } = this.props
    const { loaded, fMachines, fCustomers } = this.state

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <h1>
          <span className="text-vw-dark">EDITAR </span>
          <span className="text-vw-light">INCIDENCIA</span>
        </h1>

        <form onSubmit={this.props.handleSubmit(this.submit)}>
          <div className="row">
            <div className="col col-12 col-lg-4">
              <Box title="Asignación" icon="exclamation-triangle">
                <Field name="Customer"
                  component={renderSelectField}
                  label="Cliente">
                  <option value="">Selecciona a un cliente...</option>
                  {customers &&
                    customers
                      .filter((customer) => customer.Type === 'GENERAL')
                      .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                      .map((customer) => (
                        <option value={customer._id} key={customer._id}>
                          {customer.Fullname}
                        </option>
                      ))}
                </Field>
                <Field name="Machine"
                  component={renderSelectField}
                  label="Máquina">
                  <option value="">Selecciona una máquina...</option>
                  {machines.map(machine => {
                    return (
                      <option value={machine._id} key={machine._id}>
                        {machine.Serial}
                      </option>
                    )
                  })}
                </Field>
                <Field name="Category"
                  component={renderSelectField}
                  label="Categoria">
                  <option value="">Selecciona una categoria...</option>
                  <option value="Minor">Pregunta / Incidente menor</option>
                  <option value="Breakdown">Avería</option>
                  <option value="UrgentBreakdown">Avería urgente</option>
                </Field>
              </Box>
            </div>
            <div className="col col-12 col-lg-8">
              <Box title="Incidencia" icon="exclamation-triangle">
                <Field name="Description"
                  component={renderTextAreaField}
                  label="Descripción" />
              </Box>
            </div>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <button className="btn bg-vw-light text-white mr-1 save" type="submit"
            disabled={pristine || submitting}>
            Guardar
          </button>
        </form>
      </div>
    )
  }
}

IncidenceEditForm = reduxForm({
  form: 'IncidenceEditForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/incidences`)
  }
})(IncidenceEditForm)

const mapStateToProps = state => ({
  incidence: state.incidences.incidence,
  machines: state.machines.machines,
  customers: state.customers.customers,
  initialValues: {
    Machine: '',
    Customer: '',
    Status: '',
    Category: '',
    StartDate: new Date().toISOString().substring(0, 10),
    EndDate: new Date().toISOString().substring(0, 10)
  },
})

const mapDispatchToProps = dispatch => ({
  fetchIncidence: bindActionCreators(fetchIncidence, dispatch),
  updateIncidence: bindActionCreators(updateIncidence, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidenceEditForm)


