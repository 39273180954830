import React from 'react'
import { history } from '../store'
import * as dateFormat from 'dateformat'

class SupportTicketClosedList extends React.Component {
  goTo(number, e) {
    history.push(`/supporttickets/${number}`)
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Fecha Apertura</th>
              <th scope="col">Cliente</th>
              <th scope="col">Máquina</th>
              <th scope="col">Categoria</th>
              <th scope="col">Descripción</th>
              <th scope="col">Estado</th>
            </tr>
          </thead>
          <tbody>
            {this.props.supporttickets.map(supportticket => {
              if (supportticket.Status === "CLOSED") {
                return (
                  <tr key={supportticket.number}
                    onClick={e => this.goTo(supportticket.number, e)}>
                    <th scope="row">{supportticket.number}</th>
                    <td>{(new Date(supportticket.TimeStamp)).toLocaleString()}</td>
                    <td>{supportticket.Customer.Fullname ? supportticket.Customer.Fullname : "VACWAY"}</td>
                    <td>{supportticket.Machine.Serial}</td>
                    <td>{supportticket.Category}</td>
                    <td>{supportticket.Description}</td>
                    <td>{supportticket.Status}</td>
                  </tr>
                )
              }
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default SupportTicketClosedList

