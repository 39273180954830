import {
  ARTICLES_REQUEST, ARTICLES_SUCCESS, ARTICLES_FAILURE,
  ARTICLE_UPDATE_REQUEST, ARTICLE_UPDATE_SUCCESS, ARTICLE_UPDATE_FAILURE,
  ARTICLES_RESET
} from '../actions/articles'

const INITIAL_STATE = {
  article: null, articles: null, error: null, loading: false
}

const articlesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ARTICLES_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case ARTICLES_SUCCESS:
      return {
        ...state,
        articles: action.response, error: null, loading: false
      }
    case ARTICLES_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case ARTICLE_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case ARTICLE_UPDATE_SUCCESS:
      return {
        ...state,
        article: action.response, error: null, loading: false
      }
    case ARTICLE_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case ARTICLES_RESET:
      return {
        ...state,
        articles: null, error: null, loading: false
      }
    default:
      return state
  }
}

export default articlesReducer


