import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchQRs } from '../actions/qrs'

import QRList from '../components/QRList'
import ErrorMessage from '../components/ErrorMessage'

class QRsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {

    }

  }

  componentDidMount() {
    this.props.fetchQRs()
  }

  render() {
    const { role, type, qrs, error } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!qrs) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (
      <div>

        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-dark">PARQUE DE </span>
              <span className="text-vw-light">QRs</span>
            </h1>
          </div>
          {(role === 'ADMIN' && type === 'GOD') &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-end">
                <Link to="/qrs/add">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus mr-1"></i>Nuevo
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-12 col-md-12">
            <QRList qrs={qrs} />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  qrs: state.qrs.qrs,
  error: state.qrs.error,
  role: state.auth.role,
  type: state.auth.type
})

const mapDispatchToProps = dispatch => ({
  fetchQRs: bindActionCreators(fetchQRs, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(QRsView)
