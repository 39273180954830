import { CALL_API } from '../middleware/api'

export function addUniversalUpdateApp(remoteAction) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'universalupdates/app',
      authenticated: true,
      body: JSON.stringify(remoteAction),
      types: [UNIVERSAL_UPDATE_APP_ADD_REQUEST, UNIVERSAL_UPDATE_APP_ADD_SUCCESS,
        UNIVERSAL_UPDATE_APP_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_APP_ADD_REQUEST = 'UNIVERSAL_UPDATE_APP_ADD_REQUEST'
export const UNIVERSAL_UPDATE_APP_ADD_SUCCESS = 'UNIVERSAL_UPDATE_APP_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_APP_ADD_FAILURE = 'UNIVERSAL_UPDATE_APP_ADD_FAILURE'

export function addUniversalUpdateEspressif(remoteAction) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'universalupdates/espressif',
      authenticated: true,
      body: JSON.stringify(remoteAction),
      types: [UNIVERSAL_UPDATE_ESPRESSIF_ADD_REQUEST, UNIVERSAL_UPDATE_ESPRESSIF_ADD_SUCCESS,
        UNIVERSAL_UPDATE_ESPRESSIF_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_ESPRESSIF_ADD_REQUEST = 'UNIVERSAL_UPDATE_ESPRESSIF_ADD_REQUEST'
export const UNIVERSAL_UPDATE_ESPRESSIF_ADD_SUCCESS = 'UNIVERSAL_UPDATE_ESPRESSIF_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_ESPRESSIF_ADD_FAILURE = 'UNIVERSAL_UPDATE_ESPRESSIF_ADD_FAILURE'

export function addUniversalUpdateParameters() {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'universalupdates/parameters',
      authenticated: true,
      types: [UNIVERSAL_UPDATE_PARAMETERS_ADD_REQUEST, UNIVERSAL_UPDATE_PARAMETERS_ADD_SUCCESS,
        UNIVERSAL_UPDATE_PARAMETERS_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_PARAMETERS_ADD_REQUEST = 'UNIVERSAL_UPDATE_PARAMETERS_ADD_REQUEST'
export const UNIVERSAL_UPDATE_PARAMETERS_ADD_SUCCESS = 'UNIVERSAL_UPDATE_PARAMETERS_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_PARAMETERS_ADD_FAILURE = 'UNIVERSAL_UPDATE_PARAMETERS_ADD_FAILURE'

export function addUniversalUpdateReboot() {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'universalupdates/reboot',
      authenticated: true,
      types: [UNIVERSAL_UPDATE_REBOOT_ADD_REQUEST, UNIVERSAL_UPDATE_REBOOT_ADD_SUCCESS,
        UNIVERSAL_UPDATE_REBOOT_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_REBOOT_ADD_REQUEST = 'UNIVERSAL_UPDATE_REBOOT_ADD_REQUEST'
export const UNIVERSAL_UPDATE_REBOOT_ADD_SUCCESS = 'UNIVERSAL_UPDATE_REBOOT_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_REBOOT_ADD_FAILURE = 'UNIVERSAL_UPDATE_REBOOT_ADD_FAILURE'

export function addUniversalUpdateUnrent() {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'universalupdates/unrent',
      authenticated: true,
      types: [UNIVERSAL_UPDATE_UNRENT_ADD_REQUEST, UNIVERSAL_UPDATE_UNRENT_ADD_SUCCESS,
        UNIVERSAL_UPDATE_UNRENT_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_UNRENT_ADD_REQUEST = 'UNIVERSAL_UPDATE_UNRENT_ADD_REQUEST'
export const UNIVERSAL_UPDATE_UNRENT_ADD_SUCCESS = 'UNIVERSAL_UPDATE_UNRENT_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_UNRENT_ADD_FAILURE = 'UNIVERSAL_UPDATE_UNRENT_ADD_FAILURE'

export function addUniversalUpdateSingleParameters(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `universalupdates/parameters/${id}`,
      authenticated: true,
      types: [UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_REQUEST, UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_SUCCESS,
        UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_REQUEST = 'UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_REQUEST'
export const UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_SUCCESS = 'UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_FAILURE = 'UNIVERSAL_UPDATE_SINGLE_PARAMETERS_ADD_FAILURE'

export function addUniversalUpdateSingleReboot(id) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: `universalupdates/reboot/${id}`,
      authenticated: true,
      types: [UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_REQUEST, UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_SUCCESS,
        UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_FAILURE]
    }
  }
}

export const UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_REQUEST = 'UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_REQUEST'
export const UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_SUCCESS = 'UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_SUCCESS'
export const UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_FAILURE = 'UNIVERSAL_UPDATE_SINGLE_REBOOT_ADD_FAILURE'
