import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchAudits, fetchAuditsCSV } from '../actions/audits'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import AuditEntryList from '../components/AuditEntryList'

import AuditFilterForm from '../forms/AuditFilterForm'

class AuditView extends React.Component {
  constructor(props) {
    super(props)

    this.handleCSV = this.handleCSV.bind(this)
  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10)
    this.props.fetchAudits('', '', '', today, today)
  }

  handleCSV() {
    const { fetchAuditsCSV, form } = this.props

    fetchAuditsCSV(
      form.AuditFilterForm.values.machine,
      form.AuditFilterForm.values.type,
      form.AuditFilterForm.values.StartDate,
      form.AuditFilterForm.values.EndDate
    )
  }

  renderResume() {
    const { audits, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!audits || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let auditTotalCounts = {
      "KioscDoorOpen": 0,
      "Reboots": 0,
      "LockerOpens": 0,
      "LockerChangePin": 0,
      "LoginMaintenance": 0,
      "LockerOpenAll": 0,
    };

    this.props.audits.forEach(audit => {

      const event = audit.Evento;
      const category = audit.Description;

      if (category.includes('Abriendo taquilla:')) {
        auditTotalCounts['LockerOpens'] = (auditTotalCounts['LockerOpens'] || 0) + 1;
      }

      if (category.includes('Cambiando pin taquilla:')) {
        auditTotalCounts['LockerChangePin'] = (auditTotalCounts['LockerChangePin'] || 0) + 1;
      }

      if (category.includes('Abriendo todas las taquillas')) {
        auditTotalCounts['LockerOpenAll'] = (auditTotalCounts['LockerOpenAll'] || 0) + 1;
      }

      if (event.includes('LOGIN MANTENIMIENTO') && category.includes('LOGIN CORRECTO')) {
        auditTotalCounts['LoginMaintenance'] = (auditTotalCounts['LoginMaintenance'] || 0) + 1;
      }

      if (category.includes('Reinicio manual de máquina')) {
        auditTotalCounts['Reboots'] = (auditTotalCounts['Reboots'] || 0) + 1;
      }

      if (event.includes('ABRIR PUERTA') && category.includes('Puerta Abierta')) {
        auditTotalCounts['KioscDoorOpen'] = (auditTotalCounts['KioscDoorOpen'] || 0) + 1;
      }

    });

    return (
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Reinicios</th>
              <th scope="col">Aperturas puerta kiosco</th>
              <th scope="col">Logins mantenimiento</th>
              <th scope="col">Abrir todas</th>
              <th scope="col">Abrir taquilla individual</th>
              <th scope="col">Cambio de pin</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{auditTotalCounts ? auditTotalCounts["Reboots"] : 0}</td>
              <td>{auditTotalCounts ? auditTotalCounts["KioscDoorOpen"] : 0}</td>
              <td>{auditTotalCounts ? auditTotalCounts["LoginMaintenance"] : 0}</td>
              <td>{auditTotalCounts ? auditTotalCounts["LockerOpenAll"] : 0}</td>
              <td>{auditTotalCounts ? auditTotalCounts["LockerOpens"] : 0}</td>
              <td>{auditTotalCounts ? auditTotalCounts["LockerChangePin"] : 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderAudits() {
    const { audits, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!audits || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (<AuditEntryList audits={audits} />)
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              AUDIT
            </h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
            <div className="col d-flex justify-content-end">
              <button className="btn bg-vw-excel text-white" onClick={this.handleCSV}>
                <i className="fas fa-file-excel mr-1"></i>Descargar
              </button>
            </div>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <AuditFilterForm />
        </Box>
        <Box title="Resumen" icon="filter">
          {this.renderResume()}
        </Box>
        <Box title="Registros">
          {this.renderAudits()}
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  audits: state.audits.audits,
  error: state.audits.error,
  loading: state.audits.loading,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchAudits: bindActionCreators(fetchAudits, dispatch),
  fetchAuditsCSV: bindActionCreators(fetchAuditsCSV, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AuditView)


