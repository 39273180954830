import { CALL_API } from '../middleware/api'

export const PDAS_REQUEST = 'PDAS_REQUEST'
export const PDAS_SUCCESS = 'PDAS_SUCCESS'
export const PDAS_FAILURE = 'PDAS_FAILURE'

export function fetchPDAs() {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: 'pdas',
      authenticated: true,
      types: [PDAS_REQUEST, PDAS_SUCCESS, PDAS_FAILURE]
    }
  }
}

export const PDA_REQUEST = 'PDA_REQUEST'
export const PDA_SUCCESS = 'PDA_SUCCESS'
export const PDA_FAILURE = 'PDA_FAILURE'

export function fetchPDA(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `pdas/${id}`,
      authenticated: true,
      types: [PDA_REQUEST, PDA_SUCCESS, PDA_FAILURE]
    }
  }
}

export const PDA_BYCUSTOMER_REQUEST = 'PDA_BYCUSTOMER_REQUEST'
export const PDA_BYCUSTOMER_SUCCESS = 'PDA_BYCUSTOMER_SUCCESS'
export const PDA_BYCUSTOMER_FAILURE = 'PDA_BYCUSTOMER_FAILURE'

export function fetchPDACustomer(id) {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `pdas/customer/${id}`,
      authenticated: false,
      types: [PDA_BYCUSTOMER_REQUEST, PDA_BYCUSTOMER_SUCCESS, PDA_BYCUSTOMER_FAILURE]
    }
  }
}

export const PDA_ADD_REQUEST = 'PDA_ADD_REQUEST'
export const PDA_ADD_SUCCESS = 'PDA_ADD_SUCCESS'
export const PDA_ADD_FAILURE = 'PDA_ADD_FAILURE'

export function addPDA(pda) {
  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'pdas',
      authenticated: true,
      body: JSON.stringify(pda),
      types: [PDA_ADD_REQUEST, PDA_ADD_SUCCESS, PDA_ADD_FAILURE]
    }
  }
}

export const PDA_UPDATE_REQUEST = 'PDA_UPDATE_REQUEST'
export const PDA_UPDATE_SUCCESS = 'PDA_UPDATE_SUCCESS'
export const PDA_UPDATE_FAILURE = 'PDA_UPDATE_FAILURE'

export function updatePDA(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `pdas/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [PDA_UPDATE_REQUEST, PDA_UPDATE_SUCCESS,
        PDA_UPDATE_FAILURE]
    }
  }
}

export const PDA_RESET = 'PDA_RESET'

export function resetPDA() {
  return { type: PDA_RESET }
}

//ONLINE SALE

export const PDASALE_REQUEST = 'PDASALE_REQUEST'
export const PDASALE_SUCCESS = 'PDASALE_SUCCESS'
export const PDASALE_FAILURE = 'PDASALE_FAILURE'

export function postPDAsale(sale) {

  return {
    [CALL_API]: {
      method: 'POST',
      endpoint: 'sales/pda',
      authenticated: true,
      body: JSON.stringify(sale),
      types: [PDASALE_REQUEST, PDASALE_SUCCESS, PDASALE_FAILURE]
    }
  }
}

