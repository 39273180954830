import {
  AUTH_LOGIN_REQUEST, AUTH_LOGIN_SUCCESS, AUTH_LOGIN_FAILURE,
  AUTH_PASSWORD_RECOVERY_REQUEST, AUTH_PASSWORD_RECOVERY_SUCCESS, AUTH_PASSWORD_RECOVERY_FAILURE
} from '../actions/auth'

const INITIAL_STATE = {
  isAuth: false, id: null, fullname: null, role: null, error: null,
  loading: false, category: null
}

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isAuth: false, error: null, loading: true
      }
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true, id: action.data.id, fullname: action.data.fullname,
        role: action.data.role, type: action.data.type, category: action.data.category, loading: false
      }
    case AUTH_LOGIN_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case AUTH_PASSWORD_RECOVERY_REQUEST:
      return {
        ...state,
        emailSend: false, error: null, loading: true
      }
    case AUTH_PASSWORD_RECOVERY_SUCCESS:
      return {
        ...state,
        emailSend: true, error: false, loading: false
      }
    case AUTH_PASSWORD_RECOVERY_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default authReducer
