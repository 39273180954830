import {
  OPENINGS_REQUEST, OPENINGS_SUCCESS, OPENINGS_FAILURE
} from '../actions/openings'

const INITIAL_STATE = {
  openings: null, error: null, loading: false
}

const openingsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case OPENINGS_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case OPENINGS_SUCCESS:
        return {
          ...state,
          openings: action.response, error: null, loading: false
        }
      case OPENINGS_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      default:
        return state
    }
  }

export default openingsReducer


