import React from 'react'
import { history } from '../store'

class ModuleErrorList extends React.Component {
  goTo(Code, e) {
    history.push(`/modules/${Code}`)
  }

  render() {

    const divStyle = {
      color: 'red'
    };

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col"><span style={divStyle}>Módulos ERROR</span></th>
            </tr>
          </thead>
          <tbody>
            {this.props.modules.map(module => (

              ((module.Code).substring(0, 3) != 'VLM' && (module.Code).substring(0, 3) != 'VLK') ? (

                <tr key={module.Code}
                  onClick={e => this.goTo(module.Code, e)}>
                  <th scope="row"><span style={divStyle}>{module.Code}</span></th>
                </tr>

              ) : ("")
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ModuleErrorList
