import {
  OPERATORS_REQUEST, OPERATORS_SUCCESS, OPERATORS_FAILURE,
  OPERATOR_ADD_REQUEST, OPERATOR_ADD_SUCCESS, OPERATOR_ADD_FAILURE,
  OPERATOR_UPDATE_REQUEST, OPERATOR_UPDATE_SUCCESS, OPERATOR_UPDATE_FAILURE,
  OPERATOR_REQUEST, OPERATOR_SUCCESS, OPERATOR_FAILURE
} from '../actions/operators'

const INITIAL_STATE = {
  operator: null, operators: null, error: null, loading: false
}

const operatorsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPERATORS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case OPERATORS_SUCCESS:
      return {
        ...state,
        operators: action.response, error: null, loading: false
      }
    case OPERATORS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case OPERATOR_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case OPERATOR_ADD_SUCCESS:
      return {
        ...state,
        operator: action.response, error: null, loading: false
      }
    case OPERATOR_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case OPERATOR_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case OPERATOR_UPDATE_SUCCESS:
      return {
        ...state,
        operator: action.response, error: null, loading: false
      }
    case OPERATOR_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case OPERATOR_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case OPERATOR_SUCCESS:
      return {
        ...state,
        operator: action.response, error: null, loading: false
      }
    case OPERATOR_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default operatorsReducer


