import React from 'react'
import * as dateFormat from 'dateformat'

const style = {
  width: 125,
  height: 45,
  borderRadius: 10,
  boxShadow: '0 .20rem .50rem rgba(0, 0, 0, .15)',
  textAlign: 'center',
  color: '#000',
  fontSize: 24,
  fontWeight: 'bold',
  padding: 4,

  cursor: 'pointer'
}

function getShiftStatusColor(status) {

  if (status === 'START') {
    return '#07AB0C'
  } else if (status === 'STOP') {
    return '#EA1E1E'
  } else {
    return '#FFAA00'
  }
}

class ShiftEntryList extends React.Component {

  renderShiftStatuses(status) {
    return (
      <div style={{
        ...style,
        backgroundColor: `${getShiftStatusColor(status)}`,
        border: `5px solid ${getShiftStatusColor(status)}`
        }}>
          <h5>{status}</h5>
       </div>
    );
  }

  render() {
    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Trabajador</th>
              <th scope="col">Fecha/Hora</th>
              <th scope="col">Máquina</th>
              <th scope="col">Acción</th>
            </tr>
          </thead>
          <tbody>
            {this.props.shifts.map(shift => (
              <tr key={shift._id}>
                <td>{shift.workerName}</td>
                <td>{dateFormat(new Date(shift.date), 'dd/mm/yy HH:MM')}</td>
                <td>{shift.machine.Serial}</td>
                <td>                
                  <div className="d-inline-block p-2">
                    {this.renderShiftStatuses(shift.action)}
                  </div>
                </td>
              </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ShiftEntryList

