import React from 'react'

class PromoterStats extends React.Component {
    render() {
        const { stats } = this.props

        return (
            <div className="table-responsive">
                <table className="table table-hover table-clickable">
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">Artículo</th>
                            <th scope="col">Unidades</th>
                            <th scope="col">Comisiones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Locker Basic</td>
                            <td>{stats['LOCKERBASIC']['qty']}</td>
                            <td>{stats['LOCKERBASIC']['fees'].toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td>Locker Basic XL</td>
                            <td>{stats['LOCKERBASICXL']['qty']}</td>
                            <td>{stats['LOCKERBASICXL']['fees'].toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td>Locker Charger</td>
                            <td>{stats['LOCKERCHARGER']['qty']}</td>
                            <td>{stats['LOCKERCHARGER']['fees'].toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td>Locker Charger XL</td>
                            <td>{stats['LOCKERCHARGERXL']['qty']}</td>
                            <td>{stats['LOCKERCHARGERXL']['fees'].toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td>Tumbonas</td>
                            <td>{stats['TUMBONAS']['qty']}</td>
                            <td>{stats['TUMBONAS']['fees'].toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td>Flotadores día</td>
                            <td>{stats['FLOTADORES']['qty']}</td>
                            <td>{stats['FLOTADORES']['fees'].toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td>Flotadores medio día</td>
                            <td>{stats['FLOTADORESHALFDAY']['qty']}</td>
                            <td>{stats['FLOTADORESHALFDAY']['fees'].toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td>Fastpass</td>
                            <td>{stats['FASTPASS']['qty']}</td>
                            <td>{stats['FASTPASS']['fees'].toFixed(2)} €</td>
                        </tr>
                        <tr>
                            <td>TOTAL</td>
                            <td>
                                {stats['LOCKERBASIC']['qty'] + stats['LOCKERBASICXL']['qty'] + stats['LOCKERCHARGER']['qty'] + stats['LOCKERCHARGERXL']['qty'] + stats['TUMBONAS']['qty'] + stats['FLOTADORES']['qty'] + stats['FLOTADORESHALFDAY']['qty'] + stats['FASTPASS']['qty']}
                            </td>
                            <td>
                                {(stats['LOCKERBASIC']['fees'] + stats['LOCKERBASICXL']['fees'] + stats['LOCKERCHARGER']['fees'] + stats['LOCKERCHARGERXL']['fees'] + stats['TUMBONAS']['fees'] + stats['FLOTADORES']['fees'] + stats['FLOTADORESHALFDAY']['fees'] + stats['FASTPASS']['fees']).toFixed(2)} €
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default PromoterStats

