import React from 'react'

class AssignedModuleList extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Número de serie</th>
            </tr>
          </thead>
          <tbody>
            {this.props.Modules.map(module => (
              <tr>
                <th scope="row">{module.serialNumber}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AssignedModuleList
