import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import * as dateFormat from 'dateformat'

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'

import { fetchPromoter, updatePromoter } from '../actions/promoters'

class PromoterDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      closing: false,
      closeConfirmShown: false,
      opening: false,
      openConfirmShown: false
    }

    this.closePromoter = this.closePromoter.bind(this)
    this.closeConfirmShow = this.closeConfirmShow.bind(this)
    this.closeConfirmToggle = this.closeConfirmToggle.bind(this)
    this.openPromoter = this.openPromoter.bind(this)
    this.openConfirmShow = this.openConfirmShow.bind(this)
    this.openConfirmToggle = this.openConfirmToggle.bind(this)
  }

  componentDidMount() {
    this.props.fetchPromoter(this.props.match.params.number)
  }

  componentWillReceiveProps(nextProps) {
    if ((this.state.opening || this.state.closing) && !nextProps.loading) {
      this.props.push('/promoters')
    }
  }

  closePromoter() {
    const { updatePromoter, promoter } = this.props

    this.setState({ closing: true })
    updatePromoter(promoter.number, { status: 'BAJA' })
  }

  closeConfirmShow() {
    this.setState({ closeConfirmShown: true })
  }

  closeConfirmToggle() {
    this.setState({ closeConfirmShown: !this.state.closeConfirmShown })
  }

  openPromoter() {
    const { updatePromoter, promoter } = this.props

    this.setState({ opening: true })
    updatePromoter(promoter.number, { status: 'ALTA' })
  }

  openConfirmShow() {
    this.setState({ openConfirmShown: true })
  }

  openConfirmToggle() {
    this.setState({ openConfirmShown: !this.state.openConfirmShown })
  }

  render() {
    const { role, promoter, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!promoter || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">PROMOTOR </span>
              <span className="text-vw-dark">{promoter.name} {promoter.surname}</span>
            </h1>
          </div>
          {role === 'ADMIN' &&
            <div className="col-xs-12 col-sm-6 col-md-6 text-right">
              <Link to={`/promoters/${promoter.number}/edit`}>
                <button className="btn bg-vw-light text-white mr-1">
                  <i className="fas fa-edit mr-1"></i>Editar
                </button>
              </Link>
              {promoter.status === 'ALTA' &&
                <button className="btn btn-warning mr-1" onClick={this.closeConfirmShow}>
                  <i className="fas fa-times-circle mr-1"></i>Dar de baja
                </button>
              }
              {promoter.status === 'BAJA' &&
                <button className="btn btn-success mr-1" onClick={this.openConfirmShow}>
                  <i className="fa mr-1"></i>Dar de alta
                </button>
              }
            </div>
          }
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Promotor" icon="user-tie">
              <PropertyLabel name="Número" value={promoter.number} />
              <PropertyLabel name="Nombre" value={promoter.name} />
              <PropertyLabel name="Apellidos" value={promoter.surname} />
              {
                (promoter.birthday) &&
                <PropertyLabel name="Cumpleaños" value={dateFormat(new Date(promoter.birthday), 'dd/mm/yy')} />
              }
              <PropertyLabel name="E-Mail" value={promoter.mail} />
              <PropertyLabel name="Teléfono" value={promoter.phone} />
              <PropertyLabel name="Código / Password" value={promoter.password} />
              <PropertyLabel name="Estado" value={promoter.status} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-5">
            <Box title="Horario" icon="clock">
              <div className="row timetable timetable-border">
                <h6> Lunes: </h6>
                <label>{promoter.monday_start1}</label>
                <label>  a  </label>
                <label>{promoter.monday_end1}</label>
                <label>  y de  </label>
                <label>{promoter.monday_start2}</label>
                <label>  a  </label>
                <label>{promoter.monday_end2}</label>
              </div>
              <div className="row timetable timetable-border">
                <h6> Martes: </h6>
                <label>{promoter.tuesday_start1}</label>
                <label>  a  </label>
                <label>{promoter.tuesday_end1}</label>
                <label>  y de  </label>
                <label>{promoter.tuesday_start2}</label>
                <label>  a  </label>
                <label>{promoter.tuesday_end2}</label>
              </div>
              <div className="row timetable timetable-border">
                <h6> Miércoles: </h6>
                <label>{promoter.wednesday_start1}</label>
                <label>  a  </label>
                <label>{promoter.wednesday_end1}</label>
                <label>  y de  </label>
                <label>{promoter.wednesday_start2}</label>
                <label>  a  </label>
                <label>{promoter.wednesday_end2}</label>
              </div>
              <div className="row timetable timetable-border">
                <h6> Jueves: </h6>
                <label>{promoter.thursday_start1}</label>
                <label>  a  </label>
                <label>{promoter.thursday_end1}</label>
                <label>  y de  </label>
                <label>{promoter.thursday_start2}</label>
                <label>  a  </label>
                <label>{promoter.thursday_end2}</label>
              </div>
              <div className="row timetable timetable-border">
                <h6> Viernes: </h6>
                <label>{promoter.friday_start1}</label>
                <label>  a  </label>
                <label>{promoter.friday_end1}</label>
                <label>  y de  </label>
                <label>{promoter.friday_start2}</label>
                <label>  a  </label>
                <label>{promoter.friday_end2}</label>
              </div>
              <div className="row timetable timetable-border">
                <h6> Sábado: </h6>
                <label>{promoter.saturday_start1}</label>
                <label>  a  </label>
                <label>{promoter.saturday_end1}</label>
                <label>  y de  </label>
                <label>{promoter.saturday_start2}</label>
                <label>  a  </label>
                <label>{promoter.saturday_end2}</label>
              </div>
              <div className="row timetable timetable-border">
                <h6> Domingo: </h6>
                <label>{promoter.sunday_start1}</label>
                <label>  a  </label>
                <label>{promoter.sunday_end1}</label>
                <label>  y de  </label>
                <label>{promoter.sunday_start2}</label>
                <label>  a  </label>
                <label>{promoter.sunday_end2}</label>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-md-3">
            <Box title="Comisiones del promotor" icon="hand-holding-usd">
              <PropertyLabel name="Pack" value={promoter.Promoter_fees.VWPACK} />
              <PropertyLabel name="Lay" value={promoter.Promoter_fees.VWLAY} />
              <PropertyLabel name="Go" value={promoter.Promoter_fees.VWGO} />
              <PropertyLabel name="Cargador" value={promoter.Promoter_fees.VWCHARGER} />
            </Box>
          </div>
        </div>
        {/* Confirm close */}
        <div>
          <Modal isOpen={this.state.closeConfirmShown}
            toggle={this.closeConfirmToggle}>
            <ModalHeader toggle={this.closeConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de dar de baja al promotor?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.closePromoter}>
                Dar de baja
              </Button>
              <Button color="secondary" onClick={this.closeConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* Confirm open */}
        <div>
          <Modal isOpen={this.state.openConfirmShown}
            toggle={this.openConfirmToggle}>
            <ModalHeader toggle={this.openConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de dar de alta al promotor?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.openPromoter}>
                Dar de alta
              </Button>
              <Button color="secondary" onClick={this.openConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  promoter: state.promoters.promoter,
  loading: state.promoters.loading,
  error: state.promoters.error,
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  fetchPromoter: bindActionCreators(fetchPromoter, dispatch),
  updatePromoter: bindActionCreators(updatePromoter, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PromoterDetailsView)

