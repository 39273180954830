import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Field, reduxForm } from 'redux-form'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'
import Box from '../components/Box'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { depositSecurityBox, emptySecurityBox } from '../actions/machines'

class SecurityBoxTable extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            depositSecurityBoxModalOpen: false,
            emptySecurityBoxModalOpen: false,
            fiveBills: 0,
            tenBills: 0,
            twentyBills: 0,
            currentFiveBills: this.props.machine.Bills_in_security_box.five,
            currentTenBills: this.props.machine.Bills_in_security_box.ten,
            currentTwentyBills: this.props.machine.Bills_in_security_box.twenty,
        }

        this.openDepositDialog = this.openDepositDialog.bind(this)
        this.openEmptyDialog = this.openEmptyDialog.bind(this)
        this.closeDepositDialog = this.closeDepositDialog.bind(this)
        this.closeEmptyDialog = this.closeEmptyDialog.bind(this)
        this.onChangeSecurityBoxValue = this.onChangeSecurityBoxValue.bind(this)
        this.submitSecurityDeposit = this.submitSecurityDeposit.bind(this)
    }

    submitSecurityDeposit() {
        this.props.depositSecurityBox(this.props.machine.Serial, {
            five: this.state.fiveBills,
            ten: this.state.tenBills,
            twenty: this.state.twentyBills,
        })
        this.setState({
            depositSecurityBoxModalOpen: false,
            currentFiveBills: parseInt(this.state.currentFiveBills) + parseInt(this.state.fiveBills),
            currentTenBills: parseInt(this.state.currentTenBills) + parseInt(this.state.tenBills),
            currentTwentyBills: parseInt(this.state.currentTwentyBills) + parseInt(this.state.twentyBills),
        })
    }

    submitEmptyDeposit() {
        this.props.emptySecurityBox(this.props.machine.Serial, {})
        this.setState({
            emptySecurityBoxModalOpen: false,
            currentFiveBills: 0,
            currentTenBills: 0,
            currentTwentyBills: 0,
        })
    }

    openDepositDialog() {
         this.setState({ depositSecurityBoxModalOpen: true })
    }

    openEmptyDialog() {
        this.setState({ emptySecurityBoxModalOpen: true })
    }

    closeDepositDialog() {
         this.setState({ depositSecurityBoxModalOpen: false })
    }

    closeEmptyDialog() {
        this.setState({ emptySecurityBoxModalOpen: false })
    }

    onChangeSecurityBoxValue(key, value) {
        this.setState({ [key]: value })
    }

  render() {
    const { currentFiveBills, currentTenBills, currentTwentyBills } = this.state

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Billete</th>
              <th scope="col">Cantidad</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr key="5EUR">
              <th scope="row">5 €</th>
              <td>{`${currentFiveBills}`}</td>
              <td>{`${currentFiveBills * 5.00} €`}</td>
            </tr>
            <tr key="BILLS_10EUR">
              <th scope="row">10 €</th>
              <td>{`${currentTenBills}`}</td>
              <td>{`${currentTenBills * 10.00} €`}</td>
            </tr>
            <tr key="BILLS_20EUR">
              <th scope="row">20 €</th>
              <td>{`${currentTwentyBills}`}</td>
              <td>{`${currentTwentyBills * 20.00} €`}</td>
            </tr>
            <tr key="BILLS_TOTAL">
              <th scope="row">TOTAL</th>
              <td>{`${currentFiveBills + currentTenBills + currentTwentyBills}`}</td>
              <td>{`${currentFiveBills * 5.00 +
                      currentTenBills * 10.00 +
                      currentTwentyBills * 20.00} €`}</td>
            </tr>
          </tbody>
        </table>
        <div className="col-md-4">
            <button className="btn bg-vw-light text-white mr-1" type="submit" onClick={this.openEmptyDialog}>
              <i className="fas fa-trash mr-1"></i>
              Vaciar caja
            </button>
            <button className="btn btn-info"
                    type="button"
                    onClick={this.openDepositDialog}
            >
              <i className="fas fa-plus mr-1"></i>Ingreso
            </button>
          </div>
          <Modal isOpen={this.state.depositSecurityBoxModalOpen}>
            <ModalHeader toggle={this.terminateConfirmToggle}>
              Depositar
            </ModalHeader>
            <ModalBody>
                <div>
                    <label>Billetes de 5</label>
                    <input
                        className="form-control"
                        name="saleCommission"
                        type="text"
                        value={this.state.fiveBills}
                        onChange={(event) => this.onChangeSecurityBoxValue('fiveBills', event.target.value)}
                    />
                    <br/>
                    <label>Billetes de 10</label>
                    <input
                        className="form-control"
                        name="saleCommission"
                        type="text"
                        value={this.state.tenBills}
                        onChange={(event) => this.onChangeSecurityBoxValue('tenBills', event.target.value)}
                    />
                    <br/>
                    <label>Billetes de 20</label>
                    <input
                        className="form-control"
                        name="saleCommission"
                        type="text"
                        value={this.state.twentyBills}
                        onChange={(event) => this.onChangeSecurityBoxValue('twentyBills', event.target.value)}
                    />
                </div>

            </ModalBody>
            <ModalFooter>
               <Button color="primary mr-1"
                onClick={(event)=>this.submitSecurityDeposit()}
               >
                 Finalizar
               </Button>
              <Button color="secondary"
                      onClick={(event)=>this.closeDepositDialog()}

              >
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.emptySecurityBoxModalOpen}>
            <ModalHeader toggle={this.terminateConfirmToggle}>
              Vaciar caja
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro que deseas vacíar la caja?</p>
            </ModalBody>
            <ModalFooter>
               <Button color="primary mr-1"
               onClick={(event)=>this.submitEmptyDeposit()}
               >
                 Vaciar
               </Button>
              <Button color="secondary" onClick={(event)=>this.closeEmptyDialog()}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  role: state.auth.role
})

const mapDispatchToProps = dispatch => ({
  depositSecurityBox: bindActionCreators(depositSecurityBox, dispatch),
  emptySecurityBox:  bindActionCreators(emptySecurityBox, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SecurityBoxTable)
