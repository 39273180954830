import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchRules } from '../actions/rules'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import RulesList from '../components/RulesList'

class RulesView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchRules()
  }

  renderRules() {
    const { rules, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!rules || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (<RulesList rules={rules} />)
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">REGLAS </span>
              <span className="text-vw-dark">DE NEGOCIO</span>
            </h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
            <div className="col d-flex justify-content-end">
              <Link to="/rules/add">
                <button type="button" className="btn bg-vw-light text-white">
                  <i className="fas fa-plus"></i>
                  <span className="d-none d-sm-inline ml-1">Nueva</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Listado">
          {this.renderRules()}
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rules: state.rules.rules,
  error: state.rules.error,
  loading: state.rules.loading,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchRules: bindActionCreators(fetchRules, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(RulesView)


