import React from 'react'
import { Bar, HorizontalBar } from 'react-chartjs-2';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

class SalesPlots extends React.Component {
  constructor(props) {
    super(props);

    this.allDatasets = [
      { label: 'Básica', backgroundColor: '#001F3F', key: 'LOCKERBNS' },
      { label: 'Básica Carga', backgroundColor: '#0074D9', key: 'LOCKERCNS' },
      { label: 'Básica XL', backgroundColor: '#7FDBFF', key: 'LOCKERBWS' },
      { label: 'Básica Carga XL', backgroundColor: '#39CCCC', key: 'LOCKERCWS' },
      { label: 'Grande', backgroundColor: '#3D9970', key: 'LOCKERBNM' },
      { label: 'Grande Carga', backgroundColor: '#2ECC40', key: 'LOCKERCNM' },
      { label: 'Grande XL', backgroundColor: '#01FF70', key: 'LOCKERBWM' },
      { label: 'Grande Carga XL', backgroundColor: '#FFDC00', key: 'LOCKERCWM' },
      { label: 'Jumbo', backgroundColor: '#FF851B', key: 'LOCKERBNL' },
      { label: 'Jumbo Carga', backgroundColor: '#FF4136', key: 'LOCKERCNL' },
      { label: 'Jumbo XL', backgroundColor: '#85144B', key: 'LOCKERBWL' },
      { label: 'Jumbo Carga XL', backgroundColor: '#F012BE', key: 'LOCKERCWL' },
      { label: 'Jumbo', backgroundColor: '#B10DC9', key: 'LOCKERBNXL' },
      { label: 'Jumbo Carga', backgroundColor: '#111111', key: 'LOCKERCNXL' },
      { label: 'Jumbo XL', backgroundColor: '#AAAAAA', key: 'LOCKERBWXL' },
      { label: 'Jumbo Carga XL', backgroundColor: '#DDDDDD', key: 'LOCKERCWXL' },
      { label: 'Jumbo', backgroundColor: '#FF7F50', key: 'LOCKERBN2XL' },
      { label: 'Jumbo Carga', backgroundColor: '#FFD700', key: 'LOCKERCN2XL' },
      { label: 'Jumbo XL', backgroundColor: '#FFA500', key: 'LOCKERBW2XL' },
      { label: 'Jumbo Carga XL', backgroundColor: '#FF8C00', key: 'LOCKERCW2XL' },
      { label: 'Ski', backgroundColor: '#FF4500', key: 'LOCKERBN3XL' },
      { label: 'Ski Carga', backgroundColor: '#FF6347', key: 'LOCKERCN3XL' },
      { label: 'Ski XL', backgroundColor: '#FF8C69', key: 'LOCKERBW3XL' },
      { label: 'Ski Carga XL', backgroundColor: '#CD5C5C', key: 'LOCKERCW3XL' },
      { label: 'Tumbonas', backgroundColor: '#8B0000', key: 'TUMBONAS' },
      { label: 'Flotadores día', backgroundColor: '#FF1493', key: 'FLOTADORES' },
      { label: 'Flotadores medio día', backgroundColor: '#8B4513', key: 'FLOTADORESHALFDAY' },
      { label: 'Fastpass', backgroundColor: '#228B22', key: 'FASTPASS' }
    ];

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  computeSalesStats() {
    const { sales } = this.props

    const itemIndices = new Map([
      ['LOCKERBNS', 0],
      ['LOCKERCNS', 1],
      ['LOCKERBWS', 2],
      ['LOCKERCWS', 3],
      ['LOCKERBNM', 4],
      ['LOCKERCNM', 5],
      ['LOCKERBWM', 6],
      ['LOCKERCWM', 7],
      ['LOCKERBNL', 8],
      ['LOCKERCNL', 9],
      ['LOCKERBWL', 10],
      ['LOCKERCWL', 11],
      ['LOCKERBNXL', 12],
      ['LOCKERCNXL', 13],
      ['LOCKERBWXL', 14],
      ['LOCKERCWXL', 15],
      ['LOCKERBN2XL', 16],
      ['LOCKERCN2XL', 17],
      ['LOCKERBW2XL', 18],
      ['LOCKERCW2XL', 19],
      ['LOCKERBN3XL', 20],
      ['LOCKERCN3XL', 21],
      ['LOCKERBW3XL', 22],
      ['LOCKERCW3XL', 23],
      ['TUMBONAS', 24],
      ['FLOTADORES', 25],
      ['FLOTADORESHALFDAY', 26],
      ['FASTPASS', 27]
    ])

    let amount = 0

    let amountsPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    let qtyPerMethod = new Map([
      ['CASH', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TPV', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['TOKEN', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['QR', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['DEVOLUTION', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      ['PDA', [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]]
    ])

    for (let sale of sales) {
      let amountsPerSaleMethod = amountsPerMethod.get(sale.PaymentMethod)
      let qtyPerSaleMethod = qtyPerMethod.get(sale.PaymentMethod)

      if (sale.Items) {
        for (let item of sale.Items) {

          let itemTotal = 0
          let itemAmount = 0

          if (Math.abs(item.qty) > 1) {

            itemAmount = item.price

            if (sale.Amount != 0) {

              if (sale.Amount < 0) {
                amountsPerSaleMethod = amountsPerMethod.get("DEVOLUTION")
                qtyPerSaleMethod = qtyPerMethod.get("DEVOLUTION")
              }

              amount += itemAmount
              amountsPerSaleMethod[itemIndices.get(item.item)] += itemAmount
            }

          } else {

            itemAmount = item.price

            if (sale.Amount != 0) {

              if (sale.Amount < 0) {
                amountsPerSaleMethod = amountsPerMethod.get("DEVOLUTION")
                qtyPerSaleMethod = qtyPerMethod.get("DEVOLUTION")
              }

              amount += itemAmount
              amountsPerSaleMethod[itemIndices.get(item.item)] += itemAmount
            }

          }

          qtyPerSaleMethod[itemIndices.get(item.item)] += Math.abs(item.qty)

        }
      }

      if (sale.Amount < 0) {
        amountsPerMethod.set("DEVOLUTION", amountsPerSaleMethod)
        qtyPerMethod.set("DEVOLUTION", qtyPerSaleMethod)
      } else {
        amountsPerMethod.set(sale.PaymentMethod, amountsPerSaleMethod)
        qtyPerMethod.set(sale.PaymentMethod, qtyPerSaleMethod)
      }

    }

    return {
      amount: amount,
      amountsPerMethod: amountsPerMethod,
      qtyPerMethod: qtyPerMethod
    }
  }

  renderAmount() {
    const stats = this.computeSalesStats();

    const labels = ['Básica', 'Básica Carga', 'Básica XL', 'Básica Carga XL', 'Grande', 'Grande Carga', 'Grande XL', 'Grande Carga XL', 'Jumbo', 'Jumbo Carga', 'Jumbo XL', 'Jumbo Carga XL', 'Jumbo', 'Jumbo Carga', 'Jumbo XL', 'Jumbo Carga XL', 'Jumbo', 'Jumbo Carga', 'Jumbo XL', 'Jumbo Carga XL', 'Ski', 'Ski Carga', 'Ski XL', 'Ski Carga XL', 'Tumbonas', 'Flotadores día', 'Flotadores medio día', 'Fastpass'];

    const datasets = [
      {
        label: 'CASH',
        backgroundColor: '#00577D',
        data: stats.amountsPerMethod.get('CASH')
      },
      {
        label: 'TPV',
        backgroundColor: '#2dccd3',
        data: stats.amountsPerMethod.get('TPV')
      },
      {
        label: 'QR',
        backgroundColor: '#55FF33',
        data: stats.amountsPerMethod.get('QR')
      },
      {
        label: 'MANUAL',
        backgroundColor: '#ffc000',
        data: stats.amountsPerMethod.get('TOKEN')
      },
      {
        label: 'PDA',
        backgroundColor: '#f28111',
        data: stats.amountsPerMethod.get('PDA')
      }
    ];

    // Filter labels and datasets to include only items with non-zero values
    const filteredLabels = labels.filter((label, index) => {
      const datasetValues = datasets.map(dataset => dataset.data[index]);
      return datasetValues.some(value => value !== undefined && value !== null && value !== 0);
    });

    const filteredDatasets = datasets.map(dataset => {
      return {
        ...dataset,
        data: dataset.data.filter((value, index) => filteredLabels.includes(labels[index]))
      };
    });

    const data = {
      labels: filteredLabels,
      datasets: filteredDatasets
    };

    return (
      <Bar
        data={data}
        options={{
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: false
              }
            }],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'TOTAL (EUR)'
              }
            }]
          },
          maintainAspectRatio: false,
          responsive: true,
          width: 300,
          height: 500,
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              label: this.totalLabel
            }
          }
        }}
      />
    );
  }

  sortAmountsPerCustomer() {
    const { sales } = this.props

    const amountsPerCustomer = new Map()

    for (let sale of sales) {
      let customerAmount = amountsPerCustomer.get(sale.Customer._id)

      if (customerAmount === undefined) {
        customerAmount = [
          sale.Customer.Fullname,
          new Map([
            ['LOCKERBNS', 0],
            ['LOCKERCNS', 0],
            ['LOCKERBWS', 0],
            ['LOCKERCWS', 0],
            ['LOCKERBNM', 0],
            ['LOCKERCNM', 0],
            ['LOCKERBWM', 0],
            ['LOCKERCWM', 0],
            ['LOCKERBNL', 0],
            ['LOCKERCNL', 0],
            ['LOCKERBWL', 0],
            ['LOCKERCWL', 0],
            ['LOCKERBNXL', 0],
            ['LOCKERCNXL', 0],
            ['LOCKERBWXL', 0],
            ['LOCKERCWXL', 0],
            ['LOCKERBN2XL', 0],
            ['LOCKERCN2XL', 0],
            ['LOCKERBW2XL', 0],
            ['LOCKERCW2XL', 0],
            ['LOCKERBN3XL', 0],
            ['LOCKERCN3XL', 0],
            ['LOCKERBW3XL', 0],
            ['LOCKERCW3XL', 0],
            ['TUMBONAS', 0],
            ['FLOTADORES', 0],
            ['FLOTADORESHALFDAY', 0],
            ['FASTPASS', 0]
          ])
        ]
      }

      for (let item of sale.Items) {
        let amount = customerAmount[1].get(item.item)
        customerAmount[1].set(item.item, amount + item.price)
      }

      amountsPerCustomer.set(sale.Customer._id, customerAmount)
    }

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    let sortedAmountsPerCustomer = Array.from(amountsPerCustomer.values())
      .sort((a, b) => {
        return Array.from(b[1].values()).reduce(reducer) -
          Array.from(a[1].values()).reduce(reducer)
      })

    let names = sortedAmountsPerCustomer.map((value, index) => {
      return value[0]
    })

    let amounts = new Map([
      ['LOCKERBNS',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBNS')
        })
      ],
      ['LOCKERCNS',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCNS')
        })
      ],
      ['LOCKERBWS',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBWS')
        })
      ],
      ['LOCKERCWS',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCWS')
        })
      ],
      ['LOCKERBNM',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBNM')
        })
      ],
      ['LOCKERCNM',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCNM')
        })
      ],
      ['LOCKERBWM',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBWM')
        })
      ],
      ['LOCKERCWM',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCWM')
        })
      ],
      ['LOCKERBNL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBNL')
        })
      ],
      ['LOCKERCNL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCNL')
        })
      ],
      ['LOCKERBWL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBWL')
        })
      ],
      ['LOCKERCWL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCWL')
        })
      ],
      ['LOCKERBNXL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBNXL')
        })
      ],
      ['LOCKERCNXL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCNXL')
        })
      ],
      ['LOCKERBWXL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBWXL')
        })
      ],
      ['LOCKERCWXL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCWXL')
        })
      ],
      ['LOCKERBN2XL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBN2XL')
        })
      ],
      ['LOCKERCN2XL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCN2XL')
        })
      ],
      ['LOCKERBW2XL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBW2XL')
        })
      ],
      ['LOCKERCW2XL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCW2XL')
        })
      ],
      ['LOCKERBN3XL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBN3XL')
        })
      ],
      ['LOCKERCN3XL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCN3XL')
        })
      ],
      ['LOCKERBW3XL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBW3XL')
        })
      ],
      ['LOCKERCW3XL',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCW3XL')
        })
      ],
      ['TUMBONAS',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('TUMBONAS')
        })
      ],
      ['FLOTADORES',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('FLOTADORES')
        })
      ],
      ['FLOTADORESHALFDAY',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('FLOTADORESHALFDAY')
        })
      ],
      ['FASTPASS',
        sortedAmountsPerCustomer.map((value, index) => {
          return value[1].get('FASTPASS')
        })
      ]
    ])

    return {
      names: names,
      amounts: amounts
    }
  }

  renderAmountsPerCustomer() {
    const sortedAmountsPerCustomer = this.sortAmountsPerCustomer();

    const filteredDatasets = this.allDatasets
      .map(dataset => ({
        ...dataset,
        data: sortedAmountsPerCustomer.amounts.get(dataset.key)
      }))
      .filter(dataset => dataset.data && dataset.data.some(amount => amount != 0));

    const data = {
      labels: sortedAmountsPerCustomer.names,
      datasets: filteredDatasets
    };

    return (
      <HorizontalBar
        data={data}
        options={{
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: false
              }
            }],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'TOTAL (EUR)'
              }
            }]
          },
          maintainAspectRatio: false,
          responsive: true,
          width: 300,
          height: 500,
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              label: this.totalLabel
            }
          }
        }}
      />
    )
  }

  sortAmountsPerHour() {
    const { sales } = this.props;

    const totalAmountsPerHour = new Array(24).fill(0);
    const accumulatedAmounts = new Array(24).fill(0); // Array to store accumulated amounts

    for (let sale of sales) {
      const saleTime = new Date(sale.TimeStamp);
      const saleHour = saleTime.getUTCHours();

      totalAmountsPerHour[saleHour] += sale.Amount;
    }

    // Calculate accumulated amounts
    accumulatedAmounts[0] = totalAmountsPerHour[0];
    for (let i = 1; i < 24; i++) {
      accumulatedAmounts[i] = accumulatedAmounts[i - 1] + totalAmountsPerHour[i];
    }

    return {
      hours: Array.from({ length: 24 }, (_, i) => i),
      amounts: totalAmountsPerHour,
      accumulatedAmounts: accumulatedAmounts
    };
  }


  renderAmountsPerHour() {
    const sortedAmountsPerHour = this.sortAmountsPerHour();

    const data = {
      labels: sortedAmountsPerHour.hours.map(hour => `${hour}:00`),
      datasets: [
        {
          label: 'Total Sales Amount',
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          order: 1,
          hoverBackgroundColor: 'rgba(75, 192, 192, 0.8)',
          data: sortedAmountsPerHour.amounts
        },
        {
          label: 'Accumulated Amount',
          type: 'line', // Display as a line
          borderColor: 'rgba(255, 0, 0)',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          borderWidth: 2,
          order: 0,
          fill: false,
          data: sortedAmountsPerHour.accumulatedAmounts
        }
      ]
    };

    return (
      <Bar
        data={data}
        options={{
          scales: {
            xAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Hour'
              }
            }],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'Total Sales Amount (EUR)'
              },
              ticks: {
                beginAtZero: true
              }
            }]
          },
          maintainAspectRatio: false,
          responsive: true,
          width: 300,
          height: 500,
          legend: {
            display: true, // Show legend for multiple datasets
          }
        }}
      />
    );
  }

  renderQty() {
    const stats = this.computeSalesStats();

    const labels = ['Básica', 'Básica Carga', 'Básica XL', 'Básica Carga XL', 'Grande', 'Grande Carga', 'Grande XL', 'Grande Carga XL', 'Jumbo', 'Jumbo Carga', 'Jumbo XL', 'Jumbo Carga XL', 'Jumbo', 'Jumbo Carga', 'Jumbo XL', 'Jumbo Carga XL', 'Jumbo', 'Jumbo Carga', 'Jumbo XL', 'Jumbo Carga XL', 'Ski', 'Ski Carga', 'Ski XL', 'Ski Carga XL', 'Tumbonas', 'Flotadores día', 'Flotadores medio día', 'Fastpass'];

    const datasets = [
      {
        label: 'CASH',
        backgroundColor: '#00577D',
        data: stats.qtyPerMethod.get('CASH')
      },
      {
        label: 'TPV',
        backgroundColor: '#2dccd3',
        data: stats.qtyPerMethod.get('TPV')
      },
      {
        label: 'QR',
        backgroundColor: '#55FF33',
        data: stats.qtyPerMethod.get('QR')
      },
      {
        label: 'MANUAL',
        backgroundColor: '#ffc000',
        data: stats.qtyPerMethod.get('TOKEN')
      },
      {
        label: 'PDA',
        backgroundColor: '#f28111',
        data: stats.qtyPerMethod.get('PDA')
      }
    ];

    // Filter labels and datasets to include only items with non-zero values
    const filteredLabels = labels.filter((label, index) => {
      const datasetValues = datasets.map(dataset => dataset.data[index]);
      return datasetValues.some(value => value !== undefined && value !== null && value !== 0);
    });

    const filteredDatasets = datasets.map(dataset => {
      return {
        ...dataset,
        data: dataset.data.filter((value, index) => filteredLabels.includes(labels[index]))
      };
    });

    const data = {
      labels: filteredLabels,
      datasets: filteredDatasets
    };

    return (
      <Bar
        data={data}
        options={{
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: false
              }
            }],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'TOTAL (Unidades)'
              }
            }]
          },
          maintainAspectRatio: false,
          responsive: true,
          width: 300,
          height: 500,
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              label: this.totalLabel
            }
          }
        }}
      />
    );
  }

  sortQtyPerCustomer() {
    const { sales } = this.props

    const qtysPerCustomer = new Map()

    for (let sale of sales) {
      let customerQty = qtysPerCustomer.get(sale.Customer._id)

      if (customerQty === undefined) {
        customerQty = [
          sale.Customer.Fullname,
          new Map([
            ['LOCKERBNS', 0],
            ['LOCKERCNS', 0],
            ['LOCKERBWS', 0],
            ['LOCKERCWS', 0],
            ['LOCKERBNM', 0],
            ['LOCKERCNM', 0],
            ['LOCKERBWM', 0],
            ['LOCKERCWM', 0],
            ['LOCKERBNL', 0],
            ['LOCKERCNL', 0],
            ['LOCKERBWL', 0],
            ['LOCKERCWL', 0],
            ['LOCKERBNXL', 0],
            ['LOCKERCNXL', 0],
            ['LOCKERBWXL', 0],
            ['LOCKERCWXL', 0],
            ['LOCKERBN2XL', 0],
            ['LOCKERCN2XL', 0],
            ['LOCKERBW2XL', 0],
            ['LOCKERCW2XL', 0],
            ['LOCKERBN3XL', 0],
            ['LOCKERCN3XL', 0],
            ['LOCKERBW3XL', 0],
            ['LOCKERCW3XL', 0],
            ['TUMBONAS', 0],
            ['FLOTADORES', 0],
            ['FLOTADORESHALFDAY', 0],
            ['FASTPASS', 0]
          ])
        ]
      }

      for (let item of sale.Items) {
        if (item.price <= 0)
          continue

        let qty = customerQty[1].get(item.item)
        customerQty[1].set(item.item, qty + item.qty)
      }

      qtysPerCustomer.set(sale.Customer._id, customerQty)
    }

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    let sortedQtysPerCustomer = Array.from(qtysPerCustomer.values())
      .sort((a, b) => {
        return Array.from(b[1].values()).reduce(reducer) -
          Array.from(a[1].values()).reduce(reducer)
      })

    let names = sortedQtysPerCustomer.map((value, index) => {
      return value[0]
    })

    let qties = new Map([
      ['LOCKERBNS',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBNS')
        })
      ],
      ['LOCKERCNS',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCNS')
        })
      ],
      ['LOCKERBWS',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBWS')
        })
      ],
      ['LOCKERCWS',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCWS')
        })
      ],
      ['LOCKERBNM',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBNM')
        })
      ],
      ['LOCKERCNM',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCNM')
        })
      ],
      ['LOCKERBWM',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBWM')
        })
      ],
      ['LOCKERCWM',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCWM')
        })
      ],
      ['LOCKERBNL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBNL')
        })
      ],
      ['LOCKERCNL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCNL')
        })
      ],
      ['LOCKERBWL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBWL')
        })
      ],
      ['LOCKERCWL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCWL')
        })
      ],
      ['LOCKERBNXL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBNXL')
        })
      ],
      ['LOCKERCNXL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCNXL')
        })
      ],
      ['LOCKERBWXL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBWXL')
        })
      ],
      ['LOCKERCWXL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCWXL')
        })
      ],
      ['LOCKERBN2XL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBN2XL')
        })
      ],
      ['LOCKERCN2XL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCN2XL')
        })
      ],
      ['LOCKERBW2XL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBW2XL')
        })
      ],
      ['LOCKERCW2XL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCW2XL')
        })
      ],
      ['LOCKERBN3XL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBN3XL')
        })
      ],
      ['LOCKERCN3XL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCN3XL')
        })
      ],
      ['LOCKERBW3XL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERBW3XL')
        })
      ],
      ['LOCKERCW3XL',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('LOCKERCW3XL')
        })
      ],
      ['TUMBONAS',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('TUMBONAS')
        })
      ],
      ['FLOTADORES',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('FLOTADORES')
        })
      ],
      ['FLOTADORESHALFDAY',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('FLOTADORESHALFDAY')
        })
      ],
      ['FASTPASS',
        sortedQtysPerCustomer.map((value, index) => {
          return value[1].get('FASTPASS')
        })
      ]
    ])

    return {
      names: names,
      qties: qties
    }
  }

  renderQtyPerCustomer() {
    const sortedQtyPerCustomer = this.sortQtyPerCustomer()

    const filteredDatasets = this.allDatasets
      .map(dataset => ({
        ...dataset,
        data: sortedQtyPerCustomer.qties.get(dataset.key)
      }))
      .filter(dataset => dataset.data && dataset.data.some(qties => qties != 0));

    const data = {
      labels: sortedQtyPerCustomer.names,
      datasets: filteredDatasets
    };

    return (
      <HorizontalBar
        data={data}
        options={{
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: false
              }
            }],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'TOTAL (Unidades)'
              }
            }]
          },
          maintainAspectRatio: false,
          responsive: true,
          width: 300,
          height: 500,
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              label: this.totalLabel
            }
          }
        }}
      />
    )
  }

  totalLabel(tooltipItem, data) {
    let label = data.datasets[tooltipItem.datasetIndex].label;
    let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

    let renderedLabel = label + ": " + value;
    if (tooltipItem.datasetIndex !== 0) {
      return renderedLabel
    } else {
      // Loop through all datasets to get the actual total of the index
      let total = 0;
      for (let i = 0; i < data.datasets.length; i++)
        total += data.datasets[i].data[tooltipItem.index];

      return ["Total: " + total, renderedLabel];
    }
  }

  sortQtyPerLanguage() {
    const { sales } = this.props

    const qtysPerLanguage = new Map()

    for (let sale of sales) {
      let languageQty = qtysPerLanguage.get(sale.Language)

      if (languageQty === undefined) {
        languageQty = [
          sale.Language,
          new Map([
            ['LOCKERBNS', 0],
            ['LOCKERCNS', 0],
            ['LOCKERBWS', 0],
            ['LOCKERCWS', 0],
            ['LOCKERBNM', 0],
            ['LOCKERCNM', 0],
            ['LOCKERBWM', 0],
            ['LOCKERCWM', 0],
            ['LOCKERBNL', 0],
            ['LOCKERCNL', 0],
            ['LOCKERBWL', 0],
            ['LOCKERCWL', 0],
            ['LOCKERBNXL', 0],
            ['LOCKERCNXL', 0],
            ['LOCKERBWXL', 0],
            ['LOCKERCWXL', 0],
            ['LOCKERBN2XL', 0],
            ['LOCKERCN2XL', 0],
            ['LOCKERBW2XL', 0],
            ['LOCKERCW2XL', 0],
            ['LOCKERBN3XL', 0],
            ['LOCKERCN3XL', 0],
            ['LOCKERBW3XL', 0],
            ['LOCKERCW3XL', 0],
            ['TUMBONAS', 0],
            ['FLOTADORES', 0],
            ['FLOTADORESHALFDAY', 0],
            ['FASTPASS', 0]
          ])
        ]
      }

      for (let item of sale.Items) {
        if (item.price <= 0)
          continue

        let qty = languageQty[1].get(item.item)
        languageQty[1].set(item.item, qty + item.qty)
      }

      qtysPerLanguage.set(sale.Language, languageQty)
    }

    const reducer = (accumulator, currentValue) => accumulator + currentValue;

    let sortedQtysPerLanguage = Array.from(qtysPerLanguage.values())
      .sort((a, b) => {
        return Array.from(b[1].values()).reduce(reducer) -
          Array.from(a[1].values()).reduce(reducer)
      })

    let names = sortedQtysPerLanguage.map((value, index) => {
      return value[0]
    })

    let qties = new Map([
      ['LOCKERBNS',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBNS')
        })
      ],
      ['LOCKERCNS',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCNS')
        })
      ],
      ['LOCKERBWS',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBWS')
        })
      ],
      ['LOCKERCWS',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCWS')
        })
      ],
      ['LOCKERBNM',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBNM')
        })
      ],
      ['LOCKERCNM',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCNM')
        })
      ],
      ['LOCKERBWM',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBWM')
        })
      ],
      ['LOCKERCWM',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCWM')
        })
      ],
      ['LOCKERBNL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBNL')
        })
      ],
      ['LOCKERCNL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCNL')
        })
      ],
      ['LOCKERBWL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBWL')
        })
      ],
      ['LOCKERCWL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCWL')
        })
      ],
      ['LOCKERBNXL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBNXL')
        })
      ],
      ['LOCKERCNXL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCNXL')
        })
      ],
      ['LOCKERBWXL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBWXL')
        })
      ],
      ['LOCKERCWXL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCWXL')
        })
      ],
      ['LOCKERBN2XL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBN2XL')
        })
      ],
      ['LOCKERCN2XL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCN2XL')
        })
      ],
      ['LOCKERBW2XL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBW2XL')
        })
      ],
      ['LOCKERCW2XL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCW2XL')
        })
      ],
      ['LOCKERBN3XL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBN3XL')
        })
      ],
      ['LOCKERCN3XL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCN3XL')
        })
      ],
      ['LOCKERBW3XL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERBW3XL')
        })
      ],
      ['LOCKERCW3XL',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('LOCKERCW3XL')
        })
      ],
      ['TUMBONAS',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('TUMBONAS')
        })
      ],
      ['FLOTADORES',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('FLOTADORES')
        })
      ],
      ['FLOTADORESHALFDAY',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('FLOTADORESHALFDAY')
        })
      ],
      ['FASTPASS',
        sortedQtysPerLanguage.map((value, index) => {
          return value[1].get('FASTPASS')
        })
      ]
    ])

    return {
      names: names,
      qties: qties
    }
  }

  renderQtyPerLanguage() {
    const sortedQtyPerLanguage = this.sortQtyPerLanguage()

    const filteredDatasets = this.allDatasets
      .map(dataset => ({
        ...dataset,
        data: sortedQtyPerLanguage.qties.get(dataset.key)
      }))
      .filter(dataset => dataset.data && dataset.data.some(qties => qties != 0));

    const data = {
      labels: sortedQtyPerLanguage.names,
      datasets: filteredDatasets
    };

    return (
      <HorizontalBar
        data={data}
        options={{
          scales: {
            xAxes: [{
              stacked: true,
              ticks: {
                autoSkip: false
              }
            }],
            yAxes: [{
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: 'TOTAL (Unidades)'
              }
            }]
          },
          maintainAspectRatio: false,
          responsive: true,
          width: 300,
          height: 500,
          legend: {
            display: false,
          },
          tooltips: {
            mode: 'label',
            callbacks: {
              label: this.totalLabel
            }
          }
        }}
      />
    )
  }

  sortTimePerCustomer() {
    const { sales } = this.props;

    const timesPerCustomer = new Map();

    for (let sale of sales) {
      let customerTimes = timesPerCustomer.get(sale.Customer._id);

      if (customerTimes === undefined) {
        customerTimes = [
          sale.Customer.Fullname,
          []
        ];
      }

      // Get the operation time for the current sale
      const operationTime = sale.OperationTime;

      // Filter out negative and excessively large operation times
      if (operationTime > 0 && operationTime <= 500) {
        customerTimes[1].push(operationTime);
      }

      timesPerCustomer.set(sale.Customer._id, customerTimes);
    }

    const sortedTimesPerCustomer = Array.from(timesPerCustomer.values()).sort(
      (a, b) =>
        b[1].reduce((sum, time) => sum + time, 0) / b[1].length -
        a[1].reduce((sum, time) => sum + time, 0) / a[1].length
    );

    let names = sortedTimesPerCustomer.map(value => value[0]);
    let times = sortedTimesPerCustomer.map(value =>
      value[1].reduce((sum, time) => sum + time, 0) / value[1].length
    );

    return {
      names: names,
      times: times
    };
  }

  renderTimePerCustomer() {
    const sortedTimesPerCustomer = this.sortTimePerCustomer();
    const data = {
      labels: sortedTimesPerCustomer.names,
      datasets: [
        {
          label: 'Average Time',
          backgroundColor: '#001F3F',
          data: sortedTimesPerCustomer.times
        }
      ]
    };

    const options = {
      scales: {
        x: {
          stacked: true,
          ticks: {
            autoSkip: false
          }
        },
        y: {
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: 'Average Time (minutes:seconds)'
          },
          ticks: {
            callback: function (value) {
              const minutes = Math.floor(value / 60);
              const seconds = value % 60;
              return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            }
          }
        }
      },
      maintainAspectRatio: false,
      responsive: true,
      width: 300,
      height: 500,
      legend: {
        display: false,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: 'label',
          callbacks: {
            label: function (tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const value = dataset.data[tooltipItem.index];
              const minutes = Math.floor(value / 60);
              const seconds = value % 60;
              return `Average Time: ${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
            }
          }
        }
      }
    };

    return (
      <HorizontalBar
        data={data}
        options={options}
      />
    );
  }

  calculateOccupationPercentage() {
    const { sales, machines } = this.props;

    // Create an object to store an array of LastIndex values by customer
    const lastIndexByCustomer = {};

    machines.forEach((machine) => {
      const customerName = machine.Customer?.Fullname || 'None';

      if (!lastIndexByCustomer[customerName]) {
        lastIndexByCustomer[customerName] = [];
      }

      lastIndexByCustomer[customerName].push(machine.LastIndex);
    });

    // Create an object to store the total sales and totalLastIndex by customer
    const occupationData = {};

    sales.forEach((sale) => {
      const hasTumbonas = sale.Items ? sale.Items.some((item) => item.item === "TUMBONAS") : false;

      if (sale.Amount !== 0 && !hasTumbonas && sale.PaymentMethod !== "QR" && sale.PaymentMethod !== "TOKEN") {
        const customerName = sale.Customer.Fullname;

        // Check if this customer has any machines associated
        if (lastIndexByCustomer[customerName]) {
          // Sum up all LastIndex values for this customer's machines
          const totalLastIndex = lastIndexByCustomer[customerName].reduce((sum, lastIndex) => sum + lastIndex, 0);

          if (!occupationData[customerName]) {
            occupationData[customerName] = {
              totalSales: 0,
              totalLastIndex,
            };
          }

          // Increment the total sales count for the customer
          occupationData[customerName].totalSales += 1;
        }
      } else if (sale.Amount < 0 && !hasTumbonas && sale.PaymentMethod !== "QR" && sale.PaymentMethod !== "TOKEN") {
        const customerName = sale.Customer.Fullname;

        // Check if this customer has any machines associated
        if (lastIndexByCustomer[customerName]) {
          // Sum up all LastIndex values for this customer's machines
          const totalLastIndex = lastIndexByCustomer[customerName].reduce((sum, lastIndex) => sum + lastIndex, 0);

          if (!occupationData[customerName]) {
            occupationData[customerName] = {
              totalSales: 0,
              totalLastIndex,
            };
          }

          // Increment the total sales count for the customer
          occupationData[customerName].totalSales -= 1;
        }
      }
    });

    // Calculate the occupation percentage for each customer
    const occupationPercentage = {};
    Object.entries(occupationData).forEach(([customerName, customerData]) => {
      const { totalSales, totalLastIndex } = customerData;
      const percentage = (totalSales / totalLastIndex) * 100;

      occupationPercentage[customerName] = percentage.toFixed(2); // Rounding the percentage to 2 decimal places
    });

    return occupationPercentage;
  }

  renderOcupationPerCustomer() {
    const occupationPercentage = this.calculateOccupationPercentage();

    // Sort customer names by their corresponding occupation percentages (high to low)
    const sortedCustomerNames = Object.keys(occupationPercentage).sort((a, b) => {
      return occupationPercentage[b] - occupationPercentage[a];
    });

    const occupationPercentages = sortedCustomerNames.map((name) => parseFloat(occupationPercentage[name]));

    const data = {
      labels: sortedCustomerNames,
      datasets: [
        {
          label: 'Tasa de ocupación %',
          data: occupationPercentages,
          backgroundColor: 'rgba(75, 192, 192, 0.6)', // Set the bar color
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      width: 500,
      height: 300,
      legend: {
        display: false,
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Customer',
          },
        },
        y: {
          title: {
            display: true,
            text: 'Tasa de ocupación %',
          },
          ticks: {
            beginAtZero: false,
            min: 0, // Set the minimum value for the x-axis
            stepSize: 10, // Set the interval between ticks to 10
            callback: function (value) {
              return value + "%"; // Append '%' to the tick value
            },
          },
        },
      }
    };

    return (
      <HorizontalBar
        data={data}
        options={options}
      />
    );
  }

  render() {
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Importe
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Importe por Cliente
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Importe por Hora
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
              Cantidad
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '5' })}
              onClick={() => { this.toggle('5'); }}
            >
              Cantidad por Cliente
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '6' })}
              onClick={() => { this.toggle('6'); }}
            >
              Cantidad por Idioma
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '7' })}
              onClick={() => { this.toggle('7'); }}
            >
              Tiempo Medio de Compra
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '8' })}
              onClick={() => { this.toggle('8'); }}
            >
              Ocupación por Cliente
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div id="canvas-container">
              {this.renderAmount()}
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div id="canvas-container">
              {this.renderAmountsPerCustomer()}
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div id="canvas-container">
              {this.renderAmountsPerHour()}
            </div>
          </TabPane>
          <TabPane tabId="4">
            <div id="canvas-container">
              {this.renderQty()}
            </div>
          </TabPane>
          <TabPane tabId="5">
            <div id="canvas-container">
              {this.renderQtyPerCustomer()}
            </div>
          </TabPane>
          <TabPane tabId="6">
            <div id="canvas-container">
              {this.renderQtyPerLanguage()}
            </div>
          </TabPane>
          <TabPane tabId="7">
            <div id="canvas-container">
              {this.renderTimePerCustomer()}
            </div>
          </TabPane>
          <TabPane tabId="8">
            <div id="canvas-container">
              {this.renderOcupationPerCustomer()}
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

export default SalesPlots

