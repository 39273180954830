import React from 'react'
import { history } from '../store'

class ContractTerminatedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        customer: '',
        serial: '',
      },
    };
  }

  goTo(id, e) {
    history.push(`/contracts/${id}`)
  }

  handleFilterChange(filterKey, value) {
    this.setState(prevState => ({
      filters: {
        ...prevState.filters,
        [filterKey]: value,
      },
    }));
  }

  render() {
    const { filters } = this.state;
    const { contracts } = this.props;

    // Apply the filters to filter the contracts array
    const filteredContractTerminated = contracts.filter(contract => {
      const { customer: customerFilter, serial: serialFilter } = filters;

      if (
        customerFilter &&
        (!contract.Customer ||
          !contract.Customer.Fullname.toLowerCase().includes(customerFilter.toLowerCase()))
      ) {
        return false;
      }

      if (
        serialFilter &&
        (!contract.Machine ||
          !contract.Machine.Serial.toLowerCase().includes(serialFilter.toLowerCase()))
      ) {
        return false;
      }

      // If all conditions pass, include the customer in the filtered list
      return true;
    });

    return (
      <div className="table-responsive">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Máquina</th>
              <th scope="col">Cliente</th>
              <th scope="col">Zona</th>
              <th scope="col">Fecha inicio</th>
              <th scope="col">Fecha fin</th>
              <th scope="col">Estado</th>
            </tr>
            <tr>              
              <th scope="col">
                <input
                  type="text"
                  placeholder="Serial"
                  value={filters.serial}
                  onChange={e => this.handleFilterChange('serial', e.target.value)}
                />
              </th>
              
              <th scope="col">
                <input
                  type="text"
                  placeholder="Cliente"
                  value={filters.customer}
                  onChange={e => this.handleFilterChange('customer', e.target.value)}
                />
              </th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filteredContractTerminated
            .sort((a, b) => a.Machine.Serial.localeCompare(b.Machine.Serial))
            .map(contract => (
              contract.Status !== "VALID" ? (
                <tr key={contract.number}
                  onClick={e => this.goTo(contract._id, e)}>
                    <td>{contract.Machine.Serial}</td>
                  <td>{contract.Customer.Fullname}</td>
                  <td>{contract.Zone}</td>
                  <td>
                    {(new Date(contract.StartDate)).toDateString()}
                  </td>
                  <td>
                    {(new Date(contract.EndDate)).toDateString()}
                  </td>
                  <td>{contract.Status}</td>
                </tr>
              ) : null
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ContractTerminatedList

