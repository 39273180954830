import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import { bindActionCreators } from 'redux'
import { push } from 'react-router-redux'
import { SubmissionError, FieldArray } from 'redux-form'
import { renderInputField, renderTextAreaField, renderSelectField } from '../utils/forms'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import * as dateFormat from 'dateformat'
import PropertyLabel from '../components/PropertyLabel'

import { updateIncidence, fetchIncidence } from '../actions/incidences'
import { fetchMachines } from '../actions/machines'
import { fetchCustomers } from '../actions/customers'

class IncidenceResolutionForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      fCustomers: null,
      fMachines: null
    }

    this.load = this.load.bind(this)

    this.state = { loaded: false }
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchIncidence, fetchMachines, fetchCustomers, match } = this.props

    fetchIncidence(match.params.number)
    fetchCustomers()
    fetchMachines()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { incidence, machines, customers } = props

    if (!this.state.loaded &&
        incidence && machines && customers) {

      this.setState({
        loaded: true
      })
    }
  }

  submit(props) {
    const { updateIncidence, incidence } = this.props

    props.Resolver = this.props.fullname

    return updateIncidence(incidence.number, props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { incidence, customers, machines, error, pristine, submitting, handleSubmit } = this.props
    const { loaded, fMachines, fCustomers } = this.state

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    const saleDate = new Date(incidence.createdAt);
    saleDate.setHours(saleDate.getHours() - 2);
    const formattedDate = `${saleDate.getDate()}/${saleDate.getMonth() + 1}/${saleDate.getFullYear()} ${saleDate.getHours().toString().padStart(2, '0')}:${saleDate.getMinutes().toString().padStart(2, '0')}`;

    return (
      <div>
        <h1>
          <span className="text-vw-dark">REPARACIÓN </span>
          <span className="text-vw-light">INCIDENCIA</span>
        </h1>
        <form onSubmit={this.props.handleSubmit(this.submit)}>
          <div className="row">
            <div className="col col-12 col-lg-12">
              <Box title="Incidencia" icon="exclamation-triangle">
                <Field name="Resolution"
                  component={renderTextAreaField}
                  label="Como se ha resuelto la incidencia finalmente?" />
              </Box>
            </div>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <button className="btn bg-vw-light text-white mr-1 save" type="submit"
            disabled={pristine || submitting}>
            Guardar
          </button>
        </form>        
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Incidencia" icon="exclamation-triangle">
              <PropertyLabel name="Número" value={incidence.number} />
              <PropertyLabel name="Fecha de creación" value={formattedDate} />
              <PropertyLabel name="Máquina" value={incidence.Machine.Serial} />
              <PropertyLabel name="Categoria" value={incidence.Category} />
              <PropertyLabel name="Estado" value={incidence.Status} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Tiempos" icon="clock">
              <PropertyLabel name="Max Tiempo Reparación" value={incidence.RepairingForecastTime} />
              <PropertyLabel name="Fecha de Reparación" value={incidence.RepairingTime} />
              <PropertyLabel name="Max Tiempo Respuesta" value={incidence.ResponseForecastTime} />
              <PropertyLabel name="Fecha de Cierre" value={incidence.CloseTime} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-4">
            <Box title="Cliente" icon="user">
              <PropertyLabel name="Nombre" value={incidence.Customer.Fullname} />
              <PropertyLabel name="E-Mail" value={incidence.Customer.Email} />
              <PropertyLabel name="Teléfono" value={incidence.Customer.Phone} />
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Box title="Información" icon="info-circle-fill">
              <PropertyLabel name="Descripción" value={incidence.Description} ml />
              <PropertyLabel name="Actuación" value={incidence.Actuation} ml />
            </Box>
          </div>
        </div>
      </div>
    )
  }
}

IncidenceResolutionForm = reduxForm({
  form: 'IncidenceResolutionForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/incidences`)
  }
})(IncidenceResolutionForm)

const mapStateToProps = state => ({
  incidence: state.incidences.incidence,
  machines: state.machines.machines,
  customers: state.customers.customers,
  fullname: state.auth.fullname,
  initialValues: {
    Machine: '',
    Customer: '',
    Status: '',
    Category: '',
    StartDate: new Date().toISOString().substring(0, 10),
    EndDate: new Date().toISOString().substring(0, 10)
  },
})

const mapDispatchToProps = dispatch => ({
  fetchIncidence: bindActionCreators(fetchIncidence, dispatch),
  updateIncidence: bindActionCreators(updateIncidence, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidenceResolutionForm)


