import React from 'react'

class IncidenceCategoryBadge extends React.Component {
  getColor(category) {

    switch(category) {
      case "Minor": 
        return "info"
      case "Breakdown": 
        return "warning"
      case "UrgentBreakdown": 
        return "danger"
      default: 
        return "info"
    }
  }

  getText(category) {

    switch(category) {
      case "Minor": 
        return "PREGUNTA"
      case "Breakdown": 
        return "AVERÍA"
      case "UrgentBreakdown": 
        return "AVERÍA URGENTE"
      default: 
        return "AVERÍA"
    }
  }

  render() {
    return (
      <div className={`badge badge-${this.getColor(this.props.category)}`}>
        { this.getText(this.props.category) }
      </div>
    )
  }
}

export default IncidenceCategoryBadge

