import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { fetchOperators } from '../actions/operators'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import OperatorsList from '../components/OperatorsList'

class OperatorsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchOperators()
  }

  renderOperators() {
    const { operators, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!operators || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (<OperatorsList operators={operators} />)
  }

  render() {
    const { role, type, category } = this.props

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              AGREGADORES
            </h1>
          </div>
          {((role === 'ADMIN' && (type === 'GOD' || type === 'ROOT')) || category === 'BAQUEIRA') &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-end">
              <div className="d-flex justify-content-end">
                <Link to="/operators/add">
                  <button type="button" className="btn bg-vw-light text-white">
                    <i className="fas fa-plus"></i>
                    <span className="d-none d-sm-inline ml-1">Nuevo</span>
                  </button>
                </Link>
              </div>
            </div>
          }
        </div>
        <hr className="bg-vw-light" />
        <Box title="Listado">
          {this.renderOperators()}
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  operators: state.operators.operators,
  error: state.operators.error,
  loading: state.operators.loading,
  form: state.form,
  role: state.auth.role,
  type: state.auth.type,
  category: state.auth.category
})

const mapDispatchToProps = dispatch => ({
  fetchOperators: bindActionCreators(fetchOperators, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OperatorsView)


