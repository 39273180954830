import React from 'react'

class AssignedRulesList extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Número</th>
              <th scope="col">Regla</th>
              <th scope="col">Activa</th>
            </tr>
          </thead>
          <tbody>
            {this.props.Rules.map((rule, index) => (
              <tr>
                <th scope="row">{index + 1}</th>
                <th scope="row">{rule.BusinessRule.Name}</th>
                <th scope="row">{rule.BusinessRule.Active ? 'SI' : 'NO'}</th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AssignedRulesList
