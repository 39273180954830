import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import GoogleMapReact from 'google-map-react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as dateFormat from 'dateformat'
import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import ErrorMessage from '../components/ErrorMessage'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Line, Bar } from 'react-chartjs-2';

import MachineStatusBadge from '../components/MachineStatusBadge'
import { history } from '../store'
import MachineMarker from '../components/MachineMarker'
import RemoteActionsList from '../components/RemoteActionsList'
import MoneyTable from '../components/MoneyTable'
import { fetchAudits, fetchAuditsCSV } from '../actions/audits'

import RemoteActionAddForm from '../forms/RemoteActionAddForm'
import { deleteMachine, fetchMachine } from '../actions/machines'
import { getMachinePicture, getMachineCoordinates } from '../utils/machines'
import { fetchRemoteActions } from '../actions/remoteactions'
import { GMAPS_KEY } from '../config'
import SecurityBoxTable from '../components/SecurityBoxTable'

import { fetchMatrixs } from '../actions/matrixs'
import { fetchArticles } from '../actions/articles'
import { fetchRentals } from '../actions/rentals';

import MatrixConstructor from '../components/MatrixConstructor'
import MatrixConstructorAdmin from '../components/MatrixConstructorAdmin'
import MatrixConstructorSignal from '../components/MatrixConstructorSignal'
import MatrixConstructorBattery from '../components/MatrixConstructorBattery'
import MatrixConstructorVersion from '../components/MatrixConstructorVersion'
import MatrixConstructorBootloader from '../components/MatrixConstructorBootloader'
import MatrixConstructorOpens from '../components/MatrixConstructorOpens'

import AssignedModuleList from '../components/AssignedModuleList'
import AssignedPairingSecurityList from '../components/AssignedPairingSecurityList'
import AssignedPaymentDevicesList from '../components/AssignedPaymentDevicesList'

class MachineDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this);

    this.state = {
      loaded: false,
      deleting: false,
      deleteConfirmShown: false,
      userpinShown: false,
      batteriesShown: false,
      showModules: false,
      showPaymentDevices: false,
      showSecurity: false,
      showMatrixAdmin: false,
      showMatrixBattery: false,
      activeTab: '2',
      matrixAutoUpdate: false,
      startDate: '',
      endDate: '',
      rentals: [],
      blockedLockers: []
    };

    this.delete = this.delete.bind(this)
    this.deleteConfirmShow = this.deleteConfirmShow.bind(this)
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this)
    this.userpinToggle = this.userpinToggle.bind(this)
    this.batteriesToggle = this.batteriesToggle.bind(this)
    this.downloadAgenczia = this.downloadAgenczia.bind(this)

    this.createMatrix = this.createMatrix.bind(this)
    this.refreshMatrix = this.refreshMatrix.bind(this);
    this.handleFetchRentals = this.handleFetchRentals.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  componentDidMount() {
    const { fetchMachine, fetchRemoteActions, fetchMatrixs, fetchArticles, match } = this.props

    fetchArticles();

    fetchMachine(match.params.id)
      .then(() => {
        const { machines: { machine } } = this.props;

        if (machine) {
          fetchMatrixs(machine._id, '', '', '');
          fetchRemoteActions(machine.Serial);
        }
      })
      .catch(error => {
        // Handle error if the fetchMachine request fails
        console.error('Error fetching machine:', error);
      });

  }

  setSeasonDatesAndSubmit = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();

    let startDate, endDate;

    console.log('Current date: ', currentMonth + '/' + currentYear);

    if (currentMonth >= 0 && currentMonth < 7) {
      // If current date is between January 1st and May 1st
      startDate = new Date(currentYear - 1, 9, 1);  // October 1st last year
      endDate = new Date(currentYear, 4, 1);  // May 1st current year
    } else if (currentMonth >= 7 && currentMonth <= 11) {
      // If current date is between October 1st and December 31st
      startDate = new Date(currentYear, 9, 1);  // October 1st current year
      endDate = new Date(currentYear + 1, 4, 1);  // May 1st next year
    }

    // Both startDate and endDate should be defined by this point
    if (startDate && endDate) {
      this.setState({
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0]
      }, this.handleFetchRentals);
    } else {
      console.error("Failed to calculate the season dates.");
    }
  }

  handleFetchRentals(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    const { startDate, endDate } = this.state;
    const { fetchMatrixs, machines: { machine } } = this.props;

    if (!startDate || !endDate || !machine) return;

    this.props.fetchRentals(machine._id, '', startDate, endDate)
      .then(response => {
        let rentals = response.response;  // Accessing the actual rentals array

        if (rentals && rentals.length > 0) {
          // Filter out rentals with Status "ERROR" or "CANCELED" and Type "COUNTERINVOICE"
          rentals = rentals.filter(rental =>
            rental.Status !== "ERROR" &&
            rental.Status !== "CANCELED" &&
            rental.Type !== "COUNTERINVOICE"
          );

          const blockedLockers = rentals.flatMap(rental => rental.LockerMatricula);

          this.setState({ rentals, blockedLockers }, () => {
            // After state is updated, fetch the matrix and rerender
            fetchMatrixs(machine._id, '', '', '');
          });
        }
      })
      .catch(error => console.error("Error fetching rentals:", error));
  }


  refreshMatrix() {
    const { fetchMatrixs, machines: { machine } } = this.props;
    if (machine) {
      fetchMatrixs(machine._id, '', '', '');
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentWillReceiveProps(nextProps) {
    const { machines, customers, remoteActions } = this.props

    if (this.state.deleting && !nextProps.machines.machine) {
      this.props.push('/machines')
    }

    if (this.props.remoteActions.remoteActionsNeedRefresh) {
      this.setState({ loaded: false })
      this.props.fetchRemoteActions(machines.Serial);
    }

    if (!this.state.loaded && machines && customers && remoteActions) {
      this.setState({ loaded: true })
    }

  }

  delete() {
    const { deleteMachine, machines: { machine } } = this.props

    this.setState({ deleting: true })
    deleteMachine(machine.Serial)
  }

  deleteConfirmShow() {
    this.setState({ deleteConfirmShown: true })
  }

  deleteConfirmToggle() {
    this.setState({ deleteConfirmShown: !this.state.deleteConfirmShown })
  }

  userpinToggle() {
    this.setState({ userpinShown: !this.state.userpinShown })
  }

  batteriesToggle() {
    this.setState({ batteriesShown: !this.state.batteriesShown })
  }

  downloadAgenczia() {
    const { machines: { machine } } = this.props

    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(machine.Agenczia_della_entrate.data));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", 'agenczia_della_entrate' + ".json");
    document.body.appendChild(downloadAnchorNode);
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  renderLanguages(languages) {

    let languageImages = []

    languages.forEach(language => {

      const languageCode = language.Language.split('_')[0];

      languageImages.push(
        <div className="col"><img className="machinelistlanguage" src={`/languages/${languageCode.toLowerCase()}.png`} alt={language.Language}></img></div>
      )
    });

    return (
      <div className="row" >
        {languageImages}
      </div>
    )
  }

  generateLiveLine() {
    const { machines: { machine }, audits } = this.props;

    if (!machine) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    if (!audits) {
      return (
        <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      );
    }

    const timestamps = [];

    // Filter audits with audit.Evento === "REBOOT"
    audits.forEach((audit) => {
      if (audit.Evento === 'REBOOT') {
        timestamps.push(audit.TimeStamp);
      }
    });

    // Function to extract the hour and minute from a timestamp
    const getHourMinute = (timestamp) => {
      const date = new Date(timestamp);
      const hour = date.getHours();
      const minute = date.getMinutes();
      return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    };

    // Count the number of occurrences for each hour and minute combination
    const hourMinuteCounts = {};
    timestamps.forEach((timestamp) => {
      const hourMinute = getHourMinute(timestamp);
      hourMinuteCounts[hourMinute] = (hourMinuteCounts[hourMinute] || 0) + 1;
    });

    // Generate the chart data
    const chartData = {
      labels: Object.values(hourMinuteCounts),
      datasets: [
        {
          label: 'Reboots',
          data: Object.values(hourMinuteCounts),
          backgroundColor: 'rgba(75, 192, 192, 0.6)', // Bar color
        },
      ],
    };

    // Chart options
    const chartOptions = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      legend: {
        display: false,
      },
    };

    return (
      <div>
        <Bar data={chartData} options={chartOptions} />
      </div>
    );
  };

  updateMatrixs() {
    const { fetchMatrixs, machines: { machine } } = this.props

    // set a timer to execute the updateMatrixs function every 5 seconds
    this.timer = setInterval(() => {
      if (this.state.matrixAutoUpdate) {
        fetchMatrixs(machine._id, '', '', '');
        this.createMatrix();
        this.createMatrixAdmin();
        this.createMatrixBattery();
        this.createMatrixSignal();
        this.createMatrixVersion();
        this.createMatrixBootloader();
        this.createMatrixOpens();
      } else {
        clearInterval(this.timer);
      }
    }, 5000); // 5000 milliseconds = 5 seconds
  }

  identifyFaults() {

    const { matrixs, machines: { machine } } = this.props

    if (!machine || !matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    // BROKEN_LOCK, GREEN_BLOCK, RED_BLOCK, PINPAD_BLOCK, DOOR_BLOCK

    let TOTAL_FAULTS = 0;

    let BROKEN_LOCK = 0;
    let GREEN_BLOCK = 0;
    let RED_BLOCK = 0;
    let PINPAD_BLOCK = 0;
    let DOOR_BLOCK = 0;
    let PINPAD_ACTIVE = 0;

    let brokenLockSN = [];
    let greenBlockSN = [];
    let redBlockSN = [];
    let pinpadBlockSN = [];
    let doorBlockSN = [];
    let pinpadActiveSN = [];

    matrixs.forEach(matrix => {
      {
        (matrix.Serial === machine.Serial) && matrix.Locker.forEach((lock, index) => {

          if (lock.FaultCode !== undefined && lock.Status === 'FL') {
            switch (lock.FaultCode) {
              case 'BROKEN_LOCK':
                BROKEN_LOCK++;
                TOTAL_FAULTS++;

                brokenLockSN.push(
                  <p>{`( ${index + machine.FirstIndex} ) `}</p>
                );
                break;
              case 'GREEN_BLOCK':
                GREEN_BLOCK++;
                TOTAL_FAULTS++;

                greenBlockSN.push(
                  <p>{`( ${index + machine.FirstIndex} ) `}</p>
                );
                break;
              case 'RED_BLOCK':
                RED_BLOCK++;
                TOTAL_FAULTS++;

                redBlockSN.push(
                  <p>{`( ${index + machine.FirstIndex} ) `}</p>
                );
                break;
              case 'PINPAD_BLOCK':
                PINPAD_BLOCK++;
                TOTAL_FAULTS++;

                pinpadBlockSN.push(
                  <p>{`( ${index + machine.FirstIndex} ) `}</p>
                );
                break;
              case 'DOOR_BLOCK':
                DOOR_BLOCK++;
                TOTAL_FAULTS++;

                doorBlockSN.push(
                  <p>{`( ${index + machine.FirstIndex} ) `}</p>
                );
                break;
              case 'PINPAD_ACTIVE':
                PINPAD_ACTIVE++;
                TOTAL_FAULTS++;

                pinpadActiveSN.push(
                  <p>{`( ${index + machine.FirstIndex} ) `}</p>
                );
                break;
              default:
                break;
            }
          }
        })
      }
    })

    return (
      <div className="row p-3 justify-content-center">
        <div className="faultBoxTotal">
          <h5>TOTAL AVERIAS: {TOTAL_FAULTS}</h5>
        </div>
        <div className="faultBox">
          <h5>Pinchos rotos:</h5>
          <h5>{BROKEN_LOCK}</h5>
          <div className="row justify-content-center">{brokenLockSN}</div>
        </div>
        <div className="faultBox">
          <h5>Pin verde, no abre:</h5>
          <h5>{GREEN_BLOCK}</h5>
          <div className="row justify-content-center">{greenBlockSN}</div>
        </div>
        <div className="faultBox">
          <h5>Pin rojo, no abre:</h5>
          <h5>{RED_BLOCK}</h5>
          <div className="row justify-content-center">{redBlockSN}</div>
        </div>
        <div className="faultBox">
          <h5>Teclado no enciende:</h5>
          <h5>{PINPAD_BLOCK}</h5>
          <div className="row justify-content-center">{pinpadBlockSN}</div>
        </div>
        <div className="faultBox">
          <h5>Puerta no cierra:</h5>
          <h5>{DOOR_BLOCK}</h5>
          <div className="row justify-content-center">{doorBlockSN}</div>
        </div>
        <div className="faultBox">
          <h5>Teclado encendido:</h5>
          <h5>{PINPAD_ACTIVE}</h5>
          <div className="row justify-content-center">{pinpadActiveSN}</div>
        </div>
      </div>
    )
  }

  createMatrix(blockedLockers) {

    const { matrixs, machines: { machine }, error, loading, articles } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructor matrixs={matrixs} machine={machine} articles={articles} refreshMatrix={this.refreshMatrix} blockedLockers={blockedLockers} />

    )

  }

  createMatrixAdmin() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorAdmin matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixBattery() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorBattery matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixSignal() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorSignal matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixVersion() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorVersion matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixBootloader() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorBootloader matrixs={matrixs} machine={machine} />

    )

  }

  createMatrixOpens() {

    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (

      <MatrixConstructorOpens matrixs={matrixs} machine={machine} />

    )

  }

  renderUserpin() {
    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!machine || !matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let userpinList = []

    {
      matrixs.forEach(matrix => {
        {
          (matrix.Serial === machine.Serial) && matrix.Locker.forEach((lock, index) => {

            if (index < machine.LastIndex) {
              userpinList.push(
                <div className="ct">
                  <h5>{index + machine.FirstIndex} - {lock.Pass}</h5>
                </div>
              )
            }

          })
        }
      })
    }

    return (
      <div>
        {userpinList}
      </div>
    )
  }

  renderBatteries() {
    const { matrixs, machines: { machine }, error, loading } = this.props

    if (!machine || !matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let batteriesList = []

    {
      matrixs.forEach(matrix => {
        {
          (matrix.Serial === machine.Serial) && matrix.Locker.forEach((lock, index) => {

            if (index < machine.LastIndex) {
              batteriesList.push(
                <div className="ct">
                  <h5>{index + machine.FirstIndex} - {lock.Bateria}</h5>
                </div>
              )
            }

          })
        }
      })
    }

    return (
      <div>
        {batteriesList}
      </div>
    )
  }

  getPayments(tipo) {

    switch (tipo) {
      case 0: {
        return "TPV + CASH"
      }
      case 1: {
        return "CASH"
      }
      case 2: {
        return "TPV"
      }
      case 3: {
        return "RFID"
      }
    }

  }

  getQRMode(tipo) {

    switch (tipo) {
      case 0: {
        return "24H CON PIN"
      }
      case 1: {
        return "OTO SIN PIN"
      }
    }

  }

  getZoneName(zone) {

    switch (zone) {
      case 0:
        return 'GENERAL';
      case 1:
        return 'BAQUEIRA';
      case 2:
        return 'BERET';
      case 3:
        return 'BONAIGUA';
      case 4:
        return 'RUDA';
      case 5:
        return 'BOSQUE';
      default:
        return 'NONE';
    }
  }

  render() {
    const { matrixs, role, type, machines, machines: { machine }, remoteActions, category } = this.props
    const { startDate, endDate, blockedLockers } = this.state;

    if (machines.error) {
      return (
        <ErrorMessage message={machines.error.message} />
      )
    }

    if (!machine || machines.loading || !remoteActions) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    const coordinates = getMachineCoordinates(machine.Location)

    return (
      <div>
        <div className="row mb-2">

          {category !== 'BAQUEIRA' ? (
            <div className="col-xs-12 col-sm-6 col-md-6">
              <h1>
                <span className="text-vw-light">KIOSCO </span>
                <span className="text-vw-dark">{machine.Serial}</span>
              </h1>
            </div>
          ) : (
            <div className="col-xs-12 col-sm-12 col-md-12">
              <h1>
                <span className="text-vw-light">ZONA </span>
                <span className="text-vw-dark">{this.getZoneName(machine.Contract.Zone)} - </span>
                <span className="text-vw-light">CONTROLADOR </span>
                <span className="text-vw-dark">{machine.Serial}</span>
              </h1>
            </div>
          )
          }

          {(role === 'ADMIN') &&
            <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
              <div className="col d-flex justify-content-center">
                {(role === 'ADMIN' && type === 'GOD') &&
                  <Link to={`/machines/${machine._id}/edit`}>
                    <button className="btn bg-vw-light text-white">
                      <i className="fas fa-edit"></i>
                      <span className="d-none d-sm-inline ml-1">Editar</span>
                    </button>
                  </Link>
                }
              </div>
              <div className="col d-flex justify-content-center">
                <button className={`btn bg-vw-dark text-white ${this.state.matrixAutoUpdate ? 'rotating' : ''}`} onClick={() => { this.setState({ matrixAutoUpdate: !this.state.matrixAutoUpdate }); this.updateMatrixs(); }}>
                  <i className={`fas fa-sync`}></i>
                  <span className="d-none d-sm-inline ml-1">{this.state.matrixAutoUpdate ? 'Detener' : 'Refrescar'}</span>
                </button>
              </div>
              <div className="col d-flex justify-content-center">
                <button className="btn bg-vw-yellow text-black" onClick={() => { this.setState({ userpinShown: !this.state.userpinShown }); }}>
                  <i className="fas fa-lock"></i>
                  <span className="d-none d-sm-inline ml-1">{this.state.userpinShown ? 'Ocultar Pines' : 'Mostrar Pines'}</span>
                </button>
              </div>
              <div className="col d-flex justify-content-center">
                <button className="btn bg-vw-excel text-white" onClick={() => { this.setState({ batteriesShown: !this.state.batteriesShown }); }}>
                  <i className="fas fa-battery-full"></i>
                  <span className="d-none d-sm-inline ml-1">{this.state.batteriesShown ? 'Ocultar Baterias' : 'Mostrar Baterias'}</span>
                </button>
              </div>
              <div className="col d-flex justify-content-center">
                {(role === 'ADMIN' && type === 'GOD') &&
                  <Link to={`/matrixs/${machine._id}/edit`}>
                    <button className="btn bg-vw-purple text-white">
                      <i className="fas fa-edit"></i>
                      <span className="d-none d-sm-inline ml-1">Matriz</span>
                    </button>
                  </Link>
                }
              </div>
            </div>
          }
        </div>
        {(category !== 'BAQUEIRA') &&
          <Box title="Estado" icon="fa-chart-bar">
            <div className="row">
              <div className="col-xs-12 col-md-3">
                <PropertyLabel name="Estado actual"
                  value={<MachineStatusBadge machine={machine} />} />
                <PropertyLabel name="Última conexión"
                  value={(new Date(machine.Last_signal)).toLocaleString()} />
              </div>
              <div className="col-xs-12 col-md-1">

              </div>
              <div className="col-xs-12 col-md-2">
                <PropertyLabel
                  name="Inicio Sleep"
                  value={`${machine.SleepStart.substring(0, 2)}:${machine.SleepStart.substring(2)} h`}
                />
                <PropertyLabel
                  name="Fin Sleep"
                  value={`${machine.SleepEnd.substring(0, 2)}:${machine.SleepEnd.substring(2)} h`}
                />
              </div>
              <div className="col-xs-12 col-md-1">

              </div>
              <div className="col-xs-12 col-md-2">
                {(category !== 'BAQUEIRA') &&
                  <PropertyLabel name="Pin Máquina"
                    value={machine.PasswordMachine} />
                }
                {(category !== 'BAQUEIRA') &&
                  <PropertyLabel name="Pin Cash"
                    value={machine.PasswordFund} />
                }
                <PropertyLabel name="Pin Universal"
                  value={machine.PasswordCash} />
              </div>
              <div className="col-xs-12 col-md-1">

              </div>
              <div className="col-xs-12 col-md-2">
                <PropertyLabel name="Token"
                  value={machine.Token} />
                <PropertyLabel name="MAC"
                  value={machine.Anydesk} />
                {(role === 'ADMIN') &&
                  <PropertyLabel name="Edición"
                    value={machine.Executor} />
                }
              </div>
            </div>
          </Box>
        }
        {(role === 'ADMIN' || category === 'BAQUEIRA') &&
          <div className="row">
            <div className="col-xs-12 col-md-8">
              <Box title="Disponibilidad por fecha" icon="th">
                <div className="row">
                  <div className="col-lg-3"></div>
                  <form onSubmit={this.handleFetchRentals} className="col-lg-6">
                    <div className="row text-center">
                      <div className="col col-lg-6 form-group">
                        <label>Inicio reserva</label>
                        <input
                          type="date"
                          value={startDate}
                          onChange={(e) => this.setState({ startDate: e.target.value })}
                          className="form-control"
                        />
                      </div>
                      <div className="col col-lg-6 form-group">
                        <label>Fin reserva</label>
                        <input
                          type="date"
                          value={endDate}
                          onChange={(e) => this.setState({ endDate: e.target.value })}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-row justify-content-center mt-2">
                      <button className="btn bg-vw-dark text-white" type="submit">
                        <i className="fas fa-sync mr-1"></i>Mostrar disponibilidad
                      </button>
                      <button
                        type="button"
                        className="btn bg-vw-light text-white ml-2"
                        onClick={this.setSeasonDatesAndSubmit}
                      >
                        <i className="fas fa-calendar-alt mr-1"></i> Temporada completa
                      </button>
                    </div>
                  </form>
                  <div className="col-lg-3"></div>
                </div>
              </Box>
            </div>
            <div className="col-xs-12 col-md-4">
              <Box title="Leyenda tipos de taquillas" icon="th">
                <div className="row mt-5 mb-5 text-center">
                  <div className="col">
                    <span>2 PAX</span>
                    <div className="pax2"></div>
                  </div>
                  <div className="col">
                    <span>3 PAX</span>
                    <div className="pax3"></div>
                  </div>
                  <div className="col">
                    <span>4 PAX</span>
                    <div className="pax4"></div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        }
        {role === 'ADMIN' ? (
          <Box title="Matriz" icon="th">
            <div>
              <Nav tabs className="justify-content-center">
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >
                    Cliente
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                  >
                    Bluetooth
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '3' })}
                    onClick={() => { this.toggle('3'); }}
                  >
                    Battery
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '4' })}
                    onClick={() => { this.toggle('4'); }}
                  >
                    Signal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '5' })}
                    onClick={() => { this.toggle('5'); }}
                  >
                    Firmware
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '6' })}
                    onClick={() => { this.toggle('6'); }}
                  >
                    Bootloader
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: this.state.activeTab === '7' })}
                    onClick={() => { this.toggle('7'); }}
                  >
                    Aperturas
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={this.state.activeTab} id="matrixScroll">
                <TabPane tabId="1">
                  <br></br>
                  {this.createMatrix(blockedLockers)}
                </TabPane>
                <TabPane tabId="2">
                  <br></br>
                  {this.createMatrixAdmin()}
                </TabPane>
                <TabPane tabId="3">
                  <br></br>
                  {this.createMatrixBattery()}
                </TabPane>
                <TabPane tabId="4">
                  <br></br>
                  {this.createMatrixSignal()}
                </TabPane>
                <TabPane tabId="5">
                  <br></br>
                  {this.createMatrixVersion()}
                </TabPane>
                <TabPane tabId="6">
                  <br></br>
                  {this.createMatrixBootloader()}
                </TabPane>
                <TabPane tabId="7">
                  <br></br>
                  {this.createMatrixOpens()}
                </TabPane>
              </TabContent>
            </div>
          </Box>
        ) : (
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <Box title="Matriz" icon="th">
                <div id="matrixScroll">
                  {this.createMatrix(blockedLockers)}
                </div>
              </Box>
            </div>
          </div>
        )
        }
        {/* {role === 'ADMIN' &&
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <Box title="Linea de Vida" icon="cubes">
                {this.generateLiveLine()}
              </Box>
            </div>
          </div>
        } */}
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <Box title="Detalles" icon="cubes">
              <PropertyLabel name="Fondo de caja (EUR)"
                value={`${machine.ChangeInitial} €`} />
              <PropertyLabel name="Locker Antiguo"
                value={machine.OldLocker ? "SI" : "NO"} />
              <PropertyLabel name="Segregación por sexo"
                value={machine.GenderMode ? "SI" : "NO"} />
              <PropertyLabel name="Firmware nuevo"
                value={machine.LockersFirmwareVersion === 1 ? "SI" : "NO"} />
              <PropertyLabel name="Aviso de ticket"
                value={machine.PrinterSensorActive ? "SI" : "NO"} />
              <PropertyLabel name="Recuperación contraseña Start"
                value={machine.StartPassRecovery ? "SI" : "NO"} />
              <PropertyLabel name="Indicador de estado LED"
                value={machine.LedStatusIndicator ? "SI" : "NO"} />
              <PropertyLabel name="Métodos de pago"
                value={this.getPayments(machine.PaymentMethods)} />
              <PropertyLabel name="Pasos sonoros"
                value={machine.GeneralSoundActive ? "SI" : "NO"} />
              <PropertyLabel name="Bloqueo de alquiler (Port Aventura)"
                value={machine.UndefinedLockerRental ? "SI" : "NO"} />
            </Box>
          </div>
          <div className="col-xs-12 col-md-8">
            <Box title="Asignación" icon="arrows-alt-h">
              {!machine.Customer ? (
                <h6>machine is not allocated</h6>
              ) : (
                <div>
                  <PropertyLabel name="Cliente"
                    value={machine.Customer?.Fullname || 'None'} />
                  <PropertyLabel name="Contrato #"
                    value={machine.Contract.number} />
                  {coordinates &&
                    <div className="mb-3" style={{ width: '100%', height: '210px' }}>
                      <GoogleMapReact
                        bootstrapURLKeys={{ key: GMAPS_KEY }}
                        defaultCenter={coordinates}
                        defaultZoom={10}
                      >
                        <MachineMarker
                          lat={coordinates.lat}
                          lng={coordinates.lng}
                          machine={machine}
                        />
                      </GoogleMapReact>
                    </div>
                  }
                </div>
              )}
            </Box>
          </div>
        </div>
        {(category !== 'BAQUEIRA') &&
          <div className="row">
            <div className="col-xs-12 col-md-3">
              <Box title="Idiomas" icon="cubes">
                <PropertyLabel name="Idioma principal"
                  value={machine.Language} />
                <PropertyLabel name="Idiomas"
                  value={this.renderLanguages(machine.SupportedLanguages)} />
              </Box>
            </div>
            <div className="col-xs-12 col-md-3">
              <Box title="QR" icon="cubes">
                <PropertyLabel name="Venta QR habilitada"
                  value={machine.QRActive ? "SI" : "NO"} />
                <PropertyLabel name="Porcentaje de reserva"
                  value={`${machine.QRPercentage} %`} />
                <PropertyLabel name="Modo de funcionamiento"
                  value={this.getQRMode(machine.QRMode)} />
              </Box>
            </div>
            <div className="col-xs-12 col-md-3">
              <Box title="Limpieza" icon="cubes">
                <PropertyLabel name="Limpieza Habilitada"
                  value={machine.CleanActive ? "SI" : "NO"} />
                <PropertyLabel name="Limpiar Siempre"
                  value={machine.CleanAll ? "SI" : "NO"} />
              </Box>
              {/* <Box title="Pulseras" icon="cubes">
              <PropertyLabel name="Taquilla con pulsera"
                value={machine.WristbandLockers ? "SI" : "NO"} />
              <PropertyLabel name="Tumbona con pulsera"
                value={machine.WristbandTumbonas ? "SI" : "NO"} />
            </Box> */}
            </div>
            <div className="col-xs-12 col-md-3">
              <Box title="Reserva" icon="cubes">
                <PropertyLabel name="Reserva habilitada"
                  value={machine.BookActive ? "SI" : "NO"} />
                <PropertyLabel name="Porcentaje de reserva"
                  value={`${machine.BookPercentage} %`} />
                <PropertyLabel name="Hora de liberación"
                  value={`${machine.BookFreeTime.substring(0, 2)}:${machine.BookFreeTime.substring(2)} h`} />
              </Box>
            </div>
          </div>
        }
        {(category !== 'BAQUEIRA') &&
          <Box title="CAJAS" icon="dollar-sign">
            <MoneyTable machine={this.props.machines.machine} />
          </Box>
        }
        {role === 'ADMIN' &&
          <div className="row mb-2 mt-5">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <h1>
                <span className="text-vw-light">DETALLES DE </span>
                <span className="text-vw-dark">ADMINISTRADOR</span>
              </h1>
            </div>
          </div>
        }
        {role === 'ADMIN' &&
          <Box title="Averias" icon="info">
            {this.identifyFaults()}
          </Box>
        }
        {role === 'ADMIN' &&
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <Box title="Info Admin" icon="cubes">
                <PropertyLabel name="Pin Master"
                  value={machine.PasswordMaster} />
                <PropertyLabel name="Fecha de fabricación"
                  value={dateFormat(new Date(machine.Build_date), 'dd/mm/yy')} />
                <PropertyLabel name="Tipo"
                  value={machine.Type} />
                <PropertyLabel name="Versión del software"
                  value={machine.Sw_version} />
                <PropertyLabel name="Versión del firmware"
                  value={machine.Fw_version} />
                <PropertyLabel name="Versión del updater"
                  value={machine.Up_version} />
                <PropertyLabel name="Versión del esp32"
                  value={machine.ESP32_version} />
                <PropertyLabel name="Bloqueo Cancelar U1000"
                  value={machine.BlockU1000Cancelling ? "SI" : "NO"} />
              </Box>
            </div>
            <div className="col-xs-12 col-md-4">
              <Box title="Taquillas" icon="cubes">
                <PropertyLabel name="Night Scan activo"
                  value={machine.ScanNightAllowed ? "SI" : "NO"} />
                <PropertyLabel name="Day Scan activo"
                  value={machine.ScanDayAllowed ? "SI" : "NO"} />
                <PropertyLabel name="Day Scan Interval"
                  value={machine.ScanDayInterval} />
                <PropertyLabel name="Número de aperturas"
                  value={machine.PassOpens} />
                <PropertyLabel name="Alarma de locker abierto"
                  value={machine.AlarmDefault ? "SI" : "NO"} />
                <PropertyLabel name="Sonido de teclado activo"
                  value={machine.BuzzerDefault === '1' ? "SI" : "NO"} />
                <PropertyLabel name="Cierre de locker manual"
                  value={machine.OpenModeDefault ? "SI" : "NO"} />
                <PropertyLabel name="Password Universal"
                  value={machine.PasswordUniversal ? machine.PasswordUniversal : "NO"} />
                <PropertyLabel name="Carácteres Contraseña"
                  value={machine.PasswordLength} />
                <PropertyLabel name="Potencia BLE"
                  value={machine.BLEPower} />
                <PropertyLabel name="Cadéncia BLE"
                  value={machine.BLEAdvTime} />
                <PropertyLabel name="Encender Teclado"
                  value={machine.Keypad ? "SI" : "NO"} />
              </Box>
            </div>
            <div className="col-xs-12 col-md-4">
              <Box title="Módulos" icon="door-closed">
                <Button color="primary mr-1" style={{ marginBottom: '20px' }} onClick={() => { this.setState({ showModules: !this.state.showModules }) }}>{this.state.showModules ? 'Ocultar' : 'Mostrar'} Módulos</Button>
                {
                  this.state.showModules ? <AssignedModuleList Modules={this.props.machines.machine.Modules} /> : null
                }
              </Box>
              <Box title="Seguridad" icon="door-closed">
                <Button color="primary mr-1" style={{ marginBottom: '20px' }} onClick={() => { this.setState({ showSecurity: !this.state.showSecurity }) }}>{this.state.showSecurity ? 'Ocultar' : 'Mostrar'} Seguridad</Button>
                {
                  this.state.showSecurity ? <AssignedPairingSecurityList SecurityLockers={this.props.machines.machine.SecurityLockers} /> : null
                }
              </Box>
            </div>
          </div>
        }
        {role === 'ADMIN' &&
          <div className="row">
            <div className="col-xs-12 col-md-4">
              <Box title="Dispositivos de pago" icon="door-closed">
                <AssignedPaymentDevicesList Devices={this.props.machines.machine.PaymentDevices} />
              </Box>
            </div>
            <div className="col-xs-12 col-md-4">
              <Box title="TPV" icon="cubes">
                <PropertyLabel name="Número de serie"
                  value={machine.Tpv_serial} />
                <PropertyLabel name="Cargo máximo TPV (€)"
                  value={machine.Tpv_max} />
                <PropertyLabel name="Timeout de cancelación (s)"
                  value={machine.TpvCancelTime} />
              </Box>
              <Box title="PAYTEF" icon="cubes">
                <PropertyLabel name="TCOD"
                  value={machine.Tpv_tcod} />
              </Box>
              <Box title="REDSYS" icon="cubes">
                <PropertyLabel name="Código de comercio"
                  value={machine.TpvBusinessCode} />
                <PropertyLabel name="Número de Terminal"
                  value={machine.TpvTerminalCode} />
                <PropertyLabel name="Clave de Firma"
                  value={machine.TpvFirmKeyCode} />
              </Box>
            </div>
            <div className="col-xs-12 col-md-4">
              <Box title="CASH" icon="cubes">
                <PropertyLabel name="Máximo de devolución diario (EUR)"
                  value={machine.LimiteDevolucionDiario} />
                <PropertyLabel name="Máximo de devolución de una sola vez (EUR)"
                  value={machine.LimiteDevolucion} />
                <PropertyLabel name="Cobro máximo en Cash (EUR)"
                  value={machine.CashMax} />
                <PropertyLabel name="Caja de seguridad"
                  value={machine.SecurityBox ? "SI" : "NO"} />
                <PropertyLabel name="Billetes aceptados"
                  value={machine.BillerAcceptance} />
              </Box>
            </div>
          </div>
        }
        {role === 'ADMIN' &&
          <div className="row mb-2 mt-5">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <h1>
                <span className="text-vw-light">ACCIONES </span>
                <span className="text-vw-dark">REMOTAS</span>
              </h1>
            </div>
          </div>
        }
        {(role === 'ADMIN' && (type === 'GOD' || type === 'PROGRAMMER')) &&
          <Box title="Acciones Remotas" icon="hand-paper">
            <RemoteActionAddForm machine={machine} />
            <RemoteActionsList remoteActions={remoteActions} />
          </Box>
        }
        {(role === 'ADMIN' && type === 'GOD') &&
          <div className="row mb-2 mt-5">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <h1>
                <span className="text-vw-light">HACIENDA </span>
                <span className="text-vw-dark">ITALIANA</span>
              </h1>
            </div>
          </div>
        }
        {(role === 'ADMIN' && type === 'GOD') &&
          <Box title="Agenzia della entrate" icon="hand-paper">
            {
              (machine.Agenczia_della_entrate && machine.Agenczia_della_entrate.length)
            }

            {
              (machine.Agenczia_della_entrate && machine.Agenczia_della_entrate.date) &&
              <div>
                <hr />
                <h6 className="text-vw-dark">AGENZIA DELLA ENTRATE</h6>
                <PropertyLabel name="Fecha"
                  value={
                    machine.Agenczia_della_entrate ? dateFormat(machine.Agenczia_della_entrate.date, 'dd/mm/yy HH:MM') : ''
                  } />
                <div>
                  <div className="d-flex justify-content-between">
                    <h6>Descargar:</h6>
                    <span className="text-muted cursor-pointer" onClick={this.downloadAgenczia}>
                      <i className={`fas fa-download float-right mr-1`}></i>
                    </span>
                  </div>
                </div>
              </div>
            }
          </Box>
        }

        {/* Confirm deletion */}
        <div>
          <Modal isOpen={this.state.deleteConfirmShown}
            toggle={this.deleteConfirmToggle}>
            <ModalHeader toggle={this.deleteConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              {machine.Customer ? (
                <p>Una máquina no se puede borrar mientras tenga un contrato asignado.</p>
              ) : (
                <p>¿Estás seguro de borrar la máquina?</p>
              )}
            </ModalBody>
            <ModalFooter>
              {!machine.Customer &&
                <Button color="primary mr-1" onClick={this.delete}>
                  Borrar
                </Button>}
              <Button color="secondary" onClick={this.deleteConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* View all user pins */}
        <div>
          <Modal isOpen={this.state.userpinShown}
            toggle={this.userpinToggle}>
            <ModalHeader toggle={this.userpinToggle}>
              Listado de Pines de Usuario
            </ModalHeader>
            <ModalBody>
              {this.renderUserpin()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.userpinToggle}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* View all batteries */}
        <div>
          <Modal isOpen={this.state.batteriesShown}
            toggle={this.batteriesToggle}>
            <ModalHeader toggle={this.batteriesToggle}>
              Listado de Baterias
            </ModalHeader>
            <ModalBody>
              {this.renderBatteries()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.batteriesToggle}>
                Cerrar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machines: state.machines,
  audits: state.audits.audits,
  matrixs: state.matrixs.matrixs,
  articles: state.articles.articles,
  error: state.machines.error,
  loading: state.machines.loading,
  remoteActions: state.remoteactions,
  role: state.auth.role,
  type: state.auth.type,
  category: state.auth.category,
  rentals: state.rentals.rentals
})

const mapDispatchToProps = dispatch => ({
  deleteMachine: bindActionCreators(deleteMachine, dispatch),
  fetchMachine: bindActionCreators(fetchMachine, dispatch),
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch),
  fetchRemoteActions: bindActionCreators(fetchRemoteActions, dispatch),
  fetchAudits: bindActionCreators(fetchAudits, dispatch),
  fetchArticles: bindActionCreators(fetchArticles, dispatch),
  fetchRentals: bindActionCreators(fetchRentals, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MachineDetailsView)
