import { CALL_API } from '../middleware/api'

export const REPLACEMENTS_REQUEST = 'REPLACEMENTS_REQUEST'
export const REPLACEMENTS_SUCCESS = 'REPLACEMENTS_SUCCESS'
export const REPLACEMENTS_FAILURE = 'REPLACEMENTS_FAILURE'

export function fetchReplacements() {

  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `replacements`,
      authenticated: true,
      types: [REPLACEMENTS_REQUEST, REPLACEMENTS_SUCCESS, REPLACEMENTS_FAILURE]
    }
  }
}

export const REPLACEMENTS_UPDATE_REQUEST = 'REPLACEMENTS_UPDATE_REQUEST'
export const REPLACEMENTS_UPDATE_SUCCESS = 'REPLACEMENTS_UPDATE_SUCCESS'
export const REPLACEMENTS_UPDATE_FAILURE = 'REPLACEMENTS_UPDATE_FAILURE'

export function updateReplacement(id, props) {
  return {
    [CALL_API]: {
      method: 'PUT',
      endpoint: `replacements/${id}`,
      authenticated: true,
      body: JSON.stringify(props),
      types: [REPLACEMENTS_UPDATE_REQUEST, REPLACEMENTS_UPDATE_SUCCESS,
        REPLACEMENTS_UPDATE_FAILURE]
    }
  }
}

export const REPLACEMENT_REQUEST = 'REPLACEMENT_REQUEST'
export const REPLACEMENT_SUCCESS = 'REPLACEMENT_SUCCESS'
export const REPLACEMENT_FAILURE = 'REPLACEMENT_FAILURE'

export function fetchReplacement(id) {
  return {
    [CALL_API]: {
      method: 'GET',
      endpoint: `replacements/${id}`,
      authenticated: true,
      types: [REPLACEMENT_REQUEST, REPLACEMENT_SUCCESS,
        REPLACEMENT_FAILURE]
    }
  }
}