import React from 'react'

const StepBar = ({ step }) => {

    return (
      <div className="row stepbar">

        <div className="stepparent">
          {step > 1 ? (
            <div className="stepOld">1</div>
          ) : (
            <div className={`${step == 1 ? "stepActive" : "step"}`}>1</div>
          )}

          <div className={`${step == 1 ? "steplabelActive" : "steplabel"}`}>Zona</div>
        </div>

        <div className="stepline"></div>

        <div className="stepparent">
          {step > 2 ? (
            <div className="stepOld">2</div>
          ) : (
            <div className={`${step == 2 ? "stepActive" : "step"}`}>2</div>
          )}

          <div className={`${step == 2 ? "steplabelActive" : "steplabel"}`}>Taquilla</div>
        </div>

        <div className="stepline"></div>

        <div className="stepparent">
          {step > 3 ? (
            <div className="stepOld">3</div>
          ) : (
            <div className={`${step == 3 ? "stepActive" : "step"}`}>3</div>
          )}
          
          <div className={`${step == 3 ? "steplabelActive" : "steplabel"}`}>Teléfono</div>
        </div>

        <div className="stepline"></div>

        <div className="stepparent">
          <div className={`${step == 4 ? "stepActive" : "step"}`}>4</div>
          <div className={`${step == 4 ? "steplabelActive" : "steplabel"}`}>Ticket</div>
        </div>

      </div>
  );
};

export default StepBar;

