import {
  SUPPORTTICKETS_REQUEST, SUPPORTTICKETS_SUCCESS, SUPPORTTICKETS_FAILURE,
  SUPPORTTICKET_REQUEST, SUPPORTTICKET_SUCCESS, SUPPORTTICKET_FAILURE,
  SUPPORTTICKET_ADD_REQUEST, SUPPORTTICKET_ADD_SUCCESS, SUPPORTTICKET_ADD_FAILURE,
  SUPPORTTICKET_DELETE_REQUEST, SUPPORTTICKET_DELETE_SUCCESS, SUPPORTTICKET_DELETE_FAILURE
} from '../actions/supporttickets'

const INITIAL_STATE = {
  supporttickets: null, supportticket: null, error: null, loading: false, supportticketNeedRefresh: false
}

const supportticketsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SUPPORTTICKETS_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SUPPORTTICKETS_SUCCESS:
        return {
          ...state,
          supporttickets: action.response, error: null, loading: false
        }
      case SUPPORTTICKETS_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SUPPORTTICKET_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SUPPORTTICKET_SUCCESS:
        return {
          ...state,
          supportticket: action.response, error: null, loading: false
        }
      case SUPPORTTICKET_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
      case SUPPORTTICKET_ADD_REQUEST:
        return {
          ...state,
          error: null, loading: true
        }
      case SUPPORTTICKET_ADD_SUCCESS:
        return {
          ...state,
          supportticketNeedRefresh: true, supportticket: action.response, error: null, loading: false
        }
      case SUPPORTTICKET_ADD_FAILURE:
        return {
          ...state,
          error: action.error, loading: false
        }
        case SUPPORTTICKET_DELETE_REQUEST:
          return {
            ...state,
            error: null, loading: true
          }
        case SUPPORTTICKET_DELETE_SUCCESS:
          return {
            ...state,
            supportticketNeedRefresh: true, supportticket: action.response, error: null,
            loading: false
          }
        case SUPPORTTICKET_DELETE_FAILURE:
          return {
            ...state,
            error: action.error, loading: false
          }
      default:
        return state
    }
  }

export default supportticketsReducer

