import React from 'react'
import { history } from '../store'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchFaultsCSV } from '../actions/matrixs'

class FaultsByCustomerList extends React.Component {
  constructor(props) {
    super(props);

    this.handleDownloadPDF = this.handleDownloadPDF.bind(this)
  }

  goTo(Serial, e) {
    history.push(`/machines/${Serial}`)
  }

  handleDownloadPDF(fault) {
    const { fetchFaultsCSV } = this.props

    fetchFaultsCSV(fault.customer);
  }

  renderFaultList() {
    const { matrixs, machines } = this.props;

    // Create an object to store fault objects by customer name
    const faultsByCustomer = {};

    // Iterate over matrixs and machines to populate faultsByCustomer object
    matrixs.forEach((matrix) => {
      const serial = matrix.Serial;
      const machine = machines.find((machine) => machine.Serial === serial);

      if (machine) {
        const customer = machine.Customer;
        const customerName = customer && customer.Fullname ? customer.Fullname : '';

        matrix.Locker.forEach((lock) => {
          if (lock.FaultCode !== undefined && lock.Status === 'FL') {
            const faultCode = lock.FaultCode;
            const key = customerName;

            if (!faultsByCustomer[key]) {
              faultsByCustomer[key] = {
                customer: customerName,
                BROKEN_LOCK: 0,
                GREEN_BLOCK: 0,
                RED_BLOCK: 0,
                PINPAD_BLOCK: 0,
                DOOR_BLOCK: 0,
                PINPAD_ACTIVE: 0,
                TOTAL_FAULTS: 0,
              };
            }

            faultsByCustomer[key][faultCode]++;
            faultsByCustomer[key].TOTAL_FAULTS++;
          }
        });
      }
    });

    // Convert faultsByCustomer object to an array of fault objects
    const faultList = Object.values(faultsByCustomer);

    // Sort the faultList by customer name in alphabetical order
    faultList.sort((a, b) => a.customer.localeCompare(b.customer));

    return faultList.map((fault) => (
      <tr key={fault.customer} >
        <th>{fault.customer}</th>
        <td>{fault.TOTAL_FAULTS}</td>
        <td>{fault.BROKEN_LOCK}</td>
        <td>{fault.GREEN_BLOCK}</td>
        <td>{fault.RED_BLOCK}</td>
        <td>{fault.PINPAD_BLOCK}</td>
        <td>{fault.DOOR_BLOCK}</td>
        <td>{fault.PINPAD_ACTIVE}</td>
        <td>
          <button className="btn btn-info"
            onClick={() => this.handleDownloadPDF(fault)}>
            <i className="fas fa-file-excel mr-1"></i>Descargar
          </button>
        </td>
      </tr>
    ));
  }

  render() {
    if (!this.props.matrixs) {
      return null
    }

    return (
      <div className="table-responsive" id="content-to-pdf">
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Cliente</th>
              <th scope="col">Total averias</th>
              <th scope="col">Pinchos rotos</th>
              <th scope="col">Pin verde, no abre</th>
              <th scope="col">Pin rojo, no abre</th>
              <th scope="col">Teclado no enciende</th>
              <th scope="col">Puerta no cierra</th>
              <th scope="col">Teclado encendido</th>
              <th scope="col">Descargar Informe</th>
            </tr>
          </thead>
          <tbody>
            {this.renderFaultList()}
          </tbody>
        </table>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  fetchFaultsCSV: bindActionCreators(fetchFaultsCSV, dispatch)
})

export default connect(null, mapDispatchToProps)(FaultsByCustomerList);

