import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { fetchMachine } from '../actions/machines'
import { fetchMatrix, sendMatrix, deleteMatrix } from '../actions/matrixs'
import { fetchModule } from '../actions/modules'

import MatrixGenerator from '../components/MatrixGenerator'
import AssignedModuleList from '../components/AssignedModuleList'
import { getMachineStatus } from '../utils/machines'

class MatrixDetailsView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      modules: [],
      moduleErrors: [],
      currentPosition: 0,
      firstIndex: 1,
      tempFirstIndex: 1,
      isEditing: false,
      saveConfirmShown: false,
      deleteConfirmShown: false
    };

    this.createMatrix = this.createMatrix.bind(this);
    this.generateMatrix = this.generateMatrix.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.saveMatrix = this.saveMatrix.bind(this);
    this.saveConfirmShow = this.saveConfirmShow.bind(this);
    this.saveConfirmToggle = this.saveConfirmToggle.bind(this);

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.deleteMatrix = this.deleteMatrix.bind(this);
    this.deleteConfirmShow = this.deleteConfirmShow.bind(this);
    this.deleteConfirmToggle = this.deleteConfirmToggle.bind(this);
  }

  componentDidMount() {
    const { fetchMachine, fetchMatrix, fetchModule, match } = this.props;

    let position = 0;
    let machineIndex = 1;

    fetchMachine(match.params.id)
      .then(() => {
        const { machine } = this.props;

        if (machine && machine.Modules && machine.Modules.length > 0) {

          machineIndex = machine.FirstIndex;

          fetchMatrix(machine.Serial);

          // Create an array of promises to fetch each module
          const modulePromises = machine.Modules.map(module => {
            return fetchModule(module.serialNumber)
              .then(moduleData => {
                position += parseInt(module.serialNumber.slice(3, 5), 10);

                if (!moduleData || !moduleData.response) {
                  return { serialNumber: module.serialNumber, error: 'Este módulo no está creado o presenta algún error, revísalo!' };
                }

                return { serialNumber: module.serialNumber, data: moduleData.response };
              })
              .catch(error => {
                // Catch individual module fetch errors and return error info
                return { serialNumber: module.serialNumber, error: error.message || 'Unknown error' };
              });
          });

          // Use Promise.all to wait for all fetchModule promises to resolve
          return Promise.all(modulePromises);
        } else {
          throw new Error("No modules found for this machine.");
        }
      })
      .then(modulesData => {
        // Separate successful fetches from failed ones
        const successfulModules = modulesData.filter(m => !m.error).map(m => m.data);
        const failedModules = modulesData.filter(m => m.error);

        // Store the fetched modules data and any errors in the state
        this.setState({
          modules: successfulModules,
          moduleErrors: failedModules, // Store failed modules with errors
          loaded: true,
          currentPosition: position,
          firstIndex: machineIndex
        });

      })
      .catch(error => {
        // Handle error if the fetchMachine request fails
        console.error('Error fetching machine:', error);
      });
  }

  handleSaveClick = (event) => {
    const { matrix, machine } = this.props

    event.stopPropagation();

    let status = 'OFFLINE';

    if (machine) {
      status = getMachineStatus(machine)
    }

    if (matrix) {
      alert("Acción bloqueada! Esta máquina ya tiene una matriz generada.");
      // }else if (!machine.Status || status === 'OFFLINE') {
      //   alert("Acción bloqueada! La máquina no está online.");
      // 
    } else if (!machine.FirstIndex && !this.state.firstIndex) {
      alert("Acción bloqueada! No has asignado el primer índice.");
    } else {
      this.saveConfirmShow();
    }
  }

  saveMatrix() {
    const { sendMatrix, machine } = this.props
    const { firstIndex, currentPosition } = this.state

    const generatedMatrix = this.generateMatrix(machine);

    if (!generatedMatrix) {
      alert('Error: Matrix has not been generated yet.');
      return;
    }

    sendMatrix(machine.Serial, firstIndex, currentPosition, generatedMatrix);
    this.saveConfirmToggle();
  }

  saveConfirmShow() {
    this.setState({
      saveConfirmShown: true
    })
  }

  saveConfirmToggle() {
    this.setState({ saveConfirmShown: !this.state.saveConfirmShown });
  }

  handleDeleteClick = (event) => {
    const { matrix } = this.props

    event.stopPropagation();

    if (!matrix) {
      alert("Acción bloqueada! Esta máquina no tiene una matriz generada.");
    } else {
      this.deleteConfirmShow();
    }
  }

  deleteMatrix() {
    const { deleteMatrix, machine } = this.props

    deleteMatrix(machine.Serial);
    this.deleteConfirmToggle();
  }

  deleteConfirmShow() {
    this.setState({
      deleteConfirmShown: true
    })
  }

  deleteConfirmToggle() {
    this.setState({ deleteConfirmShown: !this.state.deleteConfirmShown });
  }

  // Handle input changes for the firstIndex input
  handleInputChange(event) {
    this.setState({ tempFirstIndex: event.target.value });
  }

  // Toggle between edit mode and display mode
  toggleEditMode() {
    const { isEditing, tempFirstIndex } = this.state;

    if (isEditing) {
      // Save the value when we leave editing mode
      this.setState({ firstIndex: parseInt(tempFirstIndex, 10), isEditing: false });
    } else {
      // Enter editing mode, set tempFirstIndex to the current firstIndex value
      this.setState({ tempFirstIndex: this.state.firstIndex, isEditing: true });
    }
  }

  getPosition(prefix, number, index) {

    let position = "";

    if (prefix === 'VLK') {
      if (number === 4) {
        if (index === 0 || index === 2) {
          position = 'C';
        }
      } else if (number === 6) {
        if (index === 0 || index === 3) {
          position = 'C';
        }
      }
    }

    return position;
  }

  getType(prefix, number, index) {

    let type = "S";

    switch (prefix) {
      case 'VLK': {
        if (number === 4) {
          if (index === 1 || index === 3) {
            type = 'M';
          }
        } else if (number === 6) {
          if (index === 1 || index === 4) {
            type = 'S';
          }
        }
        break;
      }

      case 'VLM': {
        switch (number) {
          case 12:
            break;

          case 10: {
            if (index === 4 || index === 9) {
              type = 'M';
            }
            break;
          }

          case 6: {
            type = 'M';
            break;
          }

          case 4: {
            type = 'L';
            break;
          }

          case 2: {
            type = '3XL';
            break;
          }

          case 1: {
            type = '3XL';
            break;
          }

        }
        break;
      }
    }

    return type;
  }

  generateMatrix(machine) {
    const { modules, firstIndex } = this.state;
    const Lockers = [];
    const matrix = {};
    let currentPosition = 1; // Starting position for lockers
    let currentModule = 0;

    for (let module of machine.Modules) {
      const modulePrefix = module.serialNumber.slice(0, 3);
      const numberOfLockers = parseInt(module.serialNumber.slice(3, 5), 10);
      const moduleSN = module.serialNumber;

      for (let i = 0; i < numberOfLockers; i++) {
        let locker = {
          Position: this.getPosition(modulePrefix, numberOfLockers, i),
          BLEName: `SN:${modules.find(m => m.Code === moduleSN).RegisterLockers[i].SN}`,
          Bateria: 0,
          Cleaning: 0,
          Configured: false,
          ErrorBle: "0x00",
          Espressif: modules.find(m => m.Code === moduleSN).Espressif || 0,
          Factura: "000000",
          Gender: `${modules.find(m => m.Code === moduleSN).Gender || ''}`,
          Hobbit: false,
          LastNightScanCompleted: "20240101000000",
          Matricula: currentPosition,
          Mode: `${modules.find(m => m.Code === moduleSN).Mode || 'B'}`,
          ModuleSN: moduleSN,
          Pass: "000000",
          PasswordCash: "000000",
          Phone: "",
          Pin: modules.find(m => m.Code === moduleSN).RegisterLockers[i].Pin,
          Rssi: 0,
          Status: "AV",
          Type: this.getType(modulePrefix, numberOfLockers, i),
          Wide: `${modules.find(m => m.Code === moduleSN).Wide || 'N'}`,
          KerongVersion: 0,
          FaultCode: "",
          BLEStatus: true,
          RentTime: "",
          AccessPass: "",
          PreBookingExpirationTimestamp: null,
          KerongBootVersion: null,
          BlockType: null
        };

        Lockers.push(locker);
        currentPosition++;
      }

      currentModule++;

    }

    matrix.Locker = Lockers;
    matrix.Machine = machine._id;
    matrix.Serial = machine.Serial;

    return matrix;
  }

  createMatrix() {

    const { machine, error, loading } = this.props
    const { firstIndex, currentPosition } = this.state

    const generatedMatrix = this.generateMatrix(machine);

    return (

      <MatrixGenerator matrix={generatedMatrix} machine={machine} firstIndex={firstIndex} lastIndex={currentPosition} />

    )

  }

  render() {
    const { machine, error, loading } = this.props
    const { loaded, modules, moduleErrors, firstIndex, currentPosition, tempFirstIndex, isEditing } = this.state;

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!machine || loading || !loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    if (moduleErrors.length > 0) {
      return (
        <div className="error">
          <h3>Error al cargar los siguientes módulos:</h3>
          <ul>
            {moduleErrors.map((error, index) => (
              <li key={index}>
                Module Serial Number: {error.serialNumber} - Error: {error.error}
              </li>
            ))}
          </ul>
        </div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">GENERADOR DE </span>
              <span className="text-vw-dark">MATRIZ</span>
            </h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Box title="Detalles" icon="th">
              <div className="row">
                <div className="col-xs-12 col-md-3 text-center">
                  <h5 className="text-vw-light">Primer índice</h5>
                  {isEditing ? (
                    <input
                      type="number"
                      value={tempFirstIndex}
                      onChange={this.handleInputChange}
                      className="form-control text-center"
                    />
                  ) : (
                    <h5 className="text-vw-dark">{firstIndex}</h5>
                  )}
                  <button onClick={this.toggleEditMode} className="btn btn-primary mt-2">
                    {isEditing ? 'Guardar' : 'Editar'}
                  </button>
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                  <h5 className="text-vw-light">Número total de taquillas</h5>
                  <h5 className="text-vw-dark">{currentPosition}</h5>
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                  <button onClick={(event) => this.handleSaveClick(event)} className="btn btn-info mt-2">
                    Guardar matriz
                  </button>
                </div>
                <div className="col-xs-12 col-md-3 text-center">
                  <button onClick={(event) => this.handleDeleteClick(event)} className="btn btn-danger mt-2">
                    Eliminar matriz
                  </button>
                </div>
              </div>
            </Box>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <Box title="Matriz" icon="th">
              <div id="matrixScroll">
                {this.createMatrix()}
              </div>
            </Box>
          </div>
        </div>
        <div>
          <Modal isOpen={this.state.saveConfirmShown}
            toggle={this.saveConfirmToggle}>
            <ModalHeader toggle={this.saveConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de guardar la matriz que has generado?</p>
              <br></br>
              <p>-La matriz se guardará en el sistema.</p>
              <br></br>
              <p>-Deberás solicitar o enviar la matriz a la máquina desde acciones remotas del ERP* o desde mantenimiento en la propia máquina.</p>
              <br></br>
              <p>*Este proceso puede demorar hasta 5 min dependiendo del tamaño de la matriz, una vez finalizado confirma in situ la bajada.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="primary mr-1" onClick={this.saveMatrix}>
                Guardar
              </Button>
              <Button color="secondary" onClick={this.saveConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div>
          <Modal isOpen={this.state.deleteConfirmShown}
            toggle={this.deleteConfirmToggle}>
            <ModalHeader toggle={this.deleteConfirmToggle}>
              Confirmar
            </ModalHeader>
            <ModalBody>
              <p>¿Estás seguro de eliminar la matriz de esta máquina?</p>
              <br></br>
              <p>-La matriz se eliminará en el sistema pero no en la máquina.</p>
              <br></br>
              <p>*Esta es una acción crítica, piensa muy bien antes de eliminar.</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger mr-1 text-white" onClick={this.deleteMatrix}>
                Eliminar
              </Button>
              <Button color="secondary" onClick={this.deleteConfirmToggle}>
                Cancelar
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  machine: state.machines.machine,
  matrix: state.matrixs.matrix,
  error: state.machines.error,
  loading: state.machines.loading,
  role: state.auth.role,
  type: state.auth.type,
})

const mapDispatchToProps = dispatch => ({
  fetchMachine: bindActionCreators(fetchMachine, dispatch),
  fetchMatrix: bindActionCreators(fetchMatrix, dispatch),
  sendMatrix: bindActionCreators(sendMatrix, dispatch),
  deleteMatrix: bindActionCreators(deleteMatrix, dispatch),
  fetchModule: bindActionCreators(fetchModule, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MatrixDetailsView)
