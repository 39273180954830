import React from 'react'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'
import Box from '../components/Box'

import { addObjective } from '../actions/objectives'
import { fetchCustomers } from '../actions/customers'

import { renderInputField, renderSelectField } from '../utils/forms'

class ObjectivesCreateForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { loaded: false }

    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    const { fetchCustomers } = this.props

    fetchCustomers()

    this.load(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.load(nextProps)
  }

  load(props) {
    const { customers } = props

    if (!this.state.loaded && customers) {

      this.setState({
        loaded: true
      })
    }
  }

  submit(props) {

    props.TimeStamp = new Date(props.Year)

    return this.props.addObjective(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { loaded } = this.state
    const { error, submitting, handleSubmit, customers, role, pristine } = this.props

    if (!loaded) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <h1>
          <span className="text-vw-dark">OBJETIVOS </span>
          <span className="text-vw-light">NUEVOS</span>
        </h1>
        <form onSubmit={handleSubmit(this.submit)}>
          <div className="row">
            <div className="col col-12 col-lg-12">
              <Box title="Asignación" icon="arrows-alt-h">
                <Field name="Customer"
                  component={renderSelectField}
                  label="Cliente">
                  <option value="">Selecciona un cliente...</option>
                  {customers &&
                    customers
                      .filter((customer) => customer.Type === 'GENERAL')
                      .sort((a, b) => a.Fullname.localeCompare(b.Fullname))
                      .map((customer) => (
                        <option value={customer._id} key={customer._id}>
                          {customer.Fullname}
                        </option>
                      ))}
                </Field>
                <Field name="Year" component={renderSelectField} label="Año">
                  {[...Array(10)].map((_, i) => {
                    const year = new Date().getFullYear() - i;
                    return <option key={year} value={year}>{year}</option>;
                  })}
                </Field>
              </Box>
            </div>
          </div>
          {error && <div className="alert alert-danger">{error}</div>}
          <button className="btn bg-vw-light text-white mr-1 save" type="submit"
            disabled={pristine || submitting}>
            Guardar
          </button>
        </form>
      </div>
    )
  }
}

ObjectivesCreateForm = reduxForm({
  form: 'ObjectivesCreateForm',
  enableReinitialize: true,
  onSubmitSuccess: (result, dispatch, props) => {
    props.push(`/objectives`)
  }
})(ObjectivesCreateForm)

const selector = formValueSelector('ObjectivesCreateForm')

const mapStateToProps = state => ({
  customers: state.customers.customers,
  objectives: state.objectives,
  role: state.auth.role,
  initialValues: {
    Customer: '',
    TimeStamp: new Date().toISOString().substring(0, 10),
    Year: new Date().toISOString().substring(0, 10),
  },
  formValues: {
    Customer: selector(state, 'Customer'),
    TimeStamp: selector(state, 'Year')
  }
})

const mapDispatchToProps = dispatch => ({
  addObjective: bindActionCreators(addObjective, dispatch),
  fetchCustomers: bindActionCreators(fetchCustomers, dispatch),
  push: bindActionCreators(push, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ObjectivesCreateForm)
