import React from 'react'
import { getLockersName } from '../utils/machines'

class AssignedItemList extends React.Component {
  render() {
    return (
      <div className="table-responsive">
        <table className="table table-sm table-stripped">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Código</th>
              <th scope="col">Precio (PVP) €</th>
              <th scope="col">{`Precio (PVP) ${this.props.currency}`}</th>
              <th scope="col">Stock</th>
            </tr>
          </thead>
          <tbody>
            {this.props.items.map(item => (
              <tr key={item.item.code}>
                <th scope="row">{getLockersName(item.item)}</th>
                <td>{`${parseFloat(item.price).toFixed(2)} €`}</td>
                <td>{`${(parseFloat(item.price)/this.props.conversionRate).toFixed(2)} ${this.props.currency}`}</td>
                <td>{item.stock}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default AssignedItemList
