import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchAudits, fetchAuditsCSV } from '../actions/audits'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import PinRecoverList from '../components/PinRecoverList'

import PinRecoverFilterForm from '../forms/PinRecoverFilterForm'

class PinRecoverView extends React.Component {
  constructor(props) {
    super(props)

    this.handleCSV = this.handleCSV.bind(this)
  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10)
    this.props.fetchAudits('', '', 'PIN_RECOVER', today, today)
  }

  handleCSV() {
    const { fetchAuditsCSV, form } = this.props

    fetchAuditsCSV(
      form.AuditFilterForm.values.machine,
      'PIN_RECOVER',
      form.AuditFilterForm.values.StartDate,
      form.AuditFilterForm.values.EndDate
    )
  }

  renderResume() {
    const { audits, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!audits || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    let auditTotalCounts = {
      "PinRecover": 0,
    };

    this.props.audits.forEach(audit => {

      auditTotalCounts['PinRecover'] = (auditTotalCounts['PinRecover'] || 0) + 1;

    });

    return (
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{auditTotalCounts ? auditTotalCounts["PinRecover"] : 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  renderAudits() {
    const { audits, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!audits || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    return (<PinRecoverList audits={audits} />)
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              RECUPERACIÓN DE PINES
            </h1>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-6 align-items-center d-flex justify-content-center">
            <div className="col d-flex justify-content-end">
              <button className="btn bg-vw-excel text-white" onClick={this.handleCSV}>
                <i className="fas fa-file-excel mr-1"></i>Descargar
              </button>
            </div>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <PinRecoverFilterForm />
        </Box>
        <Box title="Resumen" icon="filter">
          {this.renderResume()}
        </Box>
        <Box title="Registros">
          {this.renderAudits()}
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  audits: state.audits.audits,
  error: state.audits.error,
  loading: state.audits.loading,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchAudits: bindActionCreators(fetchAudits, dispatch),
  fetchAuditsCSV: bindActionCreators(fetchAuditsCSV, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(PinRecoverView)


