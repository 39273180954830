import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { history } from '../store'
import Box from '../components/Box'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { fetchMatrixs } from '../actions/matrixs'
import { getLockersName } from '../utils/machines'
import ErrorMessage from '../components/ErrorMessage'
import { postBooking, postPrebooking, postCancelPrebooking, fetchCustomer } from '../actions/qrs'
import { postPDAsale, fetchPDACustomer } from '../actions/pdas'
import StepBar from '../components/StepBar'

class LockerSaleView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      phoneValue: "",
      zoneSelected: 0,
      lockerSelected: "",
      lockerResult: 0,
      lockerType: "",
      lockerPass: "000000",
      machineResult: "XXXX",
      machineFirstIndex: 0,
      lockerAssigned: false,
      prebooking: false,
      lockerPin: "",
      lockerMatricula: 0,
      minutes: 5,
      seconds: 0,
      isTimeUp: false,
      booked: false,
      lockerPrice: 0,
      stepBarStep: 1,
      invoice: "00000000",
      time: "",
      customer: "",
      cif: "",
      address: ""
    };

  }

  componentDidMount() {
    // this.props.fetchMatrixs('', ''),

    this.props.fetchCustomer(this.props.id)
      .then((answer) => {
        this.props.loading = true;

        if(answer.response.Customer){
          this.props.fetchPDACustomer(answer.response.Customer);

          this.props.fetchCustomer(answer.response.Customer)
            .then((answer) => {
  
              this.setState({
                customer: answer.response.Social_name,
                cif: answer.response.Invoice_taxid,
                address: answer.response.Invoice_address
              })
  
            })
            .catch((error) => {
              console.error(error);
            });
        }else{
          this.setState({
            customer: answer.response.Social_name,
            cif: answer.response.Invoice_taxid,
            address: answer.response.Invoice_address
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });

  }

  componentWillUnmount() {
    this.resetProcess();
  }

  handleRedirect = () => {
    this.resetProcess();
  };

  startCountdown = () => {
    this.countdownInterval = setInterval(() => {
      if (this.state.seconds > 0) {
        this.setState((prevState) => ({ seconds: prevState.seconds - 1 }));
      } else {
        if (this.state.minutes > 0) {
          this.setState((prevState) => ({
            minutes: prevState.minutes - 1,
            seconds: 59,
          }));
        } else {
          clearInterval(this.countdownInterval);
          // Here you can add any logic you want to perform after the countdown reaches 0
          this.setState({ isTimeUp: true });
        }
      }
    }, 1000);
  };

  printLockerTicket(e, printIndex) {

    const content = document.getElementById(`${printIndex}`).innerHTML;
    const printWindow = window.open('', '', 'height=400,width=600');
    printWindow.document.write('<html><head><title>PIN TICKET</title>');
    printWindow.document.write('</head><body>');
    printWindow.document.write(content);
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.print();

  }

  renderZones() {
    const { customer, error } = this.props

    const zonesRow = []

    if (error) {
      return (<ErrorMessage message={error.message} />)
    }

    if (!customer) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }

    if (customer.Zones) {
      customer.Zones.map((zone, index) => {
        zonesRow.push(
          <div key={zone.Zone} className={this.state.zoneSelected === zone.Zone ? "cubeselected" : "cube"} onClick={(e) => {
            this.setState({ zoneSelected: zone.Zone, lockerAssigned: false, lockerSelected: "", phoneValue: "", stepBarStep: 2 }),
              this.props.fetchMatrixs('', '', customer._id, zone.Zone)
          }}>
            <h5>{`Zona ${zone.Zone}`}</h5>
            <hr className="bg-vw-light" />
            <h5>{zone.Description}</h5>
          </div>
        );
      })
    } else {
      this.setState({ zoneSelected: 0, lockerAssigned: false, lockerSelected: "", phoneValue: "", stepBarStep: 2 })
      this.props.fetchMatrixs('', '', customer._id, '')
    }

    return zonesRow;
  }

  renderLockers() {
    const { pda, matrixs, error, loading } = this.props;

    if (error) {
      return <ErrorMessage message={error.message} />;
    }

    if (!pda || !matrixs || loading) {
      return (
        <div>
          <div className="spinner"><img src="/images/logo.png" alt="Logo" /></div>
          <h5>Esperando Zona...</h5>
        </div>
      );
    }

    const availableLockers = new Set();

    matrixs.forEach(matrix => {
      matrix.Locker.forEach(lock => {
        if (lock.Configured && (lock.Status === "BL")) {
          availableLockers.add(lock.Mode + lock.Wide + lock.Type);
        }
      });
    });

    const lockersRow = Array.from(availableLockers).map(lockerKey => {
      const lockerName = getLockersName(`LOCKER${lockerKey}`);
      const lockerItem = pda.Items.find(item => item.item === `LOCKER${lockerKey}`);

      return lockerName ? (
        <div key={lockerKey} className={this.state.lockerSelected === lockerKey ? "cubeselected" : "cube"} onClick={() => { this.prebookLocker(lockerKey, lockerItem ? lockerItem.price : 0); }}>
          <h5>{lockerName}</h5>
          <div className="cube-price text-white mb-3">{lockerItem ? lockerItem.price.toFixed(2) + "€" : 'N/A'}</div>
          <div id={`locker${lockerKey}`}>
            <p></p>
            <div id="AV"><p></p></div>
          </div>
        </div>
      ) : null;
    });

    if (lockersRow.length === 0) {
      return <div className="p-5"><h5>Lamentamos informarte que no quedan taquillas disponibles</h5></div>;
    }

    return lockersRow;
  }

  renderPhone() {
    const { minutes, seconds } = this.state;

    return (
      <div className="input-container text-center">
        <p>Hemos reservado una taquilla, introduce un teléfono de respaldo y asigna la taquilla dentro del tiempo marcado</p>
        <input className="full mt-2 mb-2" placeholder="Introduce un teléfono" type="number" value={this.state.phoneValue} onChange={(e) => { this.setState({ phoneValue: e.target.value }) }} />
        <div className="countdownTPV">
          <p>Tiempo para confirmar</p>
          {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
        </div>
      </div>
    )
  }

  prebookLocker(lockerKey, price) {
    const prebooking = {}

    this.setState({ lockerSelected: lockerKey, stepBarStep: 3, lockerPrice: price });

    prebooking.Customer = this.props.customer._id
    prebooking.LockerSelected = lockerKey
    prebooking.Zone = this.state.zoneSelected
    prebooking.Accessibility = false

    this.props.postPrebooking(prebooking)
      .then((answer) => {

        this.setState({
          prebooking: true,
          lockerAssigned: answer.response.answerPrebooking.LockerAssigned,
          lockerPin: answer.response.answerPrebooking.LockerPin,
          lockerMatricula: answer.response.answerPrebooking.LockerMatricula
        })

        this.startCountdown();

      })
      .catch((error) => {
        console.error(error);
      });
  }

  bookLocker() {
    const { pda } = this.props

    let salePetition = {}

    const currentDate = new Date();

    // Get the individual components of the date and time
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    // Create the formatted timestamp
    const timestamp = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    salePetition.TimeStamp = timestamp
    salePetition.Amount = parseInt(this.state.lockerPrice);
    salePetition.Code = pda.Code
    salePetition.PaymentMethod = "PDA"
    salePetition.WorkerSession = "000000"
    salePetition.Language = "ES"
    salePetition.RedsysCode = ""
    salePetition.Locker = this.state.lockerAssigned

    this.props.postPDAsale(salePetition)
      .then((answer) => {

        let bookingPetition = {}

        bookingPetition.LockerAssigned = this.state.lockerAssigned
        bookingPetition.Phone = this.state.phoneValue
        bookingPetition.Origin = "RP"

        this.setState({
          invoice: answer.response.toSaveSale.Code,
          time: `${day}-${month}-${year} ${hours}:${minutes}`
        });

        this.props.postBooking(bookingPetition)
          .then((answer) => {

            clearInterval(this.countdownInterval);

            this.setState({
              booked: true,
              stepBarStep: 4
            });

          })
          .catch((error) => {
            console.error(error);
          });

      })
      .catch((error) => {
        console.error(error);
      });
  }

  resetProcess() {

    if (this.state.lockerAssigned && !this.state.booked) {
      const cancelprebooking = {}

      cancelprebooking.LockerAssigned = this.state.lockerAssigned

      this.props.postCancelPrebooking(cancelprebooking);
    }

    this.setState({
      isTimeUp: false,
      zoneSelected: 0,
      lockerSelected: "",
      lockerAssigned: false,
      prebooking: false,
      stepBarStep: 1,
      booked: false
    })

    clearInterval(this.countdownInterval);
  }

  getBase(price) {
    const taxRate = 0.21;
    const base = price / (1 + taxRate);
    return base;
  }

  getIva(price) {
    const taxRate = 0.21;
    const base = this.getBase(price);
    const iva = price - base;
    return iva;
  }

  render() {
    const { fullname, pda } = this.props
    const { isTimeUp } = this.state;

    return (
      <div className="lockerSaleContainer">
        <StepBar step={this.state.stepBarStep} />
        <br></br>
        <div className="mb-2 mt-2">
          {this.state.zoneSelected === 0 &&
            <div>
              <h2 className="text-vw-dark text-center">
                INDICA LA ZONA
              </h2>
              <div className="row justify-content-center">
                {this.renderZones()}
              </div>
            </div>
          }
          {(this.state.zoneSelected !== 0 && !this.state.lockerSelected) &&
            <div>
              <h2 className="text-vw-dark text-center">
                SELECCIONA UNA TAQUILLA
              </h2>
              {this.state.zoneSelected !== 0 ? (
                <div className="row justify-content-center">
                  {this.renderLockers()}
                </div>
              ) : (
                <div>
                  <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
                  <h5 className="ct">Esperando Zona...</h5>
                </div>
              )}
            </div>
          }
          {(this.state.zoneSelected !== 0 && this.state.lockerSelected && !this.state.booked) &&
            <div>
              <h2 className="text-vw-dark text-center">
                TELÉFONO DE RESPALDO
              </h2>
              {this.state.prebooking ? (
                <div className="row justify-content-center pt-2 pr-5 pl-5">
                  {this.renderPhone()}
                </div>
              ) : (
                <div>
                  <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
                  <h5 className="ct">Esperando Taquilla...</h5>
                </div>
              )}
            </div>
          }
        </div>
        {!this.state.booked ? (
          <div className="lockerSaleButtons">
            <div className="row mb-2 mt-2 justify-content-center">
              <button className="btn bg-warning mr-1 ml-4" disabled={!this.state.lockerAssigned || this.state.phoneValue.length < 6} onClick={() => this.bookLocker()}>
                ASIGNAR
              </button>
              <button className="btn bg-danger text-white ml-4" disabled={!this.state.zoneSelected} onClick={() => this.resetProcess()}>
                REINICIAR
              </button>
            </div>
          </div>
        ) : (
          <div className="lockerSaleButtons">
            <div className="row mb-2 mt-2 justify-content-center">
              <button className="btn bg-success text-white" disabled={!this.state.lockerAssigned || this.state.phoneValue.length < 6} onClick={e => this.printLockerTicket(e, "printableTicket")}>
                IMPRIMIR
              </button>
              <button className="btn bg-danger text-white ml-4" onClick={() => this.resetProcess()}>
                REINICIAR
              </button>
            </div>
          </div>
        )
        }
        {this.state.booked &&
          <div className="col col-12 col-lg-8 ml-auto mr-auto">
            <Box title="Ticket">
              <div className="lockerTicket" id="printableTicket">
                <hr className="bg-vw-light" />
                <h5 className="lockTitle"><span className="text-vw-dark">TAQUILLA</span></h5>
                <h1 className="lockTitle"><span>{this.state.lockerMatricula}</span></h1>
                <h5 className="lockTitle"><span className="text-vw-dark">PIN</span></h5>
                <h1 className="lockTitle"><span>{this.state.lockerPin}</span></h1>
                <h5 className="lockTitle"><span className="text-vw-dark">TIPO</span></h5>
                <h1 className="lockTitle"><span>{getLockersName(`LOCKER${this.state.lockerSelected}`)}</span></h1>
                <hr className="bg-vw-light" />
                <br></br>
                <h5 className="lockTitle"><span>{this.state.customer}</span></h5>
                <h5 className="lockTitle"><span>{this.state.cif}</span></h5>
                <h5 className="lockTitle"><span>{this.state.address}</span></h5>
                <br></br>
                <h5 className="lockTitle"><span className="text-vw-dark">Terminal:</span></h5>
                <h5 className="lockTitle"><span>{pda.Code}</span></h5>
                <h5 className="lockTitle"><span className="text-vw-dark">Factura simplificada:</span></h5>
                <h5 className="lockTitle"><span>{this.state.invoice}</span></h5>
                <h5 className="lockTitle"><span className="text-vw-dark">Fecha/Hora:</span></h5>
                <h5 className="lockTitle"><span>{this.state.time}</span></h5>
                <br></br>
                <h5 className="lockTitle"><span className="text-vw-dark">PRODUCTOS</span></h5>
                <h5 className="lockTitleJustify"><span>1x {getLockersName(`LOCKER${this.state.lockerSelected}`)}</span><span>{this.state.lockerPrice.toFixed(2)}€</span></h5>
                <br></br>
                <h5 className="lockTitleJustify"><span>MONEDA:</span><span>EUR</span></h5>
                <h5 className="lockTitleJustify"><span>BASE:</span><span>{this.getBase(this.state.lockerPrice).toFixed(2)}€</span></h5>
                <h5 className="lockTitleJustify"><span>IVA:</span><span>{this.getIva(this.state.lockerPrice).toFixed(2)}€</span></h5>
                <h5 className="lockTitle"><span>-----------------</span></h5>
                <h5 className="lockTitleJustify"><span>TOTAL:</span><span>{this.state.lockerPrice.toFixed(2)}€</span></h5>
                <br></br>
                <hr className="bg-vw-light" />
              </div>
            </Box>
          </div>
        }
        <div>
          <Modal isOpen={isTimeUp}>
            <ModalHeader>
              Tiempo de reserva agotado
            </ModalHeader>
            <ModalBody>
              Lo sentimos, pero no podemos reservar tu taquilla por más tiempo, otros usuarios deben poder acceder a ella. Vuelve a iniciar el proceso y finaliza la compra en el tiempo estipulado.
            </ModalBody>
            <ModalFooter>
              <Button color="warning" className="button-center"
                onClick={this.handleRedirect}>
                Volver al inicio
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  customer: state.customers.customer,
  matrixs: state.matrixs.matrixs,
  pda: state.pdas.pda,
  error: state.matrixs.error,
  loading: state.matrixs.loading,
  role: state.auth.role,
  fullname: state.auth.fullname,
  id: state.auth.id,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchCustomer: bindActionCreators(fetchCustomer, dispatch),
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch),
  fetchPDACustomer: bindActionCreators(fetchPDACustomer, dispatch),
  postBooking: bindActionCreators(postBooking, dispatch),
  postPrebooking: bindActionCreators(postPrebooking, dispatch),
  postCancelPrebooking: bindActionCreators(postCancelPrebooking, dispatch),
  postPDAsale: bindActionCreators(postPDAsale, dispatch),
  push: bindActionCreators(push, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LockerSaleView)
