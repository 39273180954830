import React from 'react'
import { history } from '../store'
import * as dateFormat from 'dateformat'

class IncidenceResume extends React.Component {

  render() {

    let totalIncidences = 0
    let minor = 0
    let breakdown = 0
    let urgentBreakdown = 0

    this.props.incidences.map(incidence => {

      if (incidence.Status !== "CLOSED") {
        switch (incidence.Category) {
          case "Minor":
            totalIncidences++
            minor++
            break;
          case "Breakdown":
            totalIncidences++
            breakdown++
            break;
          case "UrgentBreakdown":
            totalIncidences++
            urgentBreakdown++
            break;
        }
      }
    })

    return (
      <div className="resumeContainer">
        <div className="resumeItem">
          <h5>Total</h5>
          <h4>{totalIncidences}</h4>
        </div>
        <div className="resumeItem bg-info">
          <h5>Pregunta</h5>
          <h4>{minor}</h4>
        </div>
        <div className="resumeItem bg-warning">
          <h5>Avería</h5>
          <h4>{breakdown}</h4>
        </div>
        <div className="resumeItem bg-danger">
          <h5>Avería Urgente</h5>
          <h4>{urgentBreakdown}</h4>
        </div>
      </div>
    )
  }
}

export default IncidenceResume

