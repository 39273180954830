import {
  CALENDARS_REQUEST, CALENDARS_SUCCESS, CALENDARS_FAILURE,
  CALENDAR_ADD_REQUEST, CALENDAR_ADD_SUCCESS, CALENDAR_ADD_FAILURE,
  CALENDAR_UPDATE_REQUEST, CALENDAR_UPDATE_SUCCESS, CALENDAR_UPDATE_FAILURE,
  CALENDAR_REQUEST, CALENDAR_SUCCESS, CALENDAR_FAILURE,
  CALENDAR_SINGLE_MODIFY_REQUEST, CALENDAR_SINGLE_MODIFY_SUCCESS, CALENDAR_SINGLE_MODIFY_FAILURE,
  CALENDAR_SINGLE_DELETE_REQUEST, CALENDAR_SINGLE_DELETE_SUCCESS, CALENDAR_SINGLE_DELETE_FAILURE
} from '../actions/calendars'

const INITIAL_STATE = {
  calendar: null, calendars: null, error: null, loading: false
}

const calendarsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CALENDARS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CALENDARS_SUCCESS:
      return {
        ...state,
        calendars: action.response, error: null, loading: false
      }
    case CALENDARS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CALENDAR_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CALENDAR_ADD_SUCCESS:
      return {
        ...state,
        calendar: action.response, error: null, loading: false
      }
    case CALENDAR_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CALENDAR_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CALENDAR_UPDATE_SUCCESS:
      return {
        ...state,
        calendar: action.response, error: null, loading: false
      }
    case CALENDAR_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CALENDAR_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CALENDAR_SUCCESS:
      return {
        ...state,
        calendar: action.response, error: null, loading: false
      }
    case CALENDAR_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CALENDAR_SINGLE_MODIFY_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CALENDAR_SINGLE_MODIFY_SUCCESS:
      return {
        ...state,
        calendar: action.response, error: null, loading: false
      }
    case CALENDAR_SINGLE_MODIFY_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case CALENDAR_SINGLE_DELETE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case CALENDAR_SINGLE_DELETE_SUCCESS:
      return {
        ...state,
        calendar: action.response, error: null, loading: false
      }
    case CALENDAR_SINGLE_DELETE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default calendarsReducer


