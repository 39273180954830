import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { fetchOpenings } from '../actions/openings'

import Box from '../components/Box'
import ErrorMessage from '../components/ErrorMessage'
import OpeningsList from '../components/OpeningsList'

import OpeningsFilterForm from '../forms/OpeningsFilterForm'

class OpeningsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    const today = new Date().toISOString().substring(0, 10)
    this.props.fetchOpenings('', '', today, today)
  }

  renderOpenings() {
    const { openings, error, loading } = this.props

    if (error) {
      return (<ErrorMessage message={error.message}/>)
    }

    if (!openings || loading) {
      return (<div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>)
    }
      
    return (<OpeningsList openings={openings}/>)
  }

  render() {

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              APERTURAS
            </h1>
          </div>
        </div>
        <hr className="bg-vw-light" />
        <Box title="Filtro" icon="filter">
          <OpeningsFilterForm/>
        </Box>
        <Box title="Registros">
          { this.renderOpenings() }
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  openings: state.openings.openings,
  error: state.openings.error,
  loading: state.openings.loading,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchOpenings: bindActionCreators(fetchOpenings, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(OpeningsView)


