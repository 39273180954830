// ColorPickerField.js
import React from 'react'
import { SketchPicker } from 'react-color'

// Custom ColorPickerField component to be used with redux-form or as a standalone component
class ColorPickerField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false, // Controls the visibility of the color picker
      color: props.input ? props.input.value : '#ffffff' // Initial color value
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  handleClose() {
    this.setState({ displayColorPicker: false });
  }

  handleChange(color) {
    this.setState({ color: color.hex });
    
    // If used with redux-form, update the field value
    if (this.props.input) {
      this.props.input.onChange(color.hex);
    }

    // If used as a standalone component, trigger the onChange callback if provided
    if (this.props.onChange) {
      this.props.onChange(color.hex);
    }
  }

  render() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    };
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };

    return (
      <div>
        <div
          onClick={this.handleClick}
          style={{
            backgroundColor: this.state.color,
            width: '36px',
            height: '36px',
            border: '1px solid #ccc',
            cursor: 'pointer',
          }}
        />
        {this.state.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <SketchPicker color={this.state.color} onChange={this.handleChange} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorPickerField;
