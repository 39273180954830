import React from 'react';
import { history } from '../store';

class MatrixList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      matrixLockCounts: {}, // To store lock counts for each matrix
      matrixTotalLockCounts: {}
    };
  }

  componentDidMount() {
    this.calculateMatrixLockCounts();
  }

  calculateMatrixLockCounts() {
    const matrixLockCounts = {};
    let matrixTotalLockCounts = {};

    let versionTotalCounts = {
      "total": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "7": 0,
      "8": 0,
      "100": 0,
      "17": 0,
      "0": 0
    };

    this.props.matrixs.forEach(matrix => {
      const versionCounts = {
        "1": 0,
        "2": 0,
        "3": 0,
        "7": 0,
        "8": 0,
        "100": 0,
        "17": 0,
        "0": 0
      };

      matrix.Locker.forEach(lock => {
        const kerongVersion = lock.KerongVersion;
        versionCounts[kerongVersion] = (versionCounts[kerongVersion] || 0) + 1;
        versionTotalCounts[kerongVersion] = (versionTotalCounts[kerongVersion] || 0) + 1;
        versionTotalCounts["total"] = (versionTotalCounts["total"] || 0) + 1;
      });

      matrixLockCounts[matrix.Serial] = versionCounts;

    });

    matrixTotalLockCounts = versionTotalCounts;

    this.setState({ matrixLockCounts, matrixTotalLockCounts });
  }

  goTo(Serial, e) {
    history.push(`/machines/${Serial}`);
  }

  render() {
    const { matrixLockCounts, matrixTotalLockCounts } = this.state;

    return (
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Total Escaneados</th>
              <th scope="col">Total Versión 1</th>
              <th scope="col">Total Versión 2</th>
              <th scope="col">Total Versión 3</th>
              <th scope="col">Total Versión 7</th>
              <th scope="col">Total Versión 8</th>
              <th scope="col">Total Versión 100 (Antiguos)</th>
              <th scope="col">Total Versión 17 (Francisco)</th>
              <th scope="col">Total Versión 0 (Francisco Mala)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["total"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["1"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["2"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["3"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["7"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["8"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["100"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["17"] : 0}</td>
              <td>{matrixTotalLockCounts ? matrixTotalLockCounts["0"] : 0}</td>
            </tr>
          </tbody>
        </table>
        <table className="table table-hover table-clickable">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Máquina</th>
              <th scope="col">Versión 1</th>
              <th scope="col">Versión 2</th>
              <th scope="col">Versión 3</th>
              <th scope="col">Versión 7</th>
              <th scope="col">Versión 8</th>
              <th scope="col">Versión 100 (Antiguos)</th>
              <th scope="col">Versión 17 (Francisco)</th>
              <th scope="col">Versión 0 (Francisco Mala)</th>
            </tr>
          </thead>
          <tbody>
            {this.props.matrixs.map(matrix => (
              <tr key={matrix.Serial} onClick={e => this.goTo(matrix.Serial, e)}>
                <td>{matrix.Serial}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["1"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["2"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["3"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["7"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["8"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["100"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["17"] : 0}</td>
                <td>{matrixLockCounts[matrix.Serial] ? matrixLockCounts[matrix.Serial]["0"] : 0}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default MatrixList;

