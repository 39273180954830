import React from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, change } from 'redux-form'
import { bindActionCreators } from 'redux'
import { SubmissionError } from 'redux-form'

import { addUniversalUpdateEspressif } from '../actions/universalupdates'
import { renderInputField, renderSelectField } from '../utils/forms'

class UniversalUpdateEspressifAddForm extends React.Component {
  constructor(props) {
    super(props)

    this.submit = this.submit.bind(this)
  }

  submit(props) {

    return this.props.addUniversalUpdateEspressif(props)
      .then(response => {
        if (response.error) {
          throw new SubmissionError({
            _error: response.error.message,
            ...response.error.fields
          })
        }

        return response
      })
  }

  render() {
    const { error, pristine, submitting, handleSubmit } = this.props

    return (
      <div>
        <form onSubmit={handleSubmit(this.submit)} className="mb-3">
          <div className="form-row align-items-center flex justify-content-center">
            <div className="col-md-12">
              <Field name="arguments"
                type="text"
                component={renderInputField}
                placeholder="Argumentos" />
            </div>
          </div>
          <div className="form-row align-items-center flex justify-content-center">
            <div className="col-md-12 align-items-center flex justify-content-center">
              <button className="btn bg-vw-light text-white mr-1" type="submit"
                disabled={pristine || submitting}>
                <i className="fas fa-bolt mr-1"></i>Ejecutar
              </button>
            </div>
          </div>
          <div className="form-row">
            {error && <div className="alert alert-danger">{error}</div>}
          </div>
        </form>
      </div>
    )
  }
}

UniversalUpdateEspressifAddForm = reduxForm({
  form: 'UniversalUpdateEspressifAddForm',
})(UniversalUpdateEspressifAddForm)

const mapDispatchToProps = dispatch => ({
  addUniversalUpdateEspressif: bindActionCreators(addUniversalUpdateEspressif, dispatch),
  dispatch,
})

export default connect(() => ({}), mapDispatchToProps)(UniversalUpdateEspressifAddForm)

