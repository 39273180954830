import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Box from '../components/Box'

import { fetchMatrixs } from '../actions/matrixs'
import { fetchMachines } from '../actions/machines'
import ErrorMessage from '../components/ErrorMessage'
import LowBluetoothByCustomerList from '../components/LowBluetoothByCustomerList'
import LowBluetoothByMachineList from '../components/LowBluetoothByMachineList'

class LowBluetoothView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchMatrixs('')
    this.props.fetchMachines()
  }


  identifyLowBluetooth() {

    const { matrixs } = this.props

    if (!matrixs) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    let TOTAL_FB = 0;
    let TOTAL_UNCONFIGURED = 0;
    let TOTAL = 0;

    matrixs.forEach(matrix => {
      {
        matrix.Locker.forEach((lock, index) => {

          if (lock.Configured && lock.Status === 'FB') {
            TOTAL_FB++;
          } else if (!lock.Configured) {
            TOTAL_UNCONFIGURED++;
          }

          TOTAL++;

        })
      }
    })

    return (
      <div className="row p-3 justify-content-center">

        <div className="faultBoxTotal">
          <h5>TOTAL TAQUILLAS: {TOTAL}</h5>
        </div>
        <div className="faultBox">
          <h5>Sin configurar:</h5>
          <h5>{TOTAL_UNCONFIGURED} - {((TOTAL_UNCONFIGURED/TOTAL)*100).toFixed(2)}%</h5>
        </div>
        <div className="faultBox">
          <h5>Low Bluetooth:</h5>
          <h5>{TOTAL_FB} - {((TOTAL_FB/TOTAL)*100).toFixed(2)}%</h5>
        </div>
      </div>
    )
  }

  render() {
    const { matrixs, machines } = this.props

    if (!matrixs || !machines) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    return (
      <div>
        <div className="row mb-2">
          <div className="col-xs-12 col-sm-6 col-md-6">
            <h1 className="text-vw-dark">
              LOW BLE
            </h1>
          </div>
        </div>
        <Box title="Cómputo General" icon="info">
          {this.identifyLowBluetooth()}
        </Box>
        <Box title="Averias por cliente">
          <LowBluetoothByCustomerList matrixs={matrixs} machines={machines} />
        </Box>
        <Box title="Averias por máquina">
          <LowBluetoothByMachineList matrixs={matrixs} machines={machines} />
        </Box>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  matrixs: state.matrixs.matrixs,
  machines: state.machines.machines,
  error: state.matrixs.error,
  loading: state.matrixs.loading,
  role: state.auth.role,
  form: state.form
})

const mapDispatchToProps = dispatch => ({
  fetchMatrixs: bindActionCreators(fetchMatrixs, dispatch),
  fetchMachines: bindActionCreators(fetchMachines, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LowBluetoothView)
