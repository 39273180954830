import {
  RENTALS_REQUEST, RENTALS_SUCCESS, RENTALS_FAILURE,
  RENTAL_ADD_REQUEST, RENTAL_ADD_SUCCESS, RENTAL_ADD_FAILURE,
  RENTAL_UPDATE_REQUEST, RENTAL_UPDATE_SUCCESS, RENTAL_UPDATE_FAILURE,
  RENTAL_REQUEST, RENTAL_SUCCESS, RENTAL_FAILURE
} from '../actions/rentals'

const INITIAL_STATE = {
  rental: null, rentals: null, error: null, loading: false
}

const rentalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RENTALS_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case RENTALS_SUCCESS:
      return {
        ...state,
        rentals: action.response, error: null, loading: false
      }
    case RENTALS_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case RENTAL_ADD_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case RENTAL_ADD_SUCCESS:
      return {
        ...state,
        rental: action.response, error: null, loading: false
      }
    case RENTAL_ADD_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case RENTAL_UPDATE_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case RENTAL_UPDATE_SUCCESS:
      return {
        ...state,
        rental: action.response, error: null, loading: false
      }
    case RENTAL_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    case RENTAL_REQUEST:
      return {
        ...state,
        error: null, loading: true
      }
    case RENTAL_SUCCESS:
      return {
        ...state,
        rental: action.response, error: null, loading: false
      }
    case RENTAL_FAILURE:
      return {
        ...state,
        error: action.error, loading: false
      }
    default:
      return state
  }
}

export default rentalsReducer


