import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { push } from 'react-router-redux'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Box from '../components/Box'
import PropertyLabel from '../components/PropertyLabel'
import AssignedItemList from '../components/AssignedItemList'
import ErrorMessage from '../components/ErrorMessage'

import { fetchReplacement } from '../actions/replacements'

class replacementsView extends React.Component {
  constructor(props) {
    super(props)

  }

  componentDidMount() {
    this.props.fetchReplacement("64cca358874b8124a31919ab")
  }

  render() {
    const { role, type, replacement, error, loading } = this.props

    if (error) {
      return (
        <ErrorMessage message={error.message} />
      )
    }

    if (!replacement || loading) {
      return (
        <div class="spinner"><img src="/images/logo.png" alt="Logo" /></div>
      )
    }

    // Format function for prices with 2 decimal places
    const formatPrice = (price) => price.toFixed(2) + '€';

    return (
      <div>
        <div className="row mb-2">
          <div className="col col-12 col-xs-12 col-sm-6 col-md-6">
            <h1>
              <span className="text-vw-light">REPUESTOS </span>
            </h1>
          </div>
          <div className="col col-12 col-xs-12 col-sm-6 col-md-6 text-right">
            <Link to={`/replacements/${replacement._id}/edit`}>
              <button className="btn bg-vw-light text-white mr-1">
                <i className="fas fa-edit mr-1"></i>Editar
              </button>
            </Link>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-md-3">
            <Box title="Taquilla" icon="sliders-h">
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/CIERRE-CENTRALITA.png" alt="Hopper" />
                <h6 className="replacementTitle">Centralita:</h6>
                <span className="replacementValue">{formatPrice(replacement.LOCK_MAIN.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/CIERRE-TECLADO CON CABLE.png" alt="Hopper" />
                <h6 className="replacementTitle">Teclado:</h6>
                <span className="replacementValue">{formatPrice(replacement.LOCK_PINPAD.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/CIERRE-CIERRE FIJO.png" alt="Hopper" />
                <h6 className="replacementTitle">Cierre fijo:</h6>
                <span className="replacementValue">{formatPrice(replacement.LOCK_ANCHORAGE.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/LOCK_PLATEN.png" alt="Hopper" />
                <h6 className="replacementTitle">Pletina:</h6>
                <span className="replacementValue">{formatPrice(replacement.LOCK_PLATEN.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/BATERÍAS INDUSTRIALES.png" alt="Hopper" />
                <h6 className="replacementTitle">Baterias:</h6>
                <span className="replacementValue">{formatPrice(replacement.BATTERIES.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/BISAGRA.png" alt="Hopper" />
                <h6 className="replacementTitle">Bisagra:</h6>
                <span className="replacementValue">{formatPrice(replacement.HINGE.Price)}</span>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-md-3">
            <Box title="Kiosco" icon="sliders-h">
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/PANTALLA.png" alt="Hopper" />
                <h6 className="replacementTitle">Pantalla:</h6>
                <span className="replacementValue">{formatPrice(replacement.KIOSC_SCREEN.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/SOPORTE ENTRADA MONEDAS.png" alt="Hopper" />
                <h6 className="replacementTitle">Entrada monedas COINCO:</h6>
                <span className="replacementValue">{formatPrice(replacement.KIOSC_COINCO_COIN_ENTRY.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/SOPORTE SALIDA MONEDAS.png" alt="Hopper" />
                <h6 className="replacementTitle">Salida monedas COINCO:</h6>
                <span className="replacementValue">{formatPrice(replacement.KIOSC_COINCO_COIN_EXIT.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/PCB.png" alt="Hopper" />
                <h6 className="replacementTitle">Placa control:</h6>
                <span className="replacementValue">{formatPrice(replacement.KIOSC_PCB.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/ESPRESSIF.png" alt="Hopper" />
                <h6 className="replacementTitle">Espressif:</h6>
                <span className="replacementValue">{formatPrice(replacement.KIOSC_ESPRESSIF.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/HUB.png" alt="Hopper" />
                <h6 className="replacementTitle">Hub USB:</h6>
                <span className="replacementValue">{formatPrice(replacement.KIOSC_HUB.Price)}</span>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-md-3">
            <Box title="Métodos de pago" icon="sliders-h">
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/COINCO.png" alt="Hopper" />
                <h6 className="replacementTitle">Coinco:</h6>
                <span className="replacementValue">{formatPrice(replacement.PAYMENT_COINCO.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/NV9USB.png" alt="Hopper" />
                <h6 className="replacementTitle">NV9USB:</h6>
                <span className="replacementValue">{formatPrice(replacement.PAYMENT_NV9USB.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/SPU90.png" alt="Hopper" />
                <h6 className="replacementTitle">SPu90:</h6>
                <span className="replacementValue">{formatPrice(replacement.PAYMENT_SPU90.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/hopper.png" alt="Hopper" />
                <h6 className="replacementTitle">Hopper:</h6>
                <span className="replacementValue">{formatPrice(replacement.PAYMENT_HOPPER.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/U1000.png" alt="Hopper" />
                <h6 className="replacementTitle">U1000:</h6>
                <span className="replacementValue">{formatPrice(replacement.PAYMENT_U1000.Price)}</span>
              </div>
            </Box>
          </div>
          <div className="col-xs-12 col-md-3">
            <Box title="Impresoras" icon="sliders-h">
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/CASHINO.png" alt="Hopper" />
                <h6 className="replacementTitle">Cashino:</h6>
                <span className="replacementValue">{formatPrice(replacement.PRINTER_CASHINO.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/BROTHER.png" alt="Hopper" />
                <h6 className="replacementTitle">Brother:</h6>
                <span className="replacementValue">{formatPrice(replacement.PRINTER_BROTHER.Price)}</span>
              </div>
            </Box>
            <Box title="Otros" icon="sliders-h">
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/USB_WIRE.png" alt="Hopper" />
                <h6 className="replacementTitle">Cable USB:</h6>
                <span className="replacementValue">{formatPrice(replacement.USB_WIRE.Price)}</span>
              </div>
              <div className="row replacementContainer">
                <img className="replacement mr-3" src="/images/replacements/LOCK_NUMBER.png" alt="Hopper" />
                <h6 className="replacementTitle">Número:</h6>
                <span className="replacementValue">{formatPrice(replacement.LOCK_NUMBER.Price)}</span>
              </div>
            </Box>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-xs-12 col-md-12">
            <Box title="Puertas" icon="sliders-h">
              <div className="row mb-2">
                <div className="col-xs-12 col-md-3">
                  <div className="row replacementContainer">
                    <img className="replacement mr-3" src="/images/replacements/BASICA.png" alt="Hopper" />
                    <h6 className="replacementTitle">Básica:</h6>
                    <span className="replacementValue">{formatPrice(replacement.DOOR_BNS.Price)}</span>
                  </div>
                  <div className="row replacementContainer">
                    <img className="replacement mr-3" src="/images/replacements/BASICA.png" alt="Hopper" />
                    <h6 className="replacementTitle">Básica XL:</h6>
                    <span className="replacementValue">{formatPrice(replacement.DOOR_BWS.Price)}</span>
                  </div>
                </div>
                <div className="col-xs-12 col-md-3">
                  <div className="row replacementContainer">
                    <img className="replacement mr-3" src="/images/replacements/GRANDE.png" alt="Hopper" />
                    <h6 className="replacementTitle">Grande:</h6>
                    <span className="replacementValue">{formatPrice(replacement.DOOR_BNM.Price)}</span>
                  </div>
                  <div className="row replacementContainer">
                    <img className="replacement mr-3" src="/images/replacements/GRANDE.png" alt="Hopper" />
                    <h6 className="replacementTitle">Grande XL:</h6>
                    <span className="replacementValue">{formatPrice(replacement.DOOR_BWM.Price)}</span>
                  </div>
                </div>
                <div className="col-xs-12 col-md-3">
                  <div className="row replacementContainer">
                    <img className="replacement mr-3" src="/images/replacements/GRANDE.png" alt="Hopper" />
                    <h6 className="replacementTitle">Jumbo:</h6>
                    <span className="replacementValue">{formatPrice(replacement.DOOR_BNL.Price)}</span>
                  </div>
                  <div className="row replacementContainer">
                    <img className="replacement mr-3" src="/images/replacements/GRANDE.png" alt="Hopper" />
                    <h6 className="replacementTitle">Jumbo XL:</h6>
                    <span className="replacementValue">{formatPrice(replacement.DOOR_BWL.Price)}</span>
                  </div>
                </div>
              </div>
            </Box>
          </div >
        </div >
      </div >
    )
  }
}

const mapStateToProps = state => ({
  replacement: state.replacements.replacement,
  error: state.replacements.error,
  loading: state.replacements.loading,
  auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
  fetchReplacement: bindActionCreators(fetchReplacement, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(replacementsView)
